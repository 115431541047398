import {XILINX_VENDOR_NAME} from 'logic/constants';
import loadTextFromFile from 'util/loadTextFromFile';
import tryLoadingFromFile from 'util/tryLoadingFromFile';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

export function extractPowerTable(text) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(text, "text/xml");
    const rails = Array.prototype.slice.call(xmlDoc.querySelector('PowerRailGroups').children);

    const outputs = rails.reduce((acc, rail) => {
        if (rail.getAttribute('total_current') === "0") return acc;

        const textSequence = rail.getAttribute('sequence');
        const regexp = /\d+/ig;

        const sequence = regexp.exec(textSequence)[0];

        if (sequence === '') return acc;

        const output = {
            exactName: rail.getAttribute('name'),
            volts: parseFloat(rail.getAttribute('voltage')),
            amps: parseFloat(rail.getAttribute('total_current')) * 1.2,
            onSeq: parseInt(sequence, 10)
        };

        acc.push(output);

        return acc;
    }, []);

    return outputs;
}

export function handleLoadingAsXilinx(text) {
    return {
        name: XILINX_VENDOR_NAME,
        optimized: true,
        outputs: extractPowerTable(text),
        inputs: [{ name: "System Rail 1", vmin: 12, vmax: 12, imax: 20 }],
        templateWarning: getText(contentKeys.XILINX_XML_WARNING)
    };
}

export default function tryLoadingAsXml(fileObj) {
    return tryLoadingFromFile(fileObj, loadTextFromFile, handleLoadingAsXilinx);
}
