import PropTypes from 'prop-types';
import React from 'react';
import databind from 'redux/utils/databind';
import {actions, selectors} from 'redux/modules/startOptions';
import {selectors as projectsSelectors} from 'redux/modules/projects';
import classNames from 'classnames';
import css from './CompactProjectsList.less';
import ProjectStatus from "logic/status";
import {offlineIcon} from 'components/OfflineStatus';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const SimpleList = (props) => {
    const isEmpty = (props.items.length === 0);

    const onClick = (id, disabled, evt) => {
        if (!disabled && !isEmpty) {
            evt.stopPropagation();
            props.onSelect(id);
        }
    };

    let items;
    if (isEmpty) {
        const message = props.isOffline ? getText(contentKeys.ONLINE_PROJECTS_ARE_NOT)
            : getText(contentKeys.YOU_HAVE_NOT_SAVED);
        items = <li className={classNames(css.empty, css.listItem)}>{message}</li>;
    }
    else {
        const unlockedItems = props.items.filter(i => i.status !== ProjectStatus.GENERATING_REFERENCE_DESIGN &&
                                                      i.status !== ProjectStatus.GENERATING_ERROR);

        items = unlockedItems.map(item => {
            const disabled = props.disabled || (item.isLocalProject && !props.isOffline);
            const handlerWithId = onClick.bind(null, item.id, disabled);
            const itemClass = classNames(css.listItem, {
                [css.pointer]: !disabled,
                [css.disabled]: disabled,
                [css.selected]: item.id === props.selectedItemId
            });

            return (<li
                className={itemClass}
                disabled={disabled}
                key={`${item.id}${item.isLocalProject && '-Local'}`}
                onClick={handlerWithId}>
                <div className={css.projectIcon}>{item.isLocalProject && offlineIcon}</div>
                <div className={css.projectName}>{item.name}</div>
            </li>);
        });
    }

    const listClasses = (props.disabled) ? classNames(css.simpleList, css.disabled) : css.simpleList;
    return (<ul className={listClasses}>{items}</ul>);
};

SimpleList.propTypes = {
    disabled: PropTypes.bool,
    items: PropTypes.array,
    selectedItemId: PropTypes.string,
    onSelect: PropTypes.func,
    isOffline: PropTypes.bool
};

const CompactProjectsList = (props) => {
    return (
        <SimpleList
            disabled={props.disabled}
            items={props.projects}
            selectedItemId={props.selectedProjectId}
            onSelect={props.onSelect}
            isOffline={props.isOffline}
        />
    );
};

CompactProjectsList.propTypes = {
    disabled: PropTypes.bool,
    projects: PropTypes.array,
    selectedProjectId: PropTypes.string,
    onSelect: PropTypes.func,
    isOffline: PropTypes.bool
};

CompactProjectsList.databind = ({projects, startOptions, offline}) => {
    return {
        projects: projectsSelectors.projectsListing(projects),
        selectedProjectId: selectors.selectedProjectId(startOptions),
        isOffline: offline.isOffline
    };
};

CompactProjectsList.actions = {
    onSelect: actions.selectSavedProject
};

export default databind(CompactProjectsList);
