import React from 'react';
import MobileDialog from 'components/MobileDialog';
import PanelDropDownList from '../PanelDropDownList';
import mapValuesToTextValuePairs from '../helpers/mapValuesToTextValuePairs';
import { actions } from 'redux/modules/suggestedParts';
import SolutionType from 'logic/SolutionType';
import FiltersMultiSelectDropDownList, { multiSelectWithoutAutoToggleAllLogic }
    from '../FiltersMultiSelectDropDownList';
import { SchematicFilterType, AECQFilterType, SyncFilterType } from 'logic/constants';
import classNames from 'classnames';
import * as filtersText from '../filtersText';
import css from '../../mobilePanelStyles.less';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';
import isEqual from 'lodash/isEqual';
import { defaultFilters } from 'redux/initialStates';
import withState from 'react-powertools/data/withState';
import flowRight from 'lodash/flowRight';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

function MobileFiltersPanel(props) {
    const resetDisabled = isEqual(props.appliedSettings, defaultFilters);
    const resetFilters = () => !resetDisabled && props.onPendingSettingsChanged({ ...defaultFilters });
    const settings = { ...props.appliedSettings, ...props.pendingSettings };

    return (
        <MobileDialog
            onClose={() => {
                props.onPendingSettingsChanged({});
                props.onClose();
            }}
            header={
                <React.Fragment>
                    <span className={classNames('btn-link', css.frontGround, { disabled: resetDisabled })}
                        onClick={resetFilters}>
                        Reset All
                    </span>
                    <div
                        style={{ position: 'absolute', left: 0, right: 0, pointerEvents: 'none' }}
                        className="layout horizontal justify-center">
                        <span className="headline">Filters</span>
                    </div>
                </React.Fragment>
            } content={
                <div className={classNames('layout vertical', css.content)}>
                    <FiltersMultiSelectDropDownList
                        className={css.filterRow}
                        data={mapValuesToTextValuePairs(SolutionType)}
                        title={getText(contentKeys.SOLUTION_TYPES)}
                        info={filtersText.solutionTypeTooltipText}
                        selected={settings.solutionTypes}
                        allSelection={SolutionType.ALL}
                        onChange={solutionTypes =>
                            props.onPendingSettingsChanged({ ...props.pendingSettings, solutionTypes })}
                    />
                    <PanelDropDownList
                        className={css.filterRow}
                        title={getText(contentKeys.AECQ)}
                        info={filtersText.aecqTooltipText}
                        data={mapValuesToTextValuePairs(AECQFilterType)}
                        selected={settings.aecqFilter}
                        onChange={aecqFilter =>
                            props.onPendingSettingsChanged({ ...props.pendingSettings, aecqFilter })}
                    />
                    <PanelDropDownList
                        className={css.filterRow}
                        title={getText(contentKeys.SYNC)}
                        info={filtersText.syncStatus}
                        data={mapValuesToTextValuePairs(SyncFilterType)}
                        selected={settings.syncFilter}
                        onChange={syncFilter =>
                            props.onPendingSettingsChanged({ ...props.pendingSettings, syncFilter })}
                    />
                    <FiltersMultiSelectDropDownList
                        className={css.filterRow}
                        data={mapValuesToTextValuePairs(SchematicFilterType)}
                        info={filtersText.schematicTooltipText}
                        title={getText(contentKeys.OTHER)}
                        selected={settings.schematicFilters}
                        allSelection={SchematicFilterType.ALL}
                        selectionLogic={multiSelectWithoutAutoToggleAllLogic}
                        onChange={schematicFilters =>
                            props.onPendingSettingsChanged({ ...props.pendingSettings, schematicFilters })}
                    />
                </div>
            } footer={
                <Button
                    onClick={() => {
                        props.applyPendingFilterSettings(props.pendingSettings);
                        props.onClose();
                    }} className={css.applyBtn}
                    disabled={isEqual(props.appliedSettings, settings)}>
                    Apply Fitlers
                </Button>
            } />
    );
};

MobileFiltersPanel.propTypes = {
    onClose: PropTypes.func,
    pendingSettings: PropTypes.object.isRequired,
    appliedSettings: PropTypes.object.isRequired,
    onPendingSettingsChanged: PropTypes.func.isRequired
};

const mapStateToProps = ({ suggestedParts }) => {
    const { solutionTypes, schematicFilters, aecqFilter, syncFilter } = suggestedParts;
    return {
        appliedSettings: {
            solutionTypes,
            schematicFilters,
            aecqFilter,
            syncFilter,
        }
    };
};

const withRedux = connect(mapStateToProps, {
    applyPendingFilterSettings: actions.applyPendingFilterSettings
});

const bindData = flowRight([
    withRedux,
    withState({
        pendingSettings: {}
    })
]);

export default bindData(MobileFiltersPanel);
