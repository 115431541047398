import zipObject from 'lodash/zipObject';
import values from 'lodash/values';
import keys from 'lodash/keys';
import {ResultOrderOptions, ResultOrderDirection, PartFieldNames, GridSortDirs} from 'logic/constants';

export const MapOrderOptionToField = {
    [ResultOrderOptions.PRICE]: PartFieldNames.PRICE,
    [ResultOrderOptions.NEWEST_RELEASE_DATE]: PartFieldNames.RELEASE_DATE,
    [ResultOrderOptions.SMALLEST_PACKAGE]: PartFieldNames.PACKAGE_SIZE,
    [ResultOrderOptions.LOWEST_IQ]: PartFieldNames.IQ,
    [ResultOrderOptions.SMALLEST_FOOTPRINT]: PartFieldNames.FOOTPRINT,
    [ResultOrderOptions.LOWEST_BOM_COST]: PartFieldNames.BOM_COST,
    [ResultOrderOptions.LOWEST_BOM_COUNT]: PartFieldNames.BOM_COUNT,
    [ResultOrderOptions.EST_EFFICIENCY]: PartFieldNames.EST_EFFICIENCY,
    [ResultOrderOptions.IOUT_MAX]: PartFieldNames.IOUT_MAX,
    [ResultOrderOptions.DEVICE_NAME]: PartFieldNames.DEVICE_NAME
};

export const MapFieldToOrderOption = zipObject(values(MapOrderOptionToField), keys(MapOrderOptionToField));

export const MapOrderDirectionToDir = {
    [ResultOrderDirection.ASCENDING]: GridSortDirs.ASCENDING,
    [ResultOrderDirection.DESCENDING]: GridSortDirs.DESCENDING
};

export const MapDirToOrderDirection = {
    "asc": ResultOrderDirection.ASCENDING,
    "desc": ResultOrderDirection.DESCENDING
};

export const mapOrderByToSortInfo = function(orderBy) {
    return {
        dir: MapOrderDirectionToDir[orderBy.direction],
        field: MapOrderOptionToField[orderBy.field]
    };
};

export const mapSortInfoToOrderBy = function(sortInfo) {
    return {
        direction: MapDirToOrderDirection[sortInfo.dir],
        field: MapFieldToOrderOption[sortInfo.field]
    };
};

export const getAdditionalSortablePartFieldNames = function() {
    return [
        PartFieldNames.RELEASE_DATE,
        PartFieldNames.PACKAGE_SIZE,
        PartFieldNames.IQ,
        PartFieldNames.FOOTPRINT,
        PartFieldNames.BOM_COST,
        PartFieldNames.BOM_COUNT
    ];
};
