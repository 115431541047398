import maxJnTempTemplate from './maxJnTemp.template';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const columnsTemplate = [
    {
        title: "",
        template: `
            <div class='#if(!isFed){# #= railColor[0] #  #}else{# railGray #}# colorTab'></div>`,
        attributes: {
            class: "colorContainer #: railColor[0] + '-background'# leadingTabColumn"
        },
        headerAttributes: {
            class: "leadingTabColumn"
        }
    },
    {
        field: "sourceRailName",
        title: getText(contentKeys.SOURCE_RAIL_2),
        attributes: {class: "#: railColor[0] + '-background'# sourceRailColumn"},
        template: `<div class="sourceRailNameContainer">
        <span>#: sourceRailName#</span> #if(isFed){#
        <span class="icon icon-rail-feed # for (var i = 0; railColor.length > i; i++) {
        #feed#= railColor[i] # colorTab #} # "></span>#}#<span class="fa collapseControl fa-angle-down"></span></div>`,
        headerAttributes: {
            class: "sourceRailColumn"
        }
    },
    {
        field: "name",
        title: getText(contentKeys.OUTPUT),
        template: `<div class="outputNameContainer">
        #: name # #if(feeds){#
        <span class="icon icon-rail-feed # for (var i = 0; railColor.length > i; i++) {
        #feed#= railColor[i] # colorTab #} # "></span>#}#</div>`,
        attributes: {
            class: "#: railColor[0] + '-background'# outputColumn"
        },
        headerAttributes: {
            class: "outputColumn"
        }
    },
    {
        field: "items",
        title: getText(contentKeys.SELECT_DEVICE),
        editable: true,
        headerAttributes: {
            class: "selectDeviceColumn"
        },
        attributes: {
            class: "#: railColor[0] + '-background'# selectDeviceColumn"
        },
        template: `<span><input class="dropdownlist#:uid#" style="width:100%"/></span>`,
    },
    {
        headerTemplate: `<span>${getText(contentKeys.PMBUS_ADDRESS)}</span>`,
        editable: true,
        headerAttributes: {
            class: "pmAddressColumn"

        },
        attributes: {
            class: "#: railColor[0] + '-background'# pmAddressColumn",
        },
        template: `<span><input class="pmbusAddressDropdownList#:uid#" style="width:100%"/></span>`,
    },
    {
        title: getText(contentKeys.LL_EFF),
        headerAttributes: {
            style: "text-align:right;",
            class: "loadEfficiencyColumn"
        },
        template: "<span class='llEffValue'>#:llEff#</span>", // eslint-disable-line no-useless-escape, max-len
        attributes: {
            class: "#: railColor[0] + '-background'# loadEfficiencyColumn",
            style: "text-align:right"
        }
    },
    {
        title: getText(contentKeys.TL_EFF),
        headerAttributes: {
            style: "text-align:right;",
            class: "loadEfficiencyColumn"
        },
        template: `<span class='tlEffValue'>#:tlEff#</span>`, // eslint-disable-line no-useless-escape, max-len
        attributes: {
            class: "#: railColor[0] + '-background'# loadEfficiencyColumn",
            style: "text-align:right"
        },
    },
    {
        title: getText(contentKeys.ML_EFF),
        headerAttributes: {
            style: "text-align:right;",
            class: "loadEfficiencyColumn"
        },
        attributes: {
            class: "#: railColor[0] + '-background'# loadEfficiencyColumn",
            style: "text-align:right"
        },
        template: `<span class='mlEffValue'>#:mlEff#</span>`, // eslint-disable-line no-useless-escape, max-len
    },
    {
        title: getText(contentKeys.J_MAX),
        headerAttributes: {
            style: "text-align:right;",
            class: "jmaxColumn"
        },
        attributes: {
            class: "#: railColor[0] + '-background'# jmaxColumn",
            style: "text-align:right"
        },
        template: maxJnTempTemplate
    },
    {
        field: "selectedMsrp",
        title: getText(contentKeys.SILICON_COST)+' ($/1k)',
        headerAttributes: {
            "style": "text-align:center;",
            class: "priceColumn"
        },
        attributes: {
            class: "#: railColor[0] + '-background'# priceColumn"
        },
        template: `
            <div class="siliconCost">
                <div class="price">#if(!selectedMsrp) {#&mdash;
                    #} else {# $#: kendo.format("{0:n2}",  selectedMsrp)# #}#</div>
                <div class="costContainer"><div style="width: #: costPercentage#%" class="bar"></div></div>
            </div>`
    },
    {
        headerAttributes: {
            class: "viewDetailsColumn"
        },
        headerTemplate: `<span class="viewDetails">${getText(contentKeys.VIEW_DETAILS)}</span>`,
        attributes: {class: "#: railColor[0] + '-background'# viewDetailsColumn"},
        template: `
            <div class="viewDetails">
                <span class="fa fa-bar-chart"></span>
            </div>`,
    }
];

export default columnsTemplate;
