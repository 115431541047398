/* global kendo, $ */
import PropTypes from 'prop-types';

import React from 'react';
import {CompareAndSelectDataSource} from 'redux/compareAndSelectDataSource';
import {CompareAndSelectDetailReduxConnector} from 'redux/compareAndSelectDetailReduxConnector';
import databind from 'redux/utils/databind';
import columnsTemplate from './compareGridColumnTemplate';
import detailTemplate from './detail.template.js';
import {Row, Col} from 'bootstrap-core';
import KendoGrid from 'components/KendoGrid';
import {actions as compareAndSelectActions} from 'redux/modules/compareAndSelect';
import {createChart} from './helpers/createChart';
import {createLoadWidget} from './helpers/loadWidget';
import lodashFind from 'lodash/find';
import debounce from 'lodash/debounce';
import ChartPool from 'components/CompareAndSelect/helpers/ChartPool';
import {CHAR_MDASH} from 'logic/constants';
import createRowDataForRendering from 'logic/createRowDataForRendering';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

let tempScrollTop = null;

class CompareGrid extends React.Component {
    constructor(props) {
        super(props);
        this.topLevelDataSource = null;
        this.detailReduxConnector = null;
        this.expandedRowIndices = [];
        this.expandedOutputIds = [];
    }

    static propTypes = {
        requested: PropTypes.bool.isRequired,
        received: PropTypes.bool.isRequired,
        error: PropTypes.bool.isRequired,
        errorMessage: PropTypes.any,
        results: PropTypes.array.isRequired,
        updateSelectedParts: PropTypes.func.isRequired,
        updateSelectedPmbusAddress: PropTypes.func.isRequired,
        ambientTemperature: PropTypes.number.isRequired,
        computedData: PropTypes.array.isRequired,
        efficiencies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        selectedPartIds: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        selectedPmbusAddresses: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    };

    static databind ({compareAndSelect}) {
        return {
            results: compareAndSelect.results,
            selectedPartIds: compareAndSelect.selectedPartIds,
            efficiencies: compareAndSelect.efficiencies,
            selectedPmbusAddresses: compareAndSelect.selectedPmbusAddresses,
            requested: compareAndSelect.requested,
            received: compareAndSelect.received,
            error: compareAndSelect.error,
            errorMessage: compareAndSelect.message,
            ambientTemperature: compareAndSelect.ambientTemperature,
            computedData: compareAndSelect.computedData
        };
    }

    static actions = {
        updateSelectedParts: compareAndSelectActions.updateSelectedParts,
        updateSelectedPmbusAddress: compareAndSelectActions.updateSelectedPmbusAddress,
    };

    componentDidUpdate(prevProps) {
        if (this.props.ambientTemperature !== prevProps.ambientTemperature) {
            const rootNode = this.kendoGridRef.getRootNode();
            this.updateJunctionTempChart(rootNode, this.props.computedData);
        }
    }

    updateJunctionTempChart = debounce((rootNode, computedData) => {
        this.props.results.forEach((result, idx) => {
            const row = rootNode.find(`[data-outputid=${result.id}]`);
            if (row.length > 0) {
                const dataForThisOutput = lodashFind(
                    computedData,
                    (x) => x.id === result.id
                );
                const partNames = result.items.map((x) => x.deviceName);
                this.chartPool.updateJunctionTempChart(result.id, row, dataForThisOutput, partNames);
            }
        });
    }, 50);

    componentWillMount() {
        this.topLevelDataSource = new CompareAndSelectDataSource(window.store);
        this.detailReduxConnector = new CompareAndSelectDetailReduxConnector(window.store);
        this.chartPool = new ChartPool(createChart);
    }

    componentDidMount() {
        $(window).on('scroll', (e) => {
            this.verticalPosition = $('body').scrollTop();
        });

        // $('#compareGrid table').stickyTableHeaders();
    }

    componentWillUnmount() {
        this.topLevelDataSource.disconnect();
        $(window).off('scroll');

        // $('#compareGrid table').stickyTableHeaders('destroy');
    }

    renderDetails = (e) => {
        const output = e.data;
        const state = window.store.getState();

        // this function is growing, holding off refactoring until we have our expand/collapse and
        // refresh behaviour undercontrol
        const partNames = output.items.map((x) => x.deviceName);
        const dataForThisOutput = lodashFind(state.compareAndSelect.computedData, (x) => x.id === output.id);
        const selectedPartId = state.compareAndSelect.selectedPartIds[output.id];
        const dataForSelectedPart = lodashFind(dataForThisOutput.parts, (part) => part.id === selectedPartId);

        const models = this.detailReduxConnector.createViewModels(output);

        createLoadWidget(e.detailRow.find('.lightLoadSection'), models.lightLoad, getText(contentKeys.LIGHT_LOAD));
        createLoadWidget(e.detailRow.find('.typicalLoadSection'),
            models.typicalLoad,
            getText(contentKeys.TYPICAL_LOAD));
        createLoadWidget(e.detailRow.find('.maxLoadSection'), models.maxLoad, getText(contentKeys.MAX_LOAD));

        const inputVoltsLabel = (output.vinMin === output.vinMax) ? `${output.vinMax}V`
            : `${output.vinMin}V to ${output.vinMax}V`;

        e.detailRow.find('.inputVoltsLabel').text(inputVoltsLabel);
        e.detailRow.find('.outputVoltsAmpsLabel').text(output.nameDecoration);

        if ((dataForSelectedPart.referenceCurves.efficiency === null) ||
            (dataForSelectedPart.referenceCurves.efficiency.fsw === null)) {
            e.detailRow.find('.fswLabel').text(CHAR_MDASH);
            e.detailRow.find('.modeLabel').text(CHAR_MDASH);
        }
        else {
            const fsw = dataForSelectedPart.referenceCurves.efficiency.fsw;
            const mode = dataForSelectedPart.referenceCurves.efficiency.mode;
            e.detailRow.find('.fswLabel').text(kendo.toString(fsw, 'n0'));
            e.detailRow.find('.modeLabel').text(mode);
        }

        e.detailRow.attr('data-outputid', dataForThisOutput.id);
        this.chartPool.createOrAttachCharts(output.id, e.detailRow, dataForThisOutput, partNames);

        // reset min height that stops page jumps on select part
        $('#compareGrid > table').css('min-height', '');

        e.detailRow.find('.loading').fadeOut(500);
    }

    detailInit = (e) => {
        const output = e.data;
        const idx = this.expandedOutputIds.indexOf(output.id);
        if (idx === -1) {
            this.expandedOutputIds.push(output.id);
            e.detailRow.find('.loading').show();
            setTimeout(() => this.renderDetails(e), 50);
        }
        else {
            this.renderDetails(e);
        }
    };

    expand = () => {
        this.setState({expand: true, collapse: false});
    };

    collapse = () => {
        this.setState({collapse: true, expand: false, individualRow: false});
    };

    updateExpandedRows = (payload) => {
        const {change} = payload;
        if (change.expanded) {
            this.expandedRowIndices.push(change.rowIndex);
        }
        else {
            this.expandedRowIndices = this.expandedRowIndices.filter((rowIndex) => rowIndex !== change.rowIndex);
        }
    };

    appendPmbusAddressDropdown($el, outputRail) {
        const updateSelectedPmbusAddress = this.props.updateSelectedPmbusAddress;
        const outputId = outputRail.id;
        const partId = outputRail.selectedId;
        if (outputRail.pmbusAddressList.length === 0) {
            $el.hide();
        }
        else {
            // this dropdown does not bind its value to the outputRail as we want this to be
            // uncontrolled. We'll dispatch an action on change and but not expect a re-render
            $el.kendoDropDownList({
                autoBind: true,
                dataSource: outputRail.pmbusAddressList,
                enable: true,
                change: function() {
                    updateSelectedPmbusAddress({ outputId, partId, pmbusAddress: this.value() });
                },
                animation: false
            });

            $el.data('kendoDropDownList').value(outputRail.selectedPmbusAddress);

            // Dropdown clicks should not cause the rows to expand or collapse
            $el.closest('span').on('click', function(e) {
                e.stopPropagation();
            });
        }
    }

    appendDeviceNameDropdown($el, outputRail) {
        const updateSelectedParts = this.props.updateSelectedParts;
        const outputId = outputRail.id;
        const component = this;
        // disable expand/collapse when there are no parts
        if (outputRail.items.length === 0) {
            $el.closest('.k-master-row').addClass("emptyRail");
            $el.replaceWith('<span class="accentColor singlePartDeviceName"> No Solutions</span>');
        }
        else if (outputRail.items.length === 1) {
            $el.replaceWith('<div class="singlePartDeviceName">'+outputRail.items[0].deviceName+'</div>');
        }
        else {
            $el.kendoDropDownList({
                autoBind: true,
                dataTextField: "deviceName",
                dataValueField: "id",
                dataSource: outputRail.items,
                enable: outputRail.items.length > 1,
                value: outputRail.selectedId,
                change: function() {
                    tempScrollTop = $(window).scrollTop();
                    const value = this.value();

                    // detach the charts from this detail view (if any) to avoid their event handlers being stripped
                    component.chartPool.detach(outputId);

                    // note Intersil part id's are integers but kendo gives us back a string
                    updateSelectedParts({ outputId, partId: parseInt(value) });
                },
                animation: false
            });

            // Dropdown clicks should not cause the rows to expand or collapse
            const containingSpan = $el.closest('span');
            containingSpan.on('click', function(e) {
                e.stopPropagation();
            });

            if (outputRail.items.length === 0) {
                const bg = containingSpan.closest('span').css("background-color");
                $($el).closest('.k-dropdown').css("background-color", bg);
            }
        }
    }

    dataBound = (e) => {
        const outputRails = e.sender.dataSource.view();
        for (var i = 0; i < outputRails.length; i++) {
            const outputRail = outputRails[i];
            this.appendPmbusAddressDropdown($(".pmbusAddressDropdownList"+outputRails[i].uid), outputRail);
            this.appendDeviceNameDropdown($(".dropdownlist"+outputRails[i].uid), outputRail);
        }

        this.expandedRowIndices.forEach((rowIndex) => {
            const $row = e.sender.element.find('.k-master-row:eq('+rowIndex+')');
            $row.find('.fa.collapseControl').removeClass('fa-angle-down').addClass('fa-angle-up');
            $row.addClass('tagExpanded');
            e.sender.expandRow($row);
        });

        if (tempScrollTop !== null) {
            $(window).scrollTop(tempScrollTop);
        }
    };

    getKendoGridRef = (inst) => {
        this.kendoGridRef = inst;
    };

    render() {
        const options = {
            columns: columnsTemplate,
            scrollable: false,
            detailTemplate: kendo.template(detailTemplate),
            dataBound: this.dataBound,
            dataSource: this.topLevelDataSource
        };

        const totalCostReducer = (sum, output) => sum + (output.selectedMsrp || 0);
        const totalCost = createRowDataForRendering(
            this.props.results,
            this.props.computedData,
            this.props.efficiencies,
            this.props.selectedPartIds,
            this.props.selectedPmbusAddresses
        )
            .reduce(totalCostReducer, 0);

        return (
            <div className={"gridContainer"}>
                <Row className="gridContent">
                    <Col className="topGrid">
                        <KendoGrid
                            ref={this.getKendoGridRef}
                            id="compareGrid"
                            options={options}
                            detailInit={this.detailInit}
                            expandAll={false}
                            collapseAll={false}
                            updateExpandedRows={this.updateExpandedRows} />
                    </Col>
                    <div className="layout horizontal space-between align-center grid-footer">
                        <span className="feedFooter layout horizontal align-center">
                            <span className="icon icon-rail-feed"></span>
                            <p className="feedText">{getText(contentKeys.FEEDS_SECOND_REGULATOR)}</p>
                        </span>
                        <span className="totalCost">
                            {getText(contentKeys.TOTAL_COST)}: ${kendo.toString(totalCost, 'N')}
                        </span>
                    </div>
                </Row>
            </div>
        );
    }
}

export default databind(CompareGrid);
