/**
 * Created by bwallace on 4/27/2016.
 */
import isObject from 'lodash/isObject';
/**
 * A better version of lodash memoize
 * https://lodash.com/docs#memoize
 * This version does a better job of garbage collecting old values
 * @param func the function to be memoized
 * @returns {Function}
 */
export default function memoize(func) {
    // used to map scalar argument values to objects we can use as a key in WeakMap.
    const root = {
        scalars: new Map(),
        objects: new WeakMap(),
    };

    return function (...args) {
        // walk the arguments and retrieve the nested maps until we get to the final argument,
        // whose map will hold the results
        const stop = args.length - 1;
        let current = root;
        for (let i = 0; i < stop; ++i) {
            const arg = args[i];
            // use the WeakMap if the argument is an object.
            const map = isObject(arg) ? current.objects : current.scalars;
            if (map.has(arg)) {
                current = map.get(arg);
            }
            else {
                // we have not seen this argument before.  Create a new container
                // for it, and store it in the map before returning it.
                current = {
                    scalars: new Map(),
                    objects: new WeakMap(),
                };
                map.set(arg, current);
            }
        }

        // Now check the final map and return the answer if it is available
        const finalArg = args[stop]; // will be undefined if there are no args.  that is OK
        const finalMap = isObject(finalArg) ? current.objects : current.scalars;
        if (finalMap.has(finalArg)) {
            // we have a memoized answer
            return finalMap.get(finalArg);
        }

        // we do not have a memoized answer.  Get it
        const answer = func(...args);
        finalMap.set(finalArg, answer);
        return answer;
    };
}
