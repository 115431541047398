import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import Kendo from 'kendo-ui-react';
import xor from 'lodash/xor';
import KendoTooltipWrapper from 'kendo-plugins/TooltipWrapper';
import classNames from 'classnames';

const isSelectionAllTypes = (selection, allSelection) => {
    return selection && selection.length === 1 && selection[0] === allSelection;
};

export const multiSelectWithoutAutoToggleAllLogic = (values, selection, allSelection, data) => {
    // if ALL is any of the selections
    if (selection === allSelection) {
        return [allSelection];
    }

    if ((values.length === 1) && (selection === values[0])) {
        return values;
    }

    const withoutAll = values.filter(v => v !== allSelection);
    return xor([selection], withoutAll);
};

export const multiSelectWithAutoToggleAllLogic = (values, selection, allSelection, data) => {
    const updated = multiSelectWithoutAutoToggleAllLogic(values, selection, allSelection, data);
    return (updated.length === data.length - 1) ? [allSelection] : updated;
};

class FiltersMultiSelectDropDownList extends React.Component {
    componentDidMount() {
        KendoTooltipWrapper($(this.refs.infoIcon), this.props.info);
    }

    shouldComponentUpdate(nextProps) {
        return !isSelectionAllTypes(this.props.selected, this.props.allSelection) &&
            isSelectionAllTypes(nextProps.selected, this.props.allSelection);
    }

    static defaultProps = {
        selectionLogic: multiSelectWithAutoToggleAllLogic
    }

    render() {
        const component = this;
        const { allSelection, data, selected, selectionLogic, title, className } = this.props;

        const options = {
            dataTextField: "text",
            dataValueField: "value",
            dataSource: data,
            value: selected,
            onChange: function (value, widget) {
                if (isSelectionAllTypes(value)) {
                    widget.close();
                }
                return component.props.onChange(value);
            }
        };

        options.selectionLogic = (values, selection) => {
            return selectionLogic(values, selection, allSelection, data);
        };

        options.popupClass = classNames(css.multiSelectListPopup, className);

        return (
            <div className={classNames(css.panelItem, className)}>
                <span className={css.title}>{title}
                    <span>&nbsp;<i ref="infoIcon" className="fa fa-info-circle"></i></span>
                </span>
                <Kendo.MultiSelectDropDownList
                    className={css.panelMultiSelect}
                    options={options} />
            </div>);
    }
}

FiltersMultiSelectDropDownList.propTypes = {
    title: PropTypes.string,
    info: PropTypes.string,
    data: PropTypes.array.isRequired,
    selected: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    allSelection: PropTypes.string.isRequired,
    selectionLogic: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default FiltersMultiSelectDropDownList;
