import PropTypes from 'prop-types';
import React from 'react';
import ModalWindow from 'components/ModalWindow';
import ModalContent from 'components/ModalContent';
import css from './styles.less';
import Error from 'components/Error';
import {isAValidEmail} from 'logic/validation';
import {Phone, TabletUp} from 'styles/variables/breakpoints';
import PageSlider from 'components/PageSlider';
import mobilePanelStyles from 'components/mobilePanelStyles.less';
import MobileDialog from 'components/MobileDialog';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class EmailZipModalContent extends React.Component {
    static propTypes = {
        emailZipModalShown: PropTypes.bool.isRequired,
        closeEmailZipModal: PropTypes.func.isRequired,
        emailError: PropTypes.bool.isRequired,
        sendingEmail: PropTypes.bool.isRequired,
        emailZipFile: PropTypes.func.isRequired,
        sendProject: PropTypes.func.isRequired,
        designId: PropTypes.string.isRequired,
        projectId: PropTypes.string.isRequired,
        projects: PropTypes.array.isRequired
    };

    constructor() {
        super();
        this.state = {
            valid: false,
            email: '',
            dirty: false,
            sendChecked: false
        };
    }

    onChangeEmail = (e) => {
        const {value} = e.target;
        const valid = this.state.valid || isAValidEmail(value);
        this.setState({ email: value, valid });
    };

    onBlurEmail = (e) => {
        const {value} = e.target;
        const valid = isAValidEmail(value);
        this.setState({ valid, email: value, dirty: true });
    };

    onClickSendEmail = async () => {
        await this.props.emailZipFile(this.state.email, this.props.designId);

        if (this.state.sendChecked) {
            await this.props.sendProject(this.props.projectId, this.state.email);
        }

        this.onSendEmailComplete();
    };

    onSendEmailComplete = () => {
        // Give the user a chance to see the email error rather than closing automatically
        if (this.props.emailError) return;

        this.props.closeEmailZipModal();
    };

    toggleSend = () => {
        this.setState({
            sendChecked: !this.state.sendChecked
        });
    };

    sendCheckbox = () => {
        const sendChecked = this.state.sendChecked && 'checked';

        return (
            <div className={css.sendCheckbox} onClick={this.toggleSend} >
                <input type="checkbox"
                    name="sendCheckbox"
                    className="checkbox k-checkbox"
                    checked={sendChecked} />
                <label htmlFor="sendCheckbox" className="k-checkbox-label" />
                <span className={css.checkboxLabel}>{getText(contentKeys.SEND_THE_PROJECT)}</span>
            </div>
        );
    };

    render() {
        const {sendingEmail, emailError, projects, projectId} = this.props;
        const userOwnsProject = !!projectId && !!projects &&
                                projects.some(p => p.id === projectId);

        const invalid = (!this.state.valid && this.state.dirty);
        const invalidClass = invalid ? css.inputInvalid : "";

        return (
            <ModalContent
                loading={sendingEmail}
                onCancel={this.props.closeEmailZipModal}
                onSubmit={this.onClickSendEmail}
                submitLabel={getText(contentKeys.SEND_2)}
                canSubmit={this.state.valid} >
                <div>
                    <p>
                        <span dangerouslySetInnerHTML={{__html: getText(contentKeys.PLEASE_SPECIFY_AN_EMAIL)}}></span>
                        {userOwnsProject && <span dangerouslySetInnerHTML={{__html:
                            getText(contentKeys.YOU_COULD_ALSO_CHOOSE)}}>
                        </span>}
                    </p>
                    <span className={css.emailLabel}>{getText(contentKeys.EMAIL_ADDRESS)}</span>
                    <input
                        disabled={sendingEmail}
                        value={this.state.email}
                        className={`form-control ${css.emailInput} ${invalidClass}`}
                        onChange={this.onChangeEmail}
                        onBlur={this.onBlurEmail} />
                    {invalid && <Error className={css.emailError}>{getText(contentKeys.INVALID_EMAIL_ADDRESS)}</Error>}
                    {emailError && <Error className={css.emailError}>
                        {getText(contentKeys.ERROR_EMAILING_ZIP_FILE)}</Error>}
                    {userOwnsProject && this.sendCheckbox()}
                </div>
            </ModalContent>
        );
    }
}

const EmailZipModal = (props) => {
    const content = <EmailZipModalContent {...props} />;
    const tabletUpContent = (
        <ModalWindow
            actions={["Close"]}
            close={props.closeEmailZipModal}
            open={props.emailZipModalShown}
            width={600}
            height={320}
            center={true}
            title={getText(contentKeys.EMAIL_ZIP_FILE)}>
            {content}
        </ModalWindow>
    );

    const mobileContent = (
        <MobileDialog
            onClose={props.closeEmailZipModal}
            header={<span className="headline">{getText(contentKeys.EMAIL_ZIP_FILE)}</span>} content={
                <div className={mobilePanelStyles.content}>
                    {content}
                </div>
            } footer={null} />
    );

    return (
        <React.Fragment>
            <TabletUp>
                {props.emailZipModalShown ? tabletUpContent : null}
            </TabletUp>
            <Phone>
                <PageSlider showAuxPage={props.emailZipModalShown}
                    content={mobileContent} />
            </Phone>
        </React.Fragment>
    );
};

EmailZipModal.propTypes = {
    closeEmailZipModal: PropTypes.func.isRequired,
    emailZipModalShown: PropTypes.bool
};

export default EmailZipModal;
