import PropTypes from 'prop-types';
import React from 'react';
import databind from 'redux/utils/databind';
import Button from 'react-bootstrap/lib/Button';
import classNames from "classnames";
import {actions as editorActions} from 'redux/modules/editor';
import css from './styles.less';
import KendoTooltipOverride from 'kendo-plugins/TooltipWrapper';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class OptimizeButton extends React.Component {
    static propTypes = {
        optimize: PropTypes.func.isRequired,
        undoOptimization: PropTypes.func.isRequired,
        isOptimized: PropTypes.bool.isRequired,
        className: PropTypes.string,
        preOptimized: PropTypes.object
    };

    static databind = ({editor}) => {
        return {
            outputs: editor.outputs,
            isOptimized: editor.optimized,
            preOptimized: editor.preOptimized
        };
    };

    static actions = {
        optimize: editorActions.optimizedOutputs,
        undoOptimization: editorActions.undoOptimization
    };

    constructor (props) {
        super(props);

        this.tooltipEl = null;
    }

    componentDidMount() {
        KendoTooltipOverride($(this.tooltipEl),
            getText(contentKeys.OPTIMIZE_TO_GROUP_AND),
            null,
            'bottom');
    }

    mapTooltipRef = (el) => {
        this.tooltipEl = el;
    };

    render() {
        const { isOptimized, optimize, undoOptimization, preOptimized } = this.props;

        return (
            <div className={classNames(this.props.className, css.optimizeBtns)}>
                {isOptimized && !!preOptimized && <Button className={css.undoBtn} bsStyle="link" onClick={undoOptimization}>
                    {getText(contentKeys.UNDO)}
                </Button>}
                <Button className={css.optimizeBtn} bsStyle="link" onClick={optimize}
                    disabled={isOptimized}>{getText(contentKeys.OPTIMIZE)}</Button>
                <span ref={this.mapTooltipRef} className="fa fa-info-circle" />
            </div>
        );
    };
}

export default databind(OptimizeButton);
