import {makeAction, createReducer} from '../utils/redux-helpers';
import {NotificationType} from 'logic/constants';

function prefix (type) { return `notification/${type}`; }

let ID_COUNTER = 1;

export const actionTypes = {
    ADD_NOTIFICATION: prefix('ADD_NOTIFICATION'),
    DISMISS_NOTIFICATION: prefix('DISMISS_NOTIFICATION')
};

const createNotification = (dispatch, type, msg, autoDismiss, link) => {
    const id = ID_COUNTER++;
    if (autoDismiss) {
        setTimeout(() => dispatch(actions.dismiss(id)), 5000);
    }
    return dispatch(makeAction(actionTypes.ADD_NOTIFICATION, {type, id, msg: msg.toString(), link}));
};

export const actions = {
    showError: (msg, autoDismiss = false, link) => (dispatch) => {
        return createNotification(dispatch, NotificationType.ERROR, msg, autoDismiss, link);
    },
    showSuccess: (msg, autoDismiss = true, link) => (dispatch) => {
        return createNotification(dispatch, NotificationType.SUCCESS, msg, autoDismiss, link);
    },
    dismiss: (id) => makeAction(actionTypes.DISMISS_NOTIFICATION, id)
};

const ACTION_HANDLERS = {
    [actionTypes.ADD_NOTIFICATION]: (state, {payload}) => {
        return { ...state, notifications: [...state.notifications, payload] };
    },
    [actionTypes.DISMISS_NOTIFICATION]: (state, {payload}) => {
        return {...state, notifications: state.notifications.filter((n) => n.id !== payload)};
    }
};

const initialState = {
    notifications: []
};

export default createReducer(initialState, ACTION_HANDLERS);
