import _ from 'lodash';

export default function areNOutputPartSelectionsValid(outputs, selectedPartIds) {
    let valid = true;
    let errorCases = [];

    const outputsById = _.keyBy(outputs, "id");

    outputs.forEach(function(output) {
        if (output.items.length > 0) {
            const selected = selectedPartIds[output.id];
            const part = _.find(output.items, (part) => part.id === selected);

            if (part.numOutputs > 1) {
                const sameSelectedPartForAllOutputs = part.idsOfSuggestedOutputTuple.every(id => {
                    return selectedPartIds[id] === part.id;
                });

                if (!sameSelectedPartForAllOutputs) {
                    valid = false;

                    const outputNames = part.idsOfSuggestedOutputTuple.map(id => {
                        return outputsById[id].name;
                    });

                    errorCases.push({ outputNames });
                }

                // idsOfSuggestedOutputTuple always has 2 entries
                // const otherOutputId = part.idsOfSuggestedOutputTuple.filter((id) => id !== output.id)[0];
                // const otherOutputIdx = _.findIndex(outputs, (o) => o.id === otherOutputId);
                // const otherPartId = selectedPartIds[otherOutputId];
                // if (part.id !== otherPartId) {
                //     valid = false;
                //     const otherOutput = _.find(outputs, (o) => o.id === otherOutputId);
                //     let outputNames = [output.name, otherOutput.name];
                //     if (otherOutputIdx < idx) {
                //         outputNames = outputNames.reverse();
                //     }
                //     errorCases.push({ outputNames });
                // }
            }
        }
    });

    const info = _.uniqBy(errorCases, (a) => a.outputNames[0] + a.outputNames[1]);

    return { valid, info };
}
