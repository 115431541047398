import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import classNames from 'classnames';
import imageUrl from 'util/imageUrl';
import KendoTooltipOverride from 'kendo-plugins/TooltipWrapper';
import getText, { languageFromUrl } from 'util/translations';
import contentKeys from 'translations/contentKeys';

const noOp = () => {};

class StartNewProject extends React.Component {
    static propTypes = {
        selected: PropTypes.bool.isRequired,
        onSelect: PropTypes.func.isRequired
    };

    componentDidMount() {
        KendoTooltipOverride($('#startNewTooltip'),
            getText(contentKeys.YOU_WILL_BE_ASKED),
            null,
            'bottom');
    }

    handleClick = () => {
        this.props.onSelect();
        document.getElementById('StartSelect_ContinueBtn').scrollIntoView();
    }

    render() {
        const checked = (this.props.selected) ? "checked" : "";
        const cursorPointer = (!this.props.selected) ? css.cursorPointer : "";
        const className = classNames(css.startNewProject, cursorPointer);

        const imgUrl = ((window["devicePixelRatio"]) && (window.devicePixelRatio === 1))
            ? imageUrl(`editor_${languageFromUrl}@1x.png`) : imageUrl(`editor_${languageFromUrl}@2x.png`);
        return (
            <div className={className} onClick={this.handleClick} id="startNewProject">
                <input type="radio"
                    name="startOptions"
                    className="k-radio"
                    value="startNewProject"
                    checked={checked}
                    onChange={noOp}
                />
                <label className="k-radio-label">
                    &nbsp;&nbsp;{getText(contentKeys.START_NEW_PROJECT)}{' '}
                    <span id="startNewTooltip" className="fa fa-info-circle" />
                </label>
                <img src={imgUrl} alt="Example of the MultiLoad Configurator" />
            </div>
        );
    }
}

export default StartNewProject;
