/*
* appends a new route segment to the url, preserving the query
* */

export default newUrlSegment => {
    const baseAndQuery = location.href.split('?');
    const base = baseAndQuery[0];
    const queryParams = '?' + baseAndQuery[1] || '';

    return window.encodeURIComponent(`${base}/${newUrlSegment}${queryParams}`);
};
