import PropTypes from 'prop-types';
import React from 'react';
import Kendo from 'kendo-ui-react';
import databind from 'redux/utils/databind';
import {actions as startOptionsActions, selectors} from 'redux/modules/startOptions';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const PlatformList = function(props) {
    const onSelectComponent = function() {
        props.selectPlatform({ platform: this.value() });
    };

    const enable = props.useTemplate && props.enablePlatformSelection;

    return (<Kendo.DropDownList
        options={{
            dataSource: props.platformsForSelectedVendor,
            value: props.selectedPlatform,
            change: onSelectComponent,
            optionLabel: getText(contentKeys.SELECT_A_PLATFORM),
            enable,
            filter: 'contains',
            open: function (e) {
                e.sender.optionLabel.hide();
            },
            select: function (e) {
                document.getElementById('selectProjectTemplate').scrollIntoView();
            }
        }}
    />);
};

PlatformList.propTypes = {
    platformsForSelectedVendor: PropTypes.array.isRequired,
    selectedPlatform: PropTypes.string.isRequired,
    useTemplate: PropTypes.bool.isRequired,
    enablePlatformSelection: PropTypes.bool.isRequired,
    selectPlatform: PropTypes.func.isRequired
};

PlatformList.databind = function({startOptions}) {
    const { selectedPlatform,
        platformsForSelectedVendor,
        enablePlatformSelection } = startOptions;
    return { selectedPlatform,
        platformsForSelectedVendor,
        useTemplate: selectors.useTemplate(startOptions),
        enablePlatformSelection };
};

PlatformList.actions = {
    selectPlatform: startOptionsActions.onSelectPlatform
};

export default databind(PlatformList);
