import PropTypes from 'prop-types';
import React from 'react';
import DocumentTitle from 'components/DocumentTitle';
import ProjectNameFormContainer from 'components/ReferenceDesign/ProjectNameFormContainer';
import databind from 'redux/utils/databind';
import {actions as projectActions, setPendingAction as setPendingProjectsAction} from 'redux/modules/projects';
import makeSignInUrl from "util/makeSignInUrl";
import makeRegisterUrl, {REGISTER_PAGE} from "util/makeRegisterUrl";
import makeAppUrl from "util/makeAppUrl";
import {getPathnameFromStatus} from "logic/status";
import {forcePersist} from "redux/persist";
import getText from 'util/translations';
import contentKeys from '../../translations/contentKeys';

class ProjectNameSaveView extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        currentStatus: PropTypes.string.isRequired,

        // actions
        saveNewProject: PropTypes.func.isRequired,
        storeNewProjectName: PropTypes.func.isRequired
    };

    static databind({projects}) {
        return {
            currentStatus: (projects.current && projects.current.status) ? projects.current.status : ''
        };
    }

    static actions = {
        saveNewProject: projectActions.saveNewProject,
        storeNewProjectName: projectActions.setCurrentProjectName
    };

    onContinue = (name) => {
        this.props.saveNewProject(name);
    };

    onClickAuthButton = (page, name) => {
        const path = getPathnameFromStatus(this.props.currentStatus);

        this.props.storeNewProjectName(name);
        setPendingProjectsAction({ actionKey: 'saveNewProject', args: [name] });

        forcePersist(() => {
            const returnUrl = makeAppUrl(path);
            window.location = (page === REGISTER_PAGE)
                ? makeRegisterUrl(returnUrl) : makeSignInUrl(returnUrl);
        });
    };

    render() {
        const title = getText(contentKeys.SAVE_THIS_PROJECT);

        return (
            <DocumentTitle title="Save Project">
                {this.props.children ||
                    <ProjectNameFormContainer
                        title={title}
                        onClickAuthButton={this.onClickAuthButton}
                        onContinue={this.onContinue} />}
            </DocumentTitle>
        );
    }
}

export default databind(ProjectNameSaveView);
