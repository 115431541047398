import PropTypes from 'prop-types';
import React from 'react';
import SystemBannerLineChart from './SystemBannerLineChart';
import {AutoResizeContainer} from 'components/withElementSize';
import classNames from 'classnames';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class SystemEfficiencyChart extends React.PureComponent {
    render() {
        const {data, compact, className} = this.props;
        const percentage = data.map((pair) => {
            return [pair[0], 100*pair[1]];
        });
        return (
            <AutoResizeContainer child={({width, height}) => (
                <div className={classNames('self-stretch flex', className)}>
                    {width > 0 ? (
                        <SystemBannerLineChart
                            chartTitle={getText(contentKeys.SYSTEM_EFFICIENCY_LOAD_STEP)}
                            seriesName={getText(contentKeys.AVERAGE_SYSTEM_EFFICIENCY)}
                            xAxisTitle={getText(contentKeys.LOAD_CURRENT_OF_MAX)}
                            yAxisTitle={getText(contentKeys.EFFICIENCY)}
                            width={Math.max(width, 300)}
                            height={height}
                            yMin={0}
                            yMax={100}
                            yStep={20}
                            data={percentage}
                            compact={compact} />
                    ) : null}
                </div>
            )} />
        );
    }
}

SystemEfficiencyChart.propTypes = {
    data: PropTypes.array.isRequired,
    compact: PropTypes.bool,
    className: PropTypes.string
};

export default SystemEfficiencyChart;
