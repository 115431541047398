/* global kendo */
import PropTypes from 'prop-types';

import React from 'react';
import databind from 'redux/utils/databind';
import { Row, Col } from 'bootstrap-core';
import SystemEfficiencyChart from './SystemEfficiencyChart';
import SystemPowerDissipationChart from './SystemPowerDissipationChart';
import SystemEfficiencyDonutCharts from './SystemEfficiencyDonutCharts';
import css from './styles.less';
import classNames from 'classnames';
import Slider from '../Slider';
import { actions as compareAndSelectActions } from 'redux/modules/compareAndSelect';
import { getSelectedPartInfo } from 'logic/compareParts';
import { computeMaxJnTemp } from 'logic/junctionTemperature';
import { DEFAULT_AMBIENT_TEMP } from 'logic/constants';
import * as breakpoints from 'styles/variables/breakpoints';
import MediaQuery from 'react-responsive';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const getShowWarning = (results, selectedParts, computedData) =>
    results.some((result, index) => {
        const { selectedIndex, selectedId } = getSelectedPartInfo(results, selectedParts, index, result.id);
        return computeMaxJnTemp(selectedId, selectedIndex, computedData, index) >= 110;
    });

const AmbientTempReset = (props) => {
    const reset = () => {
        props.reset({ ambientTemperature: DEFAULT_AMBIENT_TEMP });
    };

    return (props.ambientTemperature !== DEFAULT_AMBIENT_TEMP)
        ? (<div className={classNames(css.ambientTemperatureReset, "fa", "fa-undo")}
            style={props.style} onClick={reset}></div>)
        : <div></div>;
};

AmbientTempReset.propTypes = {
    style: PropTypes.object,
    ambientTemperature: PropTypes.number,
    reset: PropTypes.func
};

class CollapseHeader extends React.Component {
    static propTypes = {
        content: PropTypes.node
    }

    state = {
        collapsed: false
    };

    handleCollapseClick = () => {
        this.setState({ collapsed: !this.state.collapsed });
    }

    render() {
        const { collapsed } = this.state;
        return (
            <React.Fragment>
                <div className={classNames(css.collapseHeader, 'layout horizontal space-between align-center')}
                    onClick={this.handleCollapseClick}>
                    <span className="heading6">
                        {`${collapsed ? 'Show' : 'Hide'} Efficiency and Dissipation Details`}
                    </span>
                    <span
                        style={{ fontSize: "20px" }}
                        className={classNames(
                            'fa collapseControl',
                            { 'fa-angle-down': collapsed, 'fa-angle-up': !collapsed })}></span>
                </div>
                <MediaQuery minWidth={breakpoints.desktopMin}>
                    {(matches) => {
                        return (matches || !collapsed) ? this.props.content : null;
                    }}
                </MediaQuery>
            </React.Fragment>
        );
    }
}

class SystemValidationBanner extends React.Component {
    constructor() {
        super();
        this.isPinned = false;
        this.isTableHeaderCloned = false;
    }

    static propTypes = {
        maxPowerDissipation: PropTypes.number.isRequired,
        ambientTemperature: PropTypes.number.isRequired,
        systemEfficiency: PropTypes.array.isRequired,
        systemPowerDissipation: PropTypes.array.isRequired,
        selectionsHavePartialEfficiencyData: PropTypes.bool.isRequired,
        updateAmbientTemperature: PropTypes.func.isRequired,
        results: PropTypes.array.isRequired,
        selectedPartIds: PropTypes.object.isRequired,
        computedData: PropTypes.array.isRequired
    };

    static databind({ compareAndSelect }) {
        return {
            results: compareAndSelect.results,
            maxPowerDissipation: compareAndSelect.maxPowerDissipation,
            ambientTemperature: compareAndSelect.ambientTemperature,
            systemEfficiency: compareAndSelect.systemEfficiency,
            systemPowerDissipation: compareAndSelect.systemPowerDissipation,
            selectionsHavePartialEfficiencyData: compareAndSelect.selectionsHavePartialEfficiencyData,
            selectedPartIds: compareAndSelect.selectedPartIds,
            computedData: compareAndSelect.computedData
        };
    };

    static actions = {
        updateAmbientTemperature: compareAndSelectActions.updateAmbientTemperature
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        if (this.isTableHeaderCloned) {
            $('#clonedGrid').remove();
        }
    }

    handleScroll = (evt) => {
        // _. throttle this call?
        const { top, height } = this.refs.inlineBanner.getBoundingClientRect();
        const pinnedBannerRect = this.refs.pinnedBanner.getBoundingClientRect();
        const OFFSET = -1 * (height - pinnedBannerRect.height);

        if (!this.isTableHeaderCloned) {
            const fixedTableHeader = $('#compareGrid table').clone();
            fixedTableHeader.addClass(css.fixedTableHeader);
            fixedTableHeader.attr('id', 'clonedGrid');
            $('.topGrid').append('<div id="cloneGrid" class="kendocompo k-grid k-widget"></div>');
            $('#cloneGrid').append(fixedTableHeader);
            this.isTableHeaderCloned = true;
        }

        if ((top < OFFSET) && (!this.isPinned)) {
            $('body').addClass(css.showPinned);
            this.isPinned = true;
        }
        else if ((top >= OFFSET) && (this.isPinned)) {
            $('body').removeClass(css.showPinned);
            this.isPinned = false;
        }
    };

    handleSliderChange = (e) => {
        this.props.updateAmbientTemperature({ ambientTemperature: e.value });
    }

    render() {
        const formattedMaxPowerDissipation = kendo.toString(this.props.maxPowerDissipation, "n2");
        const { results, selectedPartIds, computedData } = this.props;
        const tempWarning = { [css.tempWarning]: getShowWarning(results, selectedPartIds, computedData) };
        const pinnedBanner =
            (<div className={css.compactValidationBanner} ref="pinnedBanner">
                <Row className={css.content}>
                    <Col className={css.noPadding} xs={12}>
                        <div className={css.pinnedContainer}>
                            <div className={css.pinnedPanel1}>
                                <SystemEfficiencyChart data={this.props.systemEfficiency} compact={true} />
                            </div>
                            <div className={css.pinnedPanel2}>
                                <SystemPowerDissipationChart data={this.props.systemPowerDissipation} compact={true} />
                            </div>
                            <div className={css.pinnedPanel3}>
                                <div className={css.infoPanel}>
                                    <div className={css.infoPanelItem}>
                                        <div className={css.infoPanelLabel}>
                                            {getText(contentKeys.MAX_POWER_DISSIPATION)}
                                        </div>
                                        <div className={css.infoPanelValue}>{formattedMaxPowerDissipation +
                                            ' ' +getText(contentKeys.WATTS)}</div>
                                    </div>
                                    <div className={css.infoPanelItem}>
                                        <div className={css.infoPanelLabel}>
                                            {getText(contentKeys.AMBIENT_TEMPERATURE)} (T<sub>A</sub>)
                                        </div>
                                        <div className={css.compactAmbientTempContainer}>
                                            <AmbientTempReset
                                                ambientTemperature={this.props.ambientTemperature}
                                                reset={this.props.updateAmbientTemperature}
                                                style={{ marginRight: '12px' }} />
                                            <Slider onChange={this.handleSliderChange}
                                                min={-55} max={125}
                                                value={this.props.ambientTemperature}
                                                className={classNames(css.slider, css.compactAmbientTempSlider)} />
                                            <div className={classNames(css.infoPanelValue, tempWarning)}>
                                                {this.props.ambientTemperature}&deg;C
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={css.pinnedPanel4}>
                                <SystemEfficiencyDonutCharts compact={true} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>);

        const containerClass = (this.props.selectionsHavePartialEfficiencyData)
            ? classNames(css.systemValidationBanner, css.slideBehindWarning)
            : css.systemValidationBanner;

        const inlineBanner =
            (<div className={containerClass} ref="inlineBanner">
                <CollapseHeader content={(
                    <div className={classNames(css.content, 'row')}>
                        <SystemEfficiencyChart data={this.props.systemEfficiency}
                            className={css.efficiencyInlineChart}
                        />
                        <SystemPowerDissipationChart data={this.props.systemPowerDissipation}
                            className={css.powerDissipationLineChart} />
                        <div className={css.powerDisipationContainer}>
                            <div className={css.maxPowerDisContainer}>
                                <div className={css.maxPowerDisLabel}>{getText(contentKeys.MAX_POWER_DISSIPATION)}</div>
                                <div className={css.maxPowerDisValue}>{formattedMaxPowerDissipation +
                                    ' ' + getText(contentKeys.WATTS)}</div>
                            </div>
                            <SystemEfficiencyDonutCharts />
                            <div className={css.inlineAmbientTempContainer}>
                                <div className={css.ambientTempLabelContainer}>
                                    <span className={css.ambientTempLabel}>{getText(contentKeys.AMBIENT_TEMPERATURE) +
                                        ' '}(T<sub>A</sub>)</span>
                                    <span className={classNames(css.ambientTempValue, tempWarning)}>
                                        {this.props.ambientTemperature}&deg;C
                                    </span>
                                </div>
                                <Slider onChange={this.handleSliderChange}
                                    min={-55} max={125}
                                    value={this.props.ambientTemperature}
                                    className={classNames(css.slider, css.inlineAmbientTempSlider)}
                                />
                                <AmbientTempReset ambientTemperature={this.props.ambientTemperature}
                                    reset={this.props.updateAmbientTemperature} />
                            </div>
                        </div>
                    </div>
                )} />
            </div>);

        return (<div>
            {pinnedBanner}
            {inlineBanner}
        </div>);
    }
}

export default databind(SystemValidationBanner);
