
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

export const CHAR_MDASH = String.fromCharCode(8212);
export const CHAR_NBSP = String.fromCharCode(160);
export const DEFAULT_AMBIENT_TEMP = 25;
export const MATCH_SCORE_LIMIT = 1.1;
export const NUM_SAMPLES_PER_CURVE = 11;
export const IOUT_EPS = 0.001;
export const OTHER_VENDOR_NAME = "Other";
export const ALTERA_VENDOR_NAME = "Altera";
export const XILINX_VENDOR_NAME = "Xilinx";
export const UNSUPPORTED_VENDOR_FILE_MESSAGE = getText(contentKeys.ONLY_ALTERA_XLSCSV_AND) + "\n";
export const VENDOR_FILE_NOT_RECOGNISED_MESSAGE = " was not recognized as a valid file.";
export const DEFAULT_RESULTS_PER_TOPOLOGY_LIMIT = 5;
export const DEFAULT_SHOW_PRICE_SETTING = true;
export const SERVER_TIMESTAMP_FORMAT = "MM/DD/YYYY hh:mm:ss A";
export const LOCAL_TIMESTAMP_FORMAT = "DD MMM YYYY, h:mm:ss A";

export const ResultOrderOptions = {
    PRICE: getText(contentKeys.PRICE),
    NEWEST_RELEASE_DATE: getText(contentKeys.RELEASE_DATE),
    SMALLEST_PACKAGE: getText(contentKeys.PACKAGE_SIZE),
    LOWEST_IQ: getText(contentKeys.IQ),
    SMALLEST_FOOTPRINT: "Solution Size",
    LOWEST_BOM_COST: getText(contentKeys.BOM_COST),
    LOWEST_BOM_COUNT: getText(contentKeys.BOM_COUNT),
    DEVICE_NAME: getText(contentKeys.PARTS_DESCRIPTION),
    IOUT_MAX: "A (Max)",
    EST_EFFICIENCY: getText(contentKeys.EST_EFFICIENCY)
};

export const PartFieldNames = {
    PRICE: "msrp",
    RELEASE_DATE: "releasedt",
    PACKAGE_SIZE: "totalpkg",
    IQ: "totaliq",
    FOOTPRINT: "solutionfootprint",
    BOM_COST: "bomcost",
    BOM_COUNT: "bomcount",
    EST_EFFICIENCY: "estEfficiency",
    DEVICE_NAME: "deviceName",
    IOUT_MAX: "outputs",
    NOTES: "notes",
    ORCAD_ENABLED: "orcadEnabled",
    CAN_BE_SAMPLED: "canbesampled",
    ISIM_MODEL: "isimmodel",
    TOPOLOGY: "topology"
};

export const ResultOrderDirection = {
    ASCENDING: getText(contentKeys.ASCENDING),
    DESCENDING: getText(contentKeys.DESCENDING)
};

export const GridSortDirs = {
    ASCENDING: "asc",
    DESCENDING: "desc"
};

export const DEFAULT_RESULTS_ORDER_BY_SETTING = {
    field: ResultOrderOptions.PRICE,
    direction: ResultOrderDirection.ASCENDING
};

export const SchematicFilterType = {
    ALL: getText(contentKeys.ALL),
    SCHEMATIC_AVAILABLE: getText(contentKeys.SCHEMATIC_AVAILABLE),
    SAMPLES_AVAILABLE: getText(contentKeys.SAMPLES_AVAILABLE),
    ISIM_SIMULATION_AVILABLE: getText(contentKeys.ISIM_SIMULATION_AVAILABLE)
};

export const AECQFilterType = {
    ALL: getText(contentKeys.ALL),
    QUALIFIED: getText(contentKeys.AECQ_QUALIFID),
    NOT_QUALIFIED: getText(contentKeys.NOT_AECQ_QUALIFID)
};

export const SyncFilterType = {
    ALL: getText(contentKeys.ALL),
    SYNC_IN: getText(contentKeys.SYNC_IN),
    SYNC_IN_OUT: getText(contentKeys.SYNC_IN_OUT)
};

export const NotificationType = {
    SUCCESS: 'success',
    ERROR: 'error'
};
