/* global kendo */
export function formatPercent(percent, decimals = 2) {
    if (percent == null) {
        return "";
    }
    const str = kendo.toString(percent, "p" + decimals.toString());
    return (str != null) ? str.replace(/\s/g, '') : str;
}

export function formatNumber(value, decimals) {
    return kendo.toString(value, "n" + decimals);
}

// for rendering percentages
export function formatNumberWithExactly2Decimals(value) {
    return kendo.toString(value, '####.00');
}

export function formatNumberWithUpTo2Decimals(value) {
    return kendo.toString(value, '####.##');
}

export function formatNumberWithAtLeast2Decimals(value) {
    return kendo.toString(value, '####.00##');
}

// used by controls and elements that display quantities such as volts, amps
export function formatNumberVariable(value) {
    return kendo.toString(value, '####.###');
}
