import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const SolutionType = {
    ALL: getText(contentKeys.ALL),
    ANALOG: getText(contentKeys.ANALOG),
    ANALOG_IFET: getText(contentKeys.ANALOG_INTEGRATED_FET),
    ANALOG_MODULES: getText(contentKeys.ANALOG_MODULE),
    DIGITAL: getText(contentKeys.DIGITAL),
    DIGITAL_IFET: getText(contentKeys.DIGITAL_INTEGRATED_FET),
    DIGITAL_MODULES: getText(contentKeys.DIGITAL_MODULE)
};

export default SolutionType;
