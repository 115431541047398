import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import {formatPercent} from 'logic/formats';
import classNames from 'classnames';

// Generic component for rendering the donut charts in the system validation banner section of compare and select screen
// Inspiration: http://stackoverflow.com/questions/20525820/css3-simple-donut-chart
// http://stackoverflow.com/questions/30712621/pure-css3-or-svg-animated-doughnut-chart
const SystemBannerDonutChart = ({title, percentage, compact}) => {
    const percent = percentage || 0;
    const percentStr = formatPercent(percent);

    const diameter = (compact) ? 72 : 110;
    const strokeWidth = (compact) ? 4 : 6;

    const centerOffset = diameter / 2;
    const circleRadius = (diameter - strokeWidth) / 2;
    const circumference = 2 * circleRadius * Math.PI;
    const dashOffset = circumference - circumference * percent;
    const circleTransform = `rotate(-90 ${centerOffset} ${centerOffset})`;
    const containerStyle = {width: diameter};

    const classDonutChart = (compact) ? classNames(css.compact, css.donutChart) : css.donutChart;

    return (
        <div className={classDonutChart} style={containerStyle}>
            <div className={css.donutChartText}>
                <div className={css.title}>{title}</div>
                <div className={css.percentage}>{percentStr}</div>
            </div>
            <svg width={diameter} height={diameter}>
                <g>
                    <circle className={css.backgroundCircle}
                        r={circleRadius} cx={centerOffset} cy={centerOffset}
                        strokeWidth={strokeWidth}
                    />
                    <circle className={css.foregroundCircle}
                        transform={circleTransform}
                        r={circleRadius} cx={centerOffset} cy={centerOffset}
                        strokeWidth={strokeWidth}
                        strokeDasharray={circumference}
                        strokeDashoffset={dashOffset}
                    />
                </g>
            </svg>
        </div>
    );
};

SystemBannerDonutChart.propTypes = {
    title: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
    compact: PropTypes.bool
};

export default SystemBannerDonutChart;
