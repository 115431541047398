import find from 'lodash/find';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import isPlainObject from 'lodash/isPlainObject';
import { getSelectedPmbusAddressesForSelectedParts } from 'logic/compareParts';

const numOutputsMap = {
    1: 'SINGLE',
    2: 'DUAL',
    3: 'TRIPLE',
    4: 'QUAD'
};

export const dsn = ({deviceName, numOutputs, numDevicesinSolution}) => {
    const numOutputString = `-${numOutputsMap[numOutputs]}`;
    const currentShareString = `${numDevicesinSolution > 1 ? '-IS' : ''}`;
    const numDevicesString = `-${numDevicesinSolution}`;
    return `${deviceName}${numOutputString}${currentShareString}${numDevicesString}`;
};

const mapOutputFields = (selectedPart, {id, name, volts, amps, supplySeq}, pmbusAddresses) => {
    return {
        name,
        volts,
        amps,
        enablePin: selectedPart.enablePin,
        supplySeq,
        pmbusAddress: pmbusAddresses[id]
    };
};

const createPartEntryFromOutputs = (outputList, selectedPartIds, pmbusAddresses) => {
    // assuming these fields are the same for all outputs in this grouping so only referring to first one
    const {sourceRailName, vinMin, vinMax, topologyType, syncInput, syncOutput, items, id} = outputList[0];
    const partId = selectedPartIds[id];
    const selectedPart = find(items, {id: partId});

    // exclude missing parts or parts that aren't schematic enabled
    if (!selectedPart || !selectedPart.orcadEnabled) return null;

    const outputs = outputList.map((o) => mapOutputFields(selectedPart, o, pmbusAddresses));

    return {
        dsn: dsn(selectedPart),
        inputName: sourceRailName,
        vinMin,
        vinMax,
        vinNom: (vinMin + vinMax) / 2, // yes, the backend should just do this
        topologyType,
        syncIn: syncInput,
        syncFreq: null, // TODO: Is this not supported?
        syncOut: syncOutput,
        outputs
    };
};

const groupAdjacentRails = function(results, selectedPartIds) {
    let groupedOutputs = [];
    let tuple = [];
    let tuplePartId = null;

    for (let i = 0; i < results.length; i++) {
        const output = results[i];
        const partId = selectedPartIds[output.id];
        const part = find(output.items, (item) => item.id === partId);

        // this is a N-output part, it may also feed the next rails
        if (part.numOutputs > 1) {
            if (tuple.length > 0) {
                // we're working on setting up a group for a >1 output part
                const firstOutput = tuple[0];
                const partMatchesTuple = firstOutput.sourceRail === output.sourceRail && tuplePartId === partId;

                if (partMatchesTuple) {
                    // a match, push into tuple
                    tuple.push(output);
                }
                else {
                    // no match on adjacent rail, push as separate groups
                    tuple.forEach(o => groupedOutputs.push([o]));
                    groupedOutputs.push([output]);

                    tuple = [];
                    tuplePartId = null;
                }

                if (tuple.length === part.numOutputs) {
                    // tuple is full, push it onto grouped outputs list and reset tuple
                    groupedOutputs.push(tuple);

                    tuple = [];
                    tuplePartId = null;
                }
            }
            else {
                // hold on to this output and it can be grouped with the next rail
                tuple.push(output);
                tuplePartId = partId;
            }
        }
        else { // a single output part
            if (tuple.length > 0) {
                // the last part was N-output but we did not find an adjacent match to group by
                tuple.forEach(o => groupedOutputs.push([o]));

                tuple = [];
                tuplePartId = null;
            }
            // add the output to its own group
            groupedOutputs.push([output]);
        }
    }

    return groupedOutputs;
};

const createSequencer = function(refRail) {
    return {
        dsn: "",
        name: refRail.inputName,
        vinMin: refRail.vinMin,
        vinMax: refRail.vinMax,
        vinNom: refRail.vinNom,
        inputName: refRail.inputName,
        topologyType: null,
        syncIn: null,
        syncFreq: null,
        syncOut: null,
        outputs: null
    };
};

export const appendSequencerIfRequired = function(inputs) {
    const sequences = inputs.map((input) => {
        return input.outputs.map((output) => output.supplySeq);
    });
    const uniqueSequences = uniq(flatten(sequences));
    const numUnique = uniqueSequences.length;

    if (numUnique > 1) {
        const sequencer = createSequencer(inputs[0]);
        if (numUnique <= 4) {
            sequencer.dsn = "ISL8702-SINGLE-4";
        }
        else if (numUnique <= 8) {
            sequencer.dsn = "ISL8702-DUAL-8";
        }
        else {
            sequencer.dsn = "ISL8702-TRIPLE-12";
        }
        inputs.push(sequencer);
    }

    return inputs;
};

// Transform the redux state into the payload needed to generate reference design filesm
export default function getProjectInputParams (state, projectName, projectId) {
    const {results, selectedPartIds, selectedPmbusAddresses} = state.compareAndSelect;

    if (selectedPartIds.length === 0 || results.length === 0) return false;

    // group all the results by source rail id and intersil part id combined
    // this is the basis for the output list
    const groupedOutputs = groupAdjacentRails(results, selectedPartIds);

    // get just the pmbusAddresses for the finl selected parts
    const pmbusAddresses = getSelectedPmbusAddressesForSelectedParts(selectedPmbusAddresses, selectedPartIds);

    // go through each grouping and transform into an input entry
    let input = groupedOutputs
        .map((outputs) => createPartEntryFromOutputs(outputs, selectedPartIds, pmbusAddresses))
        .filter(isPlainObject);

    input = appendSequencerIfRequired(input);

    const projname = projectName || state.referenceDesign.projectName;

    return {projname, input, projectId};
};
