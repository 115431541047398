import keyBy from 'lodash/keyBy';

/*
*
*   Calculating Powers
*
* */
export const powerRequiredForEfficiency = (output, efficiency) => {
    if (efficiency === 0) return output;
    if ((!output.volts) || (!output.amps)) return {...output, powerNeeded: 0};
    return {...output, powerNeeded: (output.volts*output.amps)/efficiency};
};

export const availableOutputPower = (output, outputs) => {
    if ((!output.volts) || (!output.amps)) return {...output, availableOutput: 0};
    let powerWhenNoConnections = output.volts * output.amps;
    const availableOutput = outputs.filter((x) => x.sourceRail === output.id)
        .map((x) => x.powerNeeded)
        .reduce((p, x) => p-x, powerWhenNoConnections);
    return {...output, availableOutput};
};

export const availableInputRailPower = (input, outputs) => {
    let power = ((input.vmin+input.vmax)/2)*input.imax;
    power = outputs.filter((x) => x.sourceRail === input.id)
        .map((x) => x.powerNeeded)
        .reduce((y, x) => y-x, power);
    return {...input, power};
};

export const buildSourceRailList = (inputs, outputs, index) => {
    const thisOutputId = outputs[index].id;

    let connectedOutputs = outputs.filter((o) => !!findRootInputRailId(outputs, o));
    connectedOutputs = removeChildOutputs(connectedOutputs, thisOutputId);
    const sources = [...inputs, ...connectedOutputs];
    const validSources = sources.filter((x) => (x.id !== thisOutputId));
    return validSources.map((x) => { return { text: x.name, value: x.id }; });
};

/*
*
*   Optimizing Outputs
*
* */

import * as _ from 'lodash';

const orByKey = function(collection, key) {
    return collection.reduce((a, x) => a || x[key], false);
};

export const collapse = (outputs) => {
    const amps = outputs.reduce((a, x) => a + x.amps, 0);
    const name = outputs.reduce((a, x) => {
        return a + (", " + x.name);
    }, "").substr(2);
    const powerGoodOutput = orByKey(outputs, 'powerGoodOutput');
    const enablePinInput = orByKey(outputs, 'enablePinInput');
    const syncInput = orByKey(outputs, 'syncInput');
    const syncOutput = orByKey(outputs, 'syncOutput');
    const firstOutput = outputs[0];
    return {...firstOutput, amps, name, powerGoodOutput, enablePinInput, syncInput, syncOutput};
};

function buildTree(collection, keys, execute) {
    if (keys.length > 0) {
        let key = "";
        let remainingKeys = [];
        [key, ...remainingKeys] = keys;
        const groups = _.chain(collection).groupBy(key).map((x) => x).value();
        groups.forEach((group) => {
            return buildTree(group, remainingKeys, execute);
        });
    }
    else {
        return execute(collection);
    }
}

export const collapseOutputs = (outputs) => {
    if (!outputs) return [];
    let newOutputs = [];

    function collapseValidOutputs(group) {
        let blank, valid;
        [blank, valid] = _.partition(group,
            (x) => ((x.sourceRail === "") || (x.volts === 0) || (x.amps === 0) || (x.supplySeq === 0)));
        if (valid.length > 0) {
            newOutputs.push(collapse(valid));
        }
        if (blank.length > 0) {
            newOutputs = newOutputs.concat(blank);
        }
    }

    const keys = ['sourceRail', 'volts', 'supplySeq', 'ldo'];
    buildTree(outputs, keys, collapseValidOutputs);
    return newOutputs;
};

const sortDescending = (outputs, prop) => {
    const newOutputs = [...outputs];
    return newOutputs.sort((a, b) => (a[prop] === b[prop]) ? 0 : (a[prop] > b[prop]) ? -1 : 1);
};

export const groupSortOutputs = (outputs) => {
    if (!outputs) return [];
    if (outputs.length === 1) return outputs;
    const sourceRailGroups = _.chain(outputs).groupBy("sourceRail").map((x) => x).value();
    const sourceSeqGroups = sourceRailGroups.map((sr) => {
        return _.chain(sr).groupBy("supplySeq").map((x) => x).value();
    });
    return _.flattenDeep(sourceSeqGroups.map((x) => x.map((xx) => sortDescending(xx, "amps"))));
};

export const optimizeOutputs = (outputs) => {
    const collapsed = collapseOutputs(outputs);
    return groupSortOutputs(collapsed);
};

export const deDupeName = (suggested, existing) => {
    let suggestion = suggested;
    const LIMIT = 99;
    let i = 0;
    let dupes = existing.filter((x) => (x === suggestion));
    while ((dupes.length > 0) && (i < LIMIT)) {
        const matches = dupes[0].match('.*[-](\\d+)$');
        if (matches) {
            const numChars = matches[1].length;
            suggestion = suggestion.substr(0, suggestion.length-numChars);
            suggestion += (parseInt(matches[1]) + 1).toString();
        }
        else {
            suggestion += "-1";
        }
        dupes = existing.filter((x) => (x === suggestion));
        i++;
    }
    return suggestion;
};

/*
 *
 * Navigating the connections and doing stuff on the way
 *
 * */

function traverseChildOutputs(outputs, childIds, applyFn) {
    if ((childIds.length > 0) && (childIds[0] !== '')) {
        const LIMIT = outputs.length;
        let i = 0;
        while ((childIds.length > 0) && (i < LIMIT)) {
            // pull an id off the front of the queue
            let currentId;
            [currentId, ...childIds] = childIds;
            // get Ids of additional connections
            childIds.push(...outputs
                .filter((x) => x.sourceRail === currentId)
                .map((x) => x.id)
                .filter((x) => x.id !== ''));
            // omit those connections
            outputs = applyFn(outputs, currentId);
            i++;
        }
    }
    return outputs;
}

export function removeChildOutputs(outputs, id) {
    return traverseChildOutputs(outputs, [id], function(outputs, childId) {
        return outputs.filter((x) => x.sourceRail !== childId);
    });
}

function getChildren(outputs, inputId) {
    const children = outputs.filter(x => x.sourceRail === inputId);
    if (children.length === 0) return null;
    return children;
}

export function disconnectOutputs(outputs, inputId) {
    const children = getChildren(outputs, inputId);
    if (children === null) return outputs;
    return traverseChildOutputs(outputs, children.map(c => c.id), function(outputs, childId) {
        return outputs.map((x) => {
            if (x.id === childId) {
                x.sourceRail = "";
            }
            return x;
        });
    });
}

export function deleteInputRailAndItsConnections(state, idx) {
    if ((!state) || (Object.keys(state).length === 0)) return state;
    // remove the Input Rail
    const inputs = [...state.inputs.slice(0, idx), ...state.inputs.slice(idx+1)];
    let outputs = removeChildOutputs([...state.outputs], state.inputs[idx].id);
    return {...state, inputs, outputs};
}

function getParent(outputs, id) {
    if (id === "") return null;
    const parent = outputs.filter((x) => x.id === id);
    if (parent.length === 0) return null; // connects to an input
    return parent[0];
}

function isNotAnOutput(outputIds, item) {
    return (outputIds.filter((id) => id === item.sourceRail).length === 0);
}

export function findRootInputRailId(outputs, output) {
    if ((!output) || (!output.sourceRail)) return null;
    // otherwise find the eventual input, if any
    const outputIds = outputs.map((x) => x.id);
    if (isNotAnOutput(outputIds, output)) {
        return output.sourceRail;
    }
    let child = output;
    let parent = null;
    for (let i = 0; i < outputs.length; i++) {
        parent = getParent(outputs, child.sourceRail);
        // if we can't find this id in the output id list
        if (isNotAnOutput(outputIds, parent)) {
            return parent.sourceRail;
        }
        child = parent;
    }
    return null;
}

export function checkInvalidSupplySequence(outputs, index, newValue) {
    // if we are clearing the field don't validate
    if (newValue === 0) return null;

    // get immediate connections
    const parent = getParent(outputs, outputs[index].sourceRail);
    const children = getChildren(outputs, outputs[index].id);
    const child = (children === null || children.length === 0) ? null : children[0];
    if ((parent === null) && (child === null)) return null;

    // validate
    let invalid = null;
    if ((parent) && (parent.supplySeq > 0) && (parent.supplySeq > newValue)) {
        invalid = {index};
    }
    if ((child) && (child.supplySeq > 0) && (child.supplySeq < newValue)) {
        invalid = {index};
    }

    return invalid;
}

/*
 *
 *   Converting editor data to diagram data
 *
 * */

import colors from '../styles/variables/colors';
import {formatDeviceName} from './compareParts';
import {stripEmptyOutputs} from 'logic/matching';

const inputColors = {
    'cor1': colors['@inputColor1'],
    'cor2': colors['@inputColor2'],
    'cor3': colors['@inputColor3'],
    'cor4': colors['@inputColor4'],
    'cor5': colors['@inputColor5'],
    'cor6': colors['@inputColor6'],
    'cor7': colors['@inputColor7'],
    'cor8': colors['@inputColor8'],
    'cor9': colors['@inputColor9'],
    'cor10': colors['@inputColor10'],
    'default': '#FFF'
};

export const buildDiagramData = (stateData, forReferenceDesign) => {
    let editorState = stateData;

    // For reference design diagrams, strip out empty rails and outputs
    if (forReferenceDesign) {
        editorState = stripEmptyOutputs(stateData);
    }

    // Pulling out the inputs and outputs, most likely coming from the Redux state
    const { inputs, outputs, activeColors, selectedBlockId, selectedPartIds } = editorState;

    // Adding empty children arrays to all inputs and outputs, for ease of use. Kendo's Diagram component won't try to
    // do anything with an empty child list. Also sets isSelected on block if it is the selected block
    const addEmptyChildrenList = (item) => {
        const selectedBlockObj = selectedBlockId === item.id && !forReferenceDesign ? { isSelected: true } : {};

        return {...item, ...selectedBlockObj, childRails: []};
    };

    // Adds part name to a given output and then adds empty children and selected state.
    const addPartToOutput = (outputWithItems) => {
        const {items, ...output} = outputWithItems;
        const part = _.find(items, i => i.id === selectedPartIds[output.id]);

        return addEmptyChildrenList({...output, partName: formatDeviceName(part)});
    };

    const inputsWithChildren = inputs.map(addEmptyChildrenList);
    const outputsWithChildren = outputs.map(forReferenceDesign ? addPartToOutput : addEmptyChildrenList);

    // Groups outputs missing a sourceRail with inputs, and separates out all other outputs
    let inputsAndLooseOutputs = inputsWithChildren.concat(outputsWithChildren.filter(o => o.sourceRail === ''));
    const connectedOutputs = outputsWithChildren.filter(o => o.sourceRail !== '');

    // Creates objects where ids map directly to inputs/outputs
    let inputObj = _.keyBy(inputsAndLooseOutputs, 'id');
    let outputObj = _.keyBy(connectedOutputs, 'id');

    // Using forEach over the connectedOutputs array here so that order will be maintained from the original
    // output order. Since the iteration function refers to the memory location of each output, future mutations
    // to those outputs will reflect in the final result.
    connectedOutputs.forEach(output => {
        if (inputObj[output.sourceRail]) {
            // If the output's sourceRail is an input (or a disconnected output), add it to that input's children
            inputObj[output.sourceRail].childRails.push(outputObj[output.id]);
        }
        else {
            // If the output's sourceRail is another output, add it to that output's children
            outputObj[output.sourceRail].childRails.push(outputObj[output.id]);
        }
    });

    const setInputColor = (input) => {
        const colorData = activeColors && activeColors[input.id];
        const colorId = colorData && colorData.color ? colorData.color : 'default';
        const railColor = inputColors[colorId];

        return {
            ...inputObj[input.id],
            railColor
        };
    };

    // For reference design diagrams, strip any inputs without amperage or any inputs/loose outputs without children
    if (forReferenceDesign) {
        inputsAndLooseOutputs = inputsAndLooseOutputs.filter(i => i.childRails.length > 0 &&
                                                                  i.imax !== 0);
    }

    // Mapping over inputsAndLooseOutputs here so that order will be maintained
    return inputsAndLooseOutputs.map(setInputColor);
};

export const updateOrder = (items, itemsOrder) => {
    const itemsById = keyBy(items, 'id');
    return itemsOrder.map(id => itemsById[id]);
};
