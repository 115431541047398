import listOfCodesThatUseCommaDecimal from 'util/commaCountryList.json';

export function mapCultureCodeToUSorES(culture) {
    if ((culture != null) && (culture.length === 5)) {
        const country = culture.slice(-2);
        const matches = listOfCodesThatUseCommaDecimal.filter((code) => code === country);
        if (matches.length > 0) {
            return "es-ES";
        }
    }
    return "en-US";
}
