import {makeAction, createReducer} from '../utils/redux-helpers';
import {partsDbInitialState as initialState} from '../initialStates';
import keyBy from 'lodash/keyBy';
import {fetchVendors, fetchPlatforms} from 'api/templatesDb';
import {registerBlacklistedKeys} from 'redux/persist';

function prefix(type) { return `templatesDb/${type}`; }

export const actionTypes = {
    FETCH_DATA: prefix('FETCH_DATA'),
    REQUESTED_DATA: prefix('REQUESTED_DATA'),
    RECEIVE_DATA: prefix('RECEIVE_DATA'),
    RECEIVE_ERROR: prefix('RECEIVE_ERROR')
};

const loadVendorsAndPlatforms = function() {
    return fetchVendors().then(fetchPlatforms);
};

export const actions = {
    fetchData: () => {
        return (dispatch) => {
            dispatch(actions.requestedData());
            return loadVendorsAndPlatforms()
                .then((data) => dispatch(actions.receiveData(data)))
                .catch((message) => dispatch(actions.receiveError(message)));
        };
    },
    requestedData: (data) => makeAction(actionTypes.REQUESTED_DATA, data),
    receiveData: (data) => makeAction(actionTypes.RECEIVE_DATA, data),
    receiveError: (data) => makeAction(actionTypes.RECEIVE_ERROR, data)

};

const ACTION_HANDLERS = {
    [actionTypes.REQUESTED_DATA]: (state) => {
        return { ...state, requested: true, received: false, error: false, data: {}, version: "" };
    },
    [actionTypes.RECEIVE_DATA]: (state, {payload}) => {
        const {vendorResponse, platformsResponse} = payload;
        const version = vendorResponse.revision;
        const vendors = vendorResponse.designs.map((x) => x.vendor);
        const platforms = keyBy(platformsResponse.designs.map(
            (obj) => {
                return {
                    vendor: obj.vendor,
                    names: obj.platforms.map((p) => p.platform)
                };
            }
        ), 'vendor');
        return { ...state,
            requested: false,
            received: true,
            error: false,
            data: platformsResponse.designs,
            vendors,
            platforms,
            version };
    },
    [actionTypes.RECEIVE_ERROR]: (state, {payload}) => {
        return { ...state, requested: false, received: false, error: true, message: payload };
    }
};

registerBlacklistedKeys('templatesDb', ['requested', 'received', 'error', 'errorMessage']);

export default createReducer(initialState, ACTION_HANDLERS);
