import PropTypes from 'prop-types';
import React from 'react';
import databind from 'redux/utils/databind';
import SystemBannerDonutChart from './SystemBannerDonutChart';
import css from './styles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const SystemEfficiencyDonutCharts = function(props) {
    const containerClass = (props.compact) ? css.compactDonutChartContainer : css.donutChartContainer;

    return (
        <div className={containerClass}>
            <SystemBannerDonutChart
                title={(props.compact) ? getText(contentKeys.LL_EFF) : getText(contentKeys.LIGHT_LOAD_EFFICIENCY)}
                percentage={props.systemLightLoadEfficiency}
                compact={props.compact}
            />
            <SystemBannerDonutChart
                title={(props.compact) ? getText(contentKeys.TL_EFF) : getText(contentKeys.TYPICAL_LOAD_EFFICIENCY)}
                percentage={props.systemTypicalLoadEfficiency}
                compact={props.compact}
            />
            <SystemBannerDonutChart
                title={(props.compact) ? getText(contentKeys.ML_EFF) : getText(contentKeys.MAX_LOAD_EFFICIENCY)}
                percentage={props.systemMaxLoadEfficiency}
                compact={props.compact}
            />
        </div>
    );
};

SystemEfficiencyDonutCharts.propTypes = {
    systemLightLoadEfficiency: PropTypes.number.isRequired,
    systemTypicalLoadEfficiency: PropTypes.number.isRequired,
    systemMaxLoadEfficiency: PropTypes.number.isRequired,
    compact: PropTypes.bool
};

SystemEfficiencyDonutCharts.databind = function({compareAndSelect}) {
    return {
        systemLightLoadEfficiency: compareAndSelect.systemLightLoadEfficiency,
        systemTypicalLoadEfficiency: compareAndSelect.systemTypicalLoadEfficiency,
        systemMaxLoadEfficiency: compareAndSelect.systemMaxLoadEfficiency
    };
};

export default databind(SystemEfficiencyDonutCharts);
