import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import databind from 'redux/utils/databind';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import SuggestedPartsComponent from 'components/SuggestedParts/SuggestedPartsComponent';
import WaitForDbDataLoaded from 'components/WaitForDbDataLoaded';
import HandleValidationErrors from 'components/HandleValidationErrors';
import css from './styles.less';
import classNames from 'classnames';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class SuggestedPartsView extends React.Component {
    static propTypes = {
        requested: PropTypes.bool.isRequired,
        received: PropTypes.bool.isRequired,
        error: PropTypes.bool.isRequired,
        errorMessage: PropTypes.string
    };

    static actions = {
    };

    static databind = function({suggestedParts}) {
        return {
            requested: suggestedParts.requested,
            received: suggestedParts.received,
            error: suggestedParts.error,
            errorMessage: suggestedParts.message
        };
    };

    render() {
        return (
            <div id="spSection" className={css.suggestedParts} data-test="solutions_box">
                <div className={css.arrowContainer}>
                    <span className="sp-arrow-down"></span>
                </div>
                <WaitForDbDataLoaded>
                    <HandleValidationErrors error={this.props.error} errorMessage={this.props.errorMessage}>
                        <Row>
                            <Col>
                                <h2 className={classNames(css.suggestedPartsTitle)}>
                                    {getText(contentKeys.SUGGESTED_PARTS)}
                                </h2>
                                <p className={classNames(css.suggestedPartsInstruction, 'headline')}>
                                    {getText(contentKeys.SELECT_PARTS_YOU_MIGHT)}
                                </p>
                            </Col>
                        </Row>
                        <SuggestedPartsComponent />
                    </HandleValidationErrors>
                </WaitForDbDataLoaded>
            </div>
        );
    }
}

export default withRouter(databind(SuggestedPartsView));
