import SolutionType from '../logic/SolutionType';
import {SchematicFilterType, AECQFilterType, SyncFilterType, DEFAULT_RESULTS_PER_TOPOLOGY_LIMIT,
    DEFAULT_RESULTS_ORDER_BY_SETTING, DEFAULT_SHOW_PRICE_SETTING} from 'logic/constants';

export const defaultFilters = {
    solutionTypes: [SolutionType.ALL],
    schematicFilters: [SchematicFilterType.ALL],
    aecqFilter: AECQFilterType.ALL,
    syncFilter: SyncFilterType.ALL
};

export const defaultSettings = {
    limit: DEFAULT_RESULTS_PER_TOPOLOGY_LIMIT,
    orderBy: DEFAULT_RESULTS_ORDER_BY_SETTING,
    showPrice: DEFAULT_SHOW_PRICE_SETTING
};

export const suggestedPartsInitialState = {
    requested: false,
    received: false,
    error: false,
    errorMessage: "",
    results: [],
    tick: 0,
    ...defaultFilters,
    ...defaultSettings,
    additionalOrderByColumn: null,
    selectedParts: {},
    showWarning: true,
    counts: {},
    total: 0,
    canContinue: false,
    showSelectedParts: false,
    expandedState: { allAreExpanded: false, allAreCollapsed: true, change: null }
};

export const colorPalette = {
    inactiveColors: [
        {color: "cor1", id: ""},
        {color: "cor2", id: ""},
        {color: "cor3", id: ""},
        {color: "cor4", id: ""},
        {color: "cor5", id: ""},
        {color: "cor6", id: ""},
        {color: "cor7", id: ""},
        {color: "cor8", id: ""},
        {color: "cor9", id: ""},
        {color: "cor10", id: ""}
    ],
    activeColors: {}
};

export const partsDbInitialState = {
    requested: false,
    received: false,
    error: false,
    errorMessage: "",
    data: {},
    version: ""
};

export const vendorsInitialState = {
    requested: false,
    received: false,
    error: false,
    errorMessage: "",
    data: {},
    version: ""
};

export const efficiencyDbInitialState = {
    requested: false,
    received: false,
    error: false,
    errorMessage: "",
    vendors: [],
    platforms: [],
    version: ""
};

export const referenceDesignInitialState = {
    status: "queueing",
    requested: false,
    error: false,
    errorMessage: "",
    queuePosition: null,
    waitTime: null,
    fileList: [],
    zipfile: false,
    excludedParts: [],
    designId: null,
    emailZipModalShown: false,
    sendingEmail: false,
    emailError: false,
    // projectName: "",
    // lastModified: "",
    validProjectName: false,
    showErrors: false,
    // isSignedIn: false,
    // checkedAuthStatus: false
};
