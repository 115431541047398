import PropTypes from 'prop-types';
import React from 'react';
import Kendo from 'kendo-ui-react';
import databind from 'redux/utils/databind';
import {actions as startOptionsActions, selectors} from 'redux/modules/startOptions';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const VendorList = function(props) {
    const onSelectVendor = function() {
        props.selectVendor({ vendor: this.value() });
    };

    return (
        <Kendo.DropDownList
            options={{
                dataSource: props.templatesDb.vendors,
                value: props.selectedVendor,
                change: onSelectVendor,
                optionLabel: getText(contentKeys.SELECT_VENDOR),
                enable: props.enable && !!(props.templatesDb.vendors && props.templatesDb.vendors.length),
                open: function (e) {
                    e.sender.optionLabel.hide();
                },
                select: function (e) {
                    document.getElementById('selectProjectTemplate').scrollIntoView();
                }
            }}
        />
    );
};

VendorList.propTypes = {
    templatesDb: PropTypes.object.isRequired,
    selectedVendor: PropTypes.string.isRequired,
    enable: PropTypes.bool.isRequired,
    selectVendor: PropTypes.func.isRequired
};

VendorList.databind = function({startOptions}) {
    const { selectedVendor } = startOptions;
    return { selectedVendor, enable: selectors.useTemplate(startOptions) };
};

VendorList.actions = {
    selectVendor: startOptionsActions.onSelectVendor
};

export default databind(VendorList);
