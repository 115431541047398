import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

export default class PopupMenu extends React.Component {
    state = {
        opened: false
    };

    static propTypes = {
        children: PropTypes.node,
        getOpenButton: PropTypes.func.isRequired,
        className: PropTypes.string
    };

    static defaultProps = {
        getOpenButton: () => (
            <a>
                <i className="fa fa-caret-down" />
                <span className={css.headerFont}>{getText(contentKeys.MORE)}</span>
            </a>
        )
    };

    handleDocumentClick = (e) => {
        if (this.containerRef && !this.containerRef.contains(e.target)) {
            this.setState({ opened: false });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.opened && !prevState.opened) {
            $(window).bind('click touchend', this.handleDocumentClick);
        }
        else if (!this.state.opened && prevState.opened) {
            $(window).unbind('click touchend', this.handleDocumentClick);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click touchend', this.handleDocumentClick);
    }

    handleOpenClick = () => {
        this.setState(({ opened }) => ({ opened: !opened }));
    }

    getContainerRef = (inst) => {
        this.containerRef = inst;
    }

    render() {
        return (
            <div ref={this.getContainerRef}
                className={this.props.className}>
                <div onClick={this.handleOpenClick}>
                    {this.props.getOpenButton()}
                </div>
                {
                    this.state.opened ? (
                        <div className={css.menuList}
                            onClick={this.handleClick}>
                            {this.props.children}
                        </div>
                    ) : null
                }
            </div>
        );
    }
}
