import locale from 'util/locale';

// Include the base API URL specified in the environment variable
export default (url) => {
    if (__API_LOCALE__) {
        return '/' + locale() + __API_URL__ + url;
    }

    return __API_URL__ + url;
};
