import PropTypes from 'prop-types';
import React from 'react';
import Kendo from 'kendo-ui-react';
import css from './styles.less';
import classNames from 'classnames';

const defaultOptions = {
    min: 0,
    max: 100,
    dragHandleTitle: "",
    rightDragHandleTitle: "",
    firstDragHandleTitle: "",
    tooltip: { enabled: false },
    tickPlacement: "none",
    showButtons: false,
    smallStep: 1
};

export default class Slider extends React.PureComponent {
    componentDidUpdate() {
        this.updateValue();
    }

    componentDidMount() {
        this.updateValue();
    }

    updateValue() {
        if (this.sliderRef.$rootElement) {
            this.sliderRef.$rootElement.data('kendoSlider').value(this.props.value);
        }
    }

    getSliderRef = inst => {
        this.sliderRef = inst;
    };

    render() {
        const { className, onChange, value, ...rest } = this.props; // eslint-disable-line no-unused-vars
        const options = {
            ...defaultOptions,
            slide: onChange,
            change: onChange,
            ...rest
        };

        return (
            <div className={classNames(css.slider, className)}>
                <Kendo.Slider ref={this.getSliderRef} options={options} />
            </div>
        );
    }
}

Slider.defaultProps = {
    className: ''
};

Slider.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired
};
