import PropTypes from 'prop-types';
import React from 'react';
import Kendo from 'kendo-ui-react';
import css from './styles.less';

class MoreOptions extends React.Component {
    static propTypes = {
        children: PropTypes.object.isRequired
    };

    constructor() {
        super();
        this.state = { show: false };
    }

    toggle = () => {
        this.setState({ show: !this.state.show });
    };

    hide = () => {
        this.setState({ show: false });
    };

    render() {
        const style = {
            backgroundColor: '#fff',
            boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
            border: "none",
            padding: "15px 25px",
            borderRadius: 3
        };

        const options = {
            anchor: this.refs.target,
            origin: "bottom middle",
            position: "top right",
            collision: "flip fit",
            close: this.hide
        };

        return (
            <div>
                <div onClick={this.toggle} className={css.moreOptBtn}>
                    <a ref="target">
                        <i className="fa fa-caret-down"></i>
                    </a>
                </div>
                <Kendo.Popup
                    open={this.state.show}
                    options={options}>
                    <div style={style}>
                        {this.props.children}
                    </div>
                </Kendo.Popup>
            </div>
        );
    };
}

export default MoreOptions;
