import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';

const ModifiedCornerDetail = (props) => {
    return (props.show) ? <div className={css.modified}><div /></div> : null;
};

ModifiedCornerDetail.propTypes = {
    show: PropTypes.bool.isRequired
};

export default ModifiedCornerDetail;
