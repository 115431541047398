import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import classNames from 'classnames';

const Error = ({children, className, ...props}) => {
    return (
        <div {...props} className={classNames(className, css.errorContainer)}>
            <i className="fa fa-exclamation" />{children}
        </div>
    );
};

Error.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default Error;
