/* global kendo, $ */
import forOwn from 'lodash/forOwn';
import at from 'lodash/at';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import {mapOrderByToSortInfo} from 'logic/orderByMappings';
import {PartFieldNames, GridSortDirs} from 'logic/constants';
import {limitResults, applyAllSolutionFilters, filterBySelectedParts} from 'logic/suggestedParts';
import applyOrderBySorting from 'logic/applyOrderBySorting';

function transformGroupsForKendo(grouped) {
    let kendoGroups = [];
    forOwn(grouped, (items, topology) => {
        kendoGroups.push({
            aggregates: {},
            field: "topology",
            hasSubgroups: false,
            items: items,
            value: topology
        });
    });
    return kendoGroups;
}

function attachSortableIndexToEachGroup(groups, field) {
    forOwn(groups, function(parts, topology) {
        groups[topology].index = 0;
        if ((field !== "deviceName") && (parts.length > 0)) {
            let index = parts.reduce((a, x) => {
                return a + at(x, field)[0]; // to handle path.type.fields
            }, 0);
            index = index / parts.length;
            index += at(parts[0], field)[0]; // make 1st sort field dominant
            groups[topology].index = index;
        }
    });
}

function attachFormattedIoutMax(groups) {
    forOwn(groups, (parts) => {
        parts.forEach((part) => {
            const outputs = part.outputs;
            part.formattedIoutMax = outputs && outputs.map(o => o.ioutMax).join(' | ');
        });
    });
};

function isSortInfoEqual(a, b) {
    return (a.dir === b.dir) && (a.field === b.field);
}

function ensureInfoHasSortableField(sortInfo) {
    const compoundFieldNameIndex = sortInfo.field.search(/\..+$/);
    return (compoundFieldNameIndex !== -1)
        ? { ...sortInfo, field: sortInfo.field.slice(compoundFieldNameIndex + 1) }
        : sortInfo;
}

const getSortResolveFunction = ({field, dir}) => {
    if (field === PartFieldNames.IOUT_MAX) {
        if (dir === GridSortDirs.DESCENDING) {
            return part => {
                const outputs = part.outputs;
                return outputs && maxBy(outputs, 'ioutMax').ioutMax;
            };
        }
        return part => {
            const outputs = part.outputs;
            return outputs && minBy(outputs, 'ioutMax').ioutMax;
        };
    }
};

/**
 *
 * The detail datasource is a plain [options] object that just holds a reference
 * to the data array supplied on construction during detialInit, effectively reaching in to
 * the right part of the data structure managed by the top level data source.
 *
 * */
export class PartsDetailDataSource extends kendo.data.DataSource {
    constructor(store, data, outputHasLdoFlag, resultId) {
        let options = {};
        options.data = [];
        options.transport = {
            read: (options) => {
                // todo refactor this to use the filtered parts data already computed and stored on the state

                // read may be invoked from a sort action by the user on the header of a sortabloe column
                // in this instance options.data.sort[0] will have the new sort selection. We should not complete
                // the normal read but instead dispatch a redux action to set the main sort (orderBy) state and allow
                // that to drive the read
                if (!isSortInfoEqual(options.data.sort[0], this.local.sortInfo)) {
                    // whenever there is a change to sort order from the update redux
                    this.local.sortInfo = options.data.sort[0];
                }
                const { masterData,
                    solutionTypes,
                    limit,
                    schematicFilters, aecqFilter, syncFilter } = this.local;

                const { showSelectedParts, selectedParts } = this.store.getState().suggestedParts;

                let filteredParts = applyAllSolutionFilters(masterData,
                    solutionTypes, schematicFilters, aecqFilter, syncFilter, outputHasLdoFlag);
                if (showSelectedParts) {
                    filteredParts = filterBySelectedParts(resultId, filteredParts, selectedParts);
                }

                if (this.local.sortInfo) {
                    filteredParts = applyOrderBySorting(
                        filteredParts,
                        this.local.sortInfo, getSortResolveFunction(this.local.sortInfo));
                }

                let limited = limitResults(filteredParts, limit);
                limited = groupBy(limited, "topology");

                // create a index to rank by for a final sort on the topology order
                attachSortableIndexToEachGroup(limited, this.local.sortInfo.field);
                attachFormattedIoutMax(limited);

                options.success({ results: limited, sort: this.local.sortInfo });
            }
        };

        options.schema = {
            groups: (data) => {
                let kendoGroups = transformGroupsForKendo(data.results);
                // now we have an array of groups, sort them by the index field in the appropriate direction
                kendoGroups = sortBy(kendoGroups, 'items.index');
                if (data.sort && (data.sort.dir === "desc")) {
                    kendoGroups = kendoGroups.reverse();
                }
                return kendoGroups;
            },
            model: {
                fields: {
                    selected: { type: "boolean" },
                    deviceName: { type: "string" },
                    description: { type: "string" },
                    [PartFieldNames.RELEASE_DATE]: { type: "number" },
                    [PartFieldNames.PACKAGE_SIZE]: { type: "number" },
                    [PartFieldNames.IQ]: { type: "number" },
                    [PartFieldNames.FOOTPRINT]: { type: "number" },
                    [PartFieldNames.BOM_COST]: { type: "number" },
                    [PartFieldNames.BOM_COUNT]: { type: "number" },
                    [PartFieldNames.IOUT_MAX]: { type: "object" },
                    msrp: { type: "number" },
                    estEfficiency: { type: "number" },
                    notes: { type: "string" },
                    [PartFieldNames.ORCAD_ENABLED]: { type: "boolean" },
                    [PartFieldNames.CAN_BE_SAMPLED]: { type: "boolean" },
                    [PartFieldNames.ISIM_MODEL]: { type: "boolean" },
                    topology: { type: "string" }
                }
            }
        };

        const state = store.getState();
        const {suggestedParts} = state;
        const { limit, solutionTypes, schematicFilters, aecqFilter, syncFilter,
            showPrice, orderBy, additionalOrderByColumn } = suggestedParts;

        options.sort = ensureInfoHasSortableField(mapOrderByToSortInfo(orderBy));
        options.serverSorting = true;
        options.serverGrouping = true;
        options.group = { field: "topology", dir: "asc" };
        options.change = (e) => {
            // sort all *other* detail grids based on the sort order changes in this grid
            const newSort = e.sender.sort()[0];
            const detailGrids = $('.k-detail-row div.k-grid');
            detailGrids.each((i, node) => {
                const grid = $(node).data('kendoGrid');
                if (grid.dataSource !== this) {
                    const sortInfo = grid.dataSource.sort()[0];
                    if (sortInfo) {
                        if ((sortInfo.field !== newSort.field) || (sortInfo.dir !== newSort.dir)) {
                            grid.dataSource.setSort(newSort);
                        }
                    }
                }
            });
        };

        super(options);

        this.store = store;
        this.local = { masterData: data,
            resultId,
            outputSelectedParts: suggestedParts.selectedParts[resultId],
            sortInfo: options.sort,
            limit,
            solutionTypes,
            schematicFilters,
            aecqFilter,
            syncFilter,
            showPrice,
            additionalOrderByColumn };

        this.unsubscribe = store.subscribe(this.onStoreChange);
    }

    setSort(sortInfo) {
        this.sort(ensureInfoHasSortableField(sortInfo));
    }

    unsubscribe = () => {
        this.unsubscribe();
    };

    getLimit = () => {
        const state = this.store.getState();
        return state.suggestedParts.limit;
    };

    triggerReadOnChanges(properties) {
        const {suggestedParts} = this.store.getState();

        const hasChanges = properties.reduce((flag, p) => {
            return flag || !isEqual(this.local[p], suggestedParts[p]);
        }, false);

        if (hasChanges) {
            properties.forEach(p => {
                this.local[p] = suggestedParts[p];
            });

            this.read();
        }
    };

    onStoreChange = () => {
        this.triggerReadOnChanges(
            ['limit', 'solutionTypes', 'schematicFilters', 'aecqFilter',
                'syncFilter', 'showPrice', 'additionalOrderByColumn', 'showSelectedParts']);

        const state = this.store.getState();

        const newSelection = state.suggestedParts.selectedParts[this.local.resultId];
        if (this.local.outputSelectedParts !== newSelection) {
            this.local.outputSelectedParts = newSelection;

            // refresh to show only selected parts
            if (state.suggestedParts.showSelectedParts) {
                this.read();
            }
        }

        const newSortInfo = mapOrderByToSortInfo(state.suggestedParts.orderBy);
        if (!isSortInfoEqual(newSortInfo, this.local.sortInfo)) {
            this.local.sortInfo = newSortInfo;
            this.setSort(this.local.sortInfo);
        }
    };
};
