import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import {actions, selectors} from 'redux/modules/suggestedParts';
import SolutionType from 'logic/SolutionType';
import databind from 'redux/utils/databind';
import RestoreDefaultsItem from './RestoreDefaultsItem';
import PanelDropDownList from './PanelDropDownList';
import mapValuesToTextValuePairs from './helpers/mapValuesToTextValuePairs';
import FiltersMultiSelectDropDownList, {multiSelectWithoutAutoToggleAllLogic} from './FiltersMultiSelectDropDownList';
import {SchematicFilterType, AECQFilterType, SyncFilterType} from 'logic/constants';
import * as filtersText from './filtersText';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class FiltersPanel extends React.Component {
    static propTypes= {
        solutionTypes: PropTypes.array,
        schematic: PropTypes.array,
        aecq: PropTypes.string,
        sync: PropTypes.string,
        modified: PropTypes.bool,
        filterSolutionTypes: PropTypes.func,
        filterBySchematicStatus: PropTypes.func,
        filterByAECQStatus: PropTypes.func,
        filterBySyncStatus: PropTypes.func,
        resetFilters: PropTypes.func
    };

    static actions = {
        filterSolutionTypes: actions.filterSolutionTypes,
        filterBySchematicStatus: actions.filterBySchematicStatus,
        filterByAECQStatus: actions.filterByAECQStatus,
        filterBySyncStatus: actions.filterBySyncStatus,
        resetFilters: actions.resetFilters
    };

    static databind = ({suggestedParts}) => {
        const {solutionTypes, schematicFilters, aecqFilter, syncFilter} = suggestedParts;
        return {
            solutionTypes,
            schematic: schematicFilters,
            aecq: aecqFilter,
            sync: syncFilter,
            modified: selectors.areFiltersModified(suggestedParts)
        };
    }

    onSolutionType = (types) => {
        this.props.filterSolutionTypes(types);
    };

    onSchematicStatus = (filterType) => {
        this.props.filterBySchematicStatus(filterType);
    };

    onAECQStatus = (filterType) => {
        this.props.filterByAECQStatus(filterType);
    };

    onSyncStatus = (filterType) => {
        this.props.filterBySyncStatus(filterType);
    };

    onRestoreDefaults = () => {
        this.props.resetFilters();
    };

    render() {
        return (
            <div className={css.flexCenter}>
                <div className={css.filtersPanel}>
                    <FiltersMultiSelectDropDownList
                        data={mapValuesToTextValuePairs(SolutionType)}
                        title={getText(contentKeys.SOLUTION_TYPES)}
                        info={filtersText.solutionTypeTooltipText}
                        selected={this.props.solutionTypes}
                        allSelection={SolutionType.ALL}
                        onChange={this.onSolutionType} />
                    <PanelDropDownList
                        title={getText(contentKeys.AECQ)}
                        info={filtersText.aecqTooltipText}
                        data={mapValuesToTextValuePairs(AECQFilterType)}
                        selected={this.props.aecq}
                        onChange={this.onAECQStatus} />
                    <PanelDropDownList
                        title={getText(contentKeys.SYNC)}
                        info={filtersText.syncStatus}
                        data={mapValuesToTextValuePairs(SyncFilterType)}
                        selected={this.props.sync}
                        onChange={this.onSyncStatus} />
                    <FiltersMultiSelectDropDownList
                        data={mapValuesToTextValuePairs(SchematicFilterType)}
                        info={filtersText.schematicTooltipText}
                        title={getText(contentKeys.OTHER)}
                        selected={this.props.schematic}
                        allSelection={SchematicFilterType.ALL}
                        selectionLogic={multiSelectWithoutAutoToggleAllLogic}
                        onChange={this.onSchematicStatus} />
                    <RestoreDefaultsItem
                        onRestore={this.onRestoreDefaults}
                        disabled={!this.props.modified} />
                </div>
            </div>);
    }
}

export default databind(FiltersPanel);
