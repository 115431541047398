import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import ModalContent from 'components/ModalContent';
import {isAValidProjectName} from 'logic/validation';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class ProjectNameModalContent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        if (!props.open && (state && state.dirty)) {
            return { dirty: false };
        }
        else if (!state || !state.dirty) {
            return {
                valid: isAValidProjectName(props.name, props.names),
                newName: props.name,
                dirty: false
            };
        }
        return null;
    }

    onChangeProjectName = (e) => {
        const {value} = e.target;
        const valid = isAValidProjectName(value, this.props.names);
        this.setState({ valid, newName: value, dirty: true });
    };

    onSubmit = () => {
        this.props.onOK(this.state.newName);
    };

    render() {
        const errorClass = (!this.state.valid && this.state.dirty) ? css.inputError : "";
        return (
            <ModalContent
                loading={this.props.loading}
                onCancel={this.props.onCancel}
                onSubmit={this.onSubmit}
                submitLabel={this.props.submitLabel}
                canSubmit={this.state.valid} >
                <div>
                    <p>{this.props.prompt}</p>
                    <span className={css.projectNameLabel}>{getText(contentKeys.PROJECT_NAME)}</span>
                    <input
                        type="text"
                        disabled={this.state.loading}
                        maxLength="255"
                        value={this.state.newName}
                        className={`form-control ${css.projectNameInput} ${errorClass}`}
                        onChange={this.onChangeProjectName} />
                </div>
            </ModalContent>);
    }
}

ProjectNameModalContent.propTypes = {
    onOK: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    names: PropTypes.array,
    name: PropTypes.string,
    prompt: PropTypes.string,
    submitLabel: PropTypes.string,
    loading: PropTypes.bool
};

export default ProjectNameModalContent;
