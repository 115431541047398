import EditorCSVGenerator from 'logic/EditorCSVGenerator';
import SuggestedPartsCSVGenerator from 'logic/SuggestedPartsCSVGenerator';
import CompareAndSelectCSVGenerator from 'logic/CompareAndSelectCSVGenerator';

export class CSV {
    constructor(arrayOfGenerators) {
        this.str = "";
        this.generators = arrayOfGenerators;
    }

    reset() {
        this.str = "";
        return this;
    }

    write(values, numCols) {
        if (!numCols) {
            numCols = values.length;
        }
        for (let i = 0; i < numCols; i++) {
            let current = "";
            if (values.length > 0) [current, ...values] = values;
            this.str += current;
            this.str += ",";
        }
        return this;
    };

    trim = (n) => {
        this.str = this.str.slice(0, -1*n);
        return this;
    };

    gap = (N) => {
        N = N || 1;
        for (var n = 0; n < N; n++) {
            this.str += ",";
        }
        return this;
    };

    endl = () => {
        this.str = this.trim(1) + "\n";
        return this;
    };

    lineBreak = () => {
        this.str = this.str + ",\n";
        return this;
    };

    sectionBreak = () => {
        this.str = this.str + "\n\n\n\n";
        return this;
    };

    stringify = (arrayOfStateFragments) => {
        this.reset();
        if ((!arrayOfStateFragments) || (arrayOfStateFragments.length === 0)) return "";
        this.generators.forEach((generator, index) => {
            generator.stringify(this, arrayOfStateFragments[index]);
            this.sectionBreak();
        });
        return this.str;
    };

    toString() {
        return this.str;
    };
}

export default function createCSVBuilder(location) {
    var generators = [ new EditorCSVGenerator() ];

    if (location === '/editor/solutions') {
        generators.push(new SuggestedPartsCSVGenerator());
    }

    if (location === '/compare') {
        generators.push(new SuggestedPartsCSVGenerator());
        generators.push(new CompareAndSelectCSVGenerator());
    }

    return new CSV(generators);
}
