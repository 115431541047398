import PropTypes from 'prop-types';
import React from 'react';
import DocumentTitle from 'components/DocumentTitle';
import LoadingSpinner from 'components/LoadingSpinner';
import {actions as projectActions} from 'redux/modules/projects';
import {actions as authActions} from 'redux/modules/auth';
import {actions as headerActions} from 'redux/modules/header';
import databind from 'redux/utils/databind';
import makeSignInUrl from "util/makeSignInUrl";
import makeAppUrl from "util/makeAppUrl";
import {forcePersist} from "redux/persist";
import css from './styles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class OpenProjectView extends React.Component {
    static propTypes = {
        openProject: PropTypes.func.isRequired,
        toggleProjectHeader: PropTypes.func.isRequired,
        params: PropTypes.shape({
            projectId: PropTypes.string.isRequired
        }).isRequired,
        route: PropTypes.shape({
            shared: PropTypes.bool
        }),
        checkIsSignedInNow: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired
        }).isRequired
    };

    static actions = {
        openProject: projectActions.openProject,
        checkIsSignedInNow: authActions.checkIsSignedInNow,
        toggleProjectHeader: headerActions.toggleProjectHeader
    };

    static databind = () => {
        return {};
    };

    async componentDidMount() {
        const {openProject, params, route, location, checkIsSignedInNow, toggleProjectHeader} = this.props;
        const projectId = params.projectId.replace('.html', '');

        toggleProjectHeader(false);

        const {value} = await checkIsSignedInNow();

        if (value.isSignedIn) {
            openProject(projectId, route.shared);
        }
        else {
            forcePersist(() => {
                window.location = makeSignInUrl(makeAppUrl(location.pathname));
            });
        }
    }

    render() {
        return (
            <DocumentTitle title="Opening Project">
                <div className={css.projectLoadingContainer}>
                    <LoadingSpinner message={getText(contentKeys.OPENING_YOUR_PROJECT)} />
                </div>
            </DocumentTitle>
        );
    }
}

export default databind(OpenProjectView);
