import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import css from './styles.less';

const Pages = ({className, children, currentPage, onTransitionEnd}) => (
    <div className={classNames(css.pagesContainer, className)}
        onTransitionEnd={onTransitionEnd}
        style={{transform: `translateX(${-currentPage * 100}%)`}}>
        {children}
    </div>
);

Pages.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    currentPage: PropTypes.number.isRequired,
    onTransitionEnd: PropTypes.func
};

Pages.defaultProps = {
    currentPage: 0
};

export default Pages;
