/* global $ */

import PropTypes from 'prop-types';

import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import ActionButton from 'components/ActionButton';
import Error from 'components/Error';
import css from './styles.less';
import { isAValidProjectName } from "logic/validation";
import { SIGN_IN_PAGE } from 'util/makeSignInUrl';
import { REGISTER_PAGE } from 'util/makeRegisterUrl';
import classNames from 'classnames';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class ProjectNameForm extends React.Component {
    static propTypes = {
        // from data bind
        initialProjectName: PropTypes.string.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        isOffline: PropTypes.bool.isRequired,
        checkedAuthStatus: PropTypes.bool.isRequired,
        projectNames: PropTypes.array.isRequired,
        projectLoading: PropTypes.bool.isRequired,

        // actions
        toggleProjectHeader: PropTypes.func.isRequired,
        fetchAuthStatus: PropTypes.func.isRequired,
        onClickAuthButton: PropTypes.func.isRequired,
        onContinue: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        instructions: PropTypes.node.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            projectName: props.initialProjectName,
            valid: isAValidProjectName(props.initialProjectName, props.projectNames)
        };
    }

    componentDidMount() {
        this.props.toggleProjectHeader(false);
        this.props.fetchAuthStatus();
    }

    componentWillUnmount() {
        this.props.toggleProjectHeader(true);
    }

    onChangeProjectName = (e) => {
        const projectName = e.target.value;
        this.setState((state) => ({
            projectName,
            valid: isAValidProjectName(projectName, this.props.projectNames)
        }));
    };

    onClickSignIn = () => {
        this.props.onClickAuthButton(SIGN_IN_PAGE, this.state.projectName);
    };

    onClickRegister = () => {
        this.props.onClickAuthButton(REGISTER_PAGE, this.state.projectName);
    };

    onClickContinue = () => {
        this.props.onContinue(this.state.projectName);
    };

    render() {
        const { isSignedIn, isOffline, checkedAuthStatus, projectLoading } = this.props;
        const invalidName = !this.state.valid;
        const buttonsDisabled = invalidName || projectLoading;
        const errorClass = invalidName ? " " + css.inputError : "";

        if (!checkedAuthStatus) return null; // wait until we know the authentication status before rending anything
        const canContinue = isSignedIn || isOffline;

        return (
            <Row className={css.projectNameFormContainer}>
                <div className={css.instructionsContainer}>
                    <h2>{this.props.title}</h2>
                    <div>{this.props.instructions}</div>
                </div>
                <ul className="list-unstyled">
                    <li className="layout horizontal wrap">
                        <span className={css.projectNameLabel}>{getText(contentKeys.PROJECT_NAME)}</span>
                        <div className="layout horizontal wrap full-width flex">
                            <input
                                type="text"
                                value={this.state.projectName}
                                maxLength="255"
                                className={`form-control ${css.projectNameInput}${errorClass}`}
                                onChange={this.onChangeProjectName} />
                            {invalidName &&
                                <Error style={{ marginBottom: '0px', whiteSpace: 'initial' }} className="self-center">
                                    {getText(contentKeys.A_UNIQUE_AND_VALID, '<br/>')}
                                </Error>}
                        </div>
                    </li>
                    {canContinue &&
                        <li className={css.canContinueLi}>
                            <div className={css.continueContainer}>
                                <ActionButton
                                    id="Generate_Design"
                                    label={getText(contentKeys.CONTINUE)}
                                    disabled={buttonsDisabled}
                                    className={classNames(css.wideButton, 'large')}
                                    onClick={this.onClickContinue} />
                            </div>
                        </li>
                    }
                    {!canContinue &&
                        <li className={css.signInLi}>
                            <div className={css.signInContainer}>
                                <h3 className={css.accountLabel}>{getText(contentKeys.ALREADY_HAVE_AN_ACCOUNT)}</h3>
                                <ActionButton
                                    id="Generate_SignIn"
                                    label={getText(contentKeys.SIGN_IN)}
                                    disabled={buttonsDisabled}
                                    className={classNames(css.wideButton, 'large')}
                                    onClick={this.onClickSignIn} />
                            </div>
                        </li>
                    }
                    {!canContinue &&
                        <li className={css.noNumber}>
                            <div className={css.registrationContainer}>
                                <h3 className={css.registerLabel}>{getText(contentKeys.OR_REGISTER)}</h3>
                                <div className={css.registrationText}>
                                    {getText(contentKeys.REGISTRATION_IS_SECURE_FAST)}
                                </div>
                                <ActionButton
                                    id="Generate_Register"
                                    label={getText(contentKeys.REGISTER)}
                                    disabled={buttonsDisabled}
                                    className={classNames(css.wideButton, 'large')}
                                    onClick={this.onClickRegister} />
                            </div>
                        </li>
                    }
                </ul>
            </Row>
        );
    }
}

export default ProjectNameForm;
