import PropTypes from 'prop-types';
import React from 'react';
import SystemBannerLineChart from './SystemBannerLineChart';
import {AutoResizeContainer} from 'components/withElementSize';
import classNames from 'classnames';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class SystemPowerDissipationChart extends React.PureComponent {
    render() {
        const {data, compact, className} = this.props;
        const NUM_STEPS = 4;
        // note the height of this graph will stay the same as the efficiency chart if
        // we make sure yMax is a multiple of NUM_STEPS
        let yMax = data.reduce((a, p) => Math.max(p[1], a), 0);
        let step = 1;
        if (yMax > 0) {
            const orderOfMag = yMax > 1 ? 1 : Math.pow(10, -Math.floor(Math.log10(yMax)));
            yMax = NUM_STEPS*Math.ceil(yMax*orderOfMag/NUM_STEPS);
            step = Math.floor(yMax/NUM_STEPS) / orderOfMag;
            yMax = yMax / orderOfMag;
        }
        else {
            yMax = NUM_STEPS;
            step = 1;
        }

        return (
            <AutoResizeContainer child={({width, height}) => (
                <div className={classNames('self-stretch flex', className)}>
                    {width > 0 ? (
                        <SystemBannerLineChart
                            chartTitle={getText(contentKeys.SYSTEM_POWER_DISSIPATION)}
                            seriesName={getText(contentKeys.SUM_POWER_LOSS)}
                            xAxisTitle={getText(contentKeys.LOAD_CURRENT_OF_MAX)}
                            yAxisTitle={getText(contentKeys.POWER_DISSIPATION_W)}
                            yMin={0}
                            width={Math.max(width, 300)}
                            yMax={yMax}
                            yStep={step}
                            data={data}
                            compact={compact} />
                    ) : null}
                </div>
            )} />
        );
    }
}

SystemPowerDissipationChart.propTypes = {
    data: PropTypes.array.isRequired,
    compact: PropTypes.bool,
    className: PropTypes.string
};

export default SystemPowerDissipationChart;
