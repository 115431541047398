import fill from 'lodash/fill';
import find from 'lodash/find';
import range from 'lodash/range';

// convert from flat arrays to an array of pointy arrays for scatterLine plots
export function scatterize(curve) {
    return curve.x.map((x, idx) => [x, curve.y[idx]]);
}

export function getPercentageRange(numSamples) {
    return [ ...range(0, 100, 100/(numSamples-1)), 100 ];
}

export function defaultCurve(numSamples) {
    let defaultCurve = { x: getPercentageRange(numSamples), y: [] };
    defaultCurve.y.length = defaultCurve.x.length;
    fill(defaultCurve.y, 0);
    return defaultCurve;
}

export function getReferenceCurvesForOutputAndPart(outputId, selectedPartId, computedData) {
    const dataForThisOutput = find(computedData, (x) => x.id === outputId);
    let partData = null;
    if (selectedPartId != null) {
        partData = find(dataForThisOutput.parts, (x) => x.id === selectedPartId);
    }
    else {
        partData = dataForThisOutput.parts[0];
    }
    return (partData != null) ? partData.referenceCurves: null;
}
