import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'bootstrap-core';
import css from './styles.less';
import OptimizeButton from 'components/Editor/OptimizeButton';
import MinEfficiency from './MinEfficiency';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const SystemOutputsHeader = ({ headerSettingsClassName }) => (
    <Row className={css.systemOutputsHeader}>
        <Col xs={6} className={css.noPadding}>
            <h3 className={css.tableTitle}>{getText(contentKeys.REGULATED_OUTPUTS)}</h3>
        </Col>
        <Col xs={3} className={classNames(css.order, headerSettingsClassName)}>
            <OptimizeButton />
        </Col>
        <Col xs={3} className={classNames(css.noPadding, headerSettingsClassName)}>
            <Row className={css.minEfficiency}>
                <Col className={css.noPadding} xs={7}>
                    <span className={css.heading}>{getText(contentKeys.ASSUMED_MIN_EFFICIENCY)}</span>
                </Col>
                <Col className={css.noPadding} xs={5}>
                    <MinEfficiency />
                </Col>
            </Row>
        </Col>
    </Row>
);

SystemOutputsHeader.propTypes = {
    headerSettingsClassName: PropTypes.string
};

export default SystemOutputsHeader;
