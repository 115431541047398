/* global $ */
import kendoOverridesCss from 'styles/kendoOverrides.less';

export default ($element, content, addtlClassOverride, position = 'left') => {
    function onShow(e) {
        $(this.popup.wrapper)
            .addClass(kendoOverridesCss.tooltipWrapper)
            .addClass(addtlClassOverride);
    }

    $element.kendoTooltip({
        content,
        position,
        callout: false,
        show: onShow,
        width: 250
    });
};
