import makeApiUrl from './makeApiUrl';
import {getJson} from './ajax';
import cacheBust from './cacheBust';

export const isUserSignedIn = () => {
    // Sending the reference design status API a dummy id just for it's auth response
    // When not logged in, backend returns in {"status": null}, otherwise {} when authenticated
    // TODO: Ask Eric for a better way to do this
    return new Promise((resolve, reject) => {
        getJson(makeApiUrl(cacheBust("status.json/checklogin")))
            .then((result) => {
                const isSignedIn = (result.status !== null);
                if (!isSignedIn && (window.resetname !== undefined)) {
                    window.resetname();
                }
                resolve({isSignedIn});
            },
            (error) => {
                if (window.resetname !== undefined) {
                    window.resetname();
                }
                reject(error.statusText);
            });
    });
};

export default {isUserSignedIn};
