import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import classNames from 'classnames';
import PopupMenu from 'components/PopupMenu';
import MinEfficiency from 'components/Editor/MinEfficiency';
import OptimizeButton from 'components/Editor/OptimizeButton';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const SettingsContent = () => (
    <div className={css.settingsContent}>
        <OptimizeButton className={css.optimizeButton} />
        <div className={css.settingsMinEfficiencyContainer}>
            <div className={classNames(css.noPadding, css.flex2)}>
                <span className={css.heading}>{getText(contentKeys.ASSUMED_MIN_EFFICIENCY)}</span>
            </div>
            <div className={classNames(css.noPadding, css.flex1, css.minEfficiencyInputContainer)}>
                <MinEfficiency />
            </div>
        </div>
    </div>
);

const getSettingsIcon = () => (
    <a className={css.settingsIcon}>
        <i className="fa fa-cog" />
    </a>
);

const EditorSettings = ({ className }) => (
    <PopupMenu className={className} getOpenButton={getSettingsIcon}>
        <SettingsContent />
    </PopupMenu>
);

EditorSettings.propTypes = {
    className: PropTypes.string
};

export default EditorSettings;
