import PropTypes from 'prop-types';
import React from 'react';
import databind from 'redux/utils/databind';
import {Link} from 'react-router';
import css from './styles.less';
import helpUrl from "util/helpUrl";
import offlineVersionUrl from "util/offlineVersionUrl";
import {actions} from 'redux/modules/header';
import {actions as projectActions, selectors as projectSelectors} from 'redux/modules/projects';
import {CopyProjectModal, SendModal} from 'components/Projects/ProjectModals';
import {isReferenceDesignStatus, ProjectStatus} from 'logic/status';
import classNames from 'classnames';
import KendoTooltipOverride from 'kendo-plugins/TooltipWrapper';
import ResponsiveMenu from './ResponsiveMenu';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const preventDefault = (e) => e.preventDefault();
const noOp = () => {};

const getBackLink = (pathname) => {
    if (pathname.startsWith('/compare')) {
        return {
            url: '/editor/solutions',
            text: getText(contentKeys.EDIT_DESIGN),
            icon: 'fa-caret-left'
        };
    }
    else {
        return {
            url: '/',
            text: getText(contentKeys.NEW_PROJECT),
            icon: 'fa-plus'
        };
    }
};

class HeaderLink extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        buildCSVString: PropTypes.func.isRequired,
        trySavingProject: PropTypes.func.isRequired,
        enabled: PropTypes.bool.isRequired,
        isAProjectLoaded: PropTypes.bool.isRequired,
        showSaveProjectLink: PropTypes.bool.isRequired,
        showExportCsvLink: PropTypes.bool.isRequired,
        showBackLink: PropTypes.bool.isRequired,
        showOfflineVersionLink: PropTypes.bool.isRequired,
        showHelpLink: PropTypes.bool.isRequired,
        showYourProjectsLink: PropTypes.bool.isRequired,
        showSendLink: PropTypes.bool.isRequired,
        showCopyEditLink: PropTypes.bool.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        isOffline: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        refDesignError: PropTypes.bool.isRequired,
        designId: PropTypes.string,
        projectId: PropTypes.string.isRequired,
        isDesignShareRecipient: PropTypes.bool.isRequired,
        projectStatus: PropTypes.string.isRequired,
        projectName: PropTypes.string.isRequired,
        unsavedProject: PropTypes.bool.isRequired,
        unownedRefDesign: PropTypes.bool.isRequired,
        showCopyEditModal: PropTypes.bool.isRequired,
        projectLoading: PropTypes.bool.isRequired,
        projects: PropTypes.array,
        toggleCopyEditModal: PropTypes.func.isRequired,
        copyAndEditProject: PropTypes.func.isRequired,
        tryCopyingProject: PropTypes.func.isRequired,
        showSendModal: PropTypes.bool.isRequired,
        toggleSendModal: PropTypes.func.isRequired,
        trySendingProject: PropTypes.func.isRequired,
        sendProject: PropTypes.func.isRequired
    };

    static databind ({auth, header, projects, referenceDesign, offline}, props) {
        const {showSaveProjectLink, showExportCsvLink, showBackLink,
            showOfflineVersionLink, showHelpLink, showYourProjectsLink, showSendLink} = header;
        const { showCopyEditModal, loading, listing, current, showSendModal } = projects;
        const projectStatus = current['status'] || '';
        const {isSignedIn} = auth;

        const pathname = props.location.pathname.replace('.html', '');
        const onRefDesignPage = (isReferenceDesignStatus(projectStatus) ||
                                pathname === `/reference/${referenceDesign.designId}`) &&
                                pathname !== '/' && pathname !== '/projects';
        const isAProjectLoaded = projectSelectors.isAProjectLoaded(projects);
        const userOwnsDesign = !!referenceDesign.projectId && !!listing &&
                               listing.some(p => p.id === referenceDesign.projectId);
        const isDesignShareRecipient = !userOwnsDesign && !!current['shareId'] &&
                                       current['referenceDesignId'] === referenceDesign.designId &&
                                       current['projectId'] === referenceDesign.projectId;
        const showCopyEditLink = onRefDesignPage && !referenceDesign.error;

        let projectId = '';

        if (onRefDesignPage && referenceDesign.projectId) {
            projectId = referenceDesign.projectId;
        }
        else if (!onRefDesignPage && current['id']) {
            projectId = current['id'];
        }

        return { showSaveProjectLink,
            showExportCsvLink,
            showBackLink,
            showOfflineVersionLink,
            showHelpLink,
            showYourProjectsLink,
            showCopyEditModal,
            showSendModal,
            showSendLink: showSendLink && !referenceDesign.error,
            showCopyEditLink,
            unownedRefDesign: onRefDesignPage && !userOwnsDesign && !!referenceDesign.projectId,
            unsavedProject: !isAProjectLoaded && !referenceDesign.projectId,
            projectLoading: loading,
            projects: listing,
            isAProjectLoaded,
            isDesignShareRecipient,
            refDesignError: referenceDesign.error,
            designId: referenceDesign.designId,
            projectId,
            projectName: current['name'] || referenceDesign.projectName || '',
            projectStatus,
            projectShareId: current['shareId'] || '',
            isSignedIn,
            isOffline: offline.isOffline };
    }

    static actions = {
        buildCSVString: actions.buildCSVString,
        saveTheLoadedProject: projectActions.saveTheLoadedProject,
        trySavingProject: projectActions.trySavingProject,
        toggleCopyEditModal: projectActions.toggleCopyEditModal,
        copyAndEditProject: (projectId, name, shared) => {
            return projectActions.tryProjectAction('copyAndEditProject', projectId, name, shared);
        },
        tryCopyingProject: () => projectActions.tryProjectAction('toggleCopyEditModal', true),
        toggleSendModal: projectActions.toggleSendModal,
        trySendingProject: () => projectActions.tryProjectAction('toggleSendModal', true),
        sendProject: (projectId, email) => {
            return projectActions.tryProjectAction('sendProject', projectId, email);
        }
    };

    componentDidMount() {
        this._sendTooltipOverridden = false;
        this._copyEditTooltipOverridden = false;

        this.setupTooltips();
    }

    componentDidUpdate(prevProps) {
        if ((!prevProps.unownedRefDesign && this.props.unownedRefDesign) ||
            (prevProps.isOffline !== this.props.isOffline)) {
            this._sendTooltipOverridden = false;
        }

        this.setupTooltips();
    }

    setupTooltips = () => {
        const {unownedRefDesign, unsavedProject, isDesignShareRecipient, projectStatus, showCopyEditLink,
            showSendLink, isOffline} = this.props;
        let sendTooltipMsg = unownedRefDesign ? 'Sending a project is only available for the project owner'
            : 'Please save the project first';
        const copyEditTooltipMsg = 'The project owner must Send the project to you to allow you to copy and edit';
        const showSendTooltip = showSendLink && (unownedRefDesign || unsavedProject || isOffline) &&
                                projectStatus !== ProjectStatus.GENERATING_REFERENCE_DESIGN;
        const showCopyEditTooltip = showCopyEditLink && unownedRefDesign && !isDesignShareRecipient &&
                                    projectStatus !== ProjectStatus.GENERATING_REFERENCE_DESIGN;

        if (isOffline) {
            sendTooltipMsg = 'You must be Online to Send a project.';
        }

        if (showSendTooltip && !this._sendTooltipOverridden) {
            const $sendTooltip = $('#sendTooltip');
            const tooltip = $sendTooltip.data('kendoTooltip');
            tooltip && tooltip.destroy();

            KendoTooltipOverride($sendTooltip, sendTooltipMsg, null, 'bottom');
            this._sendTooltipOverridden = true;
        }
        else if (!showSendTooltip) {
            const tooltip = $('#sendTooltip').data('kendoTooltip');
            tooltip && tooltip.destroy();
            this._sendTooltipOverridden = false;
        }

        if (showCopyEditTooltip && !this._copyEditTooltipOverridden) {
            const $copyEditTooltip = $('#copyEditTooltip');
            const tooltip = $copyEditTooltip.data('kendoTooltip');
            tooltip && tooltip.destroy();

            KendoTooltipOverride($copyEditTooltip, copyEditTooltipMsg, null, 'bottom');
            this._copyEditTooltipOverridden = true;
        }
        else if (!showCopyEditTooltip) {
            const tooltip = $('#copyEditTooltip').data('kendoTooltip');
            tooltip && tooltip.destroy();
            this._copyEditTooltipOverridden = false;
        }
    };

    createCSV = (e) => {
        e.preventDefault();
        this.props.buildCSVString();
    };

    saveProject = () => {
        this.props.trySavingProject();
    };

    hideCopyEditModal = () => {
        this.props.toggleCopyEditModal(false);
    };

    onConfirmCopy = (name) => {
        this.props.toggleCopyEditModal(false);
        this.props.copyAndEditProject(this.props.projectId, name, this.props.isDesignShareRecipient);
    };

    startCopying = () => {
        this.props.tryCopyingProject();
    };

    copyAndEdit = () => {
        const { projectName, projectLoading, showCopyEditModal, projects, showCopyEditLink,
            unownedRefDesign, isDesignShareRecipient } = this.props;
        const projectNames = projects.map(p => p.name);
        const disabled = unownedRefDesign && !isDesignShareRecipient;

        if (!showCopyEditLink) {
            return '';
        }

        return (
            <div id="copyEditTooltip" key="copyAndEdit"
                className={classNames({ [css.disabled]: disabled }, css.headerMenuItem)}>
                <a id="Header_CopyAndEdit" className="btn-link" onClick={!disabled ? this.startCopying : null}>
                    <i className="fa fa-files-o" />
                    <span className={css.headerFont}>{getText(contentKeys.COPY_AND_EDIT)}</span>
                </a>
                {showCopyEditModal && <CopyProjectModal
                    open={showCopyEditModal}
                    loading={projectLoading}
                    name={`${projectName} copy`}
                    names={projectNames}
                    onOK={this.onConfirmCopy}
                    onClose={this.hideCopyEditModal} />}
            </div>
        );
    };

    onConfirmSend = (email) => {
        this.props.toggleSendModal(false);
        this.props.sendProject(this.props.projectId, email);
    };

    hideSendModal = () => {
        this.props.toggleSendModal(false);
    };

    startSendProject = () => {
        this.props.trySendingProject();
    };

    sendProject = () => {
        const {showSendLink, refDesignError, unownedRefDesign, unsavedProject, showSendModal,
            projectLoading, isOffline} = this.props;
        const disabled = unownedRefDesign || unsavedProject || isOffline;

        if (refDesignError || !showSendLink) {
            return '';
        }

        return (
            <div id="sendTooltip"
                key="sendProject"
                className={classNames('new-feature-send', css.headerMenuItem, { [css.disabled]: disabled })}>
                <a id="Header_SendProject" className="btn-link" onClick={!disabled ? this.startSendProject : null}>
                    <i className="fa fa-paper-plane" />
                    <span className={css.headerFont}>{getText(contentKeys.SEND)}</span>
                </a>
                {showSendModal && <SendModal
                    open={showSendModal}
                    loading={projectLoading}
                    onOK={this.onConfirmSend}
                    onClose={this.hideSendModal} />}
            </div>
        );
    };

    render() {
        const {enabled, projectLoading, isOffline} = this.props;
        const helpUrlString = helpUrl();
        const offlineVersionUrlString = offlineVersionUrl();

        let containerClassName = css.headLinks;
        if (!enabled || projectLoading) {
            containerClassName += ' ' + css.disabled;
        }

        const copyAndEditLink = this.copyAndEdit();
        const sendProject = this.sendProject();

        let saveProjectlink = "";
        if (this.props.showSaveProjectLink) {
            saveProjectlink = (
                <div key="copyAndEditLink"
                    className={classNames('new-feature-save', css.headerMenuItem)}>
                    <a id="Header_SaveProject" className="btn-link" onClick={this.saveProject}>
                        {projectLoading
                            ? <i id="projectSaveWaitSpinnerIcon" className="fa fa-spinner fa-spin" />
                            : <i id="projectSaveCheckMark" className="fa fa-check" />}
                        <span className={css.headerFont}>{getText(contentKeys.SAVE)}</span>
                    </a>
                </div>
            );
        }

        let exportCsvLink = "";
        if (this.props.showExportCsvLink) {
            exportCsvLink = (<div key="exportCsvLink" className={css.headerMenuItem}>
                <a id="Header_ExportCSV" className="btn-link" onClick={enabled ? this.createCSV : preventDefault}>
                    <i className={"fa fa-arrow-down"} />
                    <span className={css.headerFont}>{getText(contentKeys.EXPORT)}</span>
                </a>
            </div>);
        }

        let backLink = "";
        if (this.props.showBackLink) {
            const backLinkData = getBackLink(this.props.location.pathname);

            backLink = (<div key="backLinkData" className={css.headerMenuItem}>
                <Link id="Header_GoBack" to={backLinkData.url} className="btn-link"
                    data-test="go_back_menu_item" onClick={enabled ? noOp : preventDefault}>
                    <i className={`fa ${backLinkData.icon}`} />
                    <span className={css.headerFont}>{backLinkData.text}</span>
                </Link>
            </div>);
        }

        let offlineVersionLink = "";
        if (this.props.showOfflineVersionLink && !isOffline) {
            offlineVersionLink = (<div key="offlineVersionLink" className={css.headerMenuItem}>
                <a id="Header_OfflineHelp" className="new-feature-offline-link btn-link"
                    href={offlineVersionUrlString} target="_blank">
                    <span className={classNames("icon icon-powercompass-icon", css.offlineIcon)}></span>
                    <span className={css.headerFont}>{getText(contentKeys.HOW_TO_USE_OFFLINE)}</span>
                </a>
            </div>);
        }

        let helpLink = "";
        if (this.props.showHelpLink) {
            helpLink = (<div key="helpLink" className={css.headerMenuItem}>
                <a id="Header_Help" className="btn-link" href={helpUrlString} target="_blank">
                    <i className={"fa fa-info-circle"} />
                    <span className={css.headerFont}>{getText(contentKeys.HELP)}</span>
                </a>
            </div>);
        }

        let yourProjectsLink = "";
        if (this.props.showYourProjectsLink) {
            yourProjectsLink = (<div key="yourProjectsLink" className={css.headerMenuItem}>
                <Link id="Header_YourProjects" to="/projects"
                    className="btn-link"
                    data-test="your_projects_menu_item"
                    onClick={(enabled) ? noOp : preventDefault}>
                    <i className={"fa fa-list"} />
                    <span className={css.headerFont}>{getText(contentKeys.PROJECTS)}</span>
                </Link>
            </div>);
        }

        const items = [
            copyAndEditLink,
            backLink,
            saveProjectlink,
            exportCsvLink,
            sendProject,
            offlineVersionLink,
            yourProjectsLink,
            helpLink,
        ].filter(item => item !== "");

        return (
            <ResponsiveMenu popupClassName={classNames(css.headerMenuItem, css.moreButton)}
                className={containerClassName}>
                {items}
            </ResponsiveMenu>
        );
    }
}

export default databind(HeaderLink);
