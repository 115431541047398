import PropTypes from 'prop-types';
import React from 'react';
import ModalWindow from 'components/ModalWindow';
import databind from 'redux/utils/databind';
import css from 'components/styles.less';
import {Button} from 'bootstrap-core.js';
import {actions as deleteActions} from 'redux/modules/confirmDelete';
import {actions as editorActions} from 'redux/modules/editor';
import classNames from 'classnames';
import MobileDialog from 'components/MobileDialog';
import {Tablet, Desktop} from 'styles/variables/breakpoints';
import PageSlider from 'components/PageSlider';
import mobilePanelStyles from 'components/mobilePanelStyles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class ConfirmDeleteModal extends React.Component {
    static propTypes = {
        confirm: PropTypes.object.isRequired,
        clearConfirmDelete: PropTypes.func.isRequired,
        deleteInput: PropTypes.func.isRequired,
        deleteOutput: PropTypes.func.isRequired,
        onRadioChange: PropTypes.func,
        deleteRailAndConnections: PropTypes.func
    };

    static databind ({confirmDelete}) {
        return {
            confirm: confirmDelete
        };
    }

    static actions = {
        clearConfirmDelete: deleteActions.clearConfirm,
        deleteInput: function(idx, id) { return editorActions.deleteInputRail({ index: idx, id: id }); },
        deleteRailAndConnections: editorActions.deleteRailAndConnections,
        deleteOutput: function(idx) { return editorActions.deleteSystemOutput({ index: idx }); },
        onRadioChange: deleteActions.radioChange
    };

    onCancel = () => { this.props.clearConfirmDelete(); };

    onConfirm = () => {
        const confirmObj = this.props.confirm.confirmDelete;
        if (confirmObj.output) {
            this.props.deleteOutput(confirmObj.index); // pass index
        }
        else {
            if (confirmObj.deleteConnections) {
                // run delete connected rails function
                this.props.deleteRailAndConnections({ idx: confirmObj.index, id: confirmObj.id });
            }
            else {
                this.props.deleteInput(confirmObj.index, confirmObj.id); // pass index
            }
        }
        this.props.clearConfirmDelete();
    };

    onRadioChange = (e) => {
        if (e.target.htmlFor === "connectedRails") {
            this.props.onRadioChange({ deleteConnections: true });
        }
        else {
            this.props.onRadioChange({ deleteConnections: false });
        }
    };

    render() {
        let content;
        const confirmObj = this.props.confirm.confirmDelete;
        const connectedRailsClass = classNames("k-radio-label", confirmObj.deleteConnections ? "checked" : "");
        const oneRailClass = classNames("k-radio-label", !confirmObj.deleteConnections ? "checked" : "");
        const cancelButtonText = (confirmObj.output) ? getText(contentKeys.NO_CANCEL) : getText(contentKeys.CANCEL);
        const confirmButtonText = (confirmObj.output) ? getText(contentKeys.YES_DELETE) : getText(contentKeys.DELETE);
        const modalTitle = (confirmObj.output) ? getText(contentKeys.DELETE_OUTPUT)
            : getText(contentKeys.DELETE_INPUT_RAIL);
        if (confirmObj.output) {
            content = (<div>{getText(contentKeys.ARE_YOU_SURE_YOU)}</div>);
        }
        else {
            content = (
                <div>
                    <p> {getText(contentKeys.TO_DELETE_THIS_RAIL)}
                    </p>
                    <div className={"k-content"}>
                        <ul className={"fieldlist"}>
                            <li>
                                <input type="radio" name="oneRail" className={"k-radio"} />
                                <label onClick={this.onRadioChange} className={oneRailClass}
                                    htmlFor="oneRail">
                                    {getText(contentKeys.DELETE_RAIL_AND_MAKE)}
                                </label>
                            </li>
                            <li>
                                <input type="radio" className={"k-radio"} name="connectedRails" />
                                <label data-test="delete_connections"
                                    onClick={this.onRadioChange} className={connectedRailsClass}
                                    htmlFor="connectedRails">
                                    {getText(contentKeys.DELETE_RAIL_AND_DELETE)}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        }

        const mobileContent = (
            <MobileDialog
                onClose={this.onCancel}
                header={
                    <React.Fragment>
                        <span className="headline">{modalTitle}</span>
                    </React.Fragment>
                } content={<div className={mobilePanelStyles.content}>{content}</div>} footer={
                    <React.Fragment>
                        <Button onClick={this.onCancel} className="basic flex"
                            style={{margin: '0 10px'}}>
                            {cancelButtonText}
                        </Button>
                        <Button className="flex" onClick={this.onConfirm} style={{margin: '0 10px'}}>
                            {confirmButtonText}
                        </Button>
                    </React.Fragment>
                } />
        );

        const desktopContent = (
            <ModalWindow
                actions={["Close"]}
                close={this.onCancel}
                open={this.props.confirm.confirmDelete.show} // databind to redux state
                width={500}
                height={300}
                center={true}
                title={modalTitle} >
                <div data-test="confirmation_dialog">
                    {content}
                    <div className={css.modalButtons}>
                        <div className={css.buttonContainer}>
                            <Button data-test="cancel_delete" className={'btn btn-default basic'}
                                onClick={this.onCancel}>{cancelButtonText}</Button>
                            <Button data-test="confirm_delete" className={'btn btn-default'}
                                onClick={this.onConfirm}>{confirmButtonText}</Button>
                        </div>
                    </div>
                </div>
            </ModalWindow>
        );

        return (
            <React.Fragment>
                <Desktop>
                    {desktopContent}
                </Desktop>
                <Tablet>
                    <PageSlider showAuxPage={this.props.confirm.confirmDelete.show}
                        content={mobileContent} />
                </Tablet>
            </React.Fragment>
        );
    }
}

export default databind(ConfirmDeleteModal);
