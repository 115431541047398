import {flattenItems, limitResults, applyAllSolutionFilters} from 'logic/suggestedParts';
import groupBy from 'lodash/groupBy';
import forOwn from 'lodash/forOwn';
import {mapOrderByToSortInfo} from "logic/orderByMappings";
import applyOrderBySorting from "logic/applyOrderBySorting";

function addCountsBasedOnCurrentFilters(outputs,
    solutionTypes, schematicFilters, aecqFilter, syncFilter, limit, orderBy) {
    let updated = [];

    const flattened = flattenItems(outputs);

    flattened.forEach((output, idx) => {
        let filtered = applyAllSolutionFilters(output.items,
            solutionTypes, schematicFilters, aecqFilter, syncFilter, output.ldo);

        // cap counts by limit per topology
        const grouped = groupBy(filtered, "topology");
        let count = 0;
        forOwn(grouped, function(items, topology) {
            count += (items.length < limit) ? items.length : limit;
        });

        const sortInfo = mapOrderByToSortInfo(orderBy);
        filtered = applyOrderBySorting(filtered, sortInfo);
        filtered = limitResults(filtered, limit);

        updated.push({ ...output, count, filtered });
    });
    return updated;
}

export default addCountsBasedOnCurrentFilters;
