import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

export default class EditorCSVGenerator {
    constructor() {
        this.NUM_COLS = 18;
        this.NUM_INPUT_COLS = 5;
        this.NUM_OUTPUT_COLS = 12;
    }

    writeHeader = (csv, optimize, efficiency) => {
        const optimizedStr = (optimize) ? "Optimized" : "User Defined";
        const efficiencyPercentageStr = (100 * efficiency).toFixed(0) + "%";
        const efficiencyFractionStr = efficiency.toFixed(2);

        return csv.write([getText(contentKeys.INPUTS_AND_OUTPUTS)], this.NUM_COLS)
            .endl()
            .write([getText(contentKeys.SYSTEM_INPUT_RAILS)], this.NUM_INPUT_COLS)
            .gap()
            .write([
                getText(contentKeys.REGULATED_OUTPUTS), "", "Order", optimizedStr,
                getText(contentKeys.ASSUMED_MIN_EFFICIENCY), efficiencyPercentageStr
            ], this.NUM_OUTPUT_COLS)
            .endl()
            .write([
                getText(contentKeys.INPUT_NAME),
                "V(Min)",
                "V(Max)",
                "A(Max)",
                getText(contentKeys.AVAIL_POWER_W)
            ], this.NUM_INPUT_COLS)
            .gap()
            .write([
                getText(contentKeys.SOURCE_RAIL),
                getText(contentKeys.OUTPUT_NAME),
                getText(contentKeys.VOLTS_MAX),
                getText(contentKeys.AMPS_MAX),
                getText(contentKeys.SUPPLY_SEQUENCE),
                getText(contentKeys.AVAIL_OUTPUT_W),
                getText(contentKeys.EFF_INPUT_POWER_NEEDED, efficiencyFractionStr),
                getText(contentKeys.POWER_GOOD_OUTPUT),
                getText(contentKeys.ENABLE_PIN_INPUT),
                getText(contentKeys.LDO),
                getText(contentKeys.SYNC_INPUT),
                getText(contentKeys.SYNC_OUTPUT)
            ], this.NUM_OUTPUT_COLS)
            .endl();
    };

    static resolveConnections = (state) => {
        let outputs = [...state.outputs];

        // create a lookup for names by id's
        function hashById (hash, x) {
            hash[x.id] = x.name;
            return hash;
        };
        let nameById = state.inputs.reduce(hashById, {});
        nameById = outputs.reduce(hashById, nameById);

        // replace sourceRail ids
        outputs = outputs.map((x) => {
            if (x.sourceRail !== "") x.sourceRail = nameById[x.sourceRail];
            return x;
        });

        return outputs;
    };

    serialiseInput = (input) => {
        return [
            input.name,
            input.vmin.toString(),
            input.vmax.toString(),
            input.imax.toString(),
            input.power.toFixed(2)
        ];
    };

    serialiseOutput = (output) => {
        return [
            output.sourceRail,
            output.name,
            output.volts,
            output.amps,
            output.supplySeq,
            output.availableOutput.toFixed(2),
            output.powerNeeded.toFixed(2),
            output.powerGoodOutput,
            output.enablePinInput,
            output.ldo,
            output.syncInput,
            output.syncOutput
        ];
    };

    stringify(csv, state) {
        this.writeHeader(csv, state.optimize, state.efficiency);
        let outputs = EditorCSVGenerator.resolveConnections(state);
        const max = (state.inputs.length > outputs.length) ? state.inputs.length : outputs.length;

        for (let i = 0; i < max; i++) {
            let item = {input: [], output: []};

            if (state.inputs[i]) {
                item.input = this.serialiseInput(state.inputs[i]);
            }
            if (outputs[i]) {
                item.output = this.serialiseOutput(outputs[i]);
            }

            csv.write(item.input, this.NUM_INPUT_COLS)
                .gap()
                .write(item.output, this.NUM_OUTPUT_COLS)
                .endl();
        }

        return csv.toString();
    }
};
