import { PartFieldNames, GridSortDirs } from 'logic/constants';

/**
 * Tests if the querystring is carrying valid parameters.
 * @returns {Boolean} - Returns true if the querystring carries valid parameters.
 */
export function hasValidQuerystringParams() {
    return (getValidQuerystringParams() !== null);
}

/**
 * Delivers an array of querystring parameters with a very basic validation.
 * @returns {Array | null} - Returns a list of querystring parameters.
 */
export function getQuerystringParams() {
    if (
        window.location.hash.split('?')[1] === null ||
        window.location.hash.split('?')[1].split('&').length <= 1
    ) return null;

    return window.location.hash.split('?')[1].split('&');
}

/**
 * Filters querystring for valid application params only.
 * @returns {Array | null} - A list of valid application querystring parameters.
 */
export function getValidQuerystringParams() {
    const querystringParams = getQuerystringParams();

    // Halt execution if querystring validation doesn't succeed.
    if (querystringParams === null) return null;

    const processedQuerystring =
        querystringParams

            // Filters out keys or values not compliant to the desired patterns.
            .filter((param) => {
                const paramKey = param.split('=')[0];
                const paramValue = param.split('=')[1];

                return (
                    paramKey !== null &&
                    paramValue !== null &&
                    paramKey !== '_k' &&
                    paramKey.match(/^[0-9a-zA-Z_[\]]+$/)
                );
            })

            // Removes the URL array indicator ([]).
            .map((param) => {
                return param.replace("[]", "");
            });

    return (processedQuerystring.length > 0) ? processedQuerystring : null;
}

/**
 * Delivers an array of valid querystring input keys.
 * @returns {Array} - An array if valid querystring input keys.
 */
export function validQuerystringInputKeys() {
    return [
        'vmin',
        'vmax',
        'imax',
    ];
}

/**
* Delivers an array of valid querystring output keys.
* @returns {Array} - An array if valid querystring output keys.
*/
export function validQuerystringOutputKeys() {
    return [
        'volts',
        'amps',
        'onSeq',
    ];
}

/**
* Delivers an array of valid querystring sort keys.
* @returns {Array} - An array if valid querystring sort keys.
*/
export function validQuerystringSortKeys() {
    return [
        'field',
        'dir',
    ];
}

/**
* Delivers an array of valid querystring configuration keys.
* @returns {Array} - An array if valid querystring configuration keys.
*/
export function validQuerystringConfigKeys() {
    return [
        'triggerFindSolutions',
    ];
}

/**
 * Filters the querystring array to filter input params only.
 * @param {String} filterType - Input or Output filter.
 * @returns {Array | null} - An array of input querystring params.
 */
export function getFilteredQuerystringParams(filterType) {
    const validQuerystringParams = getValidQuerystringParams();

    // If querystring params array is empty, return null.
    if (validQuerystringParams === null) return null;

    // Decides on which set of keys to deliver.
    const validQuerystringFilteredKeys = () => {
        switch (filterType) {
            case 'input':
                return validQuerystringInputKeys();
            case 'output':
                return validQuerystringOutputKeys();
            case 'sort':
                return validQuerystringSortKeys();
        }

        return validQuerystringConfigKeys();
    };

    // Applies param validation.
    const querystringParamValidation = (param) => {
        switch (filterType) {
            // Input & Output need to be numeric.
            case 'input':
            case 'output':
            case 'config':
                return !isNaN(param);

            // Sort accepts numbers and latin characters,
            // including special characters `_` and `|`.
            // It also validates field and direction values
            // against the project constants.
            case 'sort':
                const fieldNames = Object.values(PartFieldNames);
                const fieldDirections = Object.values(GridSortDirs);

                return (
                    param.match(/^[0-9a-zA-Z_|]+$/) &&
                    (
                        fieldNames.includes(param) ||
                        fieldDirections.includes(param)
                    )
                );
        }

        return validQuerystringConfigKeys();
    };

    // Validates the querystring keys.
    const inputQuerystring = validQuerystringParams.filter((param) => {
        const paramKey = param.split('=')[0];
        const paramValue = param.split('=')[1];
        return (
            paramKey !== null &&
            validQuerystringFilteredKeys().includes(paramKey) &&
            paramValue !== null &&
            querystringParamValidation(paramValue)
        );
    });

    // Returns the filtered array of querystring parameters.
    return (inputQuerystring.length > 0) ? inputQuerystring : null;
}

/**
 * Prepares an array of objects corresponding to the INPUT querystring parameters.
 * @param {String | null} defaultRailName - A default name to be applied to the source rail(s)
 * @returns {Array | null} - An array of objects corresponding to the querystring parameters.
 */
export function fillInputUrlParams(defaultRailName = null) {
    const inputQuerystringParams = getFilteredQuerystringParams('input');
    const urlParams = (inputQuerystringParams !== null) ? fillUrlParams(inputQuerystringParams) : null;
    if (defaultRailName === null) return urlParams;

    // Injects information on default source Rail Name into input data.
    return urlParams.map((urlParam) => ({
        ...urlParam,
        name: defaultRailName
    }));
}

/**
 * Prepares an array of objects corresponding to the OUTPUT querystring parameters.
 * @param {String | null} defaultSourceRailName - A default name to be applied to the sourceRail param.
 * @returns {Array | null} - An array of objects corresponding to the querystring parameters.
 */
export function fillOutputUrlParams(defaultSourceRailName = null) {
    const outputQuerystringParams = getFilteredQuerystringParams('output');
    const urlParams = (outputQuerystringParams !== null) ? fillUrlParams(outputQuerystringParams) : null;
    if (defaultSourceRailName === null) return urlParams;

    // Injects information on default source Rail Name into output data.
    return urlParams.map((urlParam) => ({
        ...urlParam,
        ...(!urlParam.hasOwnProperty('onSeq') && {onSeq: 1}),
        sourceRail: defaultSourceRailName
    }));
}

/**
 * Prepares an array of objects corresponding to the CONFIGURATION querystring parameters.
 * @returns {Array | null} - An array of objects corresponding to the querystring parameters.
 */
export function fillSortUrlParams() {
    const sortQuerystringParams = getFilteredQuerystringParams('sort');

    const urlParams = (sortQuerystringParams !== null)
        ? fillUrlParams(sortQuerystringParams, false)[0]
        : null;

    return urlParams;
}

/**
 * Prepares an array of objects corresponding to the CONFIGURATION querystring parameters.
 * @returns {Array | null} - An array of objects corresponding to the querystring parameters.
 */
export function fillConfigUrlParams() {
    const configQuerystringParams = getFilteredQuerystringParams('config');

    const urlParams = (configQuerystringParams !== null)
        ? fillUrlParams(configQuerystringParams)[0]
        : { triggerFindSolutions: '1' };

    return urlParams;
}

/**
 * Gets the URL value of param key `triggerFindSolutions`.
 * @returns {String} - A numeric string with two possible values: '0' (false) | '1' (true).
 */
export function getConfigTriggerFindSolutions() {
    return fillConfigUrlParams().triggerFindSolutions;
}

/**
 * Gets the URL sort column param key: `field`.
 * @returns {String | null} - An alphanumeric string that corresponds to a column name.
 */
export function getSortColumn() {
    return fillSortUrlParams() !== null && fillSortUrlParams().hasOwnProperty('field') && fillSortUrlParams().field !== null ? fillSortUrlParams().field : null;
}

/**
 * Gets the URL sort column param key: `field`.
 * @returns {String | null} - An alphanumeric string that corresponds to a column name.
 */
export function getSortDirection() {
    return fillSortUrlParams() !== null && fillSortUrlParams().hasOwnProperty('dir') && fillSortUrlParams().dir !== null ? fillSortUrlParams().dir : 'asc';
}

/**
 * Prepares an array of objects corresponding to the querystring parameters.
 * @param {Array} params - a list of URL parameters.
 * @param {Boolean} castToNumber - determines if the value needs to be converted to a number.
 * @returns {Array} - An array of objects
 */
function fillUrlParams(params, castToNumber = true) {
    return params.reduce((accumulator, arrayItem) => {
        return checkArrayRecursive(accumulator, arrayItem.split('=')[0], arrayItem.split('=')[1], castToNumber);
    }, []);
}

/**
 * Appends a key:value pair to the sourceArray.
 * @param {Array} sourceArray - The current state of the array.
 * @param {String} refKey - The key being appended to the sourceArray.
 * @param {String} refValue - The value being associated with the key.
 * @param {Boolean} castToNumber - determines if the value needs to be converted to a number.
 * @returns {Array} - The updated array including the new key:value pair.
 */
function checkArrayRecursive(sourceArray, refKey, refValue, castToNumber) {
    const combineObjects = (sourceObject, key, value) => {
        value = (castToNumber === true) ? parseFloat(value) : value;
        return {...sourceObject, [key]: value};
    };

    const compareArrays = (arrayBefore, arrayAfter) => {
        return JSON.stringify(arrayBefore) === JSON.stringify(arrayAfter);
    };

    const parsedArray = sourceArray.map((record) => {
        return (!record.hasOwnProperty(refKey))
            ? combineObjects(record, refKey, refValue)
            : record;
    });

    const returnArray = (compareArrays(sourceArray, parsedArray))
        ? sourceArray.concat(combineObjects({}, refKey, refValue))
        : parsedArray;

    return returnArray;
}
