import PropTypes from 'prop-types';
import React from 'react';
import {Provider} from 'react-redux';
import {Router} from 'react-router';
import DocumentTitle from 'react-document-title';
import initPersistor from 'redux/persist';

export default class Root extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        routes: PropTypes.element.isRequired,
        store: PropTypes.object.isRequired
    };

    constructor() {
        super();
        this.state = { rehydrated: false };
    }

    componentWillMount() {
        window.persistor = initPersistor(store, { debounce: 1000 }, () => this.setState({ rehydrated: true }));
    }

    get content () {
        return (
            <Router history={this.props.history}>
                {this.props.routes}
            </Router>
        );
    }

    get devTools () {
        if (__DEBUG__) {
            if (__DEBUG_NEW_WINDOW__) {
                if (!window.devToolsExtension) {
                    require('../redux/utils/createDevToolsWindow').default(this.props.store);
                }
                else {
                    window.devToolsExtension.open();
                }
            }
            else if (!window.devToolsExtension) {
                const DevTools = require('containers/DevTools').default;
                return <DevTools />;
            }
        }
    }

    render () {
        if (!this.state.rehydrated) return (null);
        return (<Provider store={this.props.store}>
            <DocumentTitle title={__APPTITLE__}>
                <div style={{ height: '100%' }} data-version={__VERSION__} data-commit={__COMMIT__}>
                    {this.content}
                    {this.devTools}
                </div>
            </DocumentTitle>
        </Provider>);
    };
}
