import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import Kendo from 'kendo-ui-react';
import classNames from 'classnames';

export class SwitchComponent extends React.Component {
    static propTypes = {
        enabled: PropTypes.bool,
        checked: PropTypes.bool,
        change: PropTypes.func
    };

    render () {
        const options = {
            offLabel: "",
            onLabel: "",
            enable: this.props.enabled,
            change: this.props.change
        };
        return (
            <Kendo.mobile.Switch checked={this.props.checked} options={options} />
        );
    }
}

const SwitchOption = (props) => {
    const disabledClass = (props.enabled) ? "" : css.disabled;
    const switchClassName = classNames(props.className, disabledClass);

    const onClickLabel = () => {
        props.change({ checked: !props.checked });
    };

    return (
        <tr>
            <td className={classNames(disabledClass, 'switchLabelCell')}>
                <span onClick={onClickLabel}>{props.title}</span>
            </td>
            <td></td>
            <td className={switchClassName}>
                <SwitchComponent
                    enabled={props.enabled}
                    checked={props.checked}
                    change={props.change} />
            </td>
        </tr>
    );
};

SwitchOption.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    enabled: PropTypes.bool.isRequired,
    checked: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    id: PropTypes.string
};

export default SwitchOption;
