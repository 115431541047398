// TODO: Get this from renesas-common instead when we can import that sanely
export default () => {
    return (
        (window.relContext &&
        window.relContext.region &&
        window.relContext.region.locale) ||
        (window.localStorage && window.localStorage.getItem("locale")) ||
        window.navigator.language.toLowerCase()
    );
};
