/*
 *
 * Kendo Docs
 *
 * support.browser Object
 Convenience replacement for the now deprecated jQuery.browser. It returns an object with the browser identifier
 initialized as a boolean property and a version. The identifiers are identical to jQuery ones, e.g. "webkit",
 "opera", "msie" and "mozilla". In addition WebKit browsers will return their name e.g. "safari" and "chrome".
 *
 Chrome will return this object: { "webkit": true, "chrome": true, "version": 37 }
 IE11 will return this one: { "msie": true, "version": 11 }
 * */

const browser = {
    isChrome: function(browser) {
        return (!!browser['webkit'] && !!browser['chrome']);
    },
    isSafari: function(browser) {
        return (!!browser['webkit'] && !!browser['safari']);
    },
    isIE: function(browser) {
        return !!browser['msie'];
    },
    isFirefox: function(browser) {
        return !!browser['mozilla'];
    },
    isEdge: function(browser) {
        return !!browser['edge'];
    }
};

export default browser;
