/* global kendo, $ */
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import SolutionType from 'logic/SolutionType';
import {flattenItems} from 'logic/suggestedParts';
import {actions} from 'redux/modules/suggestedParts';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

function getTabCount(parentOutput, maxNumOutputs) {
    if (!parentOutput) {
        return 0;
    }

    return maxNumOutputs - parentOutput.numOutputs + 1;
}

export class PartsDataSource extends kendo.data.DataSource {
    constructor (store, filterType, limit) {
        // note: there is a much nicer way to do this by using kendo.data.DataSource.extend() to create an augmented
        // datasource class, perhaps skipping some of these early references.
        const options = {};
        options.transport = {
            read: (options) => {
                let results = [];
                if (this.local.results) {
                    results = flattenItems(cloneDeep(this.local.results));
                }

                const outputsById = keyBy(results, "id");
                const maxNumOutputs = Math.max(...results.map(r => r.numOutputs));

                let resultsWithSpacers = [];

                results.forEach((result) => {
                    const parentOutput = result.parentId ? outputsById[result.parentId] : null;
                    const tabCount = result.parentId ? getTabCount(parentOutput, maxNumOutputs) : 0;

                    resultsWithSpacers.push({
                        ...result,
                        tabCount
                    });

                    if (result["lastInSingleOutputGroup"] || result["lastOutputInGroup"]) {
                        resultsWithSpacers.push({spacer: true});
                    }
                });

                options.success(resultsWithSpacers);
            }
        };

        options.data = [];
        options.schema = {
            model: {
                idField: "id",
                fields: {
                    type: {type: "string"},
                    name: {type: "string"},
                    id: {type: "string"},
                    spacer: {type: "boolean"}
                }
            }
        };

        super(options);

        this.local = {
            tick: 0,
            solutionType: SolutionType.ALL,
            limit: 0,
            counts: [],
            showSelectedParts: false
        };
        this.store = store;
        this.unsubscribe = store.subscribe(this.onStoreChange);
        this.filterType = filterType;
    }

    collapseRow = (grid, $row) => {
        grid.collapseRow($row);
        $row.find('.fa.collapseControl').removeClass('fa-angle-up').addClass('fa-angle-down');
        $row.removeClass('tagExpanded');
    };

    expandRow = (grid, $row) => {
        grid.expandRow($row);
        $row.find('.fa.collapseControl').removeClass('fa-angle-down').addClass('fa-angle-up');
        $row.addClass('tagExpanded');
    };

    expandAllRowsWithSelections = ($grid, selectedParts) => {
        const dataSource = this;
        const grid = $grid.data('kendoGrid');
        const $rows = $grid.find('.k-master-row.spGrid');
        $rows.each(function() {
            const $row = $(this);
            const rowId = $row.data('rowid');
            if (selectedParts[rowId]) {
                dataSource.expandRow(grid, $row);
            }
            else {
                dataSource.collapseRow(grid, $row);
            }
        });
    };

    updateExpandCollapseAll = ($grid) => {
        const $expanded = $grid.find('.tagExpanded');
        const $rows = $grid.find('.k-master-row.spGrid:not(.emptyRail)');

        const allAreExpanded = ($expanded.length === $rows.length);
        const allAreCollapsed = ($expanded.length === 0);

        const {suggestedParts} = this.store.getState();
        const prevAllAreExpanded = suggestedParts.allAreExpanded;
        const prevAllAreCollapsed = suggestedParts.allAreCollapsed;

        if ((prevAllAreExpanded !== allAreExpanded) || (prevAllAreCollapsed !== allAreCollapsed)) {
            // filtering could have resulted in rows with zero counts having been collapsed
            // we need to check to see if all rows are now collapsed in order to update state
            // of the ExpandAll / CollapseAll contro
            this.store.dispatch(actions.setExpandedState({ allAreExpanded, allAreCollapsed }));
        }
    };

    onStoreChange = () => {
        const dataSource = this;
        const state = this.store.getState();

        if (state.suggestedParts.tick !== this.local.tick) {
            this.local.tick = state.suggestedParts.tick;
            this.local.results = state.suggestedParts.results;
            this.local.counts = [];
            this.read();
        }

        if ((this.local.results) && (this.local.results.length > 0)) {
            const $grid = $('div.k-grid');
            const component = $grid.data('kendoGrid');

            // after initial render manually updating the top level row counts here
            // as we don't want the detail view of any expanded row to be destroyed
            const newCounts = state.suggestedParts.results.map((x) => x.count || 0);
            if (!isEqual(newCounts, this.local.counts)) {
                this.local.counts = newCounts;
                const countSpans = $('.partsCount');
                countSpans.each(function(idx) { // essentially loop over each row
                    const $row = $(this).closest('tr');

                    if (newCounts[idx] === 0) {
                        $(this).text(getText(contentKeys.NO_SOLUTIONS_FOUND));
                        $row.addClass('emptyRail');
                    }
                    else {
                        $(this).text(newCounts[idx].toString());
                        $(this).closest('tr').removeClass('emptyRail');
                    }

                    // the following will collapse any rows that are no longer expanded
                    // due to for example filtering in the UI
                    if ($row.hasClass('tagExpanded') && $row.hasClass('emptyRail')) {
                        dataSource.collapseRow(component, $row);
                    }
                });
                dataSource.updateExpandCollapseAll($grid);
            }

            const showSelectedParts = state.suggestedParts.showSelectedParts;
            if (showSelectedParts!== this.local.showSelectedParts) {
                this.local.showSelectedParts = showSelectedParts;
                if (showSelectedParts) {
                    this.expandAllRowsWithSelections($grid, state.suggestedParts.selectedParts);
                    dataSource.updateExpandCollapseAll($grid);
                }
            }
        }
    };
}
