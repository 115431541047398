import keys from 'lodash/keys';
import locale from './locale';

window.powerCompassiSim = (app) => {
    const date = new Date(Date.now() + 60000);
    $.cookie('pcompass_link', app, { path: '/', expires: date });
    window.open(`/${locale()}/products/isim.html`, '_blank');
};

const makeISimUrl = (data) => {
    const { items } = data;
    return items.map(value => {
        if (value.isimmodel) {
            // take values from user input
            const { vinMax, outputs } = data;
            // take values from device
            const { deviceName, topology } = value;
            // example url fragment to be created:
            // application=PWM&part=ISL80102&topology=X&sp_vin=2.2&sp_vout=1.8&sp_iout=1.1
            const outputObject = outputs.reduce((total, output, i) => {
                // if only one output the uri components are sp_vout and sp_iout
                // if more than one output the uri components are sp_vout/sp_vout_2 and sp_iout/sp_iout_2
                return Object.assign(total,
                    {
                        ['sp_vout'+ (i>0 ? '_' + (i+1) : '')]: output.voutMax,
                        ['sp_iout'+ (i>0 ? '_' + (i+1) : '')]: output.ioutMax
                    }
                );
            }, {});

            // pass from json format to "&key=value" format
            const urlString = keys(outputObject).reduce((string, key) => {
                const value = outputObject[key];
                return string + "&"+key+"="+value;
            }, '');

            const iSimUrl = 'application=PWM&part=' + deviceName + '&topology=' + topology +
                '&sp_vin=' + vinMax + urlString;

            const iSimFn = `window.powerCompassiSim('${iSimUrl}')`;

            return Object.assign(value, {iSimUrl, iSimFn});
        }
        return value;
    });
};

export default makeISimUrl;
