import {XILINX_VENDOR_NAME} from 'logic/constants';
import findIndex from 'lodash/findIndex';
import loadTextFromFile from 'util/loadTextFromFile';
import tryLoadingFromFile from 'util/tryLoadingFromFile';

const POWER_SUPPLY_SUMMARY_TEXT = 'Power Supply Summary';
const MAX_LINES_TO_LOOK_FOR_ROWS = 20;
const MAX_TABLE_SIZE = 50;
const POWER_ROW_REGEX = /\s*\|([^|]+)+\|([\d.]+)\|([\d.]+)\|([\d.]+)\|([\d.]+)/;

export function isRowEmpty(cells) {
    return ((cells[1] === '') && (cells[2] === '') && (cells[3] === ''));
}

export function extractPowerDataFromRow(line) {
    if (!line) return null;
    const cells = line.replace(/\s/g, '').match(POWER_ROW_REGEX);
    if (cells && (cells.length === 6) && !isRowEmpty(cells)) {
        const volts = parseFloat(cells[2]);
        const amps = parseFloat(cells[3]);
        return { name: cells[1], volts, amps };
    }
    return null;
};

function fileAppearsInvalidError() {
    return new Error('Does not appear to be a valid Xilinx file');
}

function findPowerTable(lines, fromIndex) {
    const indexOfPowerSection = findIndex(lines, (line) => line.match(POWER_SUPPLY_SUMMARY_TEXT), fromIndex);
    if (indexOfPowerSection === -1) {
        throw fileAppearsInvalidError();
    }
    return indexOfPowerSection;
}

function findFirstRowOfPowerTable(lines, indexOfPowerTable) {
    let idx = indexOfPowerTable;
    const HARD_STOP = indexOfPowerTable + MAX_LINES_TO_LOOK_FOR_ROWS;
    do {
        const line = lines[idx];
        if (line.replace(/\s/g, '').match(POWER_ROW_REGEX)) {
            return idx;
        }
        idx++;
    }
    while ((idx < lines.length) && (idx < HARD_STOP));
    throw fileAppearsInvalidError();
}

export function extractPowerTable(text) {
    let lines = text.match(/[^\r\n]+/g);
    // PC-260, remove asterisks to not break import
    lines = lines.map(line => line.replace('*', ''));

    const indexOfPowerSection = findPowerTable(lines, 0);
    let firstRowIndex = -1;
    try {
        firstRowIndex = findFirstRowOfPowerTable(lines, indexOfPowerSection);
    }
    catch (e) {
        // we may have found an index section, check for another section
        const secondSection = findPowerTable(lines, indexOfPowerSection + 1);
        firstRowIndex = findFirstRowOfPowerTable(lines, secondSection);
    }
    const HARD_STOP = firstRowIndex + MAX_TABLE_SIZE;

    let outputs = [];
    let idx = firstRowIndex;
    let rowData = null;
    do {
        rowData = extractPowerDataFromRow(lines[idx]);
        if ((rowData != null) && (rowData.amps !== 0.0)) {
            outputs.push(rowData);
        }
        idx++;
    }
    while ((rowData != null) && (idx < HARD_STOP));

    return outputs;
}

export function handleLoadingAsXilinx(text) {
    return {
        name: XILINX_VENDOR_NAME,
        outputs: extractPowerTable(text)
    };
}

export default function tryLoadingAsAltera(fileObj) {
    return tryLoadingFromFile(fileObj, loadTextFromFile, handleLoadingAsXilinx);
}
