// TODO: re-add local once renesas-common allows discrete imports
// import { locale } from 'renesas-common';
import EN from "translations/en.json";
import JA from "translations/ja.json";
import ZH from "translations/zh.json";

const defaultLanguage = "en";
const supportedLanguages = { en: EN, ja: JA, zh: ZH };
export const languageFromUrl = Object.keys(supportedLanguages).reduce(
    (a, c) => (window.location.href.indexOf(`/${c}/`) + 1 ? c : a),
    defaultLanguage
);
export const templateReplacer = (translation, variables) => {
    // replaces all unique occurrences of '${anything}' with corresponding index from args
    // in case of a duplicated key, the same value is reused e.g.
    // translation = '${anything1} and ${anything2} then ${anything1} again';
    // variables = ['testA', 'testB', 'testC'];
    // result = 'testA and testB then testA again';
    // note: 'testC' is never assigned because third key ${anything1} is reused
    [...new Set(translation.match(new RegExp("\\$\\{(.*?)}", "gi")))].forEach(
        (item, index) => {
            const search = new RegExp(
                item.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&"),
                "gi"
            ); // escape special chracters
            const value =
                typeof variables[0] === "object"
                    ? variables[0][item.replace(/\${|}/gi, "")]
                    : variables[index]; // as single object or list of args
            translation = translation.replace(search, value);
        }
    );
    return translation;
};

const getText = (text, ...variables) => {
    let translation =
        supportedLanguages[languageFromUrl] &&
        supportedLanguages[languageFromUrl][text];
    if (variables && variables.length && translation) {
        translation = templateReplacer(translation, variables);
    }
    if (translation) {
        return translation;
    }
    else {
        if (!text) {
            // console.error(`Untranslated Text`);
        }
        else {
            // console.warn(
            //     `Translation "${text}" Missing For "${languageFromUrl}"`
            // );
        }
        return supportedLanguages[defaultLanguage][text] || "";
    }
};

export default getText;
