import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import {linear} from 'logic/resampling';
import {MATCH_SCORE_LIMIT} from './constants.js';

export function calcLdoEfficiency(ref) {
    const D = (ref.outputs[0].ioutMax * ref.vinMax);
    return (D === 0) ? 0 : (ref.outputs[0].ioutMax * ref.outputs[0].voutMax) / D;
}

export function calcScore(ref, peff) {
    let score = 0.0;
    score = (ref.ta === peff.ta) ? 0 : 1;
    score += (peff.fsw / 1e7);

    const refVin = (ref.vinMax + ref.vinMin) / 2;
    let ave = Math.abs(refVin - peff.vin);

    ave += Math.abs(ref.outputs[0].voutMax - peff.vout);
    if (ref.numDevicesinSolution > 1) {
        ave += Math.abs(ref.outputs[0].ioutMax/ref.numDevicesinSolution - peff.iout);
    }
    else {
        ave += Math.abs(ref.outputs[0].ioutMax - peff.iout);
    }

    return score + (ave/3.0);
}

function linearInterp(A, B, ioutMax) {
    return linear(B.efficiency, A.efficiency, B.iout, A.iout, ioutMax);
}

export function interpolate(A, B, ioutMax) {
    if (!(ioutMax >= 0)) return null;

    if ((ioutMax === 0) &&
        (A.mode.toLowerCase() === "pwm") &&
        (A.score !== 0)) return 0.4;

    if ((A.score < MATCH_SCORE_LIMIT) && (B.score < MATCH_SCORE_LIMIT)) {
        if ((A.vin === B.vin) &&
            (A.vout === B.vout) &&
            (A.iout !== B.iout) &&
            (ioutMax <= Math.max(A.iout, B.iout))) {
            return linearInterp(A, B, ioutMax);
        }

        return (A.efficiency + B.efficiency) / 2;
    }

    return null;
}

export function estimateEfficiencyFromCurves(referenceObj, dataPoints) {
    if (dataPoints.length === 0) return null;
    if (dataPoints.length === 1) return dataPoints[0].efficiency;
    dataPoints.forEach((curve, idx) => {
        dataPoints[idx].score = calcScore(referenceObj, curve);
    });
    const sorted = sortBy(dataPoints, 'score');
    if (referenceObj.outputs[0].ioutMax === undefined) {
        throw new Error("ioutMax not defined in estimateEfficiencyFromCurves");
    }
    return interpolate(sorted[0], sorted[1], referenceObj.outputs[0].ioutMax);
}

export function getDataPoints(efficiencyDb, partNumber, numOutputs) {
    const partData = find(efficiencyDb, { partNumber });
    let curves = null;
    // there may not an entry for this part
    if ((partData) && (numOutputs)) {
        curves = partData.curves.filter((x) => x.numOutputs === numOutputs);
        // there may not be data for this num outputs
        if (curves.length === 0) {
            curves = null;
        }
    }
    return curves;
}
