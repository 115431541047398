import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

export const SHOW_ALL_FILTER = 'showAll';
export const SHOW_SELECTED_FILTER = 'showSelected';

class FilterItem extends React.PureComponent {
    clickHandler = () => {
        !this.props.active && this.props.onClick(this.props.filter);
    }
    render() {
        const { active, children } = this.props;
        return (
            <span className={classNames({ [css.filter]: true, [css.active]: active })}
                onClick={this.clickHandler}>
                {children}
            </span>
        );
    }
}
FilterItem.propTypes = {
    active: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    filter: PropTypes.string.isRequired
};

const SelectionIndicator = ({
    nrOutputsWithParts,
    nrOutputs,
    nrSelectedParts,
    className = '',
    onFilterClick,
    activeFilter
}) => {
    return (
        <div className={`${css.container} ${className}`}>
            <div>
                <span className={css.info}>{`${nrOutputsWithParts}/${nrOutputs} `}</span>
                {getText(contentKeys.OUTPUTS_HAVE_SELECTED_PARTS)}
            </div>
            {nrSelectedParts
                ? (<div>
                    <FilterItem active={activeFilter === SHOW_SELECTED_FILTER}
                        filter={SHOW_SELECTED_FILTER}
                        onClick={onFilterClick}
                    >
                        <span className={css.info}>{nrSelectedParts}</span>{' '+getText(contentKeys.PARTS_SELECTED)}
                    </FilterItem>
                    {` | `}
                    <FilterItem active={activeFilter === SHOW_ALL_FILTER}
                        filter={SHOW_ALL_FILTER}
                        onClick={onFilterClick}
                    >
                        {getText(contentKeys.SHOW_ALL)}
                    </FilterItem>
                </div>)
                : null}
        </div>
    );
};

SelectionIndicator.propTypes = {
    nrOutputs: PropTypes.number.isRequired,
    nrOutputsWithParts: PropTypes.number.isRequired,
    nrSelectedParts: PropTypes.number.isRequired,
    className: PropTypes.string,
    onFilterClick: PropTypes.func.isRequired,
    activeFilter: PropTypes.string.isRequired
};

export default SelectionIndicator;
