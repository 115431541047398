import {makeAction, createReducer} from '../utils/redux-helpers';
import {suggestedPartsInitialState as initialState, defaultSettings, defaultFilters} from '../initialStates';
import {stripEmptyOutputs,
    extractSingleOutputsFromEditorState,
    matchAllParts} from 'logic/matching';
import _ from 'lodash';
import {isAtLeastOnePartPerOutputSelected} from "logic/selections";
import addCountsBasedOnCurrentFilters from 'logic/addCountsBasedOnCurrentFilters';
import {actions as compareAndSelectActions} from "redux/modules/compareAndSelect";
import getHistory from 'util/history';
import estimateEfficiencyForSuggestions from "logic/estimateEfficiencyForSuggestions";
import SolutionType from 'logic/SolutionType';
import {SchematicFilterType, AECQFilterType, SyncFilterType, ResultOrderOptions,
    DEFAULT_RESULTS_PER_TOPOLOGY_LIMIT,
    DEFAULT_RESULTS_ORDER_BY_SETTING, DEFAULT_SHOW_PRICE_SETTING} from 'logic/constants';
import forOwn from 'lodash/forOwn';
import uniq from 'lodash/uniq';
import {registerBlacklistedKeys} from 'redux/persist';

function prefix (type) { return `suggestedParts/${type}`; }

export const actionTypes = {
    LOAD_RESULTS: prefix('LOAD_RESULTS'),
    RECEIVE_RESULTS: prefix('RECEIVE_RESULTS'),
    RECEIVE_ERROR: prefix('RECEIVE_ERROR'),
    UPDATE_RESULT_PART_COUNTS: prefix('UPDATE_RESULT_PART_COUNTS'),
    FILTER_SOLUTIONS_TYPE: prefix('FILTER_SOLUTIONS_TYPE'),
    FILTER_BY_SYNC_STATUS: prefix('FILTER_BY_SYNC_STATUS'),
    FILTER_BY_SCHEMATIC: prefix('FILTER_BY_SCHEMATIC'),
    FILTER_BY_AECQ_STATUS: prefix('FILTER_BY_AECQ_STATUS'),
    APPLY_FILTERS_SETTINGS: prefix('APPLY_FILTERS_SETTINGS'),
    APPLY_PENDING_SETTINGS: prefix('APPLY_PENDING_SETTINGS'),
    RESET_FILTERS: prefix('RESET_FILTERS'),
    RESET_SETTINGS: prefix('RESET_SETTINGS'),
    LIMIT_TOPOLOGIES: prefix('LIMIT_TOPOLOGIES'),
    SET_ORDER_BY: prefix('SET_ORDER_BY'),
    SET_SHOW_PRICE: prefix('SET_SHOW_PRICE'),
    SELECT_PART: prefix('SELECT_PART'),
    CLEAR_SEARCH_RESULTS: prefix("CLEAR_SEARCH_RESULTS"),
    RELOAD_RESULTS: prefix('RELOAD_RESULTS'),
    RESET: prefix('RESET'),
    SET_ADDITIONAL_ORDER_BY_COLUMN: prefix('SET_ADDITIONAL_ORDER_BY_COLUMN'),
    CLEAR_ADDITIONAL_ORDER_BY_COLUMN: prefix('CLEAR_ADDITIONAL_ORDER_BY_COLUMN'),
    HIDE_WARNING: prefix('HIDE_WARNING'),
    UNSELECT_HIDDEN_PARTS: prefix('UNSELECT_HIDDEN_PARTS'),
    FIND_HIDDEN_PARTS: prefix('FIND_HIDDEN_PARTS'),
    UPDATE_TOTAL_SELECTIONS: prefix('UPDATE_TOTAL_SELECTIONS'),
    UPDATE_CAN_CONTINUE: prefix('UPDATE_CAN_CONTINUE'),
    DESELECT_PARTS: prefix('DESELECT_PARTS'),
    CHANGE_SHOW_SELECTED_PARTS: prefix('CHANGE_SHOW_SELECTED_PARTS'),
    SET_EXPANDED_STATE: prefix('SET_EXPANDED_STATE')
};

// TODO: This doesn't need to use a promise its all synchronus code
function getSuggestedParts(partsDbState, editorState, activeColors) {
    return new Promise((resolve, reject) => {
        if (!partsDbState.received) return reject('Parts db not loaded');

        const partsDb = partsDbState.data;
        const editor = stripEmptyOutputs(editorState);
        let singleOutputs = extractSingleOutputsFromEditorState(editor);
        let mergedSets = matchAllParts(partsDb, singleOutputs, activeColors);
        resolve(mergedSets);
    });
}

export const actions = {
    fetchResults: (data) => {
        return (dispatch, getState) => {
            dispatch(actions.loadResults());
            return getSuggestedParts(getState().partsDb, data.editor, data.activeColors)
                .then((results) => {
                    const efficiencyDb = getState().efficiencyDb.data;
                    dispatch(actions.receiveResults(estimateEfficiencyForSuggestions(results, efficiencyDb)));
                })
                .catch((error) => {
                    // calling toString in case an exception object was thrown rather than a string
                    dispatch(actions.receiveError(error.toString()));
                });
        };
    },
    loadResults: () => makeAction(actionTypes.LOAD_RESULTS),
    filterSolutionTypes: (data) => makeAction(actionTypes.FILTER_SOLUTIONS_TYPE, data),
    filterBySchematicStatus: (filterType) => makeAction(actionTypes.FILTER_BY_SCHEMATIC, filterType),
    filterByAECQStatus: (filterType) => makeAction(actionTypes.FILTER_BY_AECQ_STATUS, filterType),
    filterBySyncStatus: (filterType) => makeAction(actionTypes.FILTER_BY_SYNC_STATUS, filterType),
    applyPendingSettings: (pendingSettings) => makeAction(actionTypes.APPLY_PENDING_SETTINGS, pendingSettings),
    applyPendingFilterSettings: (pendingSettings) => makeAction(actionTypes.APPLY_FILTERS_SETTINGS, pendingSettings),
    resetFilters: () => makeAction(actionTypes.RESET_FILTERS),
    resetSettingsAndAdditionalColumn: () => dispatch => {
        dispatch(actions.clearAdditionalOrderByColumn());
        dispatch(actions.resetSettings());
    },
    resetSettings: () => makeAction(actionTypes.RESET_SETTINGS),
    receiveResults: (data) => makeAction(actionTypes.RECEIVE_RESULTS, data),
    receiveError: (data) => makeAction(actionTypes.RECEIVE_ERROR, data),
    limitTopologies: (data) => makeAction(actionTypes.LIMIT_TOPOLOGIES, data),
    orderResultsBy: (orderBy) => dispatch => {
        switch (orderBy.field) {
            case ResultOrderOptions.NEWEST_RELEASE_DATE:
            case ResultOrderOptions.SMALLEST_PACKAGE:
            case ResultOrderOptions.LOWEST_IQ:
            case ResultOrderOptions.SMALLEST_FOOTPRINT:
            case ResultOrderOptions.LOWEST_BOM_COST:
            case ResultOrderOptions.LOWEST_BOM_COUNT:
                dispatch(actions.setAdditionalOrderByColumn(orderBy));
        }
        dispatch(actions.setOrderBy(orderBy));
    },
    setOrderBy: (orderBy) => makeAction(actionTypes.SET_ORDER_BY, orderBy),
    setShowPriceAndUpdateOrderBy: (showPrice) => (dispatch) => {
        dispatch(actions.setShowPrice(showPrice));
    },
    setShowPrice: (showPrice) => makeAction(actionTypes.SET_SHOW_PRICE, showPrice),
    setSelectedPart: (data) => (dispatch, getState) => {
        dispatch(actions.selectPart(data));
        dispatch(actions.updateCanContinue());
    },
    selectPart: (data) => makeAction(actionTypes.SELECT_PART, data),
    updateCanContinue: () => makeAction(actionTypes.UPDATE_CAN_CONTINUE),
    clearSearchResults: () => makeAction(actionTypes.CLEAR_SEARCH_RESULTS),
    reloadResults: () => makeAction(actionTypes.RELOAD_RESULTS),
    reset: () => makeAction(actionTypes.RESET),
    continue: () => (dispatch, getState) => {
        dispatch(compareAndSelectActions.fetchResults());
        getHistory().push('/compare');
    },
    setAdditionalOrderByColumn: (fieldName) => makeAction(actionTypes.SET_ADDITIONAL_ORDER_BY_COLUMN, fieldName),
    clearAdditionalOrderByColumn: () => makeAction(actionTypes.CLEAR_ADDITIONAL_ORDER_BY_COLUMN),
    hideWarning: () => makeAction(actionTypes.HIDE_WARNING),
    unselectHiddenParts: () => (dispatch, getState) => {
        dispatch(actions.hideWarning());

        const {suggestedParts} = getState();
        const hiddenParts = selectors.findHiddenParts(suggestedParts);
        dispatch(actions.deselectParts(hiddenParts));

        dispatch(actions.updateCanContinue());
    },
    deselectParts: (parts) => makeAction(actionTypes.DESELECT_PARTS, parts),
    changeShowSelectedParts: (showSelectedParts) =>
        makeAction(actionTypes.CHANGE_SHOW_SELECTED_PARTS, showSelectedParts),
    setExpandedState: (expandedState) => makeAction(actionTypes.SET_EXPANDED_STATE, expandedState)
};

function updateResultsWithCountsAndGetTotal(state) {
    const { results, solutionTypes, schematicFilters, aecqFilter, syncFilter, limit, orderBy } = state;
    const updated = addCountsBasedOnCurrentFilters(results,
        solutionTypes, schematicFilters, aecqFilter, syncFilter, limit, orderBy);
    const total = updated.reduce((a, x) => a+x.count, 0);
    return { ...state, results: updated, total };
};

const updateShowSelected = (selectedParts, showSelectedParts) => {
    return showSelectedParts && selectors.getTotalSelections({ selectedParts }) > 0;
};

export const ACTION_HANDLERS = {
    [actionTypes.RESET]: (state) => initialState,
    [actionTypes.LOAD_RESULTS]: (state) => {
        // todo clear results once data source is refactored, results: [] };
        return { ...state, requested: true, received: false, error: false };
    },
    [actionTypes.RECEIVE_RESULTS]: (state, {payload}) => {
        const selectedParts = {};
        const newState = { ...state,
            requested: false,
            received: true,
            results: payload,
            tick: Date.now(),
            showSelectedParts: updateShowSelected(selectedParts, state.showSelectedParts),
            selectedParts,
            canContinue: false,
            showWarning: true,
            allAreExpanded: false,
            allAreCollapsed: true };

        return updateResultsWithCountsAndGetTotal(newState);
    },
    [actionTypes.RECEIVE_ERROR]: (state, {payload}) => {
        return { ...state, requested: false, error: true, message: payload };
    },
    [actionTypes.FILTER_SOLUTIONS_TYPE]: (state, {payload}) => {
        return updateResultsWithCountsAndGetTotal({ ...state, solutionTypes: payload, showWarning: true });
    },
    [actionTypes.FILTER_BY_SCHEMATIC]: (state, {payload}) => {
        return updateResultsWithCountsAndGetTotal({ ...state, schematicFilters: [...payload], showWarning: true });
    },
    [actionTypes.FILTER_BY_AECQ_STATUS]: (state, {payload}) => {
        return updateResultsWithCountsAndGetTotal({ ...state, aecqFilter: payload, showWarning: true });
    },
    [actionTypes.FILTER_BY_SYNC_STATUS]: (state, {payload}) => {
        return updateResultsWithCountsAndGetTotal({ ...state, syncFilter: payload, showWarning: true });
    },
    [actionTypes.APPLY_FILTERS_SETTINGS]: (state, {payload}) => {
        return updateResultsWithCountsAndGetTotal({ ...state, ...payload, showWarning: true });
    },
    [actionTypes.APPLY_PENDING_SETTINGS]: (state, {payload}) => {
        return updateResultsWithCountsAndGetTotal({
            ...state,
            ...payload,
            showWarning: true
        });
    },
    [actionTypes.RESET_FILTERS]: (state, {payload}) => {
        return updateResultsWithCountsAndGetTotal({
            ...state,
            ...defaultFilters
        });
    },
    [actionTypes.RESET_SETTINGS]: (state, {payload}) => {
        return updateResultsWithCountsAndGetTotal({
            ...state,
            ...defaultSettings
        });
    },
    [actionTypes.LIMIT_TOPOLOGIES]: (state, {payload}) => {
        return updateResultsWithCountsAndGetTotal({ ...state, limit: payload.limit, showWarning: true });
    },
    [actionTypes.SET_ORDER_BY]: (state, {payload}) => {
        return { ...state, orderBy: payload };
    },
    [actionTypes.SET_SHOW_PRICE]: (state, {payload}) => {
        return { ...state, showPrice: payload };
    },
    [actionTypes.SELECT_PART]: (state, {payload}) => {
        /* expect:
            state.selectedParts = {
                'outputId1': ['part-id-1','part-id-2',...],
                'outputId2': ['part-id-2','part-id-4',...],
                ...
            }
         */
        const { outputId, partId, checked } = payload;
        let selectedParts = { ...state.selectedParts };
        if (!state.selectedParts[outputId]) { // if first selection for this output
            selectedParts[outputId] = [];
        }

        let listOfPartIds = selectedParts[outputId];
        if (checked) {
            selectedParts[outputId] = _.uniq([ ...listOfPartIds, partId ]);
        }
        else {
            selectedParts[outputId] = listOfPartIds.filter((p) => p !== partId);
            if (selectedParts[outputId].length === 0) {
                delete selectedParts[outputId];
            }
        }
        const showSelectedParts = updateShowSelected(selectedParts, state.showSelectedParts);
        return { ...state, selectedParts, showSelectedParts };
    },
    [actionTypes.UPDATE_CAN_CONTINUE]: (state) => {
        const {results, selectedParts} = state;
        const canContinue = isAtLeastOnePartPerOutputSelected(results, selectedParts);
        return { ...state, selectedParts, canContinue };
    },
    [actionTypes.CLEAR_SEARCH_RESULTS]: (state) => {
        return { ...state, requested: true, received: false, error: false, message: "" };
    },
    [actionTypes.RELOAD_RESULTS]: (state) => {
        if (state.received) {
            return { ...state, tick: Date.now() };
        }
        return { ...state };
    },
    [actionTypes.SET_ADDITIONAL_ORDER_BY_COLUMN]: (state, {payload}) => {
        return { ...state, additionalOrderByColumn: payload };
    },
    [actionTypes.CLEAR_ADDITIONAL_ORDER_BY_COLUMN]: (state) => {
        return { ...state, additionalOrderByColumn: null };
    },
    [actionTypes.HIDE_WARNING]: (state) => {
        return { ...state, showWarning: false };
    },
    [actionTypes.DESELECT_PARTS]: (state, {payload}) => {
        const { selectedParts } = state;
        const toDeselect = payload;
        const updated = { ...selectedParts };

        forOwn(toDeselect, function(parts, outputId) {
            updated[outputId] = _.without(selectedParts[outputId], ...parts);
            if (updated[outputId].length === 0) {
                delete updated[outputId];
            }
        });
        const showSelectedParts = updateShowSelected(updated, state.showSelectedParts);
        return { ...state, selectedParts: updated, showSelectedParts };
    },
    [actionTypes.CHANGE_SHOW_SELECTED_PARTS]: (state, {payload}) => {
        return {
            ...state,
            showSelectedParts: updateShowSelected(state.selectedParts, payload)
        };
    },
    [actionTypes.SET_EXPANDED_STATE]: (state, {payload}) => {
        const {allAreExpanded, allAreCollapsed, change} = payload;
        return { ...state, expandedState: { allAreExpanded, allAreCollapsed, change } };
    }
};

export const selectors = {
    areFiltersModified: (suggestedParts) => {
        return !(
            (suggestedParts.solutionTypes.length === 1 && suggestedParts.solutionTypes[0] === SolutionType.ALL) &&
            (suggestedParts.schematicFilters.length === 1 &&
             suggestedParts.schematicFilters[0] === SchematicFilterType.ALL) &&
            (suggestedParts.aecqFilter === AECQFilterType.ALL) &&
            (suggestedParts.syncFilter === SyncFilterType.ALL));
    },
    areSettingsModified: (suggestedParts) => {
        return !((suggestedParts.limit === DEFAULT_RESULTS_PER_TOPOLOGY_LIMIT) &&
            (suggestedParts.showPrice === DEFAULT_SHOW_PRICE_SETTING) &&
            _.isEqual(suggestedParts.orderBy, DEFAULT_RESULTS_ORDER_BY_SETTING));
    },
    getTotalSelections: ({ selectedParts }) => {
        let totalSelections = 0;
        forOwn(selectedParts, function(output) {
            totalSelections += output.length;
        });
        return totalSelections;
    },
    findHiddenParts: (suggestedParts) => {
        const {results, selectedParts} = suggestedParts;

        let hidden = {};
        _.forOwn(selectedParts, (selections, outputId) => {
            const output = _.find(results, (o) => o.id === outputId);

            hidden[outputId] = [];
            selections.forEach(selection => {
                const isHidden = (_.findIndex(output.filtered, p => p.id === selection) === -1);
                if (isHidden) {
                    hidden[outputId].push(selection);
                }
            });

            if (hidden[outputId].length === 0) {
                delete hidden[outputId];
            }
        });

        return hidden;
    },
    countHiddenParts: (suggestedParts) => {
        const hiddenParts = selectors.findHiddenParts(suggestedParts);
        let count = 0;
        _.forOwn(hiddenParts, function(parts) {
            count += parts.length;
        });
        return count;
    },

    getUniqueOutputIds: (results) => {
        const resultOutputIds = results.reduce(
            (ids, result) => ids.concat(result.ids || [result.id]),
            []
        );

        return uniq(resultOutputIds);
    },

    getOutputsWithSelectedParts: (suggestedParts) => {
        const resultsWithSelectedParts = suggestedParts.results
            .filter(result => suggestedParts.selectedParts[result.id]);

        return selectors.getUniqueOutputIds(resultsWithSelectedParts);
    }
};

registerBlacklistedKeys('suggestedParts', [
    'requested',
    'received',
    'error',
    'errorMessage',
    'showSelectedParts',
    'expandedState'
]);

export default createReducer(initialState, ACTION_HANDLERS);
