/* global $ */
import makeApiUrl from './makeApiUrl';
import {getJson} from './ajax';

const VENDORS_URL = "designs.json/vendors";
const PLATFORMS_URL_STUB = "designs.json/platforms/";

export const fetchVendors = function() {
    return new Promise((resolve, reject) => {
        getJson(makeApiUrl(VENDORS_URL))
            .then(resolve, (error) => reject(error.statusText));
    });
};

export const fetchPlatforms = function(vendorResponse) {
    const allVendors = vendorResponse.designs.reduce((str, obj) => str + "/" + obj.vendor, "");
    return new Promise((resolve, reject) => {
        getJson(makeApiUrl(PLATFORMS_URL_STUB) + allVendors)
            .then((platformsResponse) => {
                resolve({vendorResponse, platformsResponse});
            },
            (error) => reject(error.statusText));
    });
};
