import React from 'react';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';

export const phoneMax = 599;
export const tabletMax = 1024;
export const desktopMin = 1025;

const propTypes = {
    children: PropTypes.node
};

export const Tablet = ({ className, ...props }) => (
    <MediaQuery maxWidth={tabletMax} className={className}>
        {matches => {
            return matches ? props.children : null;
        }}
    </MediaQuery>
);
Tablet.propTypes = propTypes;

export const TabletUp = ({ className, ...props }) => (
    <MediaQuery minWidth={phoneMax + 1} className={className}>
        {matches => {
            return matches ? props.children : null;
        }}
    </MediaQuery>
);
TabletUp.propTypes = propTypes;

export const Phone = ({ className, ...props }) => (
    <MediaQuery maxWidth={phoneMax} className={className}>
        {matches => {
            return matches ? props.children : null;
        }}
    </MediaQuery>
);

Phone.propTypes = propTypes;

export const Desktop = ({ className, ...props }) => (
    <MediaQuery minWidth={desktopMin} className={className}>
        {matches => {
            return matches ? props.children : null;
        }}
    </MediaQuery>
);

export const WithPhone = Component => props => (
    <MediaQuery maxWidth={phoneMax}>
        {phoneMatch => <Component {...props} phoneMatch={phoneMatch} />}
    </MediaQuery>
);

export const WithTablet = Component => props => (
    <MediaQuery maxWidth={tabletMax}>
        {tabletMatch => <Component {...props} tabletMatch={tabletMatch} />}
    </MediaQuery>
);

Desktop.propTypes = propTypes;
