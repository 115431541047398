/* global $ */
import PropTypes from 'prop-types';

import React from "react";
import {findDOMNode} from 'react-dom';

class KendoGrid extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        // top level grid
        options: PropTypes.object.isRequired,
        detailInit: PropTypes.func,
        expandAll: PropTypes.bool,
        collapseAll: PropTypes.bool,
        updateExpandedRows: PropTypes.func,
        onSelectParts: PropTypes.func,
        disableRowExpandCollapse: PropTypes.bool
    };

    static defaultProps = {
        options: {}
    };

    selectPart = (checked, id) => {
        this.props.onSelectParts(checked, id);
    };

    updateParentWithExpandCollapseState = (numRows, numExpanded, change) => {
        this.props.updateExpandedRows({
            allAreCollapsed: (numExpanded === 0),
            allAreExpanded: (numExpanded === numRows),
            change
        });
    };

    registerRowExpandCollapseHandler = (gridElement) => {
        var component = this;
        const nonEmptyRowSelector = ".k-master-row:not(.emptyRail)";
        gridElement.on("click", nonEmptyRowSelector, function(e) { // unbinding in componentWillUnmount
            if (component.props.disableRowExpandCollapse) return;
            const $this = $(this);
            const change = { expanded: false, collapsed: false };

            if ($this.hasClass('tagExpanded')) {
                component.instance.collapseRow($this);
                $this.find('.fa.collapseControl').toggleClass('fa-angle-up fa-angle-down');
                $this.removeClass('tagExpanded');
                change.collapsed = true;
            }
            else {
                component.instance.expandRow($this);
                $this.find('.fa.collapseControl').toggleClass('fa-angle-up fa-angle-down');
                $this.addClass('tagExpanded');
                change.expanded = true;
            }

            const $grid = $this.closest('.k-grid');
            const numRows = $grid.find('.k-master-row.spGrid:not(.emptyRail)').length;
            const numExpanded = gridElement.find('.tagExpanded').length;

            const masterRows = $this.parent().find(nonEmptyRowSelector);
            change.rowIndex = masterRows.index($this);

            component.updateParentWithExpandCollapseState(numRows, numExpanded, change);
        });
    };

    _create() {
        this.$rootElement= $(findDOMNode(this));
        let options = this.props.options;
        if (this.props.detailInit) {
            // we are rendering a detail view so hide the default allAreExpanded/collapse icon
            const callersDataBound = options.dataBound;
            options.dataBound = function(e) {
                $('.k-hierarchy-cell .k-icon').closest('td').hide();
                // add a class for specific styling on the last top level row
                $('tr.k-master-row').last().addClass('lastTopLevelRow');
                if (callersDataBound) callersDataBound(e);
            };

            options.detailInit = this.props.detailInit;

            // fix detail row spacing due to missing icon
            $(this).next().find(".k-hierarchy-cell").hide();
        }

        this.$rootElement.kendoGrid(options);
        this.instance = this.$rootElement.data('kendoGrid');

        if (this.props.detailInit) {
            this.registerRowExpandCollapseHandler(this.$rootElement);
        }
    }

    componentDidMount () {
        this._create();
    }

    _markAsCollapsed(rows) {
        rows.find('.fa.collapseControl').addClass('fa-angle-down').removeClass('fa-angle-up');
        rows.removeClass('tagExpanded');
    }

    _markEmptyRailsAsCollapsed() {
        const emptyRows = this.$rootElement.find('.emptyRail');
        this._markAsCollapsed(emptyRows);
    }

    _expandCollapseAll(nextProps) {
        const nonEmptyRowSelector = ".k-master-row:not(.emptyRail)";
        const rows = this.$rootElement.find(nonEmptyRowSelector);
        if (nextProps.collapseAll) {
            this.instance.collapseRow(rows);
            this._markAsCollapsed(rows);
        }
        else if (nextProps.expandAll) {
            this.instance.expandRow(rows);
            rows.find('.fa.collapseControl').removeClass('fa-angle-down').addClass('fa-angle-up');
            rows.addClass('tagExpanded');
        }
    }

    shouldComponentUpdate(nextProps) {
        this._expandCollapseAll(nextProps);
        this._markEmptyRailsAsCollapsed();
        return false;
    }

    componentWillUnmount() {
        const instance = this.$rootElement.data('kendoGrid');
        instance.unbind();
        if (instance.dataSource) {
            instance.dataSource.unbind('change', instance._refreshHandler);
            instance.dataSource.unbind('error', instance._errorHandler);
        }
        instance.destroy();
    }

    getRootNode() {
        return this.$rootElement;
    }

    render() {
        return (<div id={this.props.id} className="kendocompo" ></div>);
    }
}

export default KendoGrid;
