/* global kendo */
/* global $, store */
import PropTypes from 'prop-types';

import React from 'react';
import {Col} from 'bootstrap-core';
import {PartsDataSource} from 'redux/partsDataSource';
import {PartsDetailDataSourceFactory} from 'redux/partsDetailDataSourceFactory';
import SolutionType from 'logic/SolutionType';
import KendoGrid from 'components/KendoGrid';
import {actions as suggestedPartsActions} from 'redux/modules/suggestedParts';
import topLevelRowTemplate from './topLevelRow.template';
import detailDataTemplate from './detailDataRow.template';
import isArray from 'lodash/isArray';
import css from './styles.less';
import {PartFieldNames} from 'logic/constants';
import {
    mapOrderByToSortInfo,
    mapSortInfoToOrderBy,
    getAdditionalSortablePartFieldNames
} from "../../logic/orderByMappings";
import KendoTooltipWrapper from 'kendo-plugins/TooltipWrapper';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';
import makeISimUrl from 'util/makeISimUrl';
import { hasValidQuerystringParamsInCurrentCycle, getSortColumn, getSortDirection } from 'redux/modules/startOptions';

class PartsGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        this.topLevelDataSource = null;
        this.detailDataSourceFactory = null;
    }

    static propTypes = {
        updateExpandedRows: PropTypes.func.isRequired,
        expandAll: PropTypes.bool.isRequired,
        collapseAll: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func.isRequired,
        disableRowExpandCollapse: PropTypes.bool
    };

    componentWillMount() {
        this.topLevelDataSource = new PartsDataSource(window.store, SolutionType.ALL, 5);
        this.detailDataSourceFactory = new PartsDetailDataSourceFactory(window.store);
    }

    componentWillUpdate(nextProps) {
        const $grid = $('#partsGrid');
        if (nextProps.disableRowExpandCollapse) {
            $grid.addClass('disableExpandCollapse');
        }
        else {
            $grid.removeClass('disableExpandCollapse');
        }
    }

    componentWillUnmount() {
        this.topLevelDataSource.unsubscribe();
        this.topLevelDataSource = null;
        this.detailDataSourceFactory.unsubscribeAndClearAllDataSources();
        this.detailDataSourceFactory.unsubscribe();
        this.detailDataSourceFactory = null;
    }

    detailOptions = {
        columns: [
            {
                title: getText(contentKeys.SELECT),
                sortable: false
            },
            { field: PartFieldNames.DEVICE_NAME, title: getText(contentKeys.PART_DESCRIPTION), sortable: true },
            { field: PartFieldNames.RELEASE_DATE, title: getText(contentKeys.RELEASE_DATE), sortable: true },
            { field: PartFieldNames.PACKAGE_SIZE,
                title: getText(contentKeys.PACKAGE_SIZE)+" (mm&sup2;)", stortable: true },
            { field: PartFieldNames.IQ, title: getText(contentKeys.IQ)+" (A)", sortable: true },
            { field: PartFieldNames.FOOTPRINT, title: "Solution Size (mm&sup2;)", sortable: true },
            { field: PartFieldNames.BOM_COST, title: getText(contentKeys.BOM_COST), sortable: true },
            { field: PartFieldNames.BOM_COUNT, title: getText(contentKeys.BOM_COUNT), sortable: true },
            {
                field: PartFieldNames.IOUT_MAX,
                title: "A (Max)",
                headerTemplate: `<span class="">
                        <span>A (Max)</span>
                        <span class='headerTooltip ioutMaxHeader fa fa-info-circle'></span></span>`,
                headerAttributes: {
                    "class": "dAmax"
                },
                sortable: true
            },
            {
                field: PartFieldNames.PRICE,
                title: getText(contentKeys.PRICE)+" ($/1k)",
                headerAttributes: {
                    "class": "dPrice"
                }
            },
            {
                field: PartFieldNames.EST_EFFICIENCY,
                headerTemplate: `<span class="">
                        <span>${getText(contentKeys.EST_EFF)}</span>
                        <span class='headerTooltip eeHeader  fa fa-info-circle'></span></span>`,
                headerAttributes: {
                    "class": "dEstEff"
                },
                sortable: true
            },
            {field: PartFieldNames.NOTES, title: getText(contentKeys.RECOMMENDED_SOLUTION_NOTES), sortable: false},
            {field: PartFieldNames.ORCAD_ENABLED, title: " ", sortable: false},
            {field: PartFieldNames.CAN_BE_SAMPLED, title: " ", sortable: false},
            {
                field: PartFieldNames.ISIM_MODEL,
                title: " ",
                headerAttributes: {
                    class: "dCanBeSampled"
                },
                sortable: false
            },
            {field: PartFieldNames.TOPOLOGY, hidden: true, groupHeaderTemplate: "#: value #"}
        ],
        sortable: { allowUnsort: false, mode: 'single' },
        sort: (e) => {
            this.props.onSortChange(e.sort);
        },
        rowTemplate: kendo.template(detailDataTemplate),
        scrollable: false,
        dataBound: function(e) {
            $('.k-grouping-row .k-icon').hide();
            const $masterGrid = $('#partsGrid').data('kendoGrid');
            const $detailRow = this.element.closest('.k-detail-row').prev();

            // detail rows can be forced closed and may not be rendered even though a databound event comes through
            if ($detailRow.length > 0) {
                const {suggestedParts} = window.store.getState();

                const detailGrid = this.element.data('kendoGrid');
                detailGrid.showColumn(PartFieldNames.PRICE);

                const sortableFields = getAdditionalSortablePartFieldNames();

                // grid headers are hidden / shown reliably but data columns erroneously show up
                // unless we show and re-hide all columns
                sortableFields.forEach((field) => {
                    detailGrid.showColumn(field);
                    detailGrid.hideColumn(field);
                });

                if (!suggestedParts.showPrice) {
                    detailGrid.hideColumn(PartFieldNames.PRICE);
                }

                if (suggestedParts.additionalOrderByColumn) {
                    const sortInfo = mapOrderByToSortInfo(suggestedParts.additionalOrderByColumn);
                    detailGrid.showColumn(sortInfo.field);
                }

                // Applies sorting based on URL params.
                if (hasValidQuerystringParamsInCurrentCycle()) {
                    const sortColumn = getSortColumn();
                    const sortDirection = getSortDirection();

                    if (sortColumn !== null) {
                        detailGrid.showColumn(sortColumn);

                        const sortUrlParams = {
                            field: sortColumn,
                            dir: sortDirection
                        };

                        // Updates the component's order based on URL directions.
                        const orderByParams = mapSortInfoToOrderBy(sortUrlParams);
                        suggestedParts.orderBy = orderByParams;
                    }
                }

                // Treats an edge case where an additional sortable field is present in the
                // URL and the user clicks on the table header to change the sort order.
                // In that case, the system is hiding the additional sortable field, and
                // this code will make sure that column remains visible.
                else {
                    const orderByParams = suggestedParts.orderBy;
                    const sortInfo = mapOrderByToSortInfo(orderByParams);
                    if (sortInfo.field) {
                        detailGrid.showColumn(sortInfo.field);
                    }
                }

                const outputData = $masterGrid.dataItem($detailRow);
                const outputId = outputData.id;
                this.element.find('.checkbox').bind("change", function(checkboxEvent) {
                    const $this = $(this);
                    const grid = $this.closest('.k-grid').data('kendoGrid');
                    const containingRow = $this.closest('tr');
                    const partData = grid.dataItem(containingRow);
                    if (checkboxEvent.target.checked) {
                        containingRow.addClass('selectedPart');
                    }
                    else {
                        containingRow.removeClass('selectedPart');
                    }
                    store.dispatch(suggestedPartsActions.setSelectedPart({
                        outputId,
                        partId: Number(partData.id),
                        checked: checkboxEvent.target.checked }));
                });

                // reselect parts based on redux state
                // we need to do this to preserve selections on refresh, back and after sorting and filtering
                const {selectedParts} = suggestedParts;
                const selectionsForThisOutput = selectedParts[outputId];
                if (isArray(selectionsForThisOutput) && (selectionsForThisOutput.length > 0)) {
                    selectionsForThisOutput.forEach((selection, index) => {
                        const $checkbox = this.element.find("[data-id='" + selection +"']");
                        $checkbox.attr("checked", true);
                        $checkbox.closest('tr').addClass('selectedPart');
                    });
                }

                // we lose tooltips after rerendering on sorting and filtering, so reinstate here
                KendoTooltipWrapper(this.element.find('.orcadEnabled'), getText(contentKeys.THIS_PART_IS_SCHEMATIC),
                    css.schematicTooltip);

                KendoTooltipWrapper(this.element.find('.canBeSampled'), getText(contentKeys.SAMPLES_AVAILABLE),
                    css.noWrap);

                KendoTooltipWrapper(this.element.find('.isimModel'), getText(contentKeys.ISIM_SIMULATION_AVAILABLE),
                    css.noWrap);
            }
        }
    };

    detailInit = (e) => {
        const masterGrid = $('#partsGrid').data('kendoGrid');
        const outputData = masterGrid.dataItem(e.detailRow.prev());
        const outputId = outputData.id;
        const newDataItems = makeISimUrl(e.data);

        this.detailOptions.dataSource = this.detailDataSourceFactory.create(newDataItems, e.data.ldo, outputId);
        this.detailOptions.dataBinding = this.detailDataBinding;

        $("<div/>").appendTo(e.detailCell).kendoGrid(this.detailOptions);

        KendoTooltipWrapper(e.detailRow.find(".eeHeader"), getText(contentKeys.ESTIMATED_EFFICIENCY_AT_MAXIMUM),
            css.noWrap);
        KendoTooltipWrapper(
            e.detailRow.find(".ioutMaxHeader"),
            getText(contentKeys.OUTPUT_PARTS_MAY_HAVE)
        );
    };

    expandRow(rowSelector) {
        $('#partsGrid').data('kendoGrid').expandRow(rowSelector);
    }

    collapseRow(rowSelector) {
        $('#partsGrid').data('kendoGrid').collapseRow(rowSelector);
    }

    render() {
        const options = {
            columns: [
                { field: "name", aggregates: ["count"] },
                { field: "type" },
                { editable: false },
                { editable: false }
            ],
            scrollable: false,
            rowTemplate: kendo.template(topLevelRowTemplate),
            dataSource: this.topLevelDataSource
        };

        return (<Col className="topGrid">
            <KendoGrid
                id="partsGrid"
                options={options}
                expandAll={this.props.expandAll}
                collapseAll={this.props.collapseAll}
                detailInit={this.detailInit}
                updateExpandedRows={this.props.updateExpandedRows}
                disableRowExpandCollapse={this.props.disableRowExpandCollapse} />
        </Col>);
    }
}

export default PartsGrid;
