import PropTypes from 'prop-types';
import React from 'react';
import {Row, Col} from 'bootstrap-core.js';
import databind from 'redux/utils/databind';
import DocumentTitle from 'components/DocumentTitle';
import {actions as projectActions} from 'redux/modules/projects';
import {actions as offlineActions} from 'redux/modules/offline';
import {actions as headerActions} from 'redux/modules/header';
import {actions as authActions, selectors as authSelectors} from 'redux/modules/auth';
import classNames from 'classnames';
import css from './styles.less';
import SignedInCheck from 'components/Projects/SignedInCheck';
import ProjectsTable from 'components/Projects/ProjectsTable';
import {CopyProjectModal, ConfirmDeleteModal, SendModal} from 'components/Projects/ProjectModals';
import Error from 'components/Error';
import find from 'lodash/find';
import partition from 'lodash/partition';
import partialRight from 'lodash/partialRight';
import ProjectStatus from "logic/status";
import {SyncConflictModal} from "../../components/Projects/ProjectModals";
import ActionButton from 'components/ActionButton';
import {Phone} from 'styles/variables/breakpoints';
import {Link} from 'react-router';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class ProjectsView extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        // databind
        projects: PropTypes.array,
        projectsLoading: PropTypes.bool,
        isSignedIn: PropTypes.bool,
        isOffline: PropTypes.bool,
        setLinkVisibility: PropTypes.func,
        setHeaderText: PropTypes.func,
        refreshProjects: PropTypes.func,
        newProject: PropTypes.func,
        deleteProject: PropTypes.func,
        editProject: PropTypes.func,
        retryGeneration: PropTypes.func,
        retrySync: PropTypes.func,
        copyProject: PropTypes.func,
        copyAndEditProject: PropTypes.func,
        sendProject: PropTypes.func,
        checkIsSignedInNow: PropTypes.func,
        resolveConflict: PropTypes.func,
        error: PropTypes.any
    };

    constructor() {
        super();
        this.state = {
            id: "",
            name: "",
            showCopyModal: false,
            showConfirmDeleteModal: false,
            showCopyAndEditModal: false,
            showSendModal: false
        };
    }

    static databind({auth, projects, offline}) {
        return {
            projects: projects.listing,
            // TODO: This is fragile because projects.loading can be true for more reasons than just refreshing the list
            projectsLoading: projects.loading,
            error: projects.error,
            isSignedIn: authSelectors.isSignedIn(auth),
            isOffline: offline.isOffline
        };
    }

    static actions = {
        setLinkVisibility: headerActions.setLinkVisibility,
        setHeaderText: headerActions.setHeaderText,
        refreshProjects: projectActions.refreshProjects,
        deleteProject: (projectId, isLocalProject) =>
            projectActions.tryProjectAction('deleteProject', projectId, isLocalProject),
        editProject: (projectId) => projectActions.tryProjectAction('editProject', projectId),
        retryGeneration: (projectId, designId) => projectActions.tryProjectAction('editProject', projectId, designId),
        retrySync: (project) => offlineActions.retrySync(project),
        copyProject: (projectId, name) => projectActions.tryProjectAction('copyProject', projectId, name),
        copyAndEditProject: (projectId, name) => {
            return projectActions.tryProjectAction('copyAndEditProject', projectId, name);
        },
        checkIsSignedInNow: authActions.checkIsSignedInNow,
        sendProject: (projectId, email) => projectActions.tryProjectAction('sendProject', projectId, email),
        resolveConflict: offlineActions.resolveConflict
    };

    componentWillMount() {
        this.props.checkIsSignedInNow();
        this.props.refreshProjects();

        this.props.setLinkVisibility({
            showSaveProjectLink: false,
            showExportCsvLink: false,
            showBackLink: true,
            showHelpLink: true,
            showYourProjectsLink: true,
            showSendLink: false,
            showOfflineVersionLink: false
        });

        this.props.setHeaderText(getText(contentKeys.YOUR_SAVED_PROJECTS));
    }

    showCopyAndEditModal = (id) => {
        const project = find(this.props.projects, p => p.id === id);
        const {name} = project;
        this.setState({ ...this.state, showCopyAndEditModal: true, id, name: `${name} copy` });
    };

    hideCopyAndEditModal = () => {
        this.setState({ ...this.state, showCopyAndEditModal: false, id: "", loading: false });
    };

    onConfirmCopyAndEdit = async (name) => {
        this.setState({ loading: true });
        await this.props.copyAndEditProject(this.state.id, name);
    };

    showCopyModal = (id) => {
        const project = find(this.props.projects, p => p.id === id);
        const {name} = project;
        this.setState({ ...this.state, showCopyModal: true, id, name: `${name} copy` });
    };

    hideCopyModal = () => {
        this.setState({ ...this.state, showCopyModal: false, id: "" });
    };

    onConfirmCopyWithName = (name) => {
        this.props.copyProject(this.state.id, name);
        this.hideCopyModal();
    };

    showConfirmDeleteModal = (id, isLocalProject) => {
        this.setState((state) => {
            return { ...state, showConfirmDeleteModal: true, id, deleteLocal: isLocalProject };
        });
    };

    hideConfirmDeleteModal = () => {
        this.setState(state => {
            return { ...state, showConfirmDeleteModal: false, id: "", deleteLocal: false };
        });
    };

    onConfirmDelete = () => {
        this.props.deleteProject(this.state.id, this.state.deleteLocal);
        this.hideConfirmDeleteModal();
    };

    showSyncConflictModal = (id) => {
        const projects = this.props.projects.filter(p => p.id === id);
        const [offlineProjects, onlineProjects] = partition(projects, p => p.isLocalProject);
        const name = offlineProjects[0].name;
        const offlineUpdatedAt = offlineProjects[0].updatedAt;
        const onlineUpdatedAt = onlineProjects[0].updatedAt;
        this.setState({
            ...this.state,
            showSyncConflictModal: true,
            id,
            name: `${name}`,
            offlineUpdatedAt,
            onlineUpdatedAt
        });
    };

    hideSyncConflictModal = () => {
        this.setState({ ...this.state,
            showSyncConflictModal: false,
            id: "",
            name: "",
            offlineUpdatedAt: null,
            onlineUpdatedAt: null });
    };

    keepOnlineProject = () => {
        this.props.resolveConflict(this.state.id, 'KEEP_ONLINE');
        this.hideSyncConflictModal();
    };

    keepOfflineProject = () => {
        this.props.resolveConflict(this.state.id, 'KEEP_OFFLINE');
        this.hideSyncConflictModal();
    };

    keepOfflineProjectCopy = () => {
        this.props.resolveConflict(this.state.id, 'KEEP_COPY');
        this.hideSyncConflictModal();
    };

    onEdit = (id) => {
        this.props.editProject(id);
    };

    onRetryGeneration = (id, designId) => {
        this.props.retryGeneration(id, designId);
    };

    onRetrySync = (id) => {
        this.props.retrySync(id);
    };

    onConfirmSend = (email) => {
        this.props.sendProject(this.state.id, email);
        this.hideSendModal();
    };

    hideSendModal = () => {
        this.setState(state => {
            return { ...state, showSendModal: false, id: "" };
        });
    };

    showSendModal = (id) => {
        this.setState((state) => {
            return { ...state, showSendModal: true, id };
        });
    };

    render() {
        const colClasses = classNames(css.noPadding, "container-par parsys");
        const projectNames = this.props.projects.map(p => p.name);
        const [doneProjects, projectsInProgress] = partition(this.props.projects, p => {
            return p.status === ProjectStatus.DESIGN_FILE_DOWNLOAD;
        });
        return (
            <DocumentTitle title="My Projects">
                <div className={css.projects}>
                    <SignedInCheck isSignedIn={this.props.isSignedIn || this.props.isOffline}>
                        {(this.props.error) ? <Error>{this.props.error.message}</Error> : null}
                        <Row className="content-padding">
                            <Col xs={12} className={colClasses}>
                                <h2>{getText(contentKeys.PROJECTS_IN_PROGRESS)}</h2>
                            </Col>
                        </Row>
                        <Row className="content-padding">
                            <Col xs={12} className={colClasses}>
                                <ProjectsTable
                                    projects={projectsInProgress}
                                    loading={this.props.projectsLoading}
                                    isOffline={this.props.isOffline}
                                    onDelete={this.showConfirmDeleteModal}
                                    onDeleteLocal={partialRight(this.showConfirmDeleteModal, true)}
                                    onCopy={this.showCopyModal}
                                    onEdit={this.onEdit}
                                    onRetryGeneration={this.onRetryGeneration}
                                    onRetrySync={this.onRetrySync}
                                    onResolveConflict={this.showSyncConflictModal}
                                    onSend={this.showSendModal}
                                    emptyTableMsg={getText(contentKeys.THERE_ARE_NO_PROJECTS)}
                                />
                            </Col>
                        </Row>
                        <Row className="content-padding">
                            <Col xs={12} className={colClasses}>
                                <h2>{getText(contentKeys.PROJECTS_COMPLETED)}</h2>
                            </Col>
                        </Row>
                        <Row className={classNames("content-padding", css.doneProjectsRow)}>
                            <Col xs={12} className={colClasses}>
                                <ProjectsTable
                                    projects={doneProjects}
                                    loading={this.props.projectsLoading}
                                    onDelete={this.showConfirmDeleteModal}
                                    onCopyAndEdit={this.showCopyAndEditModal}
                                    onSend={this.showSendModal}
                                    onEdit={this.onEdit}
                                    emptyTableMsg={getText(contentKeys.THERE_ARE_NO_COMPLETED)}
                                />
                            </Col>
                        </Row>
                        <Phone>
                            <div className="layout horizontal justify-center">
                                <Link id="Header_GoBack" to={'/'}>
                                    <ActionButton
                                        label={getText(contentKeys.NEW_PROJECT)}
                                        className="large"
                                        onClick={this.onContinue}
                                    />
                                </Link>
                            </div>
                        </Phone>
                        {<CopyProjectModal open={this.state.showCopyModal}
                            name={this.state.name}
                            names={projectNames}
                            onOK={this.onConfirmCopyWithName}
                            onClose={this.hideCopyModal}
                            loading={this.state.loading} />}
                        {<CopyProjectModal open={this.state.showCopyAndEditModal}
                            name={this.state.name}
                            names={projectNames}
                            loading={this.state.loading}
                            onOK={this.onConfirmCopyAndEdit}
                            onClose={this.hideCopyAndEditModal} />}
                        {<ConfirmDeleteModal onConfirm={this.onConfirmDelete}
                            onClose={this.hideConfirmDeleteModal}
                            open={this.state.showConfirmDeleteModal} />}
                        {<SendModal open={this.state.showSendModal}
                            loading={this.state.loading}
                            onOK={this.onConfirmSend}
                            onClose={this.hideSendModal} />}
                        {<SyncConflictModal open={this.state.showSyncConflictModal}
                            onClose={this.hideSyncConflictModal}
                            name={this.state.name}
                            onlineUpdatedAt={this.state.onlineUpdatedAt}
                            offlineUpdatedAt={this.state.offlineUpdatedAt}
                            keepOnline={this.keepOnlineProject}
                            keepOffline={this.keepOfflineProject}
                            copyOffline={this.keepOfflineProjectCopy} />}
                    </SignedInCheck>
                </div>
            </DocumentTitle>
        );
    }
}

export default databind(ProjectsView);
