import PropTypes from 'prop-types';
import React from 'react';
import Kendo from 'kendo-ui-react';
import databind from 'redux/utils/databind';
import classNames from 'classnames';
import css from './styles.less';
import KendoTooltipWrapper from 'kendo-plugins/TooltipWrapper';
import {actions as offlineActions} from 'redux/modules/offline';
import getText from 'util/translations';
import offlineVersionUrl from 'util/offlineVersionUrl';
import contentKeys from 'translations/contentKeys';

/* eslint-disable max-len */
export const onlineIcon = (
    <svg className={css.wifiImg} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Online_Black</title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-2-Copy-2" transform="translate(0.000000, 3.000000)" fill="#353335">
                <path d="M13.3212263,5.89287411 L13.3212263,5.89287411 L11.9810726,7.18584694 C10.9976991,6.13339838 9.57154903,5.47150259 7.98469296,5.47150259 C6.41679759,5.47150259 5.00579074,6.11767543 4.02375223,7.14828884 L2.68217816,5.85773662 C4.00815554,4.49748314 5.89325822,3.64766839 7.98469296,3.64766839 C10.0940783,3.64766839 11.9935746,4.51213355 13.3212263,5.89287411 Z M16,3.30840807 L14.6607628,4.60049666 C12.9893919,2.89119855 10.6166186,1.8238342 7.98469296,1.8238342 C5.3697134,1.8238342 3.01055976,2.877498 1.34099437,4.56755983 L0,3.27756527 C2.01298416,1.25761295 4.84616653,0 7.98469296,0 C11.139163,0 13.9851788,1.27042265 16,3.30840807 Z M8,10.5 C7.17157288,10.5 6.5,9.82842712 6.5,9 C6.5,8.17157288 7.17157288,7.5 8,7.5 C8.82842712,7.5 9.5,8.17157288 9.5,9 C9.5,9.82842712 8.82842712,10.5 8,10.5 Z" id="Online"></path>
            </g>
        </g>
    </svg>
);

export const offlineIcon = (
    <svg className={css.wifiImg} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Offline_Black</title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-Copy-2" transform="translate(0.000000, 1.000000)" fill="#353335">
                <path d="M10.2552852,7.96024597 L10.375271,6.04047207 C11.5122624,6.42127926 12.5206967,7.06611809 13.3212263,7.89865847 L13.3212263,7.89865847 L11.9810726,9.1916313 C11.5005603,8.6773664 10.9143281,8.25635212 10.2552852,7.96024597 Z M5.74388513,7.94697094 C5.0888839,8.23656088 4.50484346,8.6491856 4.02375223,9.1540732 L2.68217816,7.86352098 C3.48426267,7.04070284 4.49093376,6.40465665 5.62410561,6.03049853 L5.74388513,7.94697094 Z M10.4925626,4.16380788 L10.6086126,2.30700725 C12.7022378,2.79704504 14.5626314,3.86029852 16,5.31419243 L14.6607628,6.60628102 C13.5415958,5.46171761 12.1079356,4.60498304 10.4925626,4.16380788 Z M5.50692696,4.15564008 C3.89481584,4.59016517 2.46236249,5.43820958 1.34099437,6.57334419 L0,5.28334963 C1.44005794,3.83830677 3.29987297,2.78341028 5.39094869,2.29998779 L5.50692696,4.15564008 Z M8,12.5 C7.17157288,12.5 6.5,11.8284271 6.5,11 C6.5,10.1715729 7.17157288,9.5 8,9.5 C8.82842712,9.5 9.5,10.1715729 9.5,11 C9.5,11.8284271 8.82842712,12.5 8,12.5 Z M6.5,0 L9.5,0 L9,8 L7,8 L6.5,0 Z" id="Offline"></path>
            </g>
        </g>
    </svg>
);
/* eslint-enable max-len */

class OfflineStatus extends React.Component {
    static propTypes = {
        isOffline: PropTypes.bool.isRequired,
        preparingOffline: PropTypes.bool.isRequired,
        offlineReady: PropTypes.bool.isRequired,
        error: PropTypes.object,

        toggleOfflineMode: PropTypes.func.isRequired,
        refreshData: PropTypes.func.isRequired
    };

    static actions = {
        toggleOfflineMode: offlineActions.toggleServiceWorkerOffline,
        refreshData: offlineActions.refreshOfflineData
    };

    static databind ({offline}) {
        const {isOffline, preparingOffline, offlineReady, error} = offline;

        return {
            isOffline,
            preparingOffline,
            offlineReady,
            error
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            showPopup: false
        };
    }

    componentDidUpdate() {
        if (this.state.showPopup) {
            KendoTooltipWrapper($(this.refs.infoIcon),
                getText(contentKeys.REFRESH_THE_POWERCOMPASS_WEB),
                null, 'right');
        }
    }

    goOffline = () => {
        this.props.toggleOfflineMode();
    };

    goOnline = () => {
        if (this.props.offlineReady) {
            this.props.toggleOfflineMode(false);
        }
    };

    refreshData = () => {
        if (this.props.offlineReady && !this.props.isOffline && !this.props.preparingOffline) {
            this.props.refreshData();
        }
    };

    popupContents = () => {
        const {preparingOffline, offlineReady, isOffline, error} = this.props;
        const enableSwitch = (offlineReady || isOffline) && !error;
        const enableRefresh = !isOffline && !error;
        let footerText, switchText, switchFn;

        if (offlineReady) {
            footerText = getText(contentKeys.READY_FOR_OFFLINE);
        }

        if (preparingOffline) {
            footerText = getText(contentKeys.PREPARING_FOR_OFFLINE);
        }

        if (isOffline) {
            switchText = getText(contentKeys.SWITCH_TO_ONLINE_MODE);
            switchFn = this.goOnline;
            footerText = getText(contentKeys.YOU_ARE_IN_OFFLINE);
        }
        else {
            switchText = getText(contentKeys.SWITCH_TO_OFFLINE_MODE);
            switchFn = this.goOffline;
        }

        if (error) {
            footerText = 'An error occurred, please refresh';
        }

        return (
            <div className={classNames(css.offlinePopup, { [css.offline]: isOffline })}>
                <div onClick={switchFn} className={classNames(css.row, css.switch, { [css.disabled]: !enableSwitch })}>
                    {isOffline ? onlineIcon : offlineIcon}
                    {switchText}
                </div>
                <div onClick={this.refreshData}
                    className={classNames(css.row, css.refresh, { [css.disabled]: !enableRefresh })}>
                    <i className={classNames('fa', 'fa-refresh', { 'fa-spin': preparingOffline })} />
                    {getText(contentKeys.REFRESH_OFFLINE_DATA)} <i ref="infoIcon" className="fa fa-info-circle" />
                </div>
                <div className={classNames(css.row, css.help)}>
                    <a target="_blank" href={offlineVersionUrl()}>
                        <i className="fa fa-info-circle" />{getText(contentKeys.WHATS_OFFLINE_MODE)}
                    </a>
                </div>
                <div className={classNames(css.row, css.footer)}>
                    {footerText}
                </div>
            </div>
        );
    };

    hidePopup = () => {
        this.setState({ showPopup: false });
    };

    showPopup = () => {
        this.setState({ showPopup: true });
    };

    indicator = () => {
        const {isOffline, preparingOffline} = this.props;
        const indicatorClass = classNames(css.indicator, { [css.offline]: isOffline });
        const connectionIcon = isOffline ? offlineIcon : onlineIcon;

        return (
            <div onClick={this.showPopup} className={indicatorClass}>
                {preparingOffline ? <i className="fa fa-spin fa-refresh" /> : connectionIcon}
                {isOffline ? getText(contentKeys.OFFLINE) : getText(contentKeys.ONLINE)}
            </div>
        );
    };

    render() {
        const options = {
            anchor: this.refs.target,
            origin: "bottom middle",
            position: "top left",
            collision: "flip fit",
            close: this.hidePopup
        };

        return (
            <div className={classNames('new-feature-offline', css.offlineStatus)}>
                {this.indicator()}
                <div ref="target" className={css.spacer} />
                {this.state.showPopup && <Kendo.Popup
                    open={this.state.showPopup}
                    options={options}>
                    {this.popupContents()}
                </Kendo.Popup>}
            </div>
        );
    }
}

export default databind(OfflineStatus);
