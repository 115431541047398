import PropTypes from 'prop-types';
import React from 'react';
import {Row} from 'bootstrap-core.js';
import databind from 'redux/utils/databind';
import LoadingSpinner from 'components/LoadingSpinner';
import Error from 'components/Error';
import {actions as partsDbActions} from 'redux/modules/partsDb.js';
import {actions as efficiencyDbActions} from 'redux/modules/efficiencyDb.js';

// This component wraps the content to be rendered when the DB data is successfully loaded
// for the sole purpose of firing the onDataLoaded only when the content has mounted
class SuccessWrapper extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        onMount: PropTypes.func,
    }

    componentDidMount() {
        const {onMount} = this.props;
        if (typeof onMount === 'function') {
            onMount();
        }
    }

    render() {
        return (<div>{this.props.children}</div>);
    }
}

// Wraps content that can not render until the global parts and efficiency data is available
// and renders either a loading spinner if the data request is in progress or an error if it failed to load
const WaitForDbDataLoaded = ({partsDb, efficiencyDb, onDataLoaded, children, fetchPartsDb, fetchEfficiencyDb}) => {
    const dataLoadSuccessful = partsDb.received && !partsDb.error && efficiencyDb.received && !efficiencyDb.error;
    const dataLoading = partsDb.requested || efficiencyDb.requested;

    const onClickTryAgain = (e) => {
        e.preventDefault();
        fetchPartsDb();
        fetchEfficiencyDb();
    };

    if (dataLoadSuccessful) {
        return (
            <SuccessWrapper onMount={onDataLoaded} children={children} />
        );
    }

    if (dataLoading) return (<LoadingSpinner />);

    return (
        <Row>
            <Error>Solutions can't be loaded. Please check your internet connection and&nbsp;
                <a onClick={onClickTryAgain}>try again</a>.
            </Error>
        </Row>
    );
};

WaitForDbDataLoaded.propTypes = {
    children: PropTypes.node,
    onDataLoaded: PropTypes.func, // Any work that needs to be kicked off once the data is available
    efficiencyDb: PropTypes.object,
    partsDb: PropTypes.object,
    fetchPartsDb: PropTypes.func,
    fetchEfficiencyDb: PropTypes.func
};

WaitForDbDataLoaded.actions = {
    fetchPartsDb: partsDbActions.fetchData,
    fetchEfficiencyDb: efficiencyDbActions.fetchData
};

WaitForDbDataLoaded.databind = ({efficiencyDb, partsDb}) => {
    return {
        efficiencyDb: efficiencyDb,
        partsDb: partsDb
    };
};

export default databind(WaitForDbDataLoaded);
