import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'bootstrap-core.js';
import modalCss from 'components/styles.less';
import classNames from 'classnames';
import SkipPref from './SkipPref';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

function skipModalCheckbox(toggleSkip, skipChecked) {
    const prefChecked = skipChecked && 'checked';
    return (
        <div className={modalCss.prefCheckbox} onClick={toggleSkip} >
            <input type="checkbox"
                name="prefCheckbox"
                className="checkbox k-checkbox"
                checked={prefChecked} />
            <label htmlFor="prefCheckbox" className="k-checkbox-label" />
            <span className={modalCss.checkboxLabel}>{getText(contentKeys.DONT_ASK_ME_AGAIN)}</span>
        </div>
    );
};

export default function ModalContent(props) {
    const { children, skipModalPrefKey, onCancel, cancelLabel,
        onSubmit, canSubmit, submitLabel, loading } = props;

    return (<div>
        {children}
        <SkipPref skipModalPrefKey={skipModalPrefKey}>{
            ({ updateSkip, toggleSkip, skipChecked }) => (
                <div className={modalCss.modalButtons}>
                    {skipModalPrefKey && skipModalCheckbox(toggleSkip, skipChecked)}
                    <div className={modalCss.buttonContainer}>
                        {(onCancel) ? <Button className={classNames(modalCss.cancel, 'basic')} disabled={loading}
                            onClick={onCancel}>{cancelLabel || getText(contentKeys.CANCEL)}</Button> : null}
                        {(onSubmit) ? <Button className={modalCss.submit} disabled={!canSubmit || loading}
                            onClick={() => {
                                onSubmit();
                                skipModalPrefKey && updateSkip();
                            }}>
                            {submitLabel || "OK"} {loading && <i className="fa fa-spinner fa-spin" />}
                        </Button> : null}
                    </div>
                </div>
            )}
        </SkipPref>
    </div>);
}

ModalContent.propTypes = {
    children: PropTypes.node.isRequired,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    cancelLabel: PropTypes.string,
    submitLabel: PropTypes.string,
    canSubmit: PropTypes.bool,
    loading: PropTypes.bool,
    skipModalPrefKey: PropTypes.string
};

ModalContent.defaultProps = {
    canSubmit: true
};
