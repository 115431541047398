import has from 'lodash/has';
import flatten from 'lodash/flatten';
import reject from 'lodash/reject';
import {NUM_SAMPLES_PER_CURVE} from 'logic/constants';
import xAxisSmallTemplate from './xAxisSmall.template';
import xAxisBigTemplate from './xAxisBig.template';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const xAxisLabelRotation = 60;

class ChartPool {
    constructor(createChartFn) {
        this.pool = {};
        this.createChartFn = createChartFn;
    }

    createOrAttachCharts(outputId, $detailRow, dataForThisOutput, partNames) {
        if (!this._arePooledFor(outputId)) {
            this.pool[outputId] = this._createCharts($detailRow, dataForThisOutput, partNames);
        }

        const charts = this.pool[outputId];
        $detailRow.find('.efficiencyChartContainer').append(charts.efficiency);
        $detailRow.find('.powerDissipationChartContainer').append(charts.powerDissipation);
        $detailRow.find('.junctionTempChartContainer').append(charts.junctionTemp);
        charts.junctionTemp.closest(".detailSection").css("border", "none");
    }

    detach(outputId) {
        const charts = this.pool[outputId];
        if (charts) {
            charts.efficiency.detach();
            charts.powerDissipation.detach();
            charts.junctionTemp.detach();
        }
    }

    _arePooledFor(outputId) {
        return has(this.pool, outputId);
    }

    _createCharts($detailRow, dataForThisOutput, partNames) {
        // set the efficiency chart axis dynamically
        let efficiencyDataSeries = this._buildChartSeriesData(dataForThisOutput, 'efficiency', partNames);
        // treat zeros beyond the first data point as points of missing data and swap these out for nulls to
        // make the efficiency curve visibly truncate at the end of real data
        efficiencyDataSeries.series = efficiencyDataSeries.series.map((series) => {
            return { ...series, data: series.data.map((y, idx) => ((y === 0) && (idx !== 0)) ? null : y) };
        });
        const flatData = flatten(efficiencyDataSeries.series.map((x) => x.data));
        let yAxisMin = flatData.reduce((minSoFar, value) => Math.min(minSoFar, value), 1);
        // nudge down to nearest 10th or 0.4 whichever is smaller
        yAxisMin = Math.min(Math.floor(10*yAxisMin)/10, 0.4);

        let xAxisTemplate = xAxisSmallTemplate;
        if (efficiencyDataSeries.x != null) {
            const maxXAxisValue = efficiencyDataSeries.x[efficiencyDataSeries.x.length-1];
            if (maxXAxisValue > 2*NUM_SAMPLES_PER_CURVE) {
                xAxisTemplate = xAxisBigTemplate;
            }
        }

        const chartParams = {yAxisMin, xAxisTemplate, dataForThisOutput, partNames, efficiencyDataSeries};

        // charts are rendered with all parts data and do not change across part selections
        return {
            efficiency: this._crateEfficiencyChart(chartParams),
            powerDissipation: this._cratePowerDissipationChart(chartParams),
            junctionTemp: this._createJunctionTempChart(chartParams),
            xAxisTemplate
        };
    }

    _crateEfficiencyChart({yAxisMin, xAxisTemplate, dataForThisOutput, partNames, efficiencyDataSeries}) {
        return this.createChartFn({},
            efficiencyDataSeries,
            {
                title: getText(contentKeys.EFFICIENCY_SUMMARY),
                yAxisText: getText(contentKeys.EFFICIENCY),
                yAxisFormat: "{0:p0}",
                yAxisMin,
                yAxisMax: 1,
                xAxisTemplate,
                xAxisRotation: xAxisLabelRotation,
                tooltipFormat: "{0:p0}"
            }
        );
    }

    _cratePowerDissipationChart({xAxisTemplate, dataForThisOutput, partNames}) {
        return this.createChartFn({},
            this._buildChartSeriesData(dataForThisOutput, 'powerDissipation', partNames),
            {
                title: getText(contentKeys.POWER_DISSIPATION_VS_LOAD),
                yAxisText: getText(contentKeys.POWER_DISSIPATION_W),
                yAxisFormat: "{0}",
                xAxisTemplate,
                xAxisRotation: xAxisLabelRotation,
                tooltipFormat: "{0:n3} (W)"
            });
    }

    _createJunctionTempChart({xAxisTemplate, dataForThisOutput, partNames}) {
        return this.createChartFn({},
            this._buildChartSeriesData(dataForThisOutput, 'junctionTemp', partNames),
            {
                title: getText(contentKeys.DEVICE_JUNCTION_TEMPERATURE),
                yAxisText: getText(contentKeys.JUNCTION_TEMPERATURE_C),
                yAxisFormat: "{0}",
                xAxisTemplate,
                xAxisRotation: xAxisLabelRotation,
                tooltipFormat: "{0:n1} (C)"
            });
    }

    updateJunctionTempChart(outputId, $detailRow, dataForThisOutput, partNames) {
        const chartsForOutput = this.pool[outputId];
        chartsForOutput.junctionTemp = this._createJunctionTempChart({
            xAxisTemplate: chartsForOutput.xAxisTemplate,
            dataForThisOutput,
            partNames,
            chartsForOutput
        });
        $detailRow.find('.junctionTempChartContainer')
            .empty()
            .append(chartsForOutput.junctionTemp);
        chartsForOutput.junctionTemp.closest(".detailSection").css("border", "none");
    }

    _buildChartSeriesData(data, fieldName, partNames) {
        let chartSeries = {};
        chartSeries.series = data.parts.map((part, idx) => {
            if (part.referenceCurves[fieldName] === null) return null;
            const series = {
                name: partNames[idx],
                data: part.referenceCurves[fieldName].y,
                markers: { background: function(e) { return e.series.color; } }
            };
            if (!chartSeries.x) chartSeries.x = part.referenceCurves[fieldName].x;
            return series;
        });
        chartSeries.series = reject(chartSeries.series, (item) => item === null);
        return chartSeries;
    }
};

export default ChartPool;
