import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import css from './styles.less';
import classNames from 'classnames/dedupe';

class PageSlider extends React.Component {
    static propTypes = {
        content: PropTypes.node.isRequired,
        showAuxPage: PropTypes.bool.isRequired,
        mainPage: PropTypes.string,
        sliderRoot: PropTypes.string
    }

    static defaultProps = {
        mainPage: 'body > .container',
        sliderRoot: '#pageSlider',
        showAuxPage: false
    }

    constructor(props) {
        super(props);
        const divElement = document.createElement('div');
        divElement.id = 'pageSlider';
        if (!document.getElementById("pageSlider")) {
            document.querySelector(this.props.mainPage).parentNode.insertBefore(divElement, null);
        }
        this.el = document.createElement('div');
        this.el.className = css.root;
    }

    appendElement() {
        const sliderRootEl = document.querySelector(this.props.sliderRoot);
        sliderRootEl.appendChild(this.el);
    }

    updateShowAuxPage() {
        const mainPageEl = document.querySelector(this.props.mainPage);
        if (mainPageEl) {
            mainPageEl.className = classNames(
                'mainPage',
                mainPageEl.className,
                {'showAuxPage': this.props.showAuxPage}
            );
        }
    }

    componentDidMount() {
        if (this.props.sliderRoot) {
            this.appendElement();
        }
        this.el.className = classNames(css.root, {[css.show]: this.props.showAuxPage});
        this.updateShowAuxPage();
        document.body.style.overflow = this.props.showAuxPage ? 'hidden' : 'auto';
    }

    componentDidUpdate(prevProps) {
        if (this.props.sliderRoot && this.props.sliderRoot !== prevProps.sliderRoot) {
            this.appendElement();
        }

        this.el.className = classNames(css.root, {[css.show]: this.props.showAuxPage});

        if (this.props.mainPage !== prevProps.mainPage || this.props.showAuxPage !== prevProps.showAuxPage) {
            this.updateShowAuxPage();
        }

        document.body.style.overflow = this.props.showAuxPage ? 'hidden' : 'auto';
    }

    componentWillUnmount() {
        const sliderRootEl = document.querySelector(this.props.sliderRoot);
        sliderRootEl && sliderRootEl.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(
            this.props.content,
            this.el,
        );
    }
};

export default PageSlider;
