var _ = require('lodash');
import KendoWidgetWrapper from './KendoWidgetWrapper';

var kendo = global.kendo;
if (!kendo || !kendo.ui) {
  throw new Error('kendo.ui not found');
}

import 'kendo-plugins/MultiSelectDropDownList';

function kendoWidgetName(prefix, name) {
  return 'kendo' + prefix + name;
}

function buildComponent (widget, name, prefix) {
  return KendoWidgetWrapper(kendoWidgetName(prefix, name));
}

var KendoUi = _.mapValues(kendo.ui, function (widget, name) {
  return buildComponent(widget, name, '');
});

var KendoDataViz = _.mapValues(kendo.dataviz && kendo.dataviz.ui, function (widget, name) {
  return buildComponent(widget, name, '');
});

var KendoMobileUi = _.mapValues(kendo.mobile && kendo.mobile.ui, function (widget, name) {
  return buildComponent(widget, name, 'Mobile');
});

module.exports = _.extend({
    Template: require('./KendoTemplate'),
    mobile: KendoMobileUi,
    dataviz: KendoDataViz
  },
  KendoUi
);
