import PropTypes from 'prop-types';
import React from 'react';
import {Row, Col} from 'bootstrap-core';
import css from './styles.less';
import classNames from 'classnames';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const PartialDataWarning = function(props) {
    const warning = (<div className={css.warningBanner}>
        <Row>
            <Col className={classNames(css.noPadding, css.warning, 'layout horizontal')} xs={12}>
                <span className="fa fa-warning"></span>
                <span>{getText(contentKeys.ONE_OR_MORE_SELECTED)}</span>
            </Col>
        </Row>
    </div>);

    return (props.show) ? warning : null;
};

PartialDataWarning.propTypes = {
    show: PropTypes.bool.isRequired
};

export default PartialDataWarning;
