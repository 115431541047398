/*
* replaces the route section of the url after the # and preserves the query
* */
export default absoluteAppRoute => {
    const basePathAndQuery = location.href.split('#');
    const base = basePathAndQuery[0];
    const pathAndQuery = basePathAndQuery[1].split('?');
    const queryParams = '?' + pathAndQuery[1] || '';
    const appRouteNoSlash = absoluteAppRoute[0] === '/' ? absoluteAppRoute.slice(1) : absoluteAppRoute;

    return window.encodeURIComponent(`${base}#/${appRouteNoSlash}${queryParams}`);
};
