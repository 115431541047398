/* global $ */
import {makeAction, createReducer} from '../utils/redux-helpers';
import {v1 as uuid} from 'uuid';
import {
    powerRequiredForEfficiency,
    availableOutputPower,
    availableInputRailPower,
    optimizeOutputs,
    deleteInputRailAndItsConnections,
    disconnectOutputs,
    checkInvalidSupplySequence,
    deDupeName,
    updateOrder} from 'logic/editor';
import getHistory from 'util/history';
import {actions as suggestedPartActions} from 'redux/modules/suggestedParts';
import {actions as headerActions} from 'redux/modules/header';
import {actions as projectActions} from 'redux/modules/projects';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

function prefix (type) { return `editor/${type}`; }

export const actionTypes = {
    ADD_INPUT_RAIL: prefix('ADD_INPUT_RAIL'),
    ADD_SYSTEM_OUTPUT: prefix('ADD_SYSTEM_OUTPUT'),
    DELETE_INPUT_RAIL: prefix('DELETE_INPUT_RAIL'),
    DELETE_SYSTEM_OUTPUT: prefix('DELETE_SYSTEM_OUTPUT'),
    DELETE_RAIL_AND_CONNECTIONS: prefix('DELETE_RAIL_AND_CONNECTIONS'),
    CHANGE_INPUT_RAIL: prefix('CHANGE_INPUT_RAIL'),
    CHANGE_INPUT_RAIL_NAME: prefix('CHANGE_INPUT_RAIL_NAME'),
    CHANGE_INPUTS_RAIL_ORDER: prefix('CHANGE_INPUTS_RAIL_ORDER'),
    CHANGE_OUTPUTS_ORDER: prefix('CHANGE_OUTPUTS_ORDER'),
    CHANGE_OUTPUT_SOURCE_RAIL: prefix('CHANGE_OUTPUT_SOURCE_RAIL'),
    CHANGE_OUTPUT_NAME: prefix('CHANGE_OUTPUT_NAME'),
    CHANGE_OUTPUT_VOLTSAMPS: prefix('CHANGE_OUTPUT_VOLTSAMPS'),
    CHANGE_OUTPUT_SUPPLY_SEQUENCE: prefix('CHANGE_OUTPUT_SUPPLY_SEQUENCE'),
    CHANGE_MIN_EFFICIENCY: prefix('CHANGE_MIN_EFFICIENCY'),
    OPTIMIZE_OUTPUTS: prefix('OPTIMIZE_OUTPUTS'),
    SET_OPTIMIZED: prefix('SET_OPTIMIZED'),
    UNDO_OPTIMIZATION: prefix('UNDO_OPTIMIZATION'),
    POWER_GOOD_OUTPUT: prefix('POWER_GOOD_OUTPUT'),
    ENABLE_PIN_INPUT: prefix('ENABLE_PIN_INPUT'),
    SET_SELECTED_BLOCK: prefix('SET_SELECTED_BLOCK'),
    SYNC_INPUT: prefix('SYNC_INPUT'),
    SYNC_OUTPUT: prefix('SYNC_OUTPUT'),
    TOGGLE_DIAGRAM: prefix('TOGGLE_DIAGRAM'),
    LDO: prefix("LDO"),
    RESET: prefix("RESET"),
    OVERRIDE_SUPPLY_SEQUENCE_MAX: prefix('OVERRIDE_SUPPLY_SEQUENCE_MAX'),
    SET_TEMPLATE_WARNING: prefix('SET_TEMPLATE_WARNING')
};

const addId = (data) => { return {...data, id: uuid()}; };

export const actions = {
    addInputRail: (data) => makeAction(actionTypes.ADD_INPUT_RAIL, addId(data)),
    addSystemOutput: (data) => makeAction(actionTypes.ADD_SYSTEM_OUTPUT, addId(data)),
    deleteInputRail: (data) => makeAction(actionTypes.DELETE_INPUT_RAIL, data),
    deleteSystemOutput: (data) => makeAction(actionTypes.DELETE_SYSTEM_OUTPUT, data),
    deleteRailAndConnections: (data) => makeAction(actionTypes.DELETE_RAIL_AND_CONNECTIONS, data),
    changeInputRail: (data) => makeAction(actionTypes.CHANGE_INPUT_RAIL, data),
    changeInputRailName: (data) => makeAction(actionTypes.CHANGE_INPUT_RAIL_NAME, data),
    changeInputsRailOrder: (data) => makeAction(actionTypes.CHANGE_INPUTS_RAIL_ORDER, data),
    changeOutputsOrder: (data) => makeAction(actionTypes.CHANGE_OUTPUTS_ORDER, data),
    changeSourceRail: (data) => makeAction(actionTypes.CHANGE_OUTPUT_SOURCE_RAIL, data),
    changeOutputName: (data) => makeAction(actionTypes.CHANGE_OUTPUT_NAME, data),
    changeOutputVoltsAmps: (data) => makeAction(actionTypes.CHANGE_OUTPUT_VOLTSAMPS, data),
    changeSupplySequence: (data) => makeAction(actionTypes.CHANGE_OUTPUT_SUPPLY_SEQUENCE, data),
    changeMinEfficiency: (data) => makeAction(actionTypes.CHANGE_MIN_EFFICIENCY, data),
    optimizedOutputs: (data) => makeAction(actionTypes.OPTIMIZE_OUTPUTS, data),
    undoOptimization: (data) => makeAction(actionTypes.UNDO_OPTIMIZATION, data),
    powerGoodOutput: (data) => makeAction(actionTypes.POWER_GOOD_OUTPUT, data),
    enablePinInput: (data) => makeAction(actionTypes.ENABLE_PIN_INPUT, data),
    setSelectedBlock: (data) => makeAction(actionTypes.SET_SELECTED_BLOCK, data),
    setOptimized: (data) => makeAction(actionTypes.SET_OPTIMIZED, data),
    syncInput: (data) => makeAction(actionTypes.SYNC_INPUT, data),
    syncOutput: (data) => makeAction(actionTypes.SYNC_OUTPUT, data),
    toggleDiagram: (data) => makeAction(actionTypes.TOGGLE_DIAGRAM, data),
    ldo: (data) => makeAction(actionTypes.LDO, data),
    reset: () => makeAction(actionTypes.RESET),
    setTemplateWarning: (data) => makeAction(actionTypes.SET_TEMPLATE_WARNING, data),
    createScratchDesign: () => {
        return (dispatch) => {
            dispatch(headerActions.setHeaderText('NEW PROJECT'));
            dispatch(projectActions.setCurrentProjectName(""));

            // Input.
            dispatch(actions.addInputRail({id: uuid(), name: "Rail 1", vmin: 5, vmax: 5, imax: 10}));
            dispatch(actions.addInputRail({id: uuid(), name: "Rail 2", vmin: 12, vmax: 12, imax: 10}));

            // Output.
            dispatch(actions.addSystemOutput({id: uuid(), name: getText(contentKeys.OUTPUT)+" 1"}));
            dispatch(actions.addSystemOutput({id: uuid(), name: getText(contentKeys.OUTPUT)+" 2"}));
            dispatch(actions.addSystemOutput({id: uuid(), name: getText(contentKeys.OUTPUT)+" 3"}));
            dispatch(actions.addSystemOutput({id: uuid(), name: getText(contentKeys.OUTPUT)+" 4"}));
            dispatch(actions.addSystemOutput({id: uuid(), name: getText(contentKeys.OUTPUT)+" 5"}));
        };
    },
    findSolutions: () => {
        return async (dispatch, getState) => {
            dispatch(suggestedPartActions.clearSearchResults());
            const state = getState();
            await dispatch(suggestedPartActions.fetchResults({
                editor: state.editor, activeColors: state.colorPalette.activeColors
            }));
            getHistory().push('/editor/solutions');
            const findSolutionsContainer = $("#findSolutionsContainer");
            const scrollTo = findSolutionsContainer.offset().top + findSolutionsContainer.height();
            $('html, body').animate({scrollTop: scrollTo}, 200);

            dispatch(projectActions.trySavingProject(true));
        };
    },
    createDesignFromTemplate: (design) => (dispatch, getState) => {
        let {efficiency, designName, inputs, outputs} = design;
        dispatch(headerActions.setHeaderText(designName));
        dispatch(projectActions.setCurrentProjectName(designName));
        dispatch(actions.changeMinEfficiency({ efficiency }));

        if (design.templateWarning) {
            dispatch(actions.setTemplateWarning(design.templateWarning));
        }

        // add system inputs
        inputs.forEach((input) => {
            dispatch(actions.addInputRail(input));
        });

        // because the supply sequence dropdown dynamically changes it's range this can cause template loading problems
        // so we open it up prior to adding
        dispatch(actions.overrideSupplySequenceMax(999));

        // add system outputs
        let highestOnSeq = 1;
        outputs.forEach((output) => {
            let {onSeq, offSeq, sync, ...validOutputFields} = output; // eslint-disable-line no-unused-vars
            validOutputFields.syncInput = sync;
            validOutputFields.supplySeq = onSeq;
            if (onSeq > highestOnSeq) {
                highestOnSeq = onSeq;
            }
            dispatch(actions.addSystemOutput(validOutputFields));
        });

        // setup supply sequences - we need to set these up in the sequence
        dispatch(actions.overrideSupplySequenceMax(highestOnSeq + 1));

        // Now that ids are attached, get inputs and outputs from state and
        // reconnect the source rails based on name
        inputs = getState().editor.inputs;
        outputs = getState().editor.outputs;

        function findSourceRailId(output, searchSet) {
            return searchSet.reduce((match, x) => {
                if (x.name === output.sourceRail) {
                    return x.id;
                }
                else {
                    return match;
                }
            }, "");
        }

        outputs.forEach((output, index) => {
            let sourceRailId = findSourceRailId(output, inputs);
            if (sourceRailId === "") {
                sourceRailId = findSourceRailId(output, outputs);
            }
            dispatch(actions.changeSourceRail({ index, value: sourceRailId }));
        });

        // optimization is always the last step
        if (design.optimize) {
            dispatch(actions.optimizedOutputs());
        }
        else if (design.optimized) {
            dispatch(actions.setOptimized(true));
        }
    },
    overrideSupplySequenceMax: (maxValue) => makeAction(actionTypes.OVERRIDE_SUPPLY_SEQUENCE_MAX, maxValue)
};

const invalidCheck = (outputs, idx) => {
    // assume invalid just because of a change, and let the checkInvalid call prove it or remove it
    outputs[idx].invalid = true;
    return outputs.map((x, idx, O) => {
        if (x.invalid) {
            x.invalid = (checkInvalidSupplySequence(outputs, idx, x.supplySeq) !== null);
        }
        return x;
    });
};

const ACTION_HANDLERS = {
    [actionTypes.ADD_INPUT_RAIL]: (state, {payload}) => {
        const existingNames = state.inputs.map((x) => x.name);
        const newRail = {
            id: payload.id,
            name: payload.name || deDupeName("Rail " + state.inputRailsHistory, existingNames),
            vmin: payload.vmin || 0,
            vmax: payload.vmax || 0,
            imax: payload.imax || 0,
            power: 0.0,
            invalid: false
        };
        const newRailWithPower = availableInputRailPower(newRail, state.outputs);
        const inputs = [ ...state.inputs, newRailWithPower ];
        const selectedBlock = state.showDiagram ? {selectedBlockId: newRailWithPower.id} : {};
        return {...state, inputs, inputRailsHistory: state.inputRailsHistory+1, ...selectedBlock};
    },
    [actionTypes.ADD_SYSTEM_OUTPUT]: (state, {payload}) => {
        const { efficiency } = state;
        const existingNames = state.outputs.map((x) => x.name);
        const newData = {...payload,
            name: payload.name ||
            deDupeName(getText(contentKeys.OUTPUT) + " " + state.outputRailsHistory, existingNames),
        };
        let newOutput = powerRequiredForEfficiency({...createDefaultOutput(), ...newData}, efficiency);
        newOutput = availableOutputPower(newOutput, state.outputs);
        const outputs = [...state.outputs, newOutput];
        const selectedBlock = state.showDiagram ? {selectedBlockId: newOutput.id} : {};
        return {...state, outputs, optimized: false, outputRailsHistory: state.outputRailsHistory+1, ...selectedBlock};
    },
    [actionTypes.DELETE_INPUT_RAIL]: (state, {payload}) => {
        const idx = payload.index;
        const inputs = [ ...state.inputs.slice(0, idx),
            ...state.inputs.slice(idx+1) ];
        const outputs = disconnectOutputs(state.outputs, state.inputs[idx].id);
        return {...state, inputs, outputs, selectedBlockId: ''};
    },
    [actionTypes.DELETE_SYSTEM_OUTPUT]: (state, {payload}) => {
        const idx = payload.index;
        let outputs = [...state.outputs.slice(0, idx),
            ...state.outputs.slice(idx+1)];
        outputs = disconnectOutputs(outputs, state.outputs[idx].id);
        outputs = outputs.map((x) => availableOutputPower(x, outputs));
        const inputs = state.inputs.map((x) => availableInputRailPower(x, outputs));
        return {...state, outputs, inputs, selectedBlockId: ''};
    },
    [actionTypes.DELETE_RAIL_AND_CONNECTIONS]: (state, {payload}) => {
        return {...deleteInputRailAndItsConnections(state, payload.idx), selectedBlockId: ''};
    },
    [actionTypes.CHANGE_INPUT_RAIL_NAME]: (state, {payload}) => {
        const idx = payload.index;
        const existingNames = state.inputs.map((x) => x.name);
        const changed = { ...state.inputs[idx], name: deDupeName(payload.value, existingNames) };
        const newRail = availableInputRailPower(changed, state.outputs);
        const inputs = [ ...state.inputs.slice(0, idx), newRail, ...state.inputs.slice(idx+1) ];
        return { ...state, inputs };
    },
    [actionTypes.CHANGE_INPUT_RAIL]: (state, {payload}) => {
        const idx = payload.index;
        const changed = { [payload.fieldName]: payload.value };
        const newRail = availableInputRailPower({ ...state.inputs[idx], ...changed }, state.outputs);
        newRail.invalid = (newRail.vmax < newRail.vmin && newRail.imax !==0);
        const inputs = [ ...state.inputs.slice(0, idx), newRail, ...state.inputs.slice(idx+1) ];
        return { ...state, inputs };
    },
    [actionTypes.CHANGE_OUTPUT_SOURCE_RAIL]: (state, {payload}) => {
        const idx = payload.index;
        const changed = { ...state.outputs[idx], sourceRail: payload.value };
        let outputs = [...state.outputs.slice(0, idx), changed, ...state.outputs.slice(idx+1)];
        outputs = invalidCheck(outputs, idx);
        outputs = outputs.map((x) => availableOutputPower(x, outputs));
        const inputs = state.inputs.map((x) => availableInputRailPower(x, outputs));
        return {...state, inputs, outputs, optimized: false};
    },
    [actionTypes.CHANGE_OUTPUT_NAME]: (state, {payload}) => {
        const idx = payload.index;
        const existingNames = state.outputs.map((x) => x.name);
        const changed = { ...state.outputs[idx], name: deDupeName(payload.value, existingNames) };
        const outputs = [...state.outputs.slice(0, idx), changed, ...state.outputs.slice(idx+1)];
        return { ...state, outputs };
    },
    [actionTypes.CHANGE_OUTPUT_VOLTSAMPS]: (state, {payload}) => {
        const idx = payload.index;
        const changed = { [payload.fieldName]: payload.value };
        let newOutput = { ...state.outputs[idx], ...changed };
        newOutput = powerRequiredForEfficiency(newOutput, state.efficiency);
        let outputs = [ ...state.outputs.slice(0, idx), newOutput, ...state.outputs.slice(idx+1) ];
        outputs = outputs.map((x) => availableOutputPower(x, outputs));
        const inputs = state.inputs.map((x) => availableInputRailPower(x, outputs));
        return {...state, inputs, outputs, optimized: false};
    },
    [actionTypes.CHANGE_OUTPUT_SUPPLY_SEQUENCE]: (state, {payload}) => {
        const idx = payload.index;
        const changed = { ...state.outputs[idx], supplySeq: payload.value, invalid: payload.invalid };
        let outputs = [ ...state.outputs.slice(0, idx), changed, ...state.outputs.slice(idx+1) ];
        outputs = invalidCheck(outputs, idx);
        const newMax = outputs.reduce((a, x) => { return (x.supplySeq > a) ? x.supplySeq : a; }, payload.value);
        return {...state, outputs, optimized: false, supplySequenceMax: newMax+1};
    },
    [actionTypes.CHANGE_MIN_EFFICIENCY]: (state, {payload}) => {
        let outputs = state.outputs.map((x) => powerRequiredForEfficiency(x, payload.efficiency));
        outputs = outputs.map((x) => availableOutputPower(x, outputs));
        const inputs = state.inputs.map((x) => availableInputRailPower(x, outputs));
        return {...state, inputs, outputs, efficiency: payload.efficiency};
    },
    [actionTypes.SET_OPTIMIZED]: (state, {payload}) => {
        return {
            ...state,
            optimized: payload
        };
    },
    [actionTypes.UNDO_OPTIMIZATION]: ({ preOptimized, ...state }) => {
        return {
            ...state,
            outputs: preOptimized,
            optimized: false
        };
    },
    [actionTypes.OPTIMIZE_OUTPUTS]: (state) => {
        const efficiency = state.efficiency;
        const optimizedOutputs = optimizeOutputs(state.outputs);
        const outputUpdater = o => availableOutputPower(powerRequiredForEfficiency(o, efficiency), optimizedOutputs);
        return {...state,
            outputs: optimizedOutputs.map(outputUpdater),
            optimized: true,
            preOptimized: state.outputs,
            outputRailsHistory: state.outputs.length};
    },
    [actionTypes.POWER_GOOD_OUTPUT]: (state, {payload}) => {
        const idx = payload.index;
        let updateOption = state.outputs[idx];
        updateOption = {...updateOption, powerGoodOutput: payload.switch};
        const updateOptions = [...state.outputs.slice(0, idx), updateOption, ...state.outputs.slice(idx+1)];
        return {...state, outputs: updateOptions};
    },
    [actionTypes.ENABLE_PIN_INPUT]: (state, {payload}) => {
        const idx = payload.index;
        let updateOption = state.outputs[idx];
        updateOption = {...updateOption, enablePinInput: payload.switch};
        const updateOptions = [...state.outputs.slice(0, idx), updateOption, ...state.outputs.slice(idx+1)];
        return {...state, outputs: updateOptions};
    },
    [actionTypes.SET_SELECTED_BLOCK]: (state, {payload}) => {
        if (!payload) {
            return {...state, selectedBlockId: ''};
        }

        const id = payload.id;

        return {...state, selectedBlockId: id};
    },
    [actionTypes.SYNC_INPUT]: (state, {payload}) => {
        const idx = payload.index;
        let updateOption = state.outputs[idx];
        updateOption = {...updateOption, syncInput: payload.switch};
        const updateOptions = [...state.outputs.slice(0, idx), updateOption, ...state.outputs.slice(idx+1)];
        return {...state, outputs: updateOptions};
    },
    [actionTypes.SYNC_OUTPUT]: (state, {payload}) => {
        const idx = payload.index;
        let updateOption = state.outputs[idx];
        updateOption = {...updateOption, syncOutput: payload.switch};
        const updateOptions = [...state.outputs.slice(0, idx), updateOption, ...state.outputs.slice(idx+1)];
        return {...state, outputs: updateOptions};
    },
    [actionTypes.TOGGLE_DIAGRAM]: (state, {payload}) => {
        return {...state, showDiagram: payload};
    },
    [actionTypes.LDO]: (state, {payload}) => {
        const idx = payload.index;
        let output = state.outputs[idx];
        output = {...output, ldo: payload.switch, syncInput: false, syncOutput: false};
        const outputs = [...state.outputs.slice(0, idx), output, ...state.outputs.slice(idx+1)];
        return {...state, outputs: outputs, optimized: false};
    },
    [actionTypes.RESET]: () => {
        return initialState;
    },
    [actionTypes.OVERRIDE_SUPPLY_SEQUENCE_MAX]: (state, {payload}) => {
        return { ...state, supplySequenceMax: payload };
    },
    [actionTypes.CHANGE_INPUTS_RAIL_ORDER]: (state, {payload}) => {
        return { ...state, inputs: updateOrder(state.inputs, payload) };
    },
    [actionTypes.CHANGE_OUTPUTS_ORDER]: (state, {payload}) => {
        return { ...state, optimized: false, outputs: updateOrder(state.outputs, payload) };
    },
    [actionTypes.SET_TEMPLATE_WARNING]: (state, { payload }) => {
        return { ...state, templateWarning: payload };
    }
};

export const selectors = {
    outputHasMoreOptionsSet: (output) => {
        const { ldo, powerGoodOutput, enablePinInput, syncInput, syncOutput } = output;
        return (ldo || powerGoodOutput || enablePinInput || syncInput || syncOutput);
    }
};

export const createDefaultOutput = function() {
    return {
        id: null,
        sourceRail: "",
        name: "",
        volts: 0,
        amps: 0,
        supplySeq: 1,
        availableOutput: 0,
        powerNeeded: 0,
        ldo: false,
        powerGoodOutput: false,
        enablePinInput: false,
        syncInput: false,
        syncOutput: false,
        invalid: false
    };
};

export const initialState = {
    inputs: [],
    outputs: [],
    efficiency: 0.85,
    optimized: false,
    inputRailsHistory: 1,
    outputRailsHistory: 1,
    supplySequenceMax: 2
};

export default createReducer(initialState, ACTION_HANDLERS);
