import {isError} from 'util/workerMessages';

export default function tryLoadingFromFileViaWorker(worker, message) {
    return new Promise((resolve, reject) => {
        worker.onmessage = function(e) {
            const message = e.data;
            console.log("receiving message type: ", message.type); // eslint-disable-line no-console
            const { payload } = message;
            if (isError(message)) {
                reject(payload);
            }
            else {
                resolve(payload);
            }
        };
        worker.postMessage(message);
    });
}
