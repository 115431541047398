import React from 'react';
import MobileDialog from 'components/MobileDialog';
import classNames from 'classnames';
import LimitPerTopology from 'components/SuggestedParts/LimitPerTopology';
import ColumnSettingsItem from '../ColumnSettingsItem';
import OrderResultsByItem from '../OrderResultsByItem';
import { actions } from 'redux/modules/suggestedParts';
import css from '../../mobilePanelStyles.less';
import isEqual from 'lodash/isEqual';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defaultSettings } from 'redux/initialStates';
import withState from 'react-powertools/data/withState';
import flowRight from 'lodash/flowRight';

function MobileSettingsPanel(props) {
    const resetDisabled = isEqual(props.appliedSettings, defaultSettings);
    const resetFilters = () => !resetDisabled && props.onPendingSettingsChanged(defaultSettings);
    const settings = { ...props.appliedSettings, ...props.pendingSettings };

    return (
        <MobileDialog
            onClose={() => {
                props.onPendingSettingsChanged({});
                props.onClose();
            }}
            header={
                <React.Fragment>
                    <span className={classNames('btn-link', css.frontGround, { disabled: resetDisabled })}
                        onClick={resetFilters}>
                        Reset All
                    </span>
                    <div
                        style={{ position: 'absolute', left: 0, right: 0, pointerEvents: 'none' }}
                        className="layout horizontal justify-center">
                        <span className={css.headline}>Settings</span>
                    </div>
                </React.Fragment>
            } content={
                <div className={classNames('layout vertical', css.content)}>
                    <LimitPerTopology
                        className={css.filterRow}
                        limit={settings.limit}
                        onLimit={(data) => props.onPendingSettingsChanged({ ...props.pendingSettings, ...data })}
                    />
                    <OrderResultsByItem
                        className={css.filterRow}
                        selected={settings.orderBy}
                        includePriceOption={settings.showPrice}
                        onChange={orderBy => props.onPendingSettingsChanged({ ...props.pendingSettings, orderBy })} />
                    <ColumnSettingsItem
                        className={css.filterRow}
                        checked={settings.showPrice}
                        onChange={showPrice => props.onPendingSettingsChanged({ ...props.pendingSettings, showPrice })}
                    />
                </div>
            } footer={
                <Button
                    onClick={() => {
                        props.applyPendingSettings(props.pendingSettings);
                        props.onClose();
                    }} className={css.applyBtn}
                    disabled={isEqual(props.appliedSettings, settings)}>
                    Apply Settings
                </Button>
            } />
    );
}

MobileSettingsPanel.propTypes = {
    appliedSettings: PropTypes.object.isRequired,
    pendingSettings: PropTypes.object.isRequired,
    applyPendingSettings: PropTypes.func.isRequired,
    onPendingSettingsChanged: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

const mapStateToProps = ({ suggestedParts }) => {
    const { limit, orderBy, showPrice } = suggestedParts;
    return {
        appliedSettings: { limit, orderBy, showPrice }
    };
};

const withRedux = connect(mapStateToProps, {
    applyPendingSettings: actions.applyPendingSettings
});

const bindData = flowRight([
    withRedux,
    withState({
        pendingSettings: {}
    })
]);

export default bindData(MobileSettingsPanel);
