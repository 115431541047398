/* global $ */

import PropTypes from 'prop-types';

import React from 'react';
import databind from 'redux/utils/databind';
import InputRail from './InputRail.js';
import classNames from 'classnames';
import css from './styles.less';
import {actions as editorActions} from 'redux/modules/editor';
import TableErrorComponent from "./TableError.js";
import KendoTooltipWrapper from 'kendo-plugins/TooltipWrapper';
import {SortableTBody, getTableDragAndDropHint} from './SortableList';
import {movePosition} from './helpers/movePosition';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const inputDragAndDropHint = getTableDragAndDropHint($(
    `<div class="${classNames(css.inputRails, css.cloneContainer, 'powercompass_app')}">
        <table><tbody></tbody></table>
    </div>`
));

class InputRails extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        inputs: PropTypes.array.isRequired,
        addInputRail: PropTypes.func.isRequired,
        changeInputsRailOrder: PropTypes.func.isRequired
    };

    static databind ({editor}) {
        return {
            inputs: editor.inputs
        };
    }

    static actions = {
        addInputRail: editorActions.addInputRail,
        changeInputsRailOrder: editorActions.changeInputsRailOrder
    };

    hasMaxRails = () => {
        return (this.props.inputs.length > 9);
    };

    addInputRail = (e) => {
        if (!this.hasMaxRails()) {
            this.props.addInputRail();
        }
        e.preventDefault();
    };

    validation = (idx) => {
        return (
            <TableErrorComponent colspan={7} error={"Vmin can't be higher than Vmax"} key={"error"+idx} />
        );
    };

    componentDidMount() {
        KendoTooltipWrapper($("#availToolTip"), getText(contentKeys.AVAILABLE_RAIL_POWER_MINUS));
    }

    handleRowMove = (newIndex, oldIndex) => {
        this.props.changeInputsRailOrder(
            movePosition(this.props.inputs, newIndex, oldIndex)
                .map(input => input.id)
        );
    }

    render() {
        const className = classNames("table", this.props.className);
        let inputRails = this.props.inputs.map((x, idx) => {
            return (
                <React.Fragment key={x.id}>
                    <InputRail index={idx} />
                    {x.invalid && (
                        <TableErrorComponent
                            colspan={7}
                            error={"Vmin can't be higher than Vmax"}
                        />
                    )}
                    {x.power < 0 && (
                        <TableErrorComponent
                            colspan={7}
                            error={getText(
                                contentKeys.AVAILABLE_INPUT_POWER_IS
                            )}
                        />
                    )}
                </React.Fragment>
            );
        });
        const addInputRailClass = this.hasMaxRails() ? classNames(css.addRail, css.disabled) : css.addRail;
        const addInputRail = (<a data-test="add_an_input_rail"
            className={classNames(addInputRailClass, 'btn-link')}
            onClick={this.addInputRail}>+ {getText(contentKeys.ADD_A_RAIL)}</a>);
        const R = (
            <div className={css.inputRails}>
                <table className={className}>
                    <thead>
                        <tr>
                            <th className={css.coloredTab}></th>
                            <th className={css.deleteBtn}></th>
                            <th rowSpan="2" className={css.inputName}>{getText(contentKeys.INPUT_NAME)}</th>
                            <th>V(Min)</th>
                            <th>V(Max)</th>
                            <th>A(Max)</th>
                            <th>
                                {getText(contentKeys.AVAIL_POWER_W) + " "}
                                <span id="availToolTip" className=" fa fa-info-circle"></span>
                            </th>
                        </tr>
                    </thead>
                    <SortableTBody hint={inputDragAndDropHint}
                        move={this.handleRowMove}
                        data-test="input_rails">
                        {inputRails}
                    </SortableTBody>
                </table>
                {addInputRail}
            </div>
        );

        return R;
    }
}

export default databind(InputRails);
