import {makeAction, createReducer} from '../utils/redux-helpers';
import createCSVBuilder from "logic/csv";
import fileSaver from "file-saver";
import cloneDeep from "lodash/cloneDeep";
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

function prefix (type) { return `header/${type}`; }

export const actionTypes = {
    RESET: prefix('RESET'),
    TOGGLE_PROJECT_HEADER: prefix('TOGGLE_PROJECT_HEADER'),
    TOGGLE_LINKS: prefix('TOGGLE_LINKS'),
    SET_LINK_VISIBILITY: prefix('SET_LINK_VISIBILITY'),
    SET_HEADER_TEXT: prefix('SET_HEADER_TEXT'),
    TOGGLE_PROJECT_NAME: prefix('TOGGLE_PROJECT_NAME')
};

function swapCommaForSemicolon(item, field) {
    item[field] = item[field].replace(/,/g, ';');
}

function replaceCommasWithSemicolonsInField(arrayOfObjectsWithNames, field) {
    arrayOfObjectsWithNames.forEach((item) => swapCommaForSemicolon(item, field));
}

export const actions = {
    reset: () => makeAction(actionTypes.RESET),
    toggleProjectHeader: (showHeader) => makeAction(actionTypes.TOGGLE_PROJECT_HEADER, showHeader),
    toggleProjectName: (showProjectName) => makeAction(actionTypes.TOGGLE_PROJECT_NAME, showProjectName),
    toggleLinks: (enableLinks) => makeAction(actionTypes.TOGGLE_LINKS, enableLinks),
    setLinkVisibility: (flags) => makeAction(actionTypes.SET_LINK_VISIBILITY, flags),
    setHeaderText: (text) => makeAction(actionTypes.SET_HEADER_TEXT, text),
    buildCSVString: () => (dispatch, getState) => {
        const state = getState();
        const location = state.router.locationBeforeTransitions.pathname;
        const csvBuilder = createCSVBuilder(location);
        const cloned = {
            editor: cloneDeep(state.editor),
            suggestedParts: cloneDeep(pick(state.suggestedParts, ['results', 'selectedParts'])),
            compareAndSelect: cloneDeep(omit(state.compareAndSelect), [])
        };
        cloned.compareAndSelect.inputs = cloned.editor.inputs;
        replaceCommasWithSemicolonsInField(cloned.editor.inputs, 'name');
        replaceCommasWithSemicolonsInField(cloned.editor.outputs, 'name');
        replaceCommasWithSemicolonsInField(cloned.suggestedParts.results, 'decoratedName');
        replaceCommasWithSemicolonsInField(cloned.compareAndSelect.results, 'name');
        const csvString = csvBuilder.stringify([cloned.editor, cloned.suggestedParts, cloned.compareAndSelect]);
        const blob = new Blob([csvString], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, "exportCSV.csv");
    }
};

const ACTION_HANDLERS = {
    [actionTypes.RESET]: () => initialState,
    [actionTypes.TOGGLE_PROJECT_HEADER]: (state, {payload}) => {
        return { ...state, showProjectHeader: payload };
    },
    [actionTypes.TOGGLE_LINKS]: (state, {payload}) => {
        return { ...state, enableLinks: payload };
    },
    [actionTypes.SET_LINK_VISIBILITY]: (state, {payload}) => {
        return { ...state, ...payload };
    },
    [actionTypes.SET_HEADER_TEXT]: (state, {payload}) => {
        return { ...state, headerText: payload };
    },
    [actionTypes.TOGGLE_PROJECT_NAME]: (state, {payload}) => {
        return { ...state, showProjectName: payload };
    }
};

const initialState = {
    headerText: getText(contentKeys.CHOOSE_AN_OPTION_AND),
    showProjectHeader: true,
    showProjectName: false,
    enableLinks: true,
    showSaveProjectLink: false,
    showExportCsvLink: false,
    showBackLink: false,
    showOfflineVersionLink: true,
    showHelpLink: true,
    showYourProjectsLink: true,
    showSendLink: false
};

export default createReducer(initialState, ACTION_HANDLERS);
