import PropTypes from 'prop-types';
import React from 'react';
import {actions as authActions} from 'redux/modules/auth';
import {removePendingAction as removePendingProjectsAction} from 'redux/modules/projects';
import ModalWindow from 'components/ModalWindow';
import ModalContent from 'components/ModalContent';
import databind from 'redux/utils/databind';
import makeSignInUrl from 'util/makeSignInUrl';
import {forcePersist} from 'redux/persist';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class SignInRedirectWarningModal extends React.Component {
    static propTypes={
        open: PropTypes.bool,
        returnUrl: PropTypes.string,
        hide: PropTypes.func
    };

    static databind({auth}) {
        const {showSignInRedirectWarning, returnUrl} = auth;
        return { open: showSignInRedirectWarning, returnUrl };
    };

    static actions = {
        hide: () => authActions.showSignInRedirectWarning(false)
    };

    onGoBack = () => {
        removePendingProjectsAction();
        this.props.hide();
    };

    onContinue = () => {
        const returnUrl = this.props.returnUrl;
        this.props.hide();

        forcePersist(() => {
            window.location = makeSignInUrl(returnUrl);
        });
    };

    render() {
        return (
            <ModalWindow
                actions={["close"]}
                open={this.props.open}
                close={this.onGoBack}
                height={300}
                width={500}
                title="Not Signed In"
                center={true}>
                <ModalContent
                    onCancel={this.onGoBack}
                    cancelLabel="Go Back"
                    onSubmit={this.onContinue}
                    submitLabel={getText(contentKeys.CONTINUE)}
                    canSubmit={true}>
                    <div>
                        <p>Your session may have expired and you are about to be redirected to the sign in page.</p>
                        <p>Your work is safe, and you will return to this page after signing in.</p>
                        <p>Do you want to Continue?</p>
                    </div>
                </ModalContent>
            </ModalWindow>);
    }
}

export default databind(SignInRedirectWarningModal);
