/* global */
import {makeAction, createReducer} from '../utils/redux-helpers';
import {buildDefaultSelectedPartIds,
    buildDefaultSelectedPmbusAddresses,
    computeEfficiencyAndThermalReferenceData,
    buildDefaultLoadEfficienciesForEachOutput,
    buildDefaultLoadCurrentsForEachOutput,
    updateJunctionTempComputedData,
    getSelectedPmbusAddressesForSelectedParts} from 'logic/compareParts';
import {computeNewSystemLoadEfficiencyWhereNotNull,
    computeSystemEfficiencyCurveWhereNotNull,
    computeSystemPowerDissipationData,
    doSelectedPartshavePartialEfficiencyData} from 'logic/systemSummary';
import {lookupFromReferenceCurve} from 'logic/resampling';
import {generateResultsForCompareAndSelect} from 'logic/partsForComparison';
import {getReferenceCurvesForOutputAndPart} from 'logic/curveUtils';
import {DEFAULT_AMBIENT_TEMP} from 'logic/constants';
import areNOutputPartSelectionsValid from 'logic/areNOutputPartSelectionsValid';
import _ from 'lodash';
import getHistory from '../../util/history';
import isAtLeastOnePartSchematicEnabled from "../../logic/isAtLeastOnePartSchematicEnabled";
import {actions as projectActions, selectors as projectSelectors} from 'redux/modules/projects';
import {registerBlacklistedKeys} from 'redux/persist';

function prefix (type) { return `partsForComparison/${type}`; }

export const actionTypes = {
    FETCH_RESULTS: prefix('FETCH_RESULTS'),
    FLAG_PREPARING_RESULTS: prefix('FLAG_PREPARING_RESULTS'),
    RECEIVE_RESULTS: prefix('RECEIVE_RESULTS'),
    RECEIVE_ERROR: prefix('RECEIVE_ERROR'),
    UPDATED_SELECTED_PARTS: prefix('UPDATED_SELECTED_PARTS'),
    UPDATE_AMBIENT_TEMPERATURE: prefix('UPDATE_AMBIENT_TEMPERATURE'),
    UPDATE_SELECTED_PMBUS_ADDRESS: prefix('UPDATE_SELECTED_PMBUS_ADDRESS'),
    UPDATE_LIGHT_LOAD: prefix('UPDATE_LIGHT_LOAD'),
    UPDATE_TYPICAL_LOAD: prefix('UPDATE_TYPICAL_LOAD'),
    UPDATE_MAX_LOAD: prefix('UPDATE_MAX_LOAD'),
    RESET: prefix('RESET'),
    VALIDATE_AND_GENERATE: prefix('VALIDATE_AND_GENERATE'),
    SHOW_ERROR_DIALOG: prefix('SHOW_ERROR_DIALOG'),
    HIDE_ERROR_DIALOG: prefix('HIDE_ERROR_DIALOG'),
    RELOAD_RESULTS: prefix('RELOAD_RESULTS')
};

function arePMBusAddressSelectionsValid(selectedPmbusAddresses, selectedPartIds) {
    const selectedPmbusAddressesForSelectedParts =
        _.values(getSelectedPmbusAddressesForSelectedParts(selectedPmbusAddresses, selectedPartIds));
    const uniqueAddresses = _.uniq(selectedPmbusAddressesForSelectedParts);
    return (selectedPmbusAddressesForSelectedParts.length === uniqueAddresses.length);
}

export const ValidationErrors = {
    NONE: "NONE",
    DUP_PMBUS_ADDRESS: "DUP_PMBUS",
    INVALID_N_OUTPUT_PART_SELECTION: "INVALID_N_OUTPUT",
    NO_SCHEMATIC_ENABLED_PARTS: "NO_SCHEMATIC_ENABLED"
};

export const actions = {
    fetchResults: () => {
        return async (dispatch, getState) => {
            dispatch(actions.flagPreparingResults());
            const state = getState();
            const results = generateResultsForCompareAndSelect(state.suggestedParts.results,
                state.suggestedParts.selectedParts);
            if ((results != null) && (_.isArray(results)) && (results.length > 0)) {
                await dispatch(actions.receiveResults({results, efficiencyDb: state.efficiencyDb.data}));
                dispatch(projectActions.trySavingProject(true));
            }
            else {
                dispatch(actions.receiveError("empty or null result set"));
            }
        };
    },
    flagPreparingResults: () => makeAction(actionTypes.FLAG_PREPARING_RESULTS),
    receiveResults: (data) => makeAction(actionTypes.RECEIVE_RESULTS, data),
    receiveError: (data) => makeAction(actionTypes.RECEIVE_ERROR, data),
    updateSelectedParts: (data) => makeAction(actionTypes.UPDATED_SELECTED_PARTS, data),
    updateSelectedPmbusAddress: (outputIdAndNewAddress) =>
        makeAction(actionTypes.UPDATE_SELECTED_PMBUS_ADDRESS, outputIdAndNewAddress),
    updateLightLoad: (data) => makeAction(actionTypes.UPDATE_LIGHT_LOAD, data),
    updateTypicalLoad: (data) => makeAction(actionTypes.UPDATE_TYPICAL_LOAD, data),
    updateMaxLoad: (data) => makeAction(actionTypes.UPDATE_MAX_LOAD, data),
    reset: () => makeAction(actionTypes.RESET),
    validateAndGenerate: () => async (dispatch, getState) => {
        const {selectedPmbusAddresses, selectedPartIds, results} = getState().compareAndSelect;
        const nOutputs = areNOutputPartSelectionsValid(results, selectedPartIds);
        if (!isAtLeastOnePartSchematicEnabled(results, selectedPartIds)) {
            dispatch(actions.showErrorDialog({ error: ValidationErrors.NO_SCHEMATIC_ENABLED_PARTS }));
        }
        else if (!nOutputs.valid) {
            dispatch(actions.showErrorDialog({
                error: ValidationErrors.INVALID_N_OUTPUT_PART_SELECTION, info: nOutputs.info }));
        }
        else if (!arePMBusAddressSelectionsValid(selectedPmbusAddresses, selectedPartIds)) {
            dispatch(actions.showErrorDialog({ error: ValidationErrors.DUP_PMBUS_ADDRESS }));
        }
        else {
            // If a project is loaded then we want to trigger an autosave at this point. The trySavingProject action
            // will require login if the user is not authenticated at this point.
            if (projectSelectors.isAProjectLoaded) {
                await dispatch(projectActions.trySavingProject(true));
            }

            const {auth, projects} = getState();
            if (auth.isSignedIn && projectSelectors.isAProjectLoaded(projects)) {
                getHistory().push('/reference/new');
            }
            else {
                getHistory().push('/reference');
            }
        }
    },
    showErrorDialog: (errorType) => makeAction(actionTypes.SHOW_ERROR_DIALOG, errorType),
    hideErrorDialog: () => makeAction(actionTypes.HIDE_ERROR_DIALOG),
    reloadResults: () => makeAction(actionTypes.RELOAD_RESULTS),
    updateAmbientTemperature: (data) => makeAction(actionTypes.UPDATE_AMBIENT_TEMPERATURE, data)
};

function updateLoadForOutputId(loads, outputId, newLoad) {
    const updatedLoad = { ...loads[outputId], ...newLoad };
    return { ...loads, [outputId]: updatedLoad };
}

function updateEfficiencyForOutputId(efficiencies, computedData, outputId, partId, load, fieldName) {
    const refCurve = getReferenceCurvesForOutputAndPart(outputId, partId, computedData);
    if ((refCurve != null) && (refCurve.efficiency)) {
        const effCurve = refCurve.efficiency;
        const updatedEfficiency = { ...efficiencies[outputId], [fieldName]: lookupFromReferenceCurve(effCurve, load) };
        return { ...efficiencies, [outputId]: updatedEfficiency };
    }
    return efficiencies;
}

function updateAllEfficienciesForOutputId(loads, efficiencies, computedData, outputId, partId) {
    const refCurve = getReferenceCurvesForOutputAndPart(outputId, partId, computedData);
    if ((refCurve != null) && (refCurve.efficiency != null)) {
        const effCurve = refCurve.efficiency;
        const updated = { ...efficiencies[outputId],
            lightLoad: lookupFromReferenceCurve(effCurve, loads[outputId].lightLoad),
            typicalLoad: lookupFromReferenceCurve(effCurve, loads[outputId].typicalLoad),
            maxLoad: lookupFromReferenceCurve(effCurve, loads[outputId].maxLoad),
        };
        return { ...efficiencies, [outputId]: updated };
    }
    else {
        const updated = { ...efficiencies[outputId], lightLoad: null, typicalLoad: null, maxLoad: null };
        return { ...efficiencies, [outputId]: updated };
    }
}

function updateLoadAndEfficiency(loads, efficiencies, computedData, payload, fieldName) {
    const {outputId, partId, load} = payload;
    return {
        loads: updateLoadForOutputId(loads, outputId, {[fieldName]: load}),
        efficiencies: updateEfficiencyForOutputId(efficiencies, computedData, outputId, partId, load, fieldName)
    };
}

const ACTION_HANDLERS = {
    [actionTypes.RESET]: (state) => initialState,
    [actionTypes.FLAG_PREPARING_RESULTS]: (state) => {
        return { ...state, requested: true, received: false, error: false };
    },
    [actionTypes.RECEIVE_RESULTS]: (compareAndSelectState, {payload}) => {
        const {results, efficiencyDb} = payload;
        const { ambientTemperature } = compareAndSelectState;
        const selectedPartIds = buildDefaultSelectedPartIds(results);
        const selectedPmbusAddresses = buildDefaultSelectedPmbusAddresses(results, selectedPartIds);
        const computedData = computeEfficiencyAndThermalReferenceData(results, efficiencyDb, ambientTemperature);
        const loads = buildDefaultLoadCurrentsForEachOutput(results);
        const efficiencies = buildDefaultLoadEfficienciesForEachOutput(results, loads, computedData);
        const systemLightLoadEfficiency =
            computeNewSystemLoadEfficiencyWhereNotNull(efficiencies, loads, results, 'lightLoad');
        const systemTypicalLoadEfficiency =
            computeNewSystemLoadEfficiencyWhereNotNull(efficiencies, loads, results, 'typicalLoad');
        const systemMaxLoadEfficiency =
            computeNewSystemLoadEfficiencyWhereNotNull(efficiencies, loads, results, 'maxLoad');
        const systemEfficiency = computeSystemEfficiencyCurveWhereNotNull(selectedPartIds, computedData, results);
        const { systemPowerDissipation, maxPowerDissipation } =
            computeSystemPowerDissipationData(selectedPartIds, computedData);
        const selectionsHavePartialEfficiencyData =
            doSelectedPartshavePartialEfficiencyData(computedData, selectedPartIds);
        return { ...compareAndSelectState,
            requested: false,
            received: true,
            results,
            computedData,
            selectedPartIds,
            selectedPmbusAddresses,
            loads,
            efficiencies,
            systemLightLoadEfficiency,
            systemTypicalLoadEfficiency,
            systemMaxLoadEfficiency,
            systemEfficiency,
            systemPowerDissipation,
            maxPowerDissipation,
            selectionsHavePartialEfficiencyData
        };
    },
    [actionTypes.RECEIVE_ERROR]: (state, {payload}) => {
        return { ...state, requested: false, error: true, message: payload };
    },
    [actionTypes.UPDATED_SELECTED_PARTS]: (state, {payload}) => {
        const { loads, results, computedData, efficiencies } = state;
        const { outputId, partId } = payload;
        const selectedPartIds = { ...state.selectedPartIds };
        selectedPartIds[outputId] = partId;
        const { systemPowerDissipation, maxPowerDissipation } =
            computeSystemPowerDissipationData(selectedPartIds, computedData);
        const updatedEfficiencies = updateAllEfficienciesForOutputId(loads,
            efficiencies, computedData, outputId, partId);
        const selectionsHavePartialEfficiencyData =
            doSelectedPartshavePartialEfficiencyData(computedData, selectedPartIds);
        return { ...state,
            selectedPartIds,
            efficiencies: updatedEfficiencies,
            systemLightLoadEfficiency:
                computeNewSystemLoadEfficiencyWhereNotNull(updatedEfficiencies, loads, results, 'lightLoad'),
            systemTypicalLoadEfficiency:
                computeNewSystemLoadEfficiencyWhereNotNull(updatedEfficiencies, loads, results, 'typicalLoad'),
            systemMaxLoadEfficiency:
                computeNewSystemLoadEfficiencyWhereNotNull(updatedEfficiencies, loads, results, 'maxLoad'),
            systemEfficiency: computeSystemEfficiencyCurveWhereNotNull(selectedPartIds, computedData, results),
            systemPowerDissipation,
            maxPowerDissipation,
            selectionsHavePartialEfficiencyData
        };
    },
    [actionTypes.UPDATE_SELECTED_PMBUS_ADDRESS]: (state, {payload}) => {
        const { outputId, partId, pmbusAddress } = payload;
        const selectedPmbusAddresses = { ...state.selectedPmbusAddresses };
        let addressesForThisOutput = { ...selectedPmbusAddresses[outputId] };
        addressesForThisOutput[partId] = pmbusAddress;
        selectedPmbusAddresses[outputId] = addressesForThisOutput;
        return { ...state, selectedPmbusAddresses };
    },
    [actionTypes.UPDATE_LIGHT_LOAD]: (state, {payload}) => {
        const { results, loads, efficiencies, computedData } = state;
        const updated = updateLoadAndEfficiency(loads, efficiencies, computedData, payload, 'lightLoad');
        return { ...state,
            loads: updated.loads,
            efficiencies: updated.efficiencies,
            systemLightLoadEfficiency:
                computeNewSystemLoadEfficiencyWhereNotNull(updated.efficiencies, updated.loads, results, 'lightLoad')
        };
    },
    [actionTypes.UPDATE_TYPICAL_LOAD]: (state, {payload}) => {
        const { results, loads, efficiencies, computedData } = state;
        const updated = updateLoadAndEfficiency(loads, efficiencies, computedData, payload, 'typicalLoad');
        return { ...state,
            loads: updated.loads,
            efficiencies: updated.efficiencies,
            systemTypicalLoadEfficiency:
                computeNewSystemLoadEfficiencyWhereNotNull(updated.efficiencies, updated.loads, results, 'typicalLoad')
        };
    },
    [actionTypes.UPDATE_MAX_LOAD]: (state, {payload}) => {
        const { results, loads, efficiencies, computedData } = state;
        const updated = updateLoadAndEfficiency(loads, efficiencies, computedData, payload, 'maxLoad');
        return {
            ...state,
            loads: updated.loads,
            efficiencies: updated.efficiencies,
            systemMaxLoadEfficiency:
                computeNewSystemLoadEfficiencyWhereNotNull(updated.efficiencies, updated.loads, results, 'maxLoad')
        };
    },
    [actionTypes.SHOW_ERROR_DIALOG]: (state, {payload}) => {
        return { ...state, validationError: payload.error, validationErrorInfo: payload.info || [] };
    },
    [actionTypes.HIDE_ERROR_DIALOG]: (state) => {
        return { ...state, validationError: ValidationErrors.NONE, validationErrorInfo: [] };
    },
    [actionTypes.RELOAD_RESULTS]: (state) => {
        if (state.received) {
            return { ...state, tick: Date.now() };
        }
        return { ...state };
    },
    [actionTypes.UPDATE_AMBIENT_TEMPERATURE]: (state, {payload}) => {
        return {
            ...state,
            ambientTemperature: payload.ambientTemperature,
            computedData: updateJunctionTempComputedData(state.computedData, state.results, payload.ambientTemperature)
        };
    }
};

export const initialState = {
    requested: false,
    received: false,
    error: false,
    errorMessage: "",
    results: [],
    computedData: [],
    loads: {},
    efficiencies: {},
    selectedPartIds: {},
    selectedPmbusAddresses: {},
    maxPowerDissipation: 0,
    ambientTemperature: DEFAULT_AMBIENT_TEMP,
    systemLightLoadEfficiency: 0,
    systemTypicalLoadEfficiency: 0,
    systemMaxLoadEfficiency: 0,
    systemEfficiency: [],
    systemPowerDissipation: [],
    validationError: ValidationErrors.NONE,
    validationErrorInfo: [],
    selectionsHavePartialEfficiencyData: false,
    tick: 0
};

registerBlacklistedKeys('compareAndSelect', ['requested', 'received', 'error', 'errorMessage']);

export default createReducer(initialState, ACTION_HANDLERS);
