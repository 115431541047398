import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import databind from 'redux/utils/databind';
import Dropzone from 'react-dropzone';
import {actions, selectors} from 'redux/modules/startOptions';
import MessageBox, {MESSAGE_BOX_ERROR, MESSAGE_BOX_WARNING, MESSAGE_BOX_INFO} from 'components/MessageBox';
import LoadingSpinner from 'components/LoadingSpinner';
import classNames from 'classnames';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const LoadCSVReminder = (props) => {
    const message = (
        <div>
            Please try using Chrome or MS Edge or upload a CSV file following these <span
                className={css.instructionsLink}
                onClick={props.onInstructions}>instructions
            </span>
        </div>);

    return (<div>
        <MessageBox
            onDismiss={props.onDismiss}
            type={MESSAGE_BOX_WARNING}>
            {message}
        </MessageBox>
    </div>);
};

LoadCSVReminder.propTypes = {
    onInstructions: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired
};

const DisabledDropzone = (props) => {
    return (
        <div className={css.vendorFilesDropZone}>
            <div className={css.disabledZone}>
                {getText(contentKeys.DRAG_A_FILE_HERE)}
                <span className={css.browseText}>{getText(contentKeys.BROWSE_FOR_FILE)}</span>
                <br />
                {getText(contentKeys.OPTIONAL)}
            </div>
        </div>);
};

class VendorFileDropZone extends React.Component {
    constructor() {
        super();
        this.state = { dragover: false };
    }

    static propTypes = {
        onSelectFile: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        showLoadCSVModal: PropTypes.func.isRequired,
        hideLoadCSVReminder: PropTypes.func.isRequired,
        errorMessage: PropTypes.string,
        disabled: PropTypes.bool,
        isLoading: PropTypes.bool,
        isLoaded: PropTypes.bool,
        loadingAlteraXLS: PropTypes.bool,
        showLoadCSVReminder: PropTypes.bool,
        filename: PropTypes.string,
        cancelVendorFileLoading: PropTypes.func.isRequired
    }

    static databind({startOptions}) {
        const {vendorFileError, templateBasedOnVendorFile,
            loadingVendorFile, showLoadCSVReminder, loadingAlteraXLS} = startOptions;
        const isLoaded = !!templateBasedOnVendorFile;
        const filename = (isLoaded) ? templateBasedOnVendorFile.filename : '';
        return { errorMessage: vendorFileError,
            disabled: !selectors.useTemplate(startOptions),
            isLoading: loadingVendorFile,
            loadingAlteraXLS,
            showLoadCSVReminder,
            isLoaded,
            filename };
    }

    static actions = {
        onSelectFile: actions.validateAndLoadVendorFile,
        showLoadCSVModal: actions.showLoadCSVModal,
        hideLoadCSVReminder: actions.hideLoadCSVReminder,
        reset: actions.clearStartState,
        cancelVendorFileLoading: actions.cancelVendorFileLoading
    };

    onDrop = (acceptedFiles) => {
        if (acceptedFiles && (acceptedFiles.length > 0) && (!this.props.disabled)) {
            this.props.onSelectFile(acceptedFiles[0]);
        }
    };

    componentWillReceiveProps() {
        this.setState({ dragover: false });
    }

    onDragOver = () => {
        this.setState({ dragover: true });
    };

    onDragLeave = () => {
        this.setState({ dragover: false });
    };

    resetVendorFileSelection = () => {
        this.props.reset();
    };

    showLoadCSVInstructionsModal = () => {
        this.props.showLoadCSVModal();
    };

    hideLoadCSVReminder= () => {
        this.props.hideLoadCSVReminder();
    };

    onCancel = () => {
        this.props.cancelVendorFileLoading();
    }

    render() {
        const MAX_FILE_SIZE = 50*1024*1024;

        let message;
        if (this.props.errorMessage) {
            message = (<MessageBox
                onDismiss={this.resetVendorFileSelection}
                type={MESSAGE_BOX_ERROR}>{this.props.errorMessage}</MessageBox>);
        }
        else if (this.props.isLoaded) {
            message = (
                <MessageBox
                    heading={"Selected file:"}
                    onDismiss={this.resetVendorFileSelection}
                    type={MESSAGE_BOX_INFO}>{this.props.filename}</MessageBox>);
        }
        else if (this.props.showLoadCSVReminder && !this.props.loadingAlteraXLS) {
            message = <LoadCSVReminder
                onInstructions={this.showLoadCSVInstructionsModal}
                onDismiss={this.hideLoadCSVReminder} />;
        }

        const dropZoneClassName = (this.state.dragover) ? classNames(css.zone, css.dragover) : css.zone;
        if (this.props.disabled) {
            return (<DisabledDropzone />);
        }
        else if (this.props.isLoading) {
            return (<div className={css.vendorFilesDropZone}>
                <div className={css.zone}>
                    <LoadingSpinner className={css.spinner}
                        message={"Processing file, this may take a few moments."} />
                    {(this.props.loadingAlteraXLS)
                        ? <span className={css.fasterLoadingAdvice}
                            onClick={this.showLoadCSVInstructionsModal}>
                            Learn how to upload XLS files faster
                        </span> : null}
                    <span onClick={this.onCancel} className={css.cancel}>Cancel</span>
                </div>
            </div>);
        }
        else {
            return (
                <div className={css.vendorFilesDropZone}>
                    <Dropzone className={dropZoneClassName}
                        onDrop={this.onDrop}
                        onDragOver={this.onDragOver}
                        onDragLeave={this.onDragLeave}
                        maxSize={MAX_FILE_SIZE}
                        disableClick={this.props.disabled}>
                        {getText(contentKeys.DRAG_A_FILE_HERE)}
                        <span className={css.browseText}>{getText(contentKeys.BROWSE_FOR_FILE)}</span>
                        <br />
                        {getText(contentKeys.OPTIONAL)}
                    </Dropzone>
                    {message}
                </div>);
        }
    }
}

export default databind(VendorFileDropZone);
