import keyBy from 'lodash/keyBy';
import reject from 'lodash/reject';
import flatten from 'lodash/flatten';
import map from 'lodash/map';

// Returns a list of parts that are included in the design but do not have schematic support
export default function selectedPartsWithNoSchematic(state) {
    const {selectedPartIds, results} = state.compareAndSelect;
    const partsById = keyBy(flatten(map(results, 'items')), 'id');
    const selectedParts = Object.values(selectedPartIds).map((partId) => partsById[partId]);

    return reject(selectedParts, (part) => !part || part.orcadEnabled);
}
