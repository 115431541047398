import PropTypes from 'prop-types';
import React from 'react';
import databind from 'redux/utils/databind';
import css from './styles.less';
import {selectors as projectSelectors, actions as projectActions} from 'redux/modules/projects';
import {RenameProjectModal} from 'components/Projects/ProjectModals';
import {isReferenceDesignStatus} from 'logic/status';

class ProjectName extends React.Component {
    static propTypes = {
        projects: PropTypes.array,
        projectId: PropTypes.string,
        projectName: PropTypes.string,
        projectStatus: PropTypes.string,
        headerText: PropTypes.string.isRequired,
        showProjectName: PropTypes.bool.isRequired,
        isAProjectLoaded: PropTypes.bool.isRequired,
        renameTheLoadedProject: PropTypes.func.isRequired,
        projectLoading: PropTypes.bool,
        showEditNameModal: PropTypes.bool,
        toggleEditNameModal: PropTypes.func.isRequired,
        tryEditingName: PropTypes.func.isRequired,
        toggleLoading: PropTypes.func.isRequired
    };

    static actions = {
        renameTheLoadedProject: (name) => projectActions.tryProjectAction('renameTheLoadedProject', name),
        toggleEditNameModal: projectActions.toggleEditNameModal,
        tryEditingName: () => projectActions.tryProjectAction('toggleEditNameModal', true),
        toggleLoading: projectActions.loading
    };

    static databind ({header, projects}) {
        const {current, loading, showEditNameModal, listing} = projects;
        return {
            isAProjectLoaded: projectSelectors.isAProjectLoaded(projects),
            projects: listing,
            projectName: current['name'] || '',
            projectId: current['id'] || '',
            projectStatus: current['status'] || '',
            projectLoading: loading,
            showEditNameModal: showEditNameModal,
            headerText: header.headerText,
            showProjectName: header.showProjectName
        };
    }

    startEditing = () => {
        if (this.props.isAProjectLoaded) {
            this.props.tryEditingName();
        }
    };

    hideRenameModal = () => {
        this.props.toggleEditNameModal(false);
        this.props.toggleLoading(false);
    };

    onConfirmRename = (name) => {
        this.props.renameTheLoadedProject(name);
    };

    render() {
        const { projectName, headerText, showEditNameModal, isAProjectLoaded, projects, projectStatus,
            showProjectName } = this.props;
        const projectNames = projects.map(p => p.name);
        const showEditIcon = isAProjectLoaded && !isReferenceDesignStatus(projectStatus) && showProjectName;

        return (
            <div className={css.nameWrapper}>
                <div data-test="project_name" className={css.title}>
                    <span>
                        {showProjectName && projectName ? projectName : headerText}
                        {showEditIcon && <i onClick={this.startEditing} className="fa fa-pencil" />}
                    </span>
                </div>
                {showEditIcon && <RenameProjectModal
                    open={showEditNameModal}
                    loading={this.props.projectLoading}
                    name={projectName}
                    names={projectNames}
                    onOK={this.onConfirmRename}
                    onClose={this.hideRenameModal}
                />}
            </div>
        );
    }
}

export default databind(ProjectName);
