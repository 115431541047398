import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const RestoreDefaultsItem = props => {
    return (<div className={css.restoreDefaultsItem}>
        <div className={css.divider}>
            {(props.disabled)
                ? <span className={css.disabled}>{getText(contentKeys.RESTORE_DEFAULTS)}</span>
                : <span className={css.link} onClick={props.onRestore}>{getText(contentKeys.RESTORE_DEFAULTS)}</span>}
        </div>
    </div>);
};

RestoreDefaultsItem.propTypes = {
    onRestore: PropTypes.func,
    disabled: PropTypes.bool
};

export default RestoreDefaultsItem;
