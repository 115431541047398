import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';

export const MESSAGE_BOX_INFO = 0;
export const MESSAGE_BOX_ERROR = 1;
export const MESSAGE_BOX_WARNING = 2;

class MessageBox extends React.Component {
    static propTypes = {
        heading: PropTypes.string,
        onDismiss: PropTypes.func,
        type: PropTypes.number,
        children: PropTypes.node
    };

    static defaultProps = {
        type: MESSAGE_BOX_INFO
    };

    onDismiss = () => {
        this.props.onDismiss();
    };

    render() {
        const typeClassName = (this.props.type === MESSAGE_BOX_INFO) ? css.info : css.error;
        return (
            <div className={css.messageBox}>
                {(this.props.heading) ? <div className={css.heading}>{this.props.heading}</div> : null}
                <div className={typeClassName}>
                    <div className={css.clearButton}>
                        <i className="fa fa-times" aria-hidden="true" onClick={this.onDismiss}></i>
                    </div>
                    <div>
                        {(this.props.type === MESSAGE_BOX_ERROR) ? <i className="fa fa-exclamation" /> : null}
                        {this.props.children}
                    </div>
                </div>
            </div>);
    }
}

export default MessageBox;
