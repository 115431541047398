import PropTypes from 'prop-types';
import React from 'react';
import ModalWindow from 'components/ModalWindow';
import ModalContent from 'components/ModalContent';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const ReadyToGenModal = (props) => {
    const title = getText(contentKeys.READY_TO_GENERATE_REFERENCE);
    return (
        <ModalWindow
            actions={["Close"]}
            close={props.onClose}
            open={props.open}
            width={740}
            height={400}
            center={true}
            title={title}>
            <ModalContent
                onCancel={props.onClose}
                onSubmit={props.onConfirm}
                cancelLabel={getText(contentKeys.CANCEL)}
                submitLabel={getText(contentKeys.CONTINUE)}
                canSubmit={true}
                skipModalPrefKey={"ignoreRefDesignWarning"}>
                <p>
                    {getText(contentKeys.ONCE_YOU_GENERATE_A)}
                </p>

                <p>{getText(contentKeys.CHOOSE_CONTINUE_TO_GENERATE)}</p>
            </ModalContent>
        </ModalWindow>
    );
};

ReadyToGenModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool
};

export default ReadyToGenModal;
