/* eslint max-len: 0 */
export default {
    "1_OUTPUT": 'SINGLE_OUTPUT',
    "2_OUTPUT": 'DUAL_OUTPUT',
    "3_OUTPUT": 'TRIPLE_OUTPUT',
    "4_OUTPUT": 'QUAD_OUTPUT',
    A_MORE_RECENT_VERSION: 'A_MORE_RECENT_VERSION',
    A_UNIQUE_AND_VALID: 'A_UNIQUE_AND_VALID',
    ACTIONS: 'ACTIONS',
    ADD_A_RAIL: 'ADD_A_RAIL',
    ADD_AN_OUTPUT: 'ADD_AN_OUTPUT',
    AECQ: 'AECQ',
    AECQ_QUALIFID: 'AECQ_QUALIFID',
    ALL: 'ALL',
    ALREADY_HAVE_AN_ACCOUNT: 'ALREADY_HAVE_AN_ACCOUNT',
    AMBIENT_TEMPERATURE: 'AMBIENT_TEMPERATURE',
    AMPS_MAX: 'AMPS_MAX',
    ANALOG: 'ANALOG',
    ANALOG_INTEGRATED_FET: 'ANALOG_INTEGRATED_FET',
    ANALOG_MODULE: 'ANALOG_MODULE',
    AND: 'AND',
    ARE_YOU_SURE_YOU: 'ARE_YOU_SURE_YOU',
    ARE_YOU_SURE_YOU_WANT: 'ARE_YOU_SURE_YOU_WANT',
    ASCENDING: 'ASCENDING',
    ASSUMED_MIN_EFFICIENCY: 'ASSUMED_MIN_EFFICIENCY',
    AT_LEAST_ONE_SCHEMATIC_ENABLED: 'AT_LEAST_ONE_SCHEMATIC_ENABLED',
    AVAIL_OUTPUT: 'AVAIL_OUTPUT',
    AVAIL_OUTPUT_W: 'AVAIL_OUTPUT_W',
    AVAIL_POWER_W: 'AVAIL_POWER_W',
    AVAILABLE_INPUT_POWER_IS: 'AVAILABLE_INPUT_POWER_IS',
    AVAILABLE_OUTPUT_POWER_IS: 'AVAILABLE_OUTPUT_POWER_IS',
    AVAILABLE_POWER_W: 'AVAILABLE_POWER_W',
    AVAILABLE_RAIL_POWER_MINUS: 'AVAILABLE_RAIL_POWER_MINUS',
    AVERAGE_SYSTEM_EFFICIENCY: 'AVERAGE_SYSTEM_EFFICIENCY',
    BLOCK_DIAGRAM: 'BLOCK_DIAGRAM',
    BOM_COST: 'BOM_COST',
    BOM_COUNT: 'BOM_COUNT',
    BROWSE_FOR_FILE: 'BROWSE_FOR_FILE',
    BUCK: 'BUCK',
    BY_SAVING_THIS_PROJECT: 'BY_SAVING_THIS_PROJECT',
    CANCEL: 'CANCEL',
    CHOOSE_AN_OPTION_AND: 'CHOOSE_AN_OPTION_AND',
    CHOOSE_CONTINUE_TO_GENERATE: 'CHOOSE_CONTINUE_TO_GENERATE',
    CHOOSE_PROJECT: 'CHOOSE_PROJECT',
    CLOSE: 'CLOSE',
    COLLAPSE: 'COLLAPSE',
    COLLAPSE_ALL: 'COLLAPSE_ALL',
    COLUMN_SETTINGS: 'COLUMN_SETTINGS',
    COMPARE_AND_SELECT: 'COMPARE_AND_SELECT',
    CONTENTS: 'CONTENTS',
    CONTINUE: 'CONTINUE',
    COPY: 'COPY',
    COPY_AND_EDIT: 'COPY_AND_EDIT',
    COPY_PROJECT: 'COPY_PROJECT',
    CREATE_A_NEW_PROJECT: 'CREATE_A_NEW_PROJECT',
    DELETE: 'DELETE',
    DELETE_INPUT_RAIL: 'DELETE_INPUT_RAIL',
    DELETE_OUTPUT: 'DELETE_OUTPUT',
    DELETE_PROJECT: 'DELETE_PROJECT',
    DELETE_RAIL_AND_DELETE: 'DELETE_RAIL_AND_DELETE',
    DELETE_RAIL_AND_MAKE: 'DELETE_RAIL_AND_MAKE',
    DELETE_SYSTEM_OUTPUT: 'DELETE_SYSTEM_OUTPUT',
    DESCENDING: 'DESCENDING',
    DEVICE_JUNCTION_TEMPERATURE: 'DEVICE_JUNCTION_TEMPERATURE',
    DIAGRAM: 'DIAGRAM',
    DIGITAL: 'DIGITAL',
    DIGITAL_INTEGRATED_FET: 'DIGITAL_INTEGRATED_FET',
    DIGITAL_MODULE: 'DIGITAL_MODULE',
    DISMISS: 'DISMISS',
    DONE: 'DONE',
    DONE_2: 'DONE_2',
    DONE_REFERENCE_DESIGN_FILES: 'DONE_REFERENCE_DESIGN_FILES',
    DONT_ASK_ME_AGAIN: 'DONT_ASK_ME_AGAIN',
    DOWNLOAD_ZIP_FILE: 'DOWNLOAD_ZIP_FILE',
    DRAG_A_FILE_HERE: 'DRAG_A_FILE_HERE',
    DUPLICATE_PMBUS: 'DUPLICATE_PMBUS',
    EDIT: 'EDIT',
    EDIT_DESIGN: 'EDIT_DESIGN',
    EDIT_REGULATED_OUTPUT: 'EDIT_REGULATED_OUTPUT',
    EDIT_SYSTEM_INPUT_RAIL: 'EDIT_SYSTEM_INPUT_RAIL',
    EFF_INPUT_POWER_NEEDED: 'EFF_INPUT_POWER_NEEDED',
    EFFICIENCY: 'EFFICIENCY',
    EFFICIENCY_SUMMARY: 'EFFICIENCY_SUMMARY',
    EMAIL_ADDRESS: 'EMAIL_ADDRESS',
    EMAIL_ZIP_FILE: 'EMAIL_ZIP_FILE',
    ENABLE_PIN_INPUT: 'ENABLE_PIN_INPUT',
    ENLARGE: 'ENLARGE',
    ENTER_A_NEW_UNIQUE: 'ENTER_A_NEW_UNIQUE',
    ENTER_A_UNIQUE_NAME: 'ENTER_A_UNIQUE_NAME',
    ERROR_EMAILING_ZIP_FILE: 'ERROR_EMAILING_ZIP_FILE',
    ERROR_YOUR_REFERENCE_DESIGN: 'ERROR_YOUR_REFERENCE_DESIGN',
    EST_EFF: 'EST_EFF',
    EST_EFFICIENCY: 'EST_EFFICIENCY',
    ESTIMATED_EFFICIENCY_AT_MAXIMUM: 'ESTIMATED_EFFICIENCY_AT_MAXIMUM',
    EXPAND: 'EXPAND',
    EXPAND_ALL: 'EXPAND_ALL',
    EXPORT: 'EXPORT',
    FEEDS_SECOND_REGULATOR: 'FEEDS_SECOND_REGULATOR',
    FIND_SOLUTIONS: 'FIND_SOLUTIONS',
    GENERATE_REFERENCE_DESIGN: 'GENERATE_REFERENCE_DESIGN',
    GENERATING: 'GENERATING',
    HELP: 'HELP',
    HIDE_FILTERS: 'HIDE_FILTERS',
    HIDE_SETTINGS: 'HIDE_SETTINGS',
    HOW_TO_USE_OFFLINE: 'HOW_TO_USE_OFFLINE',
    IN_PROGRESS_COMPARE_AND: 'IN_PROGRESS_COMPARE_AND',
    IN_PROGRESS_INPUTS_AND: 'IN_PROGRESS_INPUTS_AND',
    IN_PROGRESS_NAMING_THE: 'IN_PROGRESS_NAMING_THE',
    IN_PROGRESS_REVIEWING_MATCHED: 'IN_PROGRESS_REVIEWING_MATCHED',
    INPUT: 'INPUT',
    INPUT_NAME: 'INPUT_NAME',
    INPUTS_AND_OUTPUTS: 'INPUTS_AND_OUTPUTS',
    INVALID_EMAIL_ADDRESS: 'INVALID_EMAIL_ADDRESS',
    INVALID_MULTIPLE_OUTPUT: 'INVALID_MULTIPLE_OUTPUT',
    IQ: 'IQ',
    ISIM_SIMULATION_AVAILABLE: 'ISIM_SIMULATION_AVAILABLE',
    J_MAX: 'J_MAX',
    JUNCTION_TEMPERATURE_C: 'JUNCTION_TEMPERATURE_C',
    KEEP_BOTH_BY_SAVING: 'KEEP_BOTH_BY_SAVING',
    KEEP_THE_OFFLINE_VERSION: 'KEEP_THE_OFFLINE_VERSION',
    KEEP_THE_ONLINE_VERSION: 'KEEP_THE_ONLINE_VERSION',
    LAST_MODIFIED: 'LAST_MODIFIED',
    LAST_SAVED: 'LAST_SAVED',
    LDO: 'LDO',
    LEARN_MORE: 'LEARN_MORE',
    LIGHT_LOAD: 'LIGHT_LOAD',
    LIGHT_LOAD_EFFICIENCY: 'LIGHT_LOAD_EFFICIENCY',
    LIMIT_PARTS_BASED_ON: 'LIMIT_PARTS_BASED_ON',
    LIMIT_PARTS_BASED_ON_THE: 'LIMIT_PARTS_BASED_ON_THE',
    LIMIT_RESULTS: 'LIMIT_RESULTS',
    LIMIT_THE_TYPES_OF: 'LIMIT_THE_TYPES_OF',
    LL_EFF: 'LL_EFF',
    LOAD_CURRENT: 'LOAD_CURRENT',
    LOAD_CURRENT_OF_MAX: 'LOAD_CURRENT_OF_MAX',
    LOADING: 'LOADING',
    LOADING_DESIGN_FILES: 'LOADING_DESIGN_FILES',
    LOADINGJUST_A_MOMENT_PLEASE: 'LOADINGJUST_A_MOMENT_PLEASE',
    MAKE_DESIRED_EDITS_AND: 'MAKE_DESIRED_EDITS_AND',
    MATCHES: 'MATCHES',
    MAX_AMPS: 'MAX_AMPS',
    MAX_LOAD: 'MAX_LOAD',
    MAX_LOAD_EFFICIENCY: 'MAX_LOAD_EFFICIENCY',
    MAX_POWER_DISSIPATION: 'MAX_POWER_DISSIPATION',
    ML_EFF: 'ML_EFF',
    MODE: 'MODE',
    MORE: 'MORE',
    MORE_OPTIONS: 'MORE_OPTIONS',
    NEW_PROJECT: 'NEW_PROJECT',
    NEXT: 'NEXT',
    NO: 'NO',
    NO_CANCEL: 'NO_CANCEL',
    NO_SCHEMATIC_ENABLED_PARTS: 'NO_SCHEMATIC_ENABLED_PARTS',
    NO_SOLUTIONS_FOUND: 'NO_SOLUTIONS_FOUND',
    NOT_AECQ_QUALIFID: 'NOT_AECQ_QUALIFID',
    OF: 'OF',
    OF_SELECTED_PARTS_ARE: 'OF_SELECTED_PARTS_ARE',
    OFFLINE: 'OFFLINE',
    ONCE_YOU_GENERATE_A: 'ONCE_YOU_GENERATE_A',
    ONCE_YOU_SAVE_THE: 'ONCE_YOU_SAVE_THE',
    ONE_OR_MORE_SELECTED: 'ONE_OR_MORE_SELECTED',
    ONLINE: 'ONLINE',
    ONLINE_PROJECTS_ARE_NOT: 'ONLINE_PROJECTS_ARE_NOT',
    ONLY_ALPHA_NUMERIC_AZ: 'ONLY_ALPHA_NUMERIC_AZ',
    ONLY_ALTERA_XLSCSV_AND: 'ONLY_ALTERA_XLSCSV_AND',
    OPEN_SAVED_PROJECT: 'OPEN_SAVED_PROJECT',
    OPENING_YOUR_PROJECT: 'OPENING_YOUR_PROJECT',
    OPTIMIZE: 'OPTIMIZE',
    OPTIMIZE_TO_GROUP_AND: 'OPTIMIZE_TO_GROUP_AND',
    OPTIONAL: 'OPTIONAL',
    OR_REGISTER: 'OR_REGISTER',
    ORDER_RESULTS_BY: 'ORDER_RESULTS_BY',
    OTHER: 'OTHER',
    OUTPUT: 'OUTPUT',
    OUTPUT_NAME: 'OUTPUT_NAME',
    OUTPUT_PARTS_MAY_HAVE: 'OUTPUT_PARTS_MAY_HAVE',
    OUTPUTS_HAVE_SELECTED_PARTS: 'OUTPUTS_HAVE_SELECTED_PARTS',
    PACKAGE_SIZE: 'PACKAGE_SIZE',
    PART_DESCRIPTION: 'PART_DESCRIPTION',
    PART_SELECTIONS_FOR_THE: 'PART_SELECTIONS_FOR_THE',
    PARTS_DESCRIPTION: 'PARTS_DESCRIPTION',
    PARTS_SCHEMATIC_ENABLED_ICON_1: 'PARTS_SCHEMATIC_ENABLED_ICON_1',
    PARTS_SCHEMATIC_ENABLED_ICON_2: 'PARTS_SCHEMATIC_ENABLED_ICON_2',
    PARTS_SELECTED: 'PARTS_SELECTED',
    PER_TOPOLOGY: 'PER_TOPOLOGY',
    PLEASE_SELECT_A_DUAL: 'PLEASE_SELECT_A_DUAL',
    PLEASE_SELECT_A_COMBO: 'PLEASE_SELECT_A_COMBO',
    PLEASE_UNIQUE_PMBUS: 'PLEASE_UNIQUE_PMBUS',
    PLEASE_SIGN_IN_TO: 'PLEASE_SIGN_IN_TO',
    PLEASE_SPECIFY_AN_EMAIL: 'PLEASE_SPECIFY_AN_EMAIL',
    PLEASE_USE_CHROME_OR: 'PLEASE_USE_CHROME_OR',
    PMBUS_ADDRESS: 'PMBUS_ADDRESS',
    POWER_COMPASS_IS_CURRENTLY: 'POWER_COMPASS_IS_CURRENTLY',
    POWER_DISSIPATION_VS_LOAD: 'POWER_DISSIPATION_VS_LOAD',
    POWER_DISSIPATION_W: 'POWER_DISSIPATION_W',
    POWER_GOOD_OUTPUT: 'POWER_GOOD_OUTPUT',
    POWERCOMPASS_MULTILOAD_CONFIGURATOR: 'POWERCOMPASS_MULTILOAD_CONFIGURATOR',
    PREPARING_FOR_OFFLINE: 'PREPARING_FOR_OFFLINE',
    PRICE: 'PRICE',
    PROJECT_NAME: 'PROJECT_NAME',
    PROJECT_SYNC_CONFLICT: 'PROJECT_SYNC_CONFLICT',
    PROJECTS: 'PROJECTS',
    PROJECTS_COMPLETED: 'PROJECTS_COMPLETED',
    PROJECTS_IN_PROGRESS: 'PROJECTS_IN_PROGRESS',
    QUEUEING_UP_YOUR_REFERENCE: 'QUEUEING_UP_YOUR_REFERENCE',
    RAIL: 'RAIL',
    RAIL_NAME: 'RAIL_NAME',
    READY_FOR_OFFLINE: 'READY_FOR_OFFLINE',
    READY_TO_GENERATE_REFERENCE: 'READY_TO_GENERATE_REFERENCE',
    RECOMMENDED_SOLUTION_NOTES: 'RECOMMENDED_SOLUTION_NOTES',
    REFERENCE_DESIGN: 'REFERENCE_DESIGN',
    REFERENCE_DESIGN_GENERATING_PLEASE: 'REFERENCE_DESIGN_GENERATING_PLEASE',
    REFRESH_OFFLINE_DATA: 'REFRESH_OFFLINE_DATA',
    REFRESH_THE_POWERCOMPASS_WEB: 'REFRESH_THE_POWERCOMPASS_WEB',
    REGISTER: 'REGISTER',
    REGISTRATION_IS_SECURE_FAST: 'REGISTRATION_IS_SECURE_FAST',
    REGULATED_OUTPUTS: 'REGULATED_OUTPUTS',
    RELEASE_DATE: 'RELEASE_DATE',
    RENAME: 'RENAME',
    RENAME_PROJECT: 'RENAME_PROJECT',
    REQUIRED: 'REQUIRED',
    RESOLVE: 'RESOLVE',
    RESTORE_DEFAULTS: 'RESTORE_DEFAULTS',
    RETRY: 'RETRY',
    REVIEW_PROJECT_ISSUES: 'REVIEW_PROJECT_ISSUES',
    SAMPLES_AVAILABLE: 'SAMPLES_AVAILABLE',
    SAVE: 'SAVE',
    SAVE_THIS_PROJECT: 'SAVE_THIS_PROJECT',
    SCHEMATIC_AVAILABLE: 'SCHEMATIC_AVAILABLE',
    SELECT: 'SELECT',
    SELECT_A_BLOCK_TO: 'SELECT_A_BLOCK_TO',
    SELECT_A_PART_NUMBER: 'SELECT_A_PART_NUMBER',
    SELECT_A_PLATFORM: 'SELECT_A_PLATFORM',
    SELECT_DEVICE: 'SELECT_DEVICE',
    SELECT_DEVICES_FOR_THE: 'SELECT_DEVICES_FOR_THE',
    SELECT_ONE: 'SELECT_ONE',
    SELECT_PARTS_YOU_MIGHT: 'SELECT_PARTS_YOU_MIGHT',
    SELECT_VENDOR: 'SELECT_VENDOR',
    SELECTED_TEMPLATE: 'SELECTED_TEMPLATE',
    SEND: 'SEND',
    SEND_2: 'SEND_2',
    SEND_PROJECT: 'SEND_PROJECT',
    SEND_THE_PROJECT: 'SEND_THE_PROJECT',
    SET_SYNCHRONIZATION_REQUIREMENTS_FOR: 'SET_SYNCHRONIZATION_REQUIREMENTS_FOR',
    SHOW: 'SHOW',
    SHOW_ALL: 'SHOW_ALL',
    SHOW_FILTERS: 'SHOW_FILTERS',
    SHOW_SETTINGS: 'SHOW_SETTINGS',
    SIGN_IN: 'SIGN_IN',
    SILICON_COST: 'SILICON_COST',
    SOLUTION_TYPES: 'SOLUTION_TYPES',
    SOURCE_RAIL: 'SOURCE_RAIL',
    SOURCE_RAIL_2: 'SOURCE_RAIL_2',
    START_NEW_PROJECT: 'START_NEW_PROJECT',
    START_NEW_WITH_TEMPLATE: 'START_NEW_WITH_TEMPLATE',
    STARTING_A_NEW_PROJECT: 'STARTING_A_NEW_PROJECT',
    STATUS: 'STATUS',
    SUGGESTED_PARTS: 'SUGGESTED_PARTS',
    SUM_POWER_LOSS: 'SUM_POWER_LOSS',
    SUPPLY_SEQUENCE: 'SUPPLY_SEQUENCE',
    SUPPLY_SEQUENCE_CAN_NOT: 'SUPPLY_SEQUENCE_CAN_NOT',
    SWITCH_TO_OFFLINE_MODE: 'SWITCH_TO_OFFLINE_MODE',
    SWITCH_TO_ONLINE_MODE: 'SWITCH_TO_ONLINE_MODE',
    SYNC: 'SYNC',
    SYNC_CONFLICT: 'SYNC_CONFLICT',
    SYNC_FAILED: 'SYNC_FAILED',
    SYNC_IN: 'SYNC_IN',
    SYNC_IN_OUT: 'SYNC_IN_OUT',
    SYNC_INPUT: 'SYNC_INPUT',
    SYNC_OUTPUT: 'SYNC_OUTPUT',
    SYNCING: 'SYNCING',
    SYNCING_COMPLETE: 'SYNCING_COMPLETE',
    SYNCING_PROJECTS_FAILED: 'SYNCING_PROJECTS_FAILED',
    SYSTEM_EFFICIENCY_LOAD_STEP: 'SYSTEM_EFFICIENCY_LOAD_STEP',
    SYSTEM_INPUT_RAILS: 'SYSTEM_INPUT_RAILS',
    SYSTEM_POWER_DISSIPATION: 'SYSTEM_POWER_DISSIPATION',
    TABLE: 'TABLE',
    THE_DEVICE_NAMES_OF: 'THE_DEVICE_NAMES_OF',
    THE_FOLLOWING_PARTS_ARE: 'THE_FOLLOWING_PARTS_ARE',
    THE_POWERCOMPASS_TOOL_HELPS: 'THE_POWERCOMPASS_TOOL_HELPS',
    THERE_ARE_NO_COMPLETED: 'THERE_ARE_NO_COMPLETED',
    THERE_ARE_NO_PROJECTS: 'THERE_ARE_NO_PROJECTS',
    THIS_PART_IS_SCHEMATIC: 'THIS_PART_IS_SCHEMATIC',
    TL_EFF: 'TL_EFF',
    TO_ACCESS_YOUR_FILES: 'TO_ACCESS_YOUR_FILES',
    TO_ACCESS_YOUR_FILES_PLEASE: 'TO_ACCESS_YOUR_FILES_PLEASE',
    TO_DELETE_THIS_RAIL: 'TO_DELETE_THIS_RAIL',
    TOTAL_COST: 'TOTAL_COST',
    TYPICAL_LOAD: 'TYPICAL_LOAD',
    TYPICAL_LOAD_EFFICIENCY: 'TYPICAL_LOAD_EFFICIENCY',
    UNDO: 'UNDO',
    UNSELECT_HIDDEN_PARTS: 'UNSELECT_HIDDEN_PARTS',
    VIEW_DETAILS: 'VIEW_DETAILS',
    VOLTS: 'VOLTS',
    VOLTS_MAX: 'VOLTS_MAX',
    WAITING: 'WAITING',
    WATTS: 'WATTS',
    WE_ARE_GENERATING_YOUR: 'WE_ARE_GENERATING_YOUR',
    WHATS_OFFLINE_MODE: 'WHATS_OFFLINE_MODE',
    XILINX_XML_WARNING: "XILINX_XML_WARNING",
    YES: 'YES',
    YES_DELETE: 'YES_DELETE',
    YOU_ARE_CURRENTLY_BEHIND: 'YOU_ARE_CURRENTLY_BEHIND',
    YOU_ARE_IN_OFFLINE: 'YOU_ARE_IN_OFFLINE',
    YOU_CAN_SAVE_THE: 'YOU_CAN_SAVE_THE',
    YOU_CAN_SEE_AND: 'YOU_CAN_SEE_AND',
    YOU_CAN_SEND_A: 'YOU_CAN_SEND_A',
    YOU_CAN_SWITCH_TO: 'YOU_CAN_SWITCH_TO',
    YOU_CAN_UPLOAD_A: 'YOU_CAN_UPLOAD_A',
    YOU_COULD_ALSO_CHOOSE: 'YOU_COULD_ALSO_CHOOSE',
    YOU_HAVE_NOT_SAVED: 'YOU_HAVE_NOT_SAVED',
    YOU_MUST_BE_ONLINE: 'YOU_MUST_BE_ONLINE',
    YOU_MUST_BE_ONLINE_TO: 'YOU_MUST_BE_ONLINE_TO',
    YOU_WILL_BE_ASKED: 'YOU_WILL_BE_ASKED',
    YOUR_DESIGN_IS_DONE: 'YOUR_DESIGN_IS_DONE',
    YOUR_DESIGN_IS_NOW: 'YOUR_DESIGN_IS_NOW',
    YOUR_REFERENCE_DESIGN_ENCOUNTERED: 'YOUR_REFERENCE_DESIGN_ENCOUNTERED',
    YOUR_SAVED_PROJECTS: 'YOUR_SAVED_PROJECTS'
};
