import {NUM_SAMPLES_PER_CURVE} from './constants';
import {defaultCurve, scatterize, getReferenceCurvesForOutputAndPart} from './curveUtils';
import find from 'lodash/find';
import pickBy from 'lodash/pickBy';
import keys from 'lodash/keys';
import isArray from 'lodash/isArray';
import reject from 'lodash/reject';

function calculateOutputValues(output, cascadedOutputs) {
    const eff = output.efficiency;
    const vIn = output.vIn;
    const vOut = output.vOut;

    let iOut = 0;

    if (cascadedOutputs[output.id]) {
        iOut = cascadedOutputs[output.id].reduce((acc, child) => {
            return acc + calculateOutputValues(child, cascadedOutputs).iIn;
        }, 0);
    }
    else {
        iOut = output.iOut;
    }

    const pOut = vOut * iOut;
    const pIn = eff === 0 ? 0 : pOut / eff;
    const iIn = vIn === 0 ? 0 : pIn / vIn;

    return {
        vIn,
        vOut,
        iOut,
        iIn
    };
}

export function computeSystemEfficiencyPoint(primaryOutputs, childOutputs, cascadedOutputs) {
    const pInTotal = primaryOutputs.reduce((pInAcc, output) => {
        const iOut = calculateOutputValues(output, cascadedOutputs).iOut;
        const vOut = output.vOut;
        const pOut = iOut * vOut;

        const pIn = output.efficiency === 0 ? 0 : pOut / output.efficiency;

        return pInAcc + pIn;
    }, 0);

    const pOutTotal = childOutputs.reduce((pOutAcc, output) => {
        const iOut = calculateOutputValues(output, cascadedOutputs).iOut;
        const vOut = output.vOut;
        const pOut = iOut * vOut;

        return pOutAcc + pOut;
    }, 0);

    return pInTotal === 0 ? 0 : pOutTotal / pInTotal;
}

function computeNewSystemLoadEfficiency(efficiencies, loads, outputs, fieldName) {
    const outputIds = keys(efficiencies);

    let cascadedOutputs = {};
    let primaryOutputs = [];
    let childOutputs = [];

    outputIds.forEach((outputId) => {
        const efficiency = efficiencies[outputId][fieldName];
        const load = loads[outputId][fieldName];
        if ((efficiency != null) && (efficiency > 0)) {
            const output = find(outputs, (x) => x.id === outputId);

            const vOut = output.volts;
            const vIn = output.vinMax;

            const simpleOutput = {
                id: output.id,
                efficiency,
                iOut: load,
                vOut,
                vIn
            };

            if (output.feeds && !output.isFed) {
                primaryOutputs.push(simpleOutput);
            }
            else if (!output.feeds && output.isFed) {
                childOutputs.push(simpleOutput);
            }
            else if (!output.feeds && !output.isFed) {
                primaryOutputs.push(simpleOutput);
                childOutputs.push(simpleOutput);
            }

            if (output.feeds && !cascadedOutputs[outputId]) {
                cascadedOutputs[outputId] = [];
            }

            if (output.isFed) {
                const sourceOutput = find(outputs, o => o.feeds && o.id === output.sourceRail);
                const { id } = sourceOutput;

                if (!cascadedOutputs[id]) {
                    cascadedOutputs[id] = [];
                }

                cascadedOutputs[id].push(simpleOutput);
            }
        }
    });

    return computeSystemEfficiencyPoint(primaryOutputs, childOutputs, cascadedOutputs);
}

export function computeNewSystemLoadEfficiencyWhereNotNull(efficiencies, loads, outputs, fieldName) {
    const efficienciesWithValidData = pickBy(efficiencies, (x) => x[fieldName] != null);
    const numOutputsWithValidData = keys(efficienciesWithValidData).length;
    if (numOutputsWithValidData === 0) return 0;
    return computeNewSystemLoadEfficiency(efficienciesWithValidData, loads, outputs, fieldName);
}

export function computeSystemEfficiencyCurve(outputs, selectedEffCurves, emptyCurve, computePointFn) {
    if (isArray(selectedEffCurves) &&
        ((selectedEffCurves.length === 0) ||
        (selectedEffCurves[0].y === null))) return emptyCurve;
    const curve = {x: [...emptyCurve.x], y: [...emptyCurve.y]};
    const numSamples = selectedEffCurves[0].x.length;
    for (let i = 0; i < numSamples; i++) {
        let primaryOutputs = [];
        let childOutputs = [];
        let cascadedOutputs = {};

        for (let j = 0; j < selectedEffCurves.length; j++) {
            const refCurve = selectedEffCurves[j];

            if (refCurve.x[i] != null && refCurve.y[i] != null) {
                const { x, y, output } = refCurve;
                const efficiency = y[i];
                const load = x[i];

                const vOut = output.volts;
                const vIn = output.vinMax;

                const simpleOutput = {
                    id: output.id,
                    efficiency,
                    iOut: load,
                    vOut,
                    vIn
                };

                if (output.feeds && !output.isFed) {
                    primaryOutputs.push(simpleOutput);
                }
                else if (!output.feeds && output.isFed) {
                    childOutputs.push(simpleOutput);
                }
                else if (!output.feeds && !output.isFed) {
                    primaryOutputs.push(simpleOutput);
                    childOutputs.push(simpleOutput);
                }

                if (output.feeds && !cascadedOutputs[output.id]) {
                    cascadedOutputs[output.id] = [];
                }

                if (output.isFed) {
                    const sourceOutput = find(outputs, o => o.feeds && o.id === output.sourceRail);
                    const { id } = sourceOutput;

                    if (!cascadedOutputs[id]) {
                        cascadedOutputs[id] = [];
                    }

                    cascadedOutputs[id].push(simpleOutput);
                }
            }
        }

        curve.y[i] = computePointFn(primaryOutputs, childOutputs, cascadedOutputs);
    }

    return curve;
}

export function computeSystemEfficiencyCurveWhereNotNull(selectedPartIds, computedData, outputs) {
    let selectedEffCurves = outputs.map(function(output) {
        const refCurves = getReferenceCurvesForOutputAndPart(output.id, selectedPartIds[output.id], computedData);
        if ((refCurves == null) || (refCurves.efficiency == null)) {
            return null;
        }
        return {
            output,
            ...refCurves.efficiency
        };
    });
    selectedEffCurves = reject(selectedEffCurves, (x) => x === null);

    const curve = computeSystemEfficiencyCurve(outputs, selectedEffCurves,
        defaultCurve(NUM_SAMPLES_PER_CURVE), computeSystemEfficiencyPoint);

    return scatterize(curve);
}

export function computeSystemPowerDissipationData(selectedPartIds, computedData) {
    const curve = computedData.reduce(function(system, data, idx) {
        const refCurves = getReferenceCurvesForOutputAndPart(data.id, selectedPartIds[data.id], computedData);
        if ((refCurves != null) && (refCurves.powerDissipation != null)) {
            const powerCurve = refCurves.powerDissipation;
            system.y = powerCurve.y.map((y, idx) => y+system.y[idx]);
        }
        return system;
    }, defaultCurve(NUM_SAMPLES_PER_CURVE));
    const maxPowerDissipation = curve.y.reduce((a, x) => Math.max(x, a), 0);
    return { systemPowerDissipation: scatterize(curve), maxPowerDissipation };
}

export function doSelectedPartshavePartialEfficiencyData(computedData, selectedPartIds) {
    const flags = computedData.map((output) => {
        if (output.parts.length === 0) return false;
        if (Object.keys(selectedPartIds).length === 0) return false;

        const selectedPartId = selectedPartIds[output.id];
        if (!selectedPartId) return false;

        const selectedPart = find(output.parts, (part) => (part.id === selectedPartId));
        const effCurve = selectedPart.referenceCurves.efficiency;
        if (effCurve === null) {
            return true;
        }

        const hasANullSample = effCurve.y.reduce((partial, value) => partial || (value === null), false);
        if (hasANullSample) {
            return true;
        }

        return false;
    });

    return flags.reduce((partial, flag) => partial || flag, false);
}
