import {DEFAULT_AMBIENT_TEMP} from "logic/constants";
import {calcLdoEfficiency, estimateEfficiencyFromCurves, getDataPoints} from "logic/efficiency";
import {Topology} from 'logic/suggestTopology';

function estimateEfficiencyForSuggestions(results, efficiencyDb) {
    return results.map((output) => {
        if (__DEV__) {
            if ((output['numOutputs'] === undefined) ||
                (output['vinMin'] === undefined) ||
                (output['vinMax'] === undefined) ||
                (output['outputs'] === undefined) ||
                (output.outputs[0] === undefined) ||
                (output.outputs[0].voutMin === undefined) ||
                (output.outputs[0].voutMax === undefined) ||
                (output.outputs[0].ioutMax === undefined) ||
                (output.items === undefined)) {
                throw new Error('addEstEfficiency, output does not have required fields', output);
            }
        }

        // we are going to reassign the output's output electrical parameters
        // so we need to be sure we are not changing the original
        let referenceObj = _.cloneDeep(_.omit(output, 'items'));
        referenceObj.ta = DEFAULT_AMBIENT_TEMP;

        // todo obvious refactor
        _.forOwn(output.items, (parts, topology) => {
            parts.forEach(part => {
                let calcEfficiencyFn;

                if (topology === Topology.LINEAR_REGULATOR) {
                    calcEfficiencyFn = calcLdoEfficiency;
                }
                else {
                    referenceObj.numDevicesinSolution = part.numDevicesinSolution;
                    const curves = getDataPoints(efficiencyDb, part.deviceName, referenceObj.numOutputs);

                    if (!curves) {
                        part.estEfficiency = part.estEfficiency === 0 ? null : part.estEfficiency;

                        return;
                    }

                    calcEfficiencyFn = referenceObj => estimateEfficiencyFromCurves(referenceObj, curves);
                }

                const referenceOutputs = referenceObj.outputs;
                let totalEfficiency = 0;
                let efficiencyCount = 0;

                referenceOutputs.forEach((output, idx) => {
                    let otherOutputs = [...referenceObj.outputs];

                    otherOutputs.splice(idx, 1);

                    referenceObj.outputs = [output, ...otherOutputs];

                    const efficiency = calcEfficiencyFn(referenceObj);

                    if (efficiency) {
                        totalEfficiency += efficiency;
                        efficiencyCount++;
                    }
                });

                part.estEfficiency = totalEfficiency / efficiencyCount;
            });
        });

        return output;
    });
}

export default estimateEfficiencyForSuggestions;
