import PropTypes from 'prop-types';
import React from 'react';
import SwitchOption from 'components/SwitchOption';
import css from './styles.less';
import classNames from 'classnames';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class ColumnSettingsItem extends React.Component {
    static propTypes = {
        checked: PropTypes.bool,
        onChange: PropTypes.func,
        className: PropTypes.string
    };

    onChange = (e) => {
        this.props.onChange(e.checked);
    };

    render() {
        return (<div className={classNames(css.columnSettingsItem, this.props.className)}>
            <span className={css.title}>{getText(contentKeys.COLUMN_SETTINGS)}</span>
            <table>
                <thead>
                    <tr>
                        <th className="settingsTableLabel">{getText(contentKeys.SHOW)}</th>
                        <th className="settingsTableSpacer"></th>
                        <th>
                            {
                                getText(contentKeys.NO)
                            }&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{
                                getText(contentKeys.YES)
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <SwitchOption
                        title={getText(contentKeys.PRICE)+' (MSRP)'}
                        enabled={true}
                        checked={this.props.checked}
                        change={this.onChange} />
                </tbody>
            </table>
        </div>);
    };
}

export default ColumnSettingsItem;
