import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import TemplateSelector from './TemplateSelector';
import classNames from 'classnames';
import VendorFileDropZone from './VendorFileDropZone';
import KendoTooltipOverride from 'kendo-plugins/TooltipWrapper';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const noOp = () => {};

class SelectProjectTemplate extends React.Component {
    static propTypes = {
        selected: PropTypes.bool.isRequired,
        onSelect: PropTypes.func.isRequired
    };

    componentDidMount() {
        KendoTooltipOverride($('#selectTemplateTooltip'),
            getText(contentKeys.YOU_CAN_UPLOAD_A),
            null,
            'bottom');
    }

    selectIfNotSelected = (e) => {
        if (!this.props.selected) {
            this.props.onSelect(e);
            document.getElementById('StartSelect_ContinueBtn').scrollIntoView();
        }
    };

    render() {
        const checked = (this.props.selected) ? "checked" : "";
        const cursorPointer = (!this.props.selected) ? css.cursorPointer : "";
        const className = classNames(css.selectProjectTemplate, cursorPointer);

        return (
            <div className={className} onClick={this.selectIfNotSelected} id="selectProjectTemplate">
                <input type="radio"
                    className="k-radio"
                    name="startOptions"
                    value="selectProjectTemplate"
                    checked={checked}
                    onChange={noOp}
                />
                <label className="k-radio-label">
                    &nbsp;&nbsp;{getText(contentKeys.START_NEW_WITH_TEMPLATE)}{' '}
                    <span id="selectTemplateTooltip" className="fa fa-info-circle" />
                </label>
                <VendorFileDropZone />
                <TemplateSelector disabled={!this.props.selected} />
            </div>
        );
    }
}

export default SelectProjectTemplate;
