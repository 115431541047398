import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import BufferedInput, { filterAsNumber } from '../BufferedInput';
import classNames from 'classnames';
import * as breakpoints from 'styles/variables/breakpoints';
import MediaQuery from 'react-responsive';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class LimitPerTopology extends React.Component {
    static propTypes = {
        limit: PropTypes.number.isRequired,
        onLimit: PropTypes.func.isRequired,
        className: PropTypes.string
    };

    applyLimit = (val) => {
        if (parseInt(val) !== 0) {
            this.props.onLimit({limit: parseInt(val)});
        }
    };

    render() {
        return (
            <div className={classNames(css.limitItem, this.props.className)}>
                <span className={css.title}>{getText(contentKeys.LIMIT_RESULTS)}</span>
                <div className="topologyInputContainer">
                    {getText(contentKeys.PER_TOPOLOGY)}
                    <MediaQuery maxWidth={breakpoints.tabletMax}>
                        {(matches) => {
                            return (
                                <BufferedInput
                                    autoCommit={matches}
                                    className={css.limitInput}
                                    dataTest="limit"
                                    value={this.props.limit}
                                    filter={filterAsNumber}
                                    onBufferedChange={this.applyLimit} />
                            );
                        }}
                    </MediaQuery>
                </div>
            </div>);
    }
}

export default LimitPerTopology;
