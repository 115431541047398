import PropTypes from 'prop-types';
import React from 'react';
import databind from 'redux/utils/databind';
import {actions as headerActions} from 'redux/modules/header';
import {actions as referenceDesignActions} from 'redux/modules/referenceDesign';
import {actions as projectActions} from 'redux/modules/projects';
import {actions as authActions} from 'redux/modules/auth';
import DocumentTitle from 'components/DocumentTitle';
import JobStatus from 'components/ReferenceDesign/JobStatus';
import FileListing from 'components/ReferenceDesign/FileListing';
import ProjectStatus from "../../logic/status";
import makeSignInUrl from 'util/makeSignInUrl';

class ReferenceDesignView extends React.Component {
    static propTypes = {
        params: PropTypes.shape({
            generateStep: PropTypes.string,
            designId: PropTypes.string
        }).isRequired,

        // from databind
        generateReferenceDesign: PropTypes.func.isRequired,
        retryRefDesign: PropTypes.func.isRequired,
        toggleHeaderLinks: PropTypes.func.isRequired,
        toggleProjectHeader: PropTypes.func.isRequired,
        setLinkVisibility: PropTypes.func.isRequired,
        fetchFiles: PropTypes.func.isRequired,
        fetchJobStatus: PropTypes.func.isRequired,
        fetchAuthStatus: PropTypes.func.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        status: PropTypes.string.isRequired,
        waitTime: PropTypes.number,
        queuePosition: PropTypes.number,
        requested: PropTypes.bool.isRequired,
        error: PropTypes.bool.isRequired,
        errorMessage: PropTypes.string,
        fileList: PropTypes.arrayOf(PropTypes.object),
        designId: PropTypes.string,
        isEmailLink: PropTypes.bool,
        projectStatus: PropTypes.string.isRequired,
        projects: PropTypes.array.isRequired,
        sendProject: PropTypes.func.isRequired,
        zipfile: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        excludedParts: PropTypes.arrayOf(PropTypes.object),
        emailZipModalShown: PropTypes.bool.isRequired,
        showEmailZipModal: PropTypes.func.isRequired,
        closeEmailZipModal: PropTypes.func.isRequired,
        emailError: PropTypes.bool.isRequired,
        sendingEmail: PropTypes.bool.isRequired,
        emailZipFile: PropTypes.func.isRequired,
        resetModule: PropTypes.func.isRequired
    };

    static databind({referenceDesign, header, auth, projects}, props) {
        // intersil.com signin adds a .html to redirect url when logging in first time
        const generateStep = props.params.generateStep.replace('.html', '');

        // designId is an optional route param, when its not there we set it to the generateStep string
        // this is a HACK so that we can handle direct links back from the email users are sent, which does not include
        // a generate step. If there is no projectId but the generateStep is "new", then we haven't gotten a project ID
        // back from the server yet and the value should therefore be undefined.
        let designId;
        let isEmailLink = false;

        if (props.params.designId) {
            designId = props.params.designId.replace('.html', '');
        }
        else if (!props.params.designId && generateStep !== 'new') {
            designId = generateStep;

            // In this situation we have come from the /reference/<designId> route, which is only used in email
            // notifications for completed designs. We use this later on to skip the status check
            isEmailLink = true;
        }

        return { ...referenceDesign,
            isSignedIn: auth.isSignedIn,
            checkedAuthStatus: auth.checked,
            isEmailLink,
            designId,
            projects: projects.listing,
            projectStatus: projects.current['status'] || '',
            generateStep };
    }

    static actions = {
        toggleHeaderLinks: headerActions.toggleLinks,
        toggleProjectHeader: headerActions.toggleProjectHeader,
        setLinkVisibility: headerActions.setLinkVisibility,
        generateReferenceDesign: referenceDesignActions.startGeneratingReferenceDesign,
        retryRefDesign: referenceDesignActions.retryRefDesign,
        saveNewProject: projectActions.saveNewProject,
        sendProject: projectActions.sendProject,
        fetchJobStatus: referenceDesignActions.fetchStatus,
        fetchAuthStatus: authActions.checkIsSignedInNow,
        fetchFiles: referenceDesignActions.fetchFiles,
        showEmailZipModal: referenceDesignActions.showEmailZipModal,
        closeEmailZipModal: referenceDesignActions.closeEmailZipModal,
        emailZipFile: referenceDesignActions.emailZipFile,
        resetModule: referenceDesignActions.reset
    };

    componentWillMount() {
        this.props.resetModule();
    }

    componentDidMount() {
        this.props.fetchAuthStatus();

        if (this.filesAreReady()) {
            this.props.toggleHeaderLinks(true);
        }

        this.props.setLinkVisibility({
            showSaveProjectLink: false,
            showExportCsvLink: false,
            showBackLink: true,
            showHelpLink: true,
            showYourProjectsLink: true,
            showSendLink: true,
            showOfflineVersionLink: false
        });

        this.props.toggleProjectHeader(true);
    }

    componentWillUpdate(nextProps) {
        if (nextProps.isEmailLink && nextProps.checkedAuthStatus && !nextProps.isSignedIn) {
            const currentUrl = window.location.toString().split('#');
            // Appended a trailing ? to the return url because without it Renesas login dispatcher
            // seems to ignore the redirect url entirely
            const resource = `${currentUrl[0]}#/reference/${nextProps.designId}?`;
            window.location = makeSignInUrl(window.encodeURIComponent(resource));
        }
    }

    filesAreReady = () => {
        const {projectStatus, status, error, isEmailLink} = this.props;

        return !error &&
               (isEmailLink || status === "done" || projectStatus === ProjectStatus.DESIGN_FILE_DOWNLOAD);
    };

    render() {
        const {status, error, ...other} = this.props;

        let content;
        if (this.filesAreReady()) {
            content =
                <FileListing {...other} />;
        }
        else {
            content =
                <JobStatus {...other} status={status} error={error} />;
        }

        return (
            <DocumentTitle title="Reference Design">{content}</DocumentTitle>
        );
    }
}

export default databind(ReferenceDesignView);
