import PropTypes from 'prop-types';
import React from 'react';

export default class WindowSize extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired
    }

    state = {
        width: window.innerWidth,
        height: window.innerHeight
    };

    componentDidMount() {
        window.addEventListener('resize', this.windowResizeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResizeHandler);
    }

    windowResizeHandler = () => {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    render() {
        return this.props.children({ width: this.state.width, height: this.state.height });
    }
}
