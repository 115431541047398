import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export const filterAsName = (str) => {
    const matches = str.toString().match(/^[0-9a-zA-Z,._\-\s]+/);
    if (!matches) return null;
    return matches[0];
};

export const filterAsNumber = (str) => {
    const matches = str.toString().match(/^[0-9]*[.,]*[0-9]*$/);
    if (!matches) return null;
    return matches[0];
};

export const filterAsPercentage = (str) => {
    const matches = str.toString().match(/^([0-9]{0,2})%?$/);
    if (!matches) return null; // triggers reload from state
    if (matches[0] === '') return ''; // allow empty field
    if (matches[1] === '0') return null; // reload from state
    return matches[1];
};

export default class BufferedInput extends React.Component {
    static propTypes= {
        className: PropTypes.string,
        name: PropTypes.string,
        dataTest: PropTypes.string,
        value: PropTypes.any.isRequired,
        onBufferedChange: PropTypes.func.isRequired,
        clearOnFocus: PropTypes.bool,
        filter: PropTypes.func.isRequired,
        format: PropTypes.func, // to format the field when valid, it must be able to handle empty
        autoCommit: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
        this.state = { value: props.value, isDirty: false };
    }

    static defaultProps = {
        format: function(x) { return x; },
        filter: function(x) { return x; },
        autoCommit: false
    };

    componentWillReceiveProps(newProps) {
        if (newProps.value !== this.props.value) {
            this.setState({value: newProps.value, isDirty: false});
        }
    }

    stringIsEqual(filtered) {
        return (filtered === this.props.value.toString());
    }

    onFocus = () => {
        if (this.props.clearOnFocus) {
            this.setState({value: "", isDirty: true});
        }
    };

    onChange = (e) => {
        const filtered = this.props.filter(e.target.value);
        if (filtered !== null) {
            this.setState({value: e.target.value, isDirty: true});
        }
    };

    onKeyUp = (e) => {
        const filtered = this.props.filter(this.state.value);
        if ((e.keyCode === 9) || (e.keyCode === 13) || (this.props.autoCommit && filtered !== '')) {
            if ((!filtered) || (this.stringIsEqual(filtered))) {
                this.setState({value: this.props.value, isDirty: false});
            }
            else {
                this.setState({isDirty: false});
                this.props.onBufferedChange(filtered);
            }
        }
    };

    onBlur = () => {
        const filtered = this.props.filter(this.state.value);
        // if empty onBlur, trigger a re-render with the original props.value
        if ((!filtered) || (this.stringIsEqual(filtered))) { // reset
            this.setState({value: this.props.value, isDirty: false});
        }
        else { // dispatch change
            this.setState({isDirty: false});
            this.props.onBufferedChange(filtered);
        }
    };

    render() {
        const className = classNames("form-control", this.props.className);
        const value = this.state.isDirty ? this.state.value : this.props.format(this.props.value);
        return (
            <input autoComplete="off"
                name={this.props.name}
                type="text"
                data-test={this.props.dataTest}
                className={className}
                value={value}
                onChange={this.onChange}
                onKeyUp={this.onKeyUp}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
            />
        );
    }
}
