import PropTypes from 'prop-types';
import React from 'react';
import databind from 'redux/utils/databind';
import HeaderLink from 'components/HeaderLinks';
import find from 'lodash/find';
import css from './styles.less';
import classNames from 'classnames';
import ProjectName from './ProjectName';
import OfflineStatus from './OfflineStatus';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const Header = (props) => {
    const {showProjectHeader, lastModified, showProjectName} = props;
    const showLastModified = lastModified && showProjectName;

    if (!showProjectHeader) return false;

    const className = classNames(css.header, props.className);

    return (
        <div>
            <div className={className}>
                <div className={css.projectNameContainer}>
                    <ProjectName />
                    {showLastModified && <div className={css.lastModified}>
                        {getText(contentKeys.LAST_MODIFIED)}: {lastModified}
                    </div>}
                    {'serviceWorker' in navigator && <OfflineStatus />}
                </div>
                <div className={classNames(css.noPadding, css.linksWrapper)}>
                    <div className={classNames(css.toolbar, "text-center")}>
                        <HeaderLink enabled={props.enableLinks} location={props.location} />
                    </div>
                </div>
            </div>
        </div>
    );
};

Header.propTypes = {
    className: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
    showProjectHeader: PropTypes.bool.isRequired,
    showProjectName: PropTypes.bool.isRequired,
    enableLinks: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    headerText: PropTypes.string.isRequired
};

Header.databind = ({header, projects, referenceDesign}) => {
    const { current, listing } = projects;
    const { designId } = referenceDesign;
    const updatedAt = current && current['updatedAt'];
    let lastModified, showProjectName;

    if (!updatedAt && designId) {
        const projectForDesign = find(listing, p => p.referenceDesignId === designId);
        const onDesignPage = window.location.hash.indexOf(`#/reference/${designId}`) === 0;

        lastModified = (projectForDesign && projectForDesign.updatedAt) || '';
        showProjectName = (!!projectForDesign && onDesignPage) || header.showProjectName;
    }
    else {
        lastModified = updatedAt || '';
        showProjectName = header.showProjectName;
    }

    return {
        lastModified,
        showProjectHeader: header.showProjectHeader,
        showProjectName,
        enableLinks: header.enableLinks,
        headerText: header.headerText
    };
};

export default databind(Header);
