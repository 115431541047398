import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import databind from 'redux/utils/databind';
import {actions as startOptionsActions} from 'redux/modules/startOptions';
import VendorList from './VendorList';
import PlatformList from './PlatformList';
import PartsList from './PartsList.js';
import DesignNameSelector from './DesignNameSelector';

class TemplateSelector extends React.Component {
    static propTypes = {
        templatesDb: PropTypes.object.isRequired,
        availableDesigns: PropTypes.array.isRequired,
        selectedDesign: PropTypes.string.isRequired,
        setSelectedDesign: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        showPartsAndPlatformLists: PropTypes.bool.isRequired
    };

    static databind = function({ templatesDb, startOptions }) {
        const { availableDesigns, selectedDesign, showPartsAndPlatformLists } = startOptions;
        return { templatesDb, availableDesigns, selectedDesign, showPartsAndPlatformLists };
    };

    static actions = {
        setSelectedDesign: startOptionsActions.setSelectedDesign,
        reset: startOptionsActions.clearStartState
    };

    onChange = (e) => {
        this.props.setSelectedDesign({designName: e.target.htmlFor});
    };

    onDismiss = () => {
        this.props.reset();
    };

    render() {
        const platformsList = (this.props.showPartsAndPlatformLists) ? <PlatformList /> : "";
        const partsList = (this.props.showPartsAndPlatformLists) ? <PartsList /> : "";

        return (
            <div className={css.templateSelector}>
                <VendorList templatesDb={this.props.templatesDb} />
                {platformsList}
                {partsList}
                <DesignNameSelector
                    availableDesigns={this.props.availableDesigns}
                    selectedDesign={this.props.selectedDesign}
                    onChange={this.onChange}
                    onDismiss={this.onDismiss}
                    disabled={this.props.disabled}
                />
            </div>
        );
    };
}

export default databind(TemplateSelector);
