import localforage from 'localforage';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import makeApiUrl from './makeApiUrl';
import {getJson, postJson, deleteJson, putJson} from './ajax';
import {convertUTCToLocal} from 'util/datetime';
import {SERVER_TIMESTAMP_FORMAT} from 'logic/constants';
import {LocalProjectStatus} from 'redux/modules/offline';
import cacheBust from './cacheBust';

const NOT_FOUND_ERROR = 'Project not found';

async function isOffline () {
    const appOffline = window.store ? window.store.getState().offline.isOffline : false;
    const swOffline = await localforage.getItem('swOffline');
    return 'serviceWorker' in navigator &&
           (appOffline || swOffline);
}

async function getLocalProjectIds () {
    return (await localforage.getItem('localProjectIds')) || [];
}

async function getLocalProjects () {
    const localProjectIds = await getLocalProjectIds();

    if (localProjectIds.length === 0) {
        return [];
    }

    const promises = localProjectIds.map(pId => localforage.getItem(`project/${pId}`));
    const projects = await Promise.all(promises);

    return projects.filter(p => p).map(p => ({ ...p, isLocalProject: true }));
}

async function getLocalProjectById (id) {
    const project = await localforage.getItem(`project/${id}`);

    if (project) {
        return project;
    }

    throw new Error(NOT_FOUND_ERROR);
}

async function createLocalProject (data) {
    const localProjectIds = await getLocalProjectIds();
    const newId = `local-${localProjectIds.length}`;
    const updatedAt = moment.utc().format(SERVER_TIMESTAMP_FORMAT);
    const newProject = { ...data, id: newId, updatedAt, syncStatus: LocalProjectStatus.READY_TO_SYNC };
    const newProjectIds = localProjectIds.concat([newId]);

    await Promise.all([localforage.setItem('localProjectIds', newProjectIds),
        localforage.setItem(`project/${newId}`, newProject)]);

    return { requestid: newId, updatedAt };
}

async function copyLocalProject (projId, data) {
    const { wasOnlineProject, ...oldProject } = await getLocalProjectById(projId); // eslint-disable-line no-unused-vars
    const newProject = { ...oldProject, ...data };

    return await createLocalProject(newProject);
}

async function updateLocalProjectById (id, data) {
    const localProjectIds = await getLocalProjectIds();
    const updatedAt = moment.utc().format(SERVER_TIMESTAMP_FORMAT);
    const wasOnlineProject = !id.match(/^local-/);

    const oldProject = wasOnlineProject ? {} : await getLocalProjectById(id);
    const newProject = { ...oldProject,
        ...data,
        id,
        updatedAt,
        wasOnlineProject,
        syncStatus: LocalProjectStatus.READY_TO_SYNC };
    let newProjectIds = localProjectIds.filter(pId => pId !== id);

    if (newProjectIds.length === localProjectIds.length && !wasOnlineProject) {
        throw new Error(NOT_FOUND_ERROR);
    }

    newProjectIds.push(id);

    await Promise.all([localforage.setItem('localProjectIds', newProjectIds),
        localforage.setItem(`project/${id}`, newProject)]);

    return { requestid: id, updatedAt };
}

async function deleteLocalProjectById (id) {
    const localProjectIds = await getLocalProjectIds();
    const newProjectIds = localProjectIds.filter(pId => pId !== id);

    if (newProjectIds.length === localProjectIds.length) {
        throw new Error(NOT_FOUND_ERROR);
    }

    await Promise.all([localforage.setItem('localProjectIds', newProjectIds),
        localforage.removeItem(`project/${id}`)]);

    return { requestid: id };
}

function convertUpdatedAtUTCToLocal(project) {
    return { ...project, updatedAt: convertUTCToLocal(project.updatedAt), utcUpdatedAt: project.updatedAt };
}

function parseClientVersion(project) {
    let clientVersion;
    try {
        clientVersion = JSON.parse(decodeURI(project.clientVersion));
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.log("Warning could not parse client version for project id", project.id);
        console.log("clientVersion received: ", project.clientVersion); // eslint-disable-line no-console
        return project;
    }

    return { ...project, clientVersion };
}

const getSortDate = (project) => {
    return moment.utc(project.utcUpdatedAt).valueOf();
};

const sortProjects = (projects) => {
    const parsedProjects = projects.map(p => {
        const converted = convertUpdatedAtUTCToLocal(p);
        return parseClientVersion(converted);
    });

    return sortBy(parsedProjects, getSortDate).reverse();
};

async function getAll() {
    const localProjects = await getLocalProjects();
    const sortedLocalProjects = sortProjects(localProjects);
    const offline = await isOffline();

    if (!offline) {
        const onlineProjects = await getJson(makeApiUrl(cacheBust("projects.json")));
        const sortedOnlineProjects = sortProjects(onlineProjects.projects);
        return sortedLocalProjects.concat(sortedOnlineProjects);
    }
    else {
        return sortedLocalProjects;
    }
}

function getSharedById(id) {
    return getJson(makeApiUrl(`receive.design/${id}`))
        .then(convertUpdatedAtUTCToLocal)
        .then(parseClientVersion);
}

async function getById(id) {
    const offline = await isOffline();
    const getProject = offline ? getLocalProjectById(id)
        : getJson(makeApiUrl(cacheBust(`project.json/${id}`)));

    return getProject
        .then(convertUpdatedAtUTCToLocal)
        .then(parseClientVersion);
}

async function createNew(data) {
    const offline = await isOffline();
    return offline ? createLocalProject(data)
        : postJson(makeApiUrl(cacheBust(`project`)), data);
}

async function deleteById(id) {
    const offline = await isOffline();
    return offline ? deleteLocalProjectById(id)
        : deleteJson(makeApiUrl(cacheBust('project')), { id });
}

async function renameById(id, name) {
    const offline = await isOffline();
    return offline ? updateById(id, { name })
        : putJson(makeApiUrl(cacheBust('project/name')), { id, name });
}

async function updateById(id, data) {
    const { name, status, clientVersion, referenceDesignId, payload } = data;
    const offline = await isOffline();
    return offline ? updateLocalProjectById(id, {name, status, clientVersion, referenceDesignId, payload})
        : putJson(makeApiUrl(cacheBust('project')),
            {id, name, status, clientVersion, referenceDesignId, payload});
}

async function copyById(id, name, status) {
    let data = { id, name };
    const offline = await isOffline();

    if (status) {
        data.status = status;
    }

    return offline ? copyLocalProject(id, data)
        : postJson(makeApiUrl(cacheBust('project/duplicate')), data);
}

function shareById(projectId, recipientEmail) {
    let data = { projectId, recipientEmail };

    return postJson(makeApiUrl(cacheBust('share.design')), data);
}

export default { getAll,
    getById,
    createNew,
    deleteById,
    renameById,
    updateById,
    sortProjects,
    copyById,
    getSharedById,
    shareById,
    getLocalProjectIds,
    getLocalProjects,
    getLocalProjectById,
    deleteLocalProjectById,
    isOffline };
