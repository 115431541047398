import {colorPalette as initialState} from '../initialStates';
import {createReducer} from "../utils/redux-helpers";

// ------------------------------------
// Action Handlers
// ------------------------------------

export const delegateAddColor = (state, newID) => {
    if (!state.activeColors[newID]) {
        // assign a new color
        let newColor = { ...state.inactiveColors[0], id: newID };
        let activeColors = { ...state.activeColors };
        activeColors[newID] = newColor;
        const inactiveColors = state.inactiveColors.slice(1);
        return { ...state, activeColors, inactiveColors };
    }
    else {
        return state;
    }
};

export const delegateDeleteColor = (state, inputId) => {
    if (state.activeColors[inputId]) {
        const { [inputId]: oldColor, ...activeColors } = state.activeColors;
        const inactiveColors = [...state.inactiveColors, { ...oldColor, id: "" }];
        return {...state, activeColors, inactiveColors};
    }
    else {
        return state;
    }
};

const ACTION_HANDLERS = {
    "editor/ADD_INPUT_RAIL": (state, {payload}) => {
        return delegateAddColor({...state}, payload.id);
    },
    "editor/DELETE_INPUT_RAIL": (state, {payload}) => {
        return delegateDeleteColor({...state}, payload.id);
    },
    "editor/DELETE_RAIL_AND_CONNECTIONS": (state, {payload}) => {
        return delegateDeleteColor({...state}, payload.id);
    },
    "editor/RESET": () => {
        return initialState;
    }
};

// ------------------------------------
// Reducer
// ------------------------------------

export default createReducer(initialState, ACTION_HANDLERS);
