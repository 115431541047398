import PropTypes from 'prop-types';
import React from 'react';
import ModalWindow from 'components/ModalWindow';
import databind from 'redux/utils/databind';
import css from 'components/styles.less';
import {Button} from 'bootstrap-core.js';
import {actions as deleteActions} from 'redux/modules/confirmDelete';
import {actions as editorActions} from 'redux/modules/editor';
import classNames from 'classnames';
import MobileDialog from 'components/MobileDialog';
import {Tablet, Desktop} from 'styles/variables/breakpoints';
import PageSlider from 'components/PageSlider';
import mobilePanelStyles from 'components/mobilePanelStyles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class TemplateWarningModal extends React.Component {
    static propTypes = {
        templateWarning: PropTypes.string.isRequired,
        setTemplateWarning: PropTypes.func.isRequired
    };

    static databind ({editor}) {
        return {
            templateWarning: editor.templateWarning
        };
    }

    static actions = {
        setTemplateWarning: editorActions.setTemplateWarning,
    };

    onConfirm = () => { this.props.setTemplateWarning(""); };

    render() {
        const mobileContent = (
            <MobileDialog
                onClose={this.onConfirm}
                content={<div className={mobilePanelStyles.content}>{this.props.templateWarning}</div>}
                footer={
                    <React.Fragment>
                        <Button className="flex" onClick={this.onConfirm} style={{margin: '0 10px'}}>
                            {getText(contentKeys.CLOSE)}
                        </Button>
                    </React.Fragment>
                } />
        );

        const desktopContent = (
            <ModalWindow
                className={css.modalRoot}
                actions={["Close"]}
                close={this.onConfirm}
                open={this.props.templateWarning} // databind to redux state
                width={500}
                height={200}
                center={true} >
                <div data-test="confirmation_dialog">
                    {this.props.templateWarning}
                    <div className={css.modalButtons}>
                        <div className={css.buttonContainer}>
                            <Button data-test="confirm_delete" className={'btn btn-default'}
                                onClick={this.onConfirm}>{getText(contentKeys.CLOSE)}</Button>
                        </div>
                    </div>
                </div>
            </ModalWindow>
        );

        return (
            <React.Fragment>
                <Desktop>
                    {desktopContent}
                </Desktop>
                <Tablet>
                    <PageSlider showAuxPage={this.props.templateWarning}
                        content={mobileContent} />
                </Tablet>
            </React.Fragment>
        );
    }
}

export default databind(TemplateWarningModal);
