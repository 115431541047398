/* global $ */
import PropTypes from 'prop-types';

import React from 'react';
import { Row } from 'bootstrap-core.js';
import DocumentTitle from 'components/DocumentTitle';
import css from './styles.less';
import { actions as compareAndSelectActions } from 'redux/modules/compareAndSelect';
import ActionButton from 'components/ActionButton';
import SystemValidationBanner from 'components/CompareAndSelect/SystemValidationBanner';
import CompareAndSelectGrid from 'components/CompareAndSelect/CompareAndSelectGrid';
import ReadyToGenModal from 'components/CompareAndSelect/ReadyToGenModal';
import WaitForDbDataLoaded from 'components/WaitForDbDataLoaded';
import databind from 'redux/utils/databind';
import classNames from 'classnames';
import ValidationModal from 'components/CompareAndSelect/ValidationModal';
import PartialDataWarning from 'components/CompareAndSelect/PartialDataWarning';
import UserPreferences from 'util/userPrefs';
import KendoTooltipOverride from 'kendo-plugins/TooltipWrapper';
import { Tablet, Desktop } from 'styles/variables/breakpoints';
import PageSlider from 'components/PageSlider';
import ReadyToGenMobileModal from 'components/CompareAndSelect/ReadyToGenMobileModal';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const offlineGenMsg = 'You must be online to submit a design generation request.';

class CompareView extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        reloadResults: PropTypes.func,
        validateAndGenerate: PropTypes.func,
        selectionsHavePartialEfficiencyData: PropTypes.bool.isRequired,
        disableGeneration: PropTypes.bool
    };

    static databind({ compareAndSelect, offline, projects }) {
        return {
            selectionsHavePartialEfficiencyData: compareAndSelect.selectionsHavePartialEfficiencyData,
            disableGeneration: offline.isOffline || (projects.current && projects.current.isLocalProject)
        };
    }

    static actions = {
        reloadResults: compareAndSelectActions.reloadResults,
        validateAndGenerate: compareAndSelectActions.validateAndGenerate
    };

    constructor(props) {
        super(props);

        this.state = {
            showGenWarning: false
        };
    }

    componentDidMount() {
        if (this.props.disableGeneration) {
            KendoTooltipOverride($('#CompareAndSelect_GenerateTop'), offlineGenMsg, null, 'bottom');
            KendoTooltipOverride($('#CompareAndSelect_GenerateBottom'), offlineGenMsg, null, 'top');
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.disableGeneration && prevProps.disableGeneration) {
            const topGenTooltip = $('#CompareAndSelect_GenerateTop').data('kendoTooltip');
            const bottomGenTooltip = $('#CompareAndSelect_GenerateBottom').data('kendoTooltip');

            topGenTooltip && topGenTooltip.destroy();
            bottomGenTooltip && bottomGenTooltip.destroy();
        }
        else if (this.props.disableGeneration && !prevProps.disableGeneration) {
            KendoTooltipOverride($('#CompareAndSelect_GenerateTop'), offlineGenMsg, null, 'bottom');
            KendoTooltipOverride($('#CompareAndSelect_GenerateBottom'), offlineGenMsg, null, 'top');
        }
    }

    hideGenWarning = () => {
        this.setState({
            showGenWarning: false
        });
    };

    generate = () => {
        this.hideGenWarning();
        this.props.validateAndGenerate();
    };

    tryGenerating = () => {
        if (this.props.disableGeneration) {
            return;
        }

        if (UserPreferences.ignoreRefDesignWarning) {
            this.props.validateAndGenerate();
        }
        else {
            this.setState({
                showGenWarning: true
            });
        }
    };

    render() {
        return (
            <DocumentTitle title={getText(contentKeys.COMPARE_AND_SELECT)}>
                <div>
                    <div className="content-padding row">
                        <div className={classNames("layout horizontal space-between", css.genRefDesignHeader)}>
                            <div className={classNames(css.noPadding, "container-par parsys")}>
                                <h2>{getText(contentKeys.COMPARE_AND_SELECT)}</h2>
                                <p className="headline">
                                    {getText(contentKeys.SELECT_DEVICES_FOR_THE)}
                                </p>
                            </div>
                            <div className={classNames(css.noPadding, css.topActionBtnContainer)}>
                                <ActionButton
                                    id="CompareAndSelect_GenerateTop"
                                    label={getText(contentKeys.GENERATE_REFERENCE_DESIGN)}
                                    testHook="gen_reference_design_top"
                                    onClick={this.tryGenerating}
                                    disabled={this.props.disableGeneration}
                                />
                            </div>
                        </div>
                    </div>
                    <WaitForDbDataLoaded onDataLoaded={this.props.reloadResults}>
                        <PartialDataWarning show={this.props.selectionsHavePartialEfficiencyData} />
                        <SystemValidationBanner />
                        <Row className={classNames(css.noPadding)}>
                            <CompareAndSelectGrid />
                        </Row>
                    </WaitForDbDataLoaded>
                    <Row className={classNames('content-padding', css.bottomActionBtnContainer)}>
                        <ActionButton
                            id="CompareAndSelect_GenerateBottom"
                            label={getText(contentKeys.GENERATE_REFERENCE_DESIGN)}
                            testHook="gen_reference_design_bottom"
                            onClick={this.tryGenerating}
                            disabled={this.props.disableGeneration}
                        />
                    </Row>
                    <ValidationModal />
                    <Desktop>
                        {this.state.showGenWarning && <ReadyToGenModal
                            open={this.state.showGenWarning}
                            onClose={this.hideGenWarning}
                            onConfirm={this.generate}
                        />}
                    </Desktop>
                    <Tablet>
                        <PageSlider showAuxPage={this.state.showGenWarning}
                            content={
                                <ReadyToGenMobileModal onClose={this.hideGenWarning}
                                    onConfirm={this.generate}
                                    skipModalPrefKey={"ignoreRefDesignWarning"} />
                            } />
                    </Tablet>
                </div>
            </DocumentTitle>
        );
    }
}

export default databind(CompareView);
