import cloneDeep from 'lodash/cloneDeep';
import flattenDeep from 'lodash/flattenDeep';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';

// Check the project against the parts DB for parts that no longer exist and update the state accordingly
export default function(project, partsDb) {
    const updatedProject = cloneDeep(project);
    const removedPartIds = [];
    // extract all the part IDs from the parts DB into a set
    const allPartIds =
        flattenDeep(Object.values(partsDb.data).map((d, idx) => Object.values(d.parts))).map((p) => p.id);
    const validIds = new Set(allPartIds);

    const isValidId = (id) => {
        const valid = validIds.has(id);
        if (!valid) {
            removedPartIds.push(id);
        }
        return valid;
    };
    const hasValidId = ({id}) => isValidId(id);
    const clearResults = (results) => {
        return results.map((result) => {
            result.items = result.items.filter(hasValidId);
            result.filtered = result.filtered.filter(hasValidId);
            return result;
        });
    };

    // suggestedParts - selectedParts and results (items, filtered)
    const suggestedParts = updatedProject.suggestedParts;
    suggestedParts.selectedParts = mapValues(suggestedParts.selectedParts, (ids) => ids.filter(isValidId));
    suggestedParts.results = clearResults(suggestedParts.results);

    // compareAndSelect - selectedPartsIds, results, computedData, selectedPmbusAddresses
    const compareAndSelect = updatedProject.compareAndSelect;
    compareAndSelect.results = clearResults(compareAndSelect.results);
    compareAndSelect.selectedPartIds = pickBy(compareAndSelect.selectedPartIds, isValidId);
    compareAndSelect.computedData = compareAndSelect.computedData.map((c) => {
        c.parts = c.parts.filter(hasValidId);
        return c;
    });
    compareAndSelect.selectedPmbusAddresses = mapValues(compareAndSelect.selectedPmbusAddresses, (p) => {
        return pickBy(p, (_, id) => isValidId(+id));
    });

    return {updatedProject, removedPartIds: uniq(removedPartIds)};
}
