import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router';
import databind from 'redux/utils/databind';
import {actions} from 'redux/modules/notification';
import {NotificationType} from 'logic/constants';
import {CSSTransitionGroup} from 'react-transition-group';
import css from './styles.less';

class NotificationContainer extends React.PureComponent {
    static propTypes = {
        // from data bind
        notifications: PropTypes.array,
        dismiss: PropTypes.func.isRequired
    };

    static databind(state) {
        return {
            notifications: state.notification.notifications
        };
    }

    static actions = {
        // TODO: Remove the show functions, they are just here for dev testing purposes
        showError: actions.showError,
        showSuccess: actions.showSuccess,
        dismiss: actions.dismiss
    };

    componentDidMount() {
        window.showError = this.props.showError;
        window.showSuccess = this.props.showSuccess;
    }

    renderNotification = (args) => {
        const {id, msg, type, link} = args;
        const iconClass = type === NotificationType.SUCCESS ? "fa-check" : "fa-exclamation";
        const onClick = () => this.props.dismiss(id);

        return (
            <div key={id} className={`container ${css.notification} ${css[type]}`}>
                <div className={`content-padding row ${css.notificationInner}`}>
                    <i className={`${css.notificationIcon} fa ${iconClass}`} />
                    {msg}
                    {link && <Link className={css.link} onClick={onClick} to={link.url}>{link.text}</Link>}
                    <i className={`fa fa-times ${css.dismissBtn}`} onClick={onClick} />
                </div>
            </div>
        );
    };

    render() {
        const {notifications} = this.props;

        return (
            <div className={`container ${css.notificationContainer}`}>
                <CSSTransitionGroup
                    transitionName={{
                        enter: css.notificationEnter,
                        enterActive: css.notificationEnterActive,
                        leave: css.notificationLeave,
                        leaveActive: css.notificationLeaveActive,
                    }}
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}>
                    {notifications.map(this.renderNotification)}
                </CSSTransitionGroup>
            </div>
        );
    }
}

export default databind(NotificationContainer);
