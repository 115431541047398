import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import css from './styles.less';

const TableErrorComponent = (props) => {
    return (
        <tr className={css.tcError}>
            <td colSpan={props.colspan}> <span
                className={classNames(css.icon, "icon", "icon-exclaim-solid")}></span>{props.error}</td>
        </tr>
    );
};

TableErrorComponent.propTypes = {
    colspan: PropTypes.number,
    error: PropTypes.string,
};

export default TableErrorComponent;
