import PropTypes from 'prop-types';
import React from 'react';
import Kendo from 'kendo-ui-react';
import classNames from 'classnames';
import css from './styles.less';
import range from 'lodash/range';

export default class SupplySequenceSelect extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        max: PropTypes.number.isRequired,
        selected: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired
    };

    shouldComponentUpdate(nextProps) {
        const {className, max, selected} = this.props;

        return className !== nextProps.className ||
               max !== nextProps.max ||
               selected !== nextProps.selected;
    }

    render() {
        const props = this.props;
        const classnames = classNames(css.supplySequence, props.className);
        const sequence = range(1, props.max + 1);
        const dropDownData = [
            ...sequence.map((x) => {
                return {text: x.toString(), value: x};
            })
        ];

        const dropDownOptions = {
            dataTextField: "text",
            dataValueField: "value",
            dataSource: dropDownData,
            value: props.selected,
            change: function (e) {
                this.close();
                return props.onChange(this.value());
            }
        };

        return (
            <div data-test="supply_sequence">
                <Kendo.DropDownList className={classnames} options={dropDownOptions} />
            </div>
        );
    }
}
