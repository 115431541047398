import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux';
import startOptions from './modules/startOptions';
import editor from './modules/editor';
import colorPalette from './modules/colorPalette';
import confirmDelete from './modules/confirmDelete';
import suggestedParts from './modules/suggestedParts';
import compareAndSelect from './modules/compareAndSelect';
import templatesDb from './modules/templatesDb';
import partsDb from './modules/partsDb';
import efficiencyDb from './modules/efficiencyDb';
import header from './modules/header';
import referenceDesign from './modules/referenceDesign';
import projects from './modules/projects';
import auth from './modules/auth';
import notification from './modules/notification';
import offline from './modules/offline';
import pick from 'lodash/pick';

const appReducer = combineReducers({
    startOptions,
    editor,
    suggestedParts,
    compareAndSelect,
    colorPalette,
    confirmDelete,
    templatesDb,
    partsDb,
    efficiencyDb,
    header,
    referenceDesign,
    projects,
    auth,
    notification,
    offline,
    router
});

const rebootReducer = function(state, action) {
    if (action.type === "REBOOT") {
        return appReducer({ ...pick(state, window.UNPERSISTED_STATE_KEYS), ...action.payload }, action);
    }
    return appReducer(state, action);
};

export default rebootReducer;
