import PropTypes from 'prop-types';
import React from 'react';
import ModalWindow from 'components/ModalWindow';
import ProjectNameModalContent from './ProjectNameModalContent';
import ModalContent from 'components/ModalContent';
import {isAValidEmail} from 'logic/validation';
import css from './styles.less';
import Error from "components/Error";
import {Phone, TabletUp} from 'styles/variables/breakpoints';
import PageSlider from 'components/PageSlider';
import {Button} from 'bootstrap-core.js';
import MobileDialog from 'components/MobileDialog';
import mobilePanelStyles from 'components/mobilePanelStyles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const commonPropTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    names: PropTypes.array,
    open: PropTypes.bool,
    name: PropTypes.string,
    loading: PropTypes.bool
};

class SendModalContent extends React.Component {
    static propTypes = commonPropTypes;

    constructor() {
        super();
        this.state = {
            valid: false,
            email: '',
            dirty: false
        };
    }

    onChangeEmail = (e) => {
        const {value} = e.target;
        const valid = this.state.valid || isAValidEmail(value);
        this.setState({ email: value, valid });
    };

    onBlurEmail = (e) => {
        const {value} = e.target;
        const valid = isAValidEmail(value);
        this.setState({ valid, email: value, dirty: true });
    };

    onSubmit = () => {
        this.props.onOK(this.state.email);
    };

    render() {
        const error = (!this.state.valid && this.state.dirty);
        const errorClass = error ? css.inputError : "";

        return (
            <ModalContent
                loading={this.props.loading}
                onCancel={this.props.onClose}
                onSubmit={this.onSubmit}
                submitLabel={getText(contentKeys.SEND_2)}
                canSubmit={this.state.valid} >
                <div>
                    <p>
                        {getText(contentKeys.YOU_CAN_SEND_A)}
                    </p>
                    <span className={css.emailLabel}>{getText(contentKeys.EMAIL_ADDRESS)}</span>
                    <input
                        disabled={this.state.loading}
                        value={this.state.email}
                        className={`form-control ${css.emailInput} ${errorClass}`}
                        onChange={this.onChangeEmail}
                        onBlur={this.onBlurEmail} />
                    {error && <Error className={css.emailError}>{getText(contentKeys.INVALID_EMAIL_ADDRESS)}</Error>}
                </div>
            </ModalContent>
        );
    }
}

export const SyncConflictModal = (props) => {
    const content = (
        <div className={css.syncConflictModal}>
            <p>
                {getText(contentKeys.A_MORE_RECENT_VERSION, `<b>${props.name}</b>`)}
            </p>
            <div className={css.conflictOption}>
                <a onClick={props.keepOnline}>
                    <i className="fa fa-arrow-right" />{getText(contentKeys.KEEP_THE_ONLINE_VERSION)}
                </a>
                <div className={css.lastModified}>{getText(contentKeys.LAST_MODIFIED)} {props.onlineUpdatedAt}</div>
            </div>
            <div className={css.conflictOption}>
                <a onClick={props.keepOffline}>
                    <i className="fa fa-arrow-right" />{getText(contentKeys.KEEP_THE_OFFLINE_VERSION)}
                </a>
                <div className={css.lastModified}>{getText(contentKeys.LAST_MODIFIED)} {props.offlineUpdatedAt}</div>
            </div>
            <div className={css.conflictOption}>
                <a onClick={props.copyOffline}>
                    <i className="fa fa-arrow-right" />{getText(contentKeys.KEEP_BOTH_BY_SAVING)}
                </a>
            </div>
        </div>
    );
    const tabletUpContent = (
        <ModalWindow
            actions={["Close"]}
            close={props.onClose}
            open={props.open}
            width={560}
            height={385}
            center={true}
            title="Project Sync Conflict">
            <ModalContent
                onCancel={props.onClose}>
                {content}
            </ModalContent>
        </ModalWindow>
    );

    const mobileContent = (
        <MobileDialog
            onClose={props.onClose}
            header={
                <React.Fragment>
                    <span className="headline">{getText(contentKeys.SEND_PROJECT)}</span>
                </React.Fragment>
            } content={
                <div className={mobilePanelStyles.content}>
                    {content}
                </div>
            } footer={
                <Button className="flex basic" onClick={props.onClose} style={{margin: '0 10px'}}>
                    Cancel
                </Button>
            } />
    );

    return (
        <React.Fragment>
            <TabletUp>
                {props.open ? tabletUpContent : null}
            </TabletUp>
            <Phone>
                <PageSlider showAuxPage={props.open}
                    content={mobileContent} />
            </Phone>
        </React.Fragment>
    );
};

SyncConflictModal.propTypes = {
    name: PropTypes.string,
    onClose: PropTypes.func,
    keepOnline: PropTypes.func,
    keepOffline: PropTypes.func,
    copyOffline: PropTypes.func,
    open: PropTypes.bool,
    onlineUpdatedAt: PropTypes.string,
    offlineUpdatedAt: PropTypes.string
};

export const SendModal = (props) => {
    const tabletUpContent = (
        <ModalWindow
            actions={["Close"]}
            close={props.onClose}
            open={props.open}
            width={600}
            height={320}
            center={true}
            title={getText(contentKeys.SEND_PROJECT)}>
            <SendModalContent {...props} />
        </ModalWindow>
    );

    const mobileContent = (
        <MobileDialog
            onClose={props.onClose}
            header={
                <React.Fragment>
                    <span className="headline">{getText(contentKeys.SEND_PROJECT)}</span>
                </React.Fragment>
            } content={
                <div className={mobilePanelStyles.content}>
                    {<SendModalContent {...props} />}</div>} footer={null} />
    );

    return (
        <React.Fragment>
            <TabletUp>
                {props.open ? tabletUpContent : null}
            </TabletUp>
            <Phone>
                <PageSlider showAuxPage={props.open}
                    content={mobileContent} />
            </Phone>
        </React.Fragment>
    );
};

SendModal.propTypes = commonPropTypes;

export class RenameProjectModal extends React.Component {
    static propTypes = commonPropTypes;

    render() {
        const content = (<ProjectNameModalContent
            names={this.props.names}
            name={this.props.name}
            loading={this.props.loading}
            prompt={getText(contentKeys.ENTER_A_NEW_UNIQUE)}
            submitLabel={getText(contentKeys.RENAME)}
            onChange={this.onChangeProjectName}
            onOK={this.props.onOK}
            onCancel={this.props.onClose} />);

        return (
            <ModalWindow
                actions={["Close"]}
                close={this.props.onClose}
                open={this.props.open}
                width={600}
                height={400}
                center={true}
                title={getText(contentKeys.RENAME_PROJECT)}>
                {content}
            </ModalWindow>
        );
    }
}

export class CopyProjectModal extends React.Component {
    static propTypes = commonPropTypes;

    render() {
        const content = (<ProjectNameModalContent
            names={this.props.names}
            name={this.props.name}
            loading={this.props.loading}
            prompt={getText(contentKeys.ENTER_A_UNIQUE_NAME)}
            submitLabel={getText(contentKeys.COPY)}
            onChange={this.onChangeProjectName}
            onOK={this.props.onOK}
            open={this.props.open}
            onCancel={this.props.onClose} />);

        const tabletUpContent = (
            <ModalWindow
                actions={["Close"]}
                close={this.props.onClose}
                open={this.props.open}
                width={600}
                height={400}
                center={true}
                title={getText(contentKeys.COPY_PROJECT)}>
                {content}
            </ModalWindow>
        );

        const mobileContent = (
            <MobileDialog
                onClose={this.props.onClose}
                header={
                    <React.Fragment>
                        <span className="headline">{getText(contentKeys.COPY_PROJECT)}</span>
                    </React.Fragment>
                } content={<div className={mobilePanelStyles.content}>{content}</div>} footer={null} />
        );

        return (
            <React.Fragment>
                <TabletUp>
                    {this.props.open ? tabletUpContent : null}
                </TabletUp>
                <Phone>
                    <PageSlider showAuxPage={this.props.open}
                        content={mobileContent} />
                </Phone>
            </React.Fragment>
        );
    }
}

export const ConfirmDeleteModal = (props) => {
    const mobileContent = (
        <MobileDialog
            onClose={props.onClose}
            header={
                <React.Fragment>
                    <span className="headline">{getText(contentKeys.DELETE_PROJECT)}</span>
                </React.Fragment>
            } content={<div className={mobilePanelStyles.content}>{
                <div><p>{getText(contentKeys.ARE_YOU_SURE_YOU_WANT)}</p></div>
            }</div>} footer={
                <React.Fragment>
                    <Button onClick={props.onClose} className="basic flex"
                        style={{margin: '0 10px'}}>
                        {getText(contentKeys.NO_CANCEL)}
                    </Button>
                    <Button className="flex" onClick={props.onConfirm} style={{margin: '0 10px'}}>
                        {getText(contentKeys.YES_DELETE)}
                    </Button>
                </React.Fragment>
            } />
    );

    const tabletUpContent = (
        <ModalWindow
            actions={["close"]}
            close={props.onClose}
            open={props.open}
            width={500}
            height={300}
            center={true}
            title={getText(contentKeys.DELETE_PROJECT)}>
            <ModalContent
                onCancel={props.onClose}
                onSubmit={props.onConfirm}
                cancelLabel={getText(contentKeys.NO_CANCEL)}
                submitLabel={getText(contentKeys.YES_DELETE)}
                canSubmit={true}>
                <div><p>{getText(contentKeys.ARE_YOU_SURE_YOU_WANT)}</p></div>
            </ModalContent>
        </ModalWindow>
    );

    return (
        <React.Fragment>
            <TabletUp>
                {props.open ? tabletUpContent : null}
            </TabletUp>
            <Phone>
                <PageSlider showAuxPage={props.open}
                    content={mobileContent} />
            </Phone>
        </React.Fragment>
    );
};

ConfirmDeleteModal.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    open: PropTypes.bool.isRequired
};
