import loadTextFromFile from 'util/loadTextFromFile';
import tryLoadingFromFile from 'util/tryLoadingFromFile';
import csvToWorksheet from 'logic/csvToWorksheet';
import parser from 'logic/alteraReportParsing.js';

export function extractPowerTable() {
    return [];
}

export function handleLoadingAsAlteraCSV(text) {
    let wb = { Sheets: { Report: csvToWorksheet(text) } };

    if (!parser.validateAsAltera(wb)) {
        throw new Error('Does not appear to be a valid Altera file');
    }

    return parser.extractDesignSummary(wb);
}

function tryLoadingAsAlteraFromCSV(fileObj) {
    return tryLoadingFromFile(fileObj, loadTextFromFile, handleLoadingAsAlteraCSV);
}

export default tryLoadingAsAlteraFromCSV;
