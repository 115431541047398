import find from 'lodash/find';
import flatten from 'lodash/flatten';
import values from 'lodash/values';

import EN from "translations/en.json";
import contentKeys from 'translations/contentKeys';

export default class SuggestedPartsCSVGenerator {
    writeAnOutputRail(csv, selectedParts, output) {
        const numOutputString = EN[contentKeys[`${output.numOutputs}_OUTPUT`]];

        csv.write([output.decoratedName])
            .gap(5)
            .write([output.count + " Matches"])
            .gap(2)
            .write([numOutputString])
            .endl();
        const partList = flatten(values(output.items));
        const selected = selectedParts[output.id];
        if (selected && (selected.length > 0)) {
            csv.write(["Topology"])
                .gap(2)
                .write(["Device Name", "ioutMax", "Price ($/1k)", "Schematic Enabled", "Notes"])
                .gap(2)
                .write(["Description"]).endl();

            selected.forEach((partId) => {
                const part = find(partList, (part) => part.id === partId);
                csv.write([part.topology])
                    .gap(2)
                    .write([part.deviceName, part.outputs[0].ioutMax,
                        part.msrp, part.orcadEnabled, part.notes])
                    .gap(2)
                    .write([part.description]).endl();
            });
        }
        else {
            csv.write(["No Parts Selected"]).endl();
        }
        csv.lineBreak();
    }

    stringify(csv, state) {
        csv.write(["Suggested Parts"]).gap(1).write(["Selections"]).endl();
        csv.lineBreak();
        state.results.forEach((output) => this.writeAnOutputRail(csv, state.selectedParts, output));
    }
};
