export default function tryLoadingFromFile(fileObj, loaderFn, handlerFn) {
    return new Promise((resolve, reject) => {
        loaderFn(fileObj, (arrayBuffer) => {
            try {
                let data = handlerFn(arrayBuffer);
                data.fileObj = fileObj;
                data.filename = fileObj.name;
                resolve(data);
            }
            catch (e) {
                reject(e);
            }
        });
    });
}
