import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
const LOCAL_STORAGE_KEY = 'POWER_COMPASS_APP_STATE';
const storage = window.sessionStorage; // window.localStorage
const DEBOUNCE_WAIT = 1000;

export const loadState = () => {
    try {
        const json = storage.getItem(LOCAL_STORAGE_KEY);
        if (json == null) {
            return undefined;
        }
        return JSON.parse(json);
    }
    catch (err) {
        return undefined;
    }
};

export function saveStateDirectlyWithExclusions() {
    const state = getSaveableState(window.store.getState);
    return saveState(state);
}

function getSaveableState(getState) {
    // NOTE: Excluding the efficiencyDb data because it exceeds the default local storage size
    // NOTE: Excluding router state so no to override URL route
    const nextState = getState();
    window.UNPERSISTED_STATE_KEYS = ['router', 'efficiencyDb', 'partsDb', 'notification'];
    return omit(nextState, window.UNPERSISTED_STATE_KEYS);
}

export const saveState = (state) => {
    try {
        storage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    }
    catch (err) {
        console.log('Failed to save state to local storage: ', err); // eslint-disable-line no-console
    }
};

const debouncedSaveState = debounce(saveState, DEBOUNCE_WAIT);

export const localStorageSave = function({getState}) {
    return (next) => (action) => {
        // Persist state changes at most once a second
        next(action);
        const nextState = getSaveableState(getState);
        debouncedSaveState(nextState);
        return nextState;
    };
};
