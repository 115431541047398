import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import css from './styles.less';
import { mapStatusToMessage, ProjectStatus } from 'logic/status';
import { offlineIcon } from "components/OfflineStatus";
import { SyncFailureStatuses, LocalProjectStatus } from "redux/modules/offline";
import KendoTooltipOverride from 'kendo-plugins/TooltipWrapper';
import { Phone, TabletUp } from 'styles/variables/breakpoints';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class ProjectsTableRow extends React.Component {
    isLocked = () => {
        const { status, isLocalProject, isOffline, syncStatus, projects, id } = this.props;
        const localConflictExists = projects.filter(p => p.id === id && p.isLocalProject).length > 0 &&
            !isLocalProject;

        return status === ProjectStatus.GENERATING_REFERENCE_DESIGN ||
            (isLocalProject && !isOffline && !SyncFailureStatuses.has(syncStatus)) ||
            localConflictExists;
    };

    wrapId = (fn, passRefDesignId) => {
        if (this.isLocked()) {
            return (e) => e.preventDefault();
        }

        return (e) => {
            e.preventDefault();
            if (passRefDesignId) {
                fn(this.props.id, this.props.referenceDesignId);
            }
            else {
                fn(this.props.id);
            }
        };
    };

    constructor(props) {
        super(props);

        this._sendTooltipOverridden = false;
        this._deleteTooltipOverridden = false;
    }

    setupTooltips = () => {
        const showSendTooltip = this.props.isOffline;
        const showDeleteTooltip = this.props.wasOnlineProject;
        const $sendTooltip = $(this.refs.sendTooltip);
        const $deleteTooltip = $(this.refs.deleteTooltip);

        if (showSendTooltip && !this._sendTooltipOverridden) {
            const tooltip = $sendTooltip.data('kendoTooltip');
            tooltip && tooltip.destroy();
            KendoTooltipOverride($sendTooltip, getText(contentKeys.YOU_MUST_BE_ONLINE_TO), null, 'bottom');
            this._sendTooltipOverridden = true;
        }
        else if (!showSendTooltip) {
            const tooltip = $sendTooltip.data('kendoTooltip');
            tooltip && tooltip.destroy();
            this._sendTooltipOverridden = false;
        }

        if (showDeleteTooltip && !this._deleteTooltipOverridden) {
            const tooltip = $deleteTooltip.data('kendoTooltip');
            tooltip && tooltip.destroy();
            KendoTooltipOverride($deleteTooltip,
                getText(contentKeys.YOU_MUST_BE_ONLINE), null, 'bottom');
            this._deleteTooltipOverridden = true;
        }
        else if (!showDeleteTooltip) {
            const tooltip = $deleteTooltip.data('kendoTooltip');
            tooltip && tooltip.destroy();
            this._deleteTooltipOverridden = false;
        }
    };

    componentDidMount() {
        this.setupTooltips();
    }

    componentDidUpdate(prevProps) {
        // The project actions for this row are removed from the DOM while syncing and then added back,
        // so any applicable tooltips must be re-overridden
        if (this.props.syncStatus !== 'SYNCING' && prevProps.syncStatus === 'SYNCING') {
            this._deleteTooltipOverridden = false;
            this._sendTooltipOverridden = false;
        }

        this.setupTooltips();
    }

    render() {
        const { status, syncStatus, isLocalProject, isOffline, name, updatedAt, wasOnlineProject, onDeleteLocal,
            onResolveConflict, onRetryGeneration, onCopyAndEdit, onCopy, onEdit, onSend, onDelete,
            onRetrySync } = this.props;

        const isDone = status === ProjectStatus.DESIGN_FILE_DOWNLOAD;
        const syncError = SyncFailureStatuses.has(syncStatus) && !isOffline;
        const syncing = syncStatus === LocalProjectStatus.SYNCING;
        const syncComplete = syncStatus === LocalProjectStatus.SYNC_COMPLETE;
        const isError = status === ProjectStatus.GENERATING_ERROR || syncError;
        const rowClass = classNames({ [css.locked]: this.isLocked(), [css.error]: isError });
        const retryFn = isLocalProject ? this.wrapId(onRetrySync) : this.wrapId(onRetryGeneration, true);
        const deleteFn = isLocalProject ? this.wrapId(onDeleteLocal) : this.wrapId(onDelete);

        let errorMessage;

        if (syncStatus === LocalProjectStatus.SYNC_CONFLICT) {
            errorMessage = getText(contentKeys.SYNC_CONFLICT);
        }
        else if (syncStatus === LocalProjectStatus.SYNC_FAILED) {
            errorMessage = getText(contentKeys.SYNC_FAILED);
        }

        const actions = <React.Fragment>
            {syncing &&
                <td className={css.sync} colSpan="4">
                    <i className="fa fa-spin fa-refresh" /> {getText(contentKeys.SYNCING)}
                </td>
            }
            {syncComplete &&
                <td className={css.sync} colSpan="4">
                    <i className="fa fa-check" /> {getText(contentKeys.SYNCING_COMPLETE)}
                </td>
            }
            {isError &&
                <td className={classNames(css.sync, { [css.active]: !!errorMessage })} colSpan="2">
                    {errorMessage && <i className="fa fa-exclamation-triangle" />}{errorMessage}
                </td>
            }
            {isError && syncStatus !== LocalProjectStatus.SYNC_CONFLICT &&
                <td className={css.retry}>
                    <span className={css.links} onClick={retryFn}>{getText(contentKeys.RETRY)}</span>
                </td>
            }
            {isError && syncStatus === LocalProjectStatus.SYNC_CONFLICT &&
                <td className={css.retry}>
                    <span className={css.links} onClick={this.wrapId(onResolveConflict)}>
                        {getText(contentKeys.RESOLVE)}
                    </span>
                </td>
            }
            {isDone && !isError &&
                <td className={css.copyAndEdit} colSpan="2">
                    <span className={css.links} onClick={this.wrapId(onCopyAndEdit)}>
                        {getText(contentKeys.COPY_AND_EDIT)}
                    </span>
                </td>
            }
            {!isDone && !isError && !syncing && !syncComplete &&
                <td className={css.copy}>
                    <span className={css.links} onClick={this.wrapId(onCopy)}>{getText(contentKeys.COPY)}</span>
                </td>
            }
            {!isDone && !isError && !syncing && !syncComplete &&
                <td className={css.edit}>
                    <span className={css.links} onClick={this.wrapId(onEdit)}>{getText(contentKeys.EDIT)}</span>
                </td>
            }
            {!isError && !syncing && !syncComplete &&
                <td className={classNames(css.send, { [css.disabled]: isOffline })}>
                    <span className={css.links} ref="sendTooltip"
                        onClick={!isOffline && this.wrapId(onSend)}>
                        {getText(contentKeys.SEND_2)}
                    </span>
                </td>}
            {!syncing && !syncComplete &&
                <td className={classNames(css.delete, { [css.disabled]: wasOnlineProject })}>
                    <span className={css.links} ref="deleteTooltip"
                        onClick={!wasOnlineProject && deleteFn}>
                        {getText(contentKeys.DELETE)}
                    </span>
                </td>}
        </React.Fragment>;

        const offline = (
            <td className={css.projectIcon}>
                {isLocalProject && offlineIcon}
            </td>
        );

        return (<tr className={rowClass}>
            <Phone>
                <div className={classNames('layout horizontal', css.phoneContainer)}>
                    {offline}
                    <div className="layout vertical">
                        <td className={css.name}>
                            <span className={css.links} onClick={!isError ? this.wrapId(onEdit) : null}>
                                {name}
                            </span>
                        </td>
                        <td className={css.date}>{updatedAt}</td>
                        <td className={css.status}>{mapStatusToMessage(status)}</td>
                        <div className={css.actionsContainer}>{actions}</div>
                    </div>
                </div>
            </Phone>
            <TabletUp>
                {offline}
                <td className={css.name}>
                    <span className={css.links} onClick={!isError && this.wrapId(onEdit)}>
                        {name}
                    </span>
                </td>
                <td className={css.date}>{updatedAt}</td>
                <td className={css.status}>{mapStatusToMessage(status)}</td>
                {actions}
            </TabletUp>
        </tr>);
    }
}

ProjectsTableRow.propTypes = {
    id: PropTypes.string,
    projects: PropTypes.array,
    referenceDesignId: PropTypes.string,
    name: PropTypes.string,
    updatedAt: PropTypes.string,
    status: PropTypes.string,
    isLocalProject: PropTypes.bool,
    wasOnlineProject: PropTypes.bool,
    isOffline: PropTypes.bool,
    syncStatus: PropTypes.string,
    onDelete: PropTypes.func,
    onDeleteLocal: PropTypes.func,
    onCopy: PropTypes.func,
    onEdit: PropTypes.func,
    onSend: PropTypes.func,
    onCopyAndEdit: PropTypes.func,
    onRetryGeneration: PropTypes.func,
    onRetrySync: PropTypes.func,
    onResolveConflict: PropTypes.func
};

class ProjectsTable extends React.Component {
    loadingTableRow = () => {
        return (
            <div className={css.loading}>
                <div className={css.animation}>
                    <div className={css.spacer} />
                    <div className={css.spacer} />
                    <div className={css.spacer} />
                </div>
            </div>
        );
    };

    emptyTableRow = () => {
        return (
            <tr>
                <td className={css.emptyTableMsg} colSpan="8">
                    {this.props.emptyTableMsg}
                </td>
            </tr>
        );
    };

    rows = () => {
        const { projects, onDelete, onCopy, onEdit, onCopyAndEdit, onRetryGeneration, onResolveConflict,
            onSend, isOffline, onRetrySync, onDeleteLocal } = this.props;

        if (!projects || projects.length === 0) {
            return this.emptyTableRow();
        }

        return projects.map(
            (project, i) => <ProjectsTableRow {...project}
                key={i}
                isOffline={isOffline}
                projects={projects}
                onDelete={onDelete}
                onCopy={onCopy}
                onCopyAndEdit={onCopyAndEdit}
                onEdit={onEdit}
                onSend={onSend}
                onRetryGeneration={onRetryGeneration}
                onRetrySync={onRetrySync}
                onResolveConflict={onResolveConflict}
                onDeleteLocal={onDeleteLocal}
            />);
    };

    render() {
        if (this.props.loading) {
            return this.loadingTableRow();
        }

        return (<div className={css.projectsTable}>
            <table>
                <thead>
                    <tr>
                        <th className={css.emptyHeader}></th>
                        <th className={css.projectNameHeader}>{getText(contentKeys.PROJECT_NAME)}</th>
                        <th className={css.lastSavedHeader}>{getText(contentKeys.LAST_SAVED)}</th>
                        <th className={css.statusHeader}>{getText(contentKeys.STATUS)}</th>
                        <th className={css.actions} colSpan="4">{getText(contentKeys.ACTIONS)}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.rows()}
                </tbody>
            </table>
        </div>);
    }
}

ProjectsTable.propTypes = {
    loading: PropTypes.bool,
    projects: PropTypes.array,
    emptyTableMsg: PropTypes.string.isRequired,
    isOffline: PropTypes.bool,
    onDelete: PropTypes.func,
    onCopy: PropTypes.func,
    onCopyAndEdit: PropTypes.func,
    onEdit: PropTypes.func,
    onSend: PropTypes.func,
    onRetryGeneration: PropTypes.func,
    onRetrySync: PropTypes.func,
    onResolveConflict: PropTypes.func,
    onDeleteLocal: PropTypes.func
};

export default ProjectsTable;
