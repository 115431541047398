import {makeAction, createReducer} from '../utils/redux-helpers';
import {efficiencyDbInitialState as initialState} from '../initialStates';
import loadEfficiencyDb from 'api/loadEfficiencyDb';

function prefix (type) { return `efficiencyDb/${type}`; }

export const actionTypes = {
    FETCH_DATA: prefix('FETCH_DATA'),
    LOAD_DATA: prefix('LOAD_DATA'),
    RECEIVE_DATA: prefix('RECEIVE_DATA'),
    RECEIVE_ERROR: prefix('RECEIVE_ERROR')
};

export const actions = {
    fetchData: () => {
        return (dispatch) => {
            dispatch(actions.loadData());
            return loadEfficiencyDb()
                .then((results) => dispatch(actions.receiveData(results)))
                .catch((message) => dispatch(actions.receiveError(message)));
        };
    },
    loadData: () => makeAction(actionTypes.LOAD_DATA),
    receiveData: (data) => makeAction(actionTypes.RECEIVE_DATA, data),
    receiveError: (data) => makeAction(actionTypes.RECEIVE_ERROR, data)
};

const ACTION_HANDLERS = {
    [actionTypes.LOAD_DATA]: (state) => {
        return { ...state, requested: true, received: false, error: false, data: {}, version: "" };
    },
    [actionTypes.RECEIVE_DATA]: (state, {payload}) => {
        const version = payload.revision;
        const data = payload.parts;
        return { ...state, requested: false, received: true, error: false, data, version };
    },
    [actionTypes.RECEIVE_ERROR]: (state, {payload}) => {
        return { ...state, requested: false, received: false, error: true, message: payload };
    }
};

export default createReducer(initialState, ACTION_HANDLERS);
