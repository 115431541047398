/* global kendo */
import {makeAction, createReducer} from 'redux/utils/redux-helpers';
import {requestPartsForPlatform, requestDesign} from 'api/startSelectTemplates';
import isArray from 'lodash/isArray';
import findIndex from 'lodash/findIndex';
import getHistory from 'util/history';
import {actions as editorActions} from 'redux/modules//editor';
import {actions as suggestedPartsActions} from 'redux/modules//suggestedParts';
import {actions as compareAndSelectActions} from 'redux/modules/compareAndSelect';
import {actions as referenceDesignActions} from 'redux/modules/referenceDesign';
import {actions as projectActions} from 'redux/modules/projects';
import {OTHER_VENDOR_NAME,
    UNSUPPORTED_VENDOR_FILE_MESSAGE,
    VENDOR_FILE_NOT_RECOGNISED_MESSAGE} from 'logic/constants';
import buildTemplateFromVendorDesignSummary from 'logic/buildTemplateFromVendorDesignSummary.js';
import hasFileExtension from 'logic/hasFileExtension';
import tryLoadingAsXilinx from 'logic/tryLoadingAsXilinx';
import tryLoadingAsXml from 'logic/tryLoadingAsXml';
import tryLoadingFromFileViaWorker from 'util/tryLoadingFromFileViaWorker';
import XlsxWorker from "worker-loader!util/xlsxWorker.js";
import messages from 'util/workerMessages.js';
import detect from 'util/browser';
import tryLoadingAsAlteraFromCSV from 'logic/tryLoadingAsAlteraFromCSV';
import {registerBlacklistedKeys} from 'redux/persist';
import { fillInputUrlParams, fillOutputUrlParams } from 'logic/querystringParser';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

function prefix (type) { return `startOptions/${type}`; }

export const actionTypes = {
    RESET: prefix('RESET'),
    SET_START_NEW_PROJECT: prefix('SELECT_START_NEW'),
    SELECT_USE_TEMPLATE: prefix('SELECT_USE_TEMPLATE'),
    SELECT_USE_SAVED_PROJECT: prefix('SELECT_USE_SAVED_PROJECT'),
    SELECT_VENDOR: prefix('SELECT_VENDOR'),
    SELECT_OTHER_VENDOR: prefix('SELECT_OTHER_VENDOR'),
    SET_SELECTED_PLATFORM: prefix('SET_SELECTED_PLATFORM'),
    SET_PARTS_FOR_PLATFORM: prefix('SET_PARTS_FOR_PLATFORM'),
    SET_SELECTED_PART_NUMBER: prefix('SET_SELECTED_PART_NUMBER'),
    SET_AVAILABLE_DESIGNS: prefix('SET_DESIGNS_FOR_PART'),
    SET_AVAILABLE_DESIGNS_DIRECT_FROM_PLATFORMS: prefix('SET_AVAILABLE_DESIGNS_DIRECT_FROM_PLATFORMS'),
    SET_SELECTED_DESIGN: prefix('SET_SELECTED_DESIGN'),
    SET_SELECTED_SAVED_PROJECT_ID: prefix('SET_SELECTED_SAVED_PROJECT_ID'),
    CLEAR_SELECTED_SAVED_PROJECT_ID: prefix('CLEAR_SELECTED_SAVED_PROJECT_ID'),
    RESET_VENDOR: prefix('RESET_VENDOR'),
    RESET_PLATFORM_DATA: prefix('RESET_PLATFORM_DATA'),
    RESET_PART_NUMBER_DATA: prefix('RESET_PART_NUMBER_DATA'),
    RESET_DESIGN_DATA: prefix('RESET_DESIGN_DATA'),
    RECEIVE_ERROR: prefix('RECEIVE_ERROR'),
    SET_DESIGN_USING_TEMPLATE_FROM_LOCAL_FILE: prefix('SET_DESIGN_USING_TEMPLATE_FROM_LOCAL_FILE'),
    SHOW_VENDOR_FILE_ERROR: prefix('SHOW_VENDOR_FILE_ERROR'),
    CLEAR_VENDOR_FILE_ERROR: prefix('CLEAR_VENDOR_FILE_ERROR'),
    GENERATE_TEMPLATE: prefix('GENERATE_TEMPLATE'),
    SET_TEMPLATE_BASED_ON_VENDOR_FILE: prefix('SET_TEMPLATE_BASED_ON_VENDOR_FILE'),
    LOAD_VENDOR_FILE: prefix('LOAD_VENDOR_FILE'),
    CLEAR_VENDOR_FILE_TEMPLATE: prefix('CLEAR_VENDOR_FILE_TEMPLATE'),
    CLEAR_CAN_CONTINUE: prefix('CLEAR_CAN_CONTINUE'),
    LOADING_VENDOR_TEMPLATE: prefix('LOADING_VENDOR_TEMPLATE'),
    SET_LOADING_ALTERA_XLS: prefix('SET_LOADING_ALTERA_XLS'),
    CLEAR_LOADING_VENDOR_TEMPLATE: prefix('CLEAR_LOADING_VENDOR_TEMPLATE'),
    SHOW_LOAD_CSV_MODAL: prefix('SHOW_LOAD_CSV_MODAL'),
    HIDE_LOAD_CSV_MODAL: prefix('HIDE_LOAD_CSV_MODAL'),
    HIDE_LOAD_CSV_REMINDER: prefix('HIDE_LOAD_CSV_REMINDER')
};

function canLoadAlteraXLS() {
    const {browser} = kendo.support;
    return (detect.isChrome(browser) || detect.isEdge(browser));
}

export const ValidStartOptions = {
    START_NEW: "START_NEW",
    USE_TEMPLATE: "USE TEMPLATE",
    USE_SAVED_PROJECT: "USE SAVED PROJECT"
};

export const actions = {
    reset: () => makeAction(actionTypes.RESET),
    selectNewProject: () => (dispatch) => {
        dispatch(actions.setStartNewProject());
        dispatch(actions.cancelVendorFileLoading());
    },
    setStartNewProject: () => makeAction(actionTypes.SET_START_NEW_PROJECT, {}),
    selectUseTemplate: () => makeAction(actionTypes.SELECT_USE_TEMPLATE, {}),
    selectUseSavedProject: () => makeAction(actionTypes.SELECT_USE_SAVED_PROJECT, {}),
    clearStartState: () => (dispatch) => {
        dispatch(actions.clearUnrecognisedVendorFileError());
        dispatch(actions.clearVendorFileTemplate());
        dispatch(actions.clearLoadingVendorTemplate());
        dispatch(actions.resetVendor());
        dispatch(actions.resetPlatformData());
        dispatch(actions.resetPartNumberData());
        dispatch(actions.resetDesignData());
        dispatch(actions.clearCanContinue());
        dispatch(actions.hideLoadCSVReminder());
        dispatch(actions.clearSavedProjectId());
    },
    onSelectVendor: (data) => (dispatch, getState) => {
        dispatch(actions.clearStartState());
        const templatesDb = getState().templatesDb;
        const {vendor} = data;
        if (vendor === OTHER_VENDOR_NAME) {
            dispatch(actions.selectOtherVendor({ ...data, templatesDb }));
            dispatch(actions.setAvailableDesignsDirectFromPlatforms({templatesDb}));
        }
        else {
            dispatch(actions.selectVendor({ ...data, templatesDb }));
        }
    },
    selectVendor: (data) => makeAction(actionTypes.SELECT_VENDOR, data),
    selectOtherVendor: (data) => makeAction(actionTypes.SELECT_OTHER_VENDOR, data),
    onSelectPlatform: (data) => {
        const {platform} = data;
        return (dispatch, getState) => {
            const { selectedVendor } = getState().startOptions;
            dispatch(actions.resetPartNumberData());
            dispatch(actions.resetDesignData());
            requestPartsForPlatform(selectedVendor, platform)
                .then((response) => {
                    dispatch(actions.setSelectedPlatform({ enablePartNumberSelection: true, platform }));
                    let parts = [];
                    if (isArray(response.designs) && (response.designs.length > 0) &&
                        isArray(response.designs[0].platforms) && (response.designs[0].platforms.length > 0)) {
                        parts = response.designs[0].platforms[0].parts;
                    }
                    dispatch(actions.setPartsForPlatform({ parts }));
                })
                .catch((message) => dispatch(actions.receiveError(message)));
        };
    },
    setSelectedPlatform: (data) => makeAction(actionTypes.SET_SELECTED_PLATFORM, data),
    setPartsForPlatform: (data) => makeAction(actionTypes.SET_PARTS_FOR_PLATFORM, data),
    onSelectPart: (data) => {
        const { partNumber } = data;
        return (dispatch, getState) => {
            dispatch(actions.resetDesignData());
            dispatch(actions.setSelectedPart({ partNumber }));
            dispatch(actions.setAvailableDesigns({ partNumber }));
            const {partsForSelectedPlatform} = getState().startOptions;
            let designName = "";
            if (isArray(partsForSelectedPlatform) && (partsForSelectedPlatform.length > 0)) {
                const index = findIndex(partsForSelectedPlatform, part => part.part === partNumber);
                designName = partsForSelectedPlatform[index].designName;
            }
            dispatch(actions.setSelectedDesign({ designName }));
        };
    },
    setSelectedPart: (data) => makeAction(actionTypes.SET_SELECTED_PART_NUMBER, data),
    setAvailableDesigns: (data) => makeAction(actionTypes.SET_AVAILABLE_DESIGNS, data),
    setAvailableDesignsDirectFromPlatforms: (data) =>
        makeAction(actionTypes.SET_AVAILABLE_DESIGNS_DIRECT_FROM_PLATFORMS, data),
    setSelectedDesign: (data) => makeAction(actionTypes.SET_SELECTED_DESIGN, data),
    resetVendor: () => makeAction(actionTypes.RESET_VENDOR),
    resetPlatformData: () => makeAction(actionTypes.RESET_PLATFORM_DATA, {}),
    resetPartNumberData: () => makeAction(actionTypes.RESET_PART_NUMBER_DATA, {}),
    resetDesignData: () => makeAction(actionTypes.RESET_DESIGN_DATA, {}),
    selectSavedProject: projectId => makeAction(actionTypes.SET_SELECTED_SAVED_PROJECT_ID, projectId),
    clearSavedProjectId: () => makeAction(actionTypes.CLEAR_SELECTED_SAVED_PROJECT_ID),
    onContinue: () => (dispatch, getState) => {
        const {startOptions} = getState();
        const {selectedVendor, selectedDesign, templateBasedOnVendorFile, selectedProjectId} = startOptions;
        dispatch(editorActions.reset());
        dispatch(suggestedPartsActions.reset());
        dispatch(compareAndSelectActions.reset());
        dispatch(referenceDesignActions.reset());

        if (hasValidQuerystringParamsInCurrentCycle()) {
            const systemRailName = 'System Rail 1';
            const inputUrlParams = fillInputUrlParams(systemRailName);
            const outputUrlParams = fillOutputUrlParams(systemRailName);

            if (inputUrlParams !== null || outputUrlParams !== null) {
                const urlModel = {
                    designName: getText(contentKeys.NEW_PROJECT),
                    efficiency: 0.85,
                    inputs: inputUrlParams,
                    outputs: outputUrlParams,
                };

                // dispatch(editorActions.createDesignFromTemplate();
                dispatch(editorActions.createDesignFromTemplate(urlModel));
                dispatch(projectActions.clearCurrentProjectSummary());
                getHistory().push('/editor');
            }
            else {
                const message = "There is a problem with URL data params.";
                dispatch(actions.receiveError({message}));
            }
        }
        else if (selectors.startNewProject(startOptions)) {
            dispatch(projectActions.clearCurrentProjectSummary());
            dispatch(editorActions.createScratchDesign());
            getHistory().push('/editor');
        }
        else if (selectors.useTemplate(startOptions)) {
            if (templateBasedOnVendorFile) {
                dispatch(editorActions.createDesignFromTemplate(templateBasedOnVendorFile));
                dispatch(projectActions.clearCurrentProjectSummary());
                getHistory().push('/editor');
            }
            else {
                requestDesign(selectedVendor, selectedDesign)
                    .then((response) => {
                        const {designs} = response;
                        if (isArray(designs) && (designs.length > 0)) {
                            dispatch(editorActions.createDesignFromTemplate(designs[0]));
                            dispatch(projectActions.clearCurrentProjectSummary());
                            dispatch(projectActions.setCurrentProjectName(designs[0].designName));
                            getHistory().push('/editor');
                        }
                        else {
                            const message = selectedDesign + " not found";
                            dispatch(actions.receiveError({message}));
                        }
                    })
                    .catch((message) => dispatch(actions.receiveError({message})));
            }
        }
        else if (selectors.useSavedProject(startOptions)) {
            dispatch(projectActions.clearCurrentProjectSummary());
            dispatch(projectActions.tryProjectAction('editProject', selectedProjectId));
        }
        else {
            throw new Error("Unexpected start option", startOptions.startProjectOption);
        }
    },
    receiveError: () => makeAction(actionTypes.RECEIVE_ERROR, {}),
    validateAndLoadVendorFile: (fileObj) => (dispatch) => {
        dispatch(actions.clearStartState());
        dispatch(actions.loadingVendorTemplate());
        let designSummary = null;
        if (hasFileExtension(fileObj, 'xls') || hasFileExtension(fileObj, 'xlsx')) {
            if (canLoadAlteraXLS()) {
                dispatch(actions.setLoadingAlteraXLS());
                terminateWebWorker();
                createNewWorker();
                designSummary = tryLoadingFromFileViaWorker(getWorker(),
                    messages.createLoadThisMessage(fileObj));
            }
            else {
                dispatch(actions.clearLoadingVendorTemplate());
                return dispatch(actions.showLoadCSVModal());
            }
        }
        else if (hasFileExtension(fileObj, 'csv')) {
            designSummary = tryLoadingAsAlteraFromCSV(fileObj);
        }
        else if (hasFileExtension(fileObj, 'pwr')) {
            designSummary = tryLoadingAsXilinx(fileObj);
        }
        else if (hasFileExtension(fileObj, 'xml')) {
            designSummary = tryLoadingAsXml(fileObj);
        }
        else {
            dispatch(actions.showVendorFileError(UNSUPPORTED_VENDOR_FILE_MESSAGE));
            return;
        }

        designSummary.then((summary) => {
            dispatch(actions.selectOtherVendor({ vendor: summary.name }));
            dispatch(actions.generateTemplate(summary));
            terminateWebWorker();
        }, () => {
            const unrecognised = `${fileObj.name} ${VENDOR_FILE_NOT_RECOGNISED_MESSAGE}`;
            dispatch(actions.showVendorFileError(unrecognised));
            terminateWebWorker();
        });
    },
    setDesignUsingTemplateFromLocalFile: (filename) =>
        makeAction(actionTypes.SET_DESIGN_USING_TEMPLATE_FROM_LOCAL_FILE, filename),
    clearUnrecognisedVendorFileError: () => makeAction(actionTypes.CLEAR_VENDOR_FILE_ERROR),
    showVendorFileError: (filename) => makeAction(actionTypes.SHOW_VENDOR_FILE_ERROR, filename),
    generateTemplate: (designSummary) => (dispatch) => {
        const template = buildTemplateFromVendorDesignSummary(designSummary);
        dispatch(actions.setVendorTemplate(template));
    },
    setVendorTemplate: (template) => makeAction(actionTypes.SET_TEMPLATE_BASED_ON_VENDOR_FILE, template),
    clearVendorFileTemplate: () => makeAction(actionTypes.CLEAR_VENDOR_FILE_TEMPLATE),
    clearCanContinue: () => makeAction(actionTypes.CLEAR_CAN_CONTINUE),
    loadingVendorTemplate: () => makeAction(actionTypes.LOADING_VENDOR_TEMPLATE),
    clearLoadingVendorTemplate: () => makeAction((actionTypes.CLEAR_LOADING_VENDOR_TEMPLATE)),
    setLoadingAlteraXLS: () => makeAction(actionTypes.SET_LOADING_ALTERA_XLS),
    showLoadCSVModal: () => makeAction(actionTypes.SHOW_LOAD_CSV_MODAL),
    hideLoadCSVModal: () => makeAction(actionTypes.HIDE_LOAD_CSV_MODAL),
    hideLoadCSVReminder: () => makeAction(actionTypes.HIDE_LOAD_CSV_REMINDER),
    handleFullPageFileDrop: (evt) => (dispatch) => {
        const fileObj = evt.dataTransfer.files[0];
        dispatch(actions.selectUseTemplate());
        dispatch(actions.validateAndLoadVendorFile(fileObj));
    },
    cancelVendorFileLoading: () => (dispatch) => {
        dispatch(actions.clearLoadingVendorTemplate());
        terminateWebWorker();
    }
};

function getWorker() {
    return window.xlsxLoadingWorker;
}

function setWorker(w) {
    window.xlsxLoadingWorker = w;
}

function createNewWorker() {
    console.log('creating new xlsx worker'); // eslint-disable-line no-console
    setWorker(new XlsxWorker());
}

function terminateWebWorker() {
    if (getWorker()) {
        console.log('terminating xlsx worker'); // eslint-disable-line no-console
        getWorker().terminate();
    }
    window.xlsxLoadingWorker = null;
}

const ACTION_HANDLERS = {
    [actionTypes.RESET]: () => initialState,
    [actionTypes.RESET_VENDOR]: function(state) {
        return { ...state, selectedVendor: "", showPartsAndPlatformLists: false };
    },
    [actionTypes.RESET_PLATFORM_DATA]: function(state) {
        return { ...state, selectedPlatform: "", partsForSelectedPlatform: [], enablePlatformSelection: false };
    },
    [actionTypes.RESET_PART_NUMBER_DATA]: function(state) {
        return { ...state, selectedPartNumber: "", availableDesigns: [], enablePartNumberSelection: false };
    },
    [actionTypes.RESET_DESIGN_DATA]: function(state) {
        return { ...state, selectedDesign: "" };
    },
    [actionTypes.SET_START_NEW_PROJECT]: function(state, {payload}) {
        if (state.startProjectOption === ValidStartOptions.START_NEW) return state;
        return { ...state,
            startProjectOption: ValidStartOptions.START_NEW,
            enableVendorSelection: false,
            canContinue: true };
    },
    [actionTypes.SELECT_USE_TEMPLATE]: function(state, {payload}) {
        if (!state.startProjectOption === ValidStartOptions.USE_TEMPLATE) return state;
        const canContinue = (state.selectedDesign !== "");
        return { ...state,
            startProjectOption: ValidStartOptions.USE_TEMPLATE,
            enableVendorSelection: true,
            canContinue};
    },
    [actionTypes.SELECT_USE_SAVED_PROJECT]: function(state, {payload}) {
        if (!state.startProjectOption === ValidStartOptions.USE_SAVED_PROJECT) return state;
        const canContinue = (state.selectedProjectId !== "");
        return { ...state, startProjectOption: ValidStartOptions.USE_SAVED_PROJECT, canContinue };
    },
    [actionTypes.SELECT_VENDOR]: function(state, {payload}) {
        const {vendor, templatesDb} = payload;
        const platformsForSelectedVendor = (templatesDb.platforms[vendor]) ? templatesDb.platforms[vendor].names : [];
        return { ...state,
            selectedVendor: vendor,
            platformsForSelectedVendor,
            enablePlatformSelection: true,
            canContinue: false,
            showPartsAndPlatformLists: true };
    },
    [actionTypes.SELECT_OTHER_VENDOR]: function(state, {payload}) {
        const {vendor} = payload;
        return { ...state,
            selectedVendor: vendor,
            platformsForSelectedVendor: [],
            enablePlatformSelection: false,
            canContinue: true,
            showPartsAndPlatformLists: false };
    },
    [actionTypes.SET_SELECTED_PLATFORM]: function(state, {payload}) {
        const { enablePartNumberSelection, platform } = payload;
        return { ...state,
            selectedPlatform: platform,
            enablePartNumberSelection,
            canContinue: false };
    },
    [actionTypes.SET_PARTS_FOR_PLATFORM]: function(state, {payload}) {
        const { parts } = payload;
        return { ...state, partsForSelectedPlatform: parts };
    },
    [actionTypes.SET_SELECTED_PART_NUMBER]: function(state, {payload}) {
        const {partNumber} = payload;
        return { ...state, selectedPartNumber: partNumber };
    },
    [actionTypes.SET_AVAILABLE_DESIGNS]: function(state, {payload}) {
        const {partsForSelectedPlatform} = state;
        const {partNumber} = payload;
        let availableDesigns = [];
        const part = partsForSelectedPlatform.filter((x) => x.part === partNumber);
        if (part.length > 0) {
            availableDesigns = [part[0].designName];
        }
        return { ...state, availableDesigns, selectedDesign: "" };
    },
    [actionTypes.SET_AVAILABLE_DESIGNS_DIRECT_FROM_PLATFORMS]: function(state, {payload}) {
        const {templatesDb} = payload;
        const availableDesigns = templatesDb.platforms[OTHER_VENDOR_NAME].names;
        const selectedDesign = (isArray(availableDesigns) && (availableDesigns.length > 0)) ? availableDesigns[0] : "";
        return { ...state, availableDesigns, selectedDesign };
    },
    [actionTypes.SET_SELECTED_DESIGN]: function(state, {payload}) {
        const {designName} = payload;
        const canContinue = (designName !== "");
        return { ...state,
            selectedDesign: designName,
            canContinue};
    },
    [actionTypes.RECEIVE_ERROR]: function(state, {payload}) {
        return { ...state, errorMessage: payload.message };
    },
    [actionTypes.SHOW_VENDOR_FILE_ERROR]: function(state, {payload}) {
        return { ...state, canContinue: false, loadingVendorFile: false, vendorFileError: payload };
    },
    [actionTypes.CLEAR_VENDOR_FILE_ERROR]: function(state, {payload}) {
        return { ...state, vendorFileError: null };
    },
    [actionTypes.SET_DESIGN_USING_TEMPLATE_FROM_LOCAL_FILE]: function(state, {payload}) {
        return { ...state, availableDesigns: [], selectedDesign: "" };
    },
    [actionTypes.SET_TEMPLATE_BASED_ON_VENDOR_FILE]: function(state, {payload}) {
        return { ...state, templateBasedOnVendorFile: payload, loadingVendorFile: false };
    },
    [actionTypes.CLEAR_VENDOR_FILE_TEMPLATE]: function(state, {payload}) {
        return { ...state, templateBasedOnVendorFile: null };
    },
    [actionTypes.CLEAR_CAN_CONTINUE]: function(state) {
        return { ...state, canContinue: false };
    },
    [actionTypes.LOADING_VENDOR_TEMPLATE]: function(state) {
        return { ...state, loadingVendorFile: true, loadingAlteraXLS: false };
    },
    [actionTypes.CLEAR_LOADING_VENDOR_TEMPLATE]: function(state) {
        return { ...state, loadingVendorFile: false };
    },
    [actionTypes.SHOW_LOAD_CSV_MODAL]: function(state) {
        return { ...state, showLoadCSVModal: true, showLoadCSVReminder: true };
    },
    [actionTypes.HIDE_LOAD_CSV_MODAL]: function(state) {
        return { ...state, showLoadCSVModal: false };
    },
    [actionTypes.HIDE_LOAD_CSV_REMINDER]: function(state) {
        return { ...state, showLoadCSVReminder: false };
    },
    [actionTypes.SET_LOADING_ALTERA_XLS]: function(state) {
        return { ...state, loadingAlteraXLS: true };
    },
    [actionTypes.SET_SELECTED_SAVED_PROJECT_ID]: function(state, {payload}) {
        const canContinue = ((payload !== "") && (selectors.useSavedProject(state)));
        return { ...state, selectedProjectId: payload, canContinue };
    },
    [actionTypes.CLEAR_SELECTED_SAVED_PROJECT_ID]: function(state) {
        return { ...state, selectedProjectId: "" };
    }
};

export const initialState = {
    startProjectOption: ValidStartOptions.START_NEW,
    selectedVendor: "",
    showPartsAndPlatformLists: false,
    platformsForSelectedVendor: [],
    enablePlatformSelection: false,
    selectedPlatform: "",
    partsForSelectedPlatform: [],
    enablePartNumberSelection: false,
    selectedPartNumber: "",
    availableDesigns: [],
    selectedDesign: "",
    errorMessage: "Some Initial Error",
    canContinue: true,
    loadingVendorFile: false,
    loadingAlteraXLS: false,
    templateBasedOnVendorFile: null,
    selectedProjectId: "",
    vendorFileError: null,
    showLoadCSVModal: false,
    showLoadCSVReminder: false
};

registerBlacklistedKeys('startOptions', [
    'errorMessage',
    'loadingVendorFile',
    'loadingAlteraXLS',
    'vendorFileError',
    'showLoadCSVModal',
    'showLoadCSVReminder'
]);

/**
 * Delivers a wait time in milliseconds for some activity to take place when parsing URL values.
 */
export const waitTime = 1000;

/**
 * Getter: tests if the current page load cycle was triggered via querystring parameters.
 * @returns {Boolean} true if the page was triggered by querystring parameters.
 */
export const hasValidQuerystringParamsInCurrentCycle =
    () => sessionStorage.getItem('hasValidQuerystringParams') === '1';

/**
 * Setter: updates session storage variable 'hasValidQuerystringParams' to '1' (false).
 * @returns {void}
 */
export const openQuerystringParamsCycle = () => {
    sessionStorage.setItem('hasValidQuerystringParams', '1');
};

/**
* Setter: updates session storage variable 'hasValidQuerystringParams' to '0' (false).
* @returns {void}
*/
export const closeQuerystringParamsCycle = () => {
    sessionStorage.setItem('hasValidQuerystringParams', '0');
};

/**
 * Getter: tests if the Find Solutions button should be automatically clicked during a
 * querystring parameters load cycle.
 * @returns {Boolean} true if the Find Solutions button should be clicked.
 */
export const getConfigTriggerFindSolutions =
    () => sessionStorage.getItem('powercompassConfigTriggerFindSolutions') === '1';

/**
 * Setter: defines if the Find Solutions button should be automatically clicked during a
 * querystring parameters load cycle.
 * @returns {Boolean} true if the Find Solutions button should be clicked.
 */
export const setConfigTriggerFindSolutions = (configValue = '1') => {
    sessionStorage.setItem('powercompassConfigTriggerFindSolutions', configValue);
};

/**
 * Getter: gets the sort column being used on the current querystring parameters load cycle.
 * @returns {String|null} the sort column name. Null if no sort column is defined.
 */
export const getSortColumn =
    () => sessionStorage.getItem('powercompassSortColumn');

/**
 * Setter: sets the sort column being used on the current querystring parameters load cycle.
 * @returns {void}
 */
export const setSortColumn = (sortColumn) => {
    sessionStorage.setItem('powercompassSortColumn', sortColumn);
};

/**
 * Getter: gets the sort direction being used on the current querystring parameters load cycle.
 * @returns {String|null} the sort direction. Null if no sort direction is defined.
 */
export const getSortDirection =
    () => sessionStorage.getItem('powercompassSortDirection');

/**
 * Setter: sets the sort direction being used on the current querystring parameters load cycle.
 * @returns {String|null} the sort direction. Null if no sort direction is defined.
 */
export const setSortDirection = (sortDirection) => {
    sessionStorage.setItem('powercompassSortDirection', sortDirection);
};

export const selectors = {
    startNewProject: module => module.startProjectOption === ValidStartOptions.START_NEW,
    useTemplate: module => module.startProjectOption === ValidStartOptions.USE_TEMPLATE,
    useSavedProject: module => module.startProjectOption === ValidStartOptions.USE_SAVED_PROJECT,
    selectedProjectId: module => module.selectedProjectId,
    canContinue: module => module.canContinue
};

export default createReducer(initialState, ACTION_HANDLERS);
