import PropTypes from 'prop-types';
import React from 'react';
import Kendo from 'kendo-ui-react';

class ModalWindow extends React.Component {
    static propTypes = {
        children: PropTypes.object.isRequired,
        open: PropTypes.bool.isRequired,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        center: PropTypes.bool,
        actions: PropTypes.array,
        title: PropTypes.string,
        close: PropTypes.func,
        className: PropTypes.string
    };

    static EMPTY_ARRAY = [];

    render () {
        const options = {
            actions: this.props.actions || ModalWindow.EMPTY_ARRAY,
            close: this.props.close,
            animation: false,
            maximize: function(e) {
                e.sender.toggleMaximization();
            },
            title: this.props.title || "",
            modal: true,
            width: this.props.width,
            height: this.props.height,
            visible: false
        };

        return (
            <Kendo.Window
                className={this.props.className}
                open={this.props.open}
                center={this.props.center}
                options={options}>
                {this.props.children}
            </Kendo.Window>
        );
    }
}

export default ModalWindow;
