import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import ModalWindow from 'components/ModalWindow';
import ModalContent from 'components/ModalContent';
import MobileDialog from 'components/MobileDialog';
import InvalidNOutputPartContent from './InvalidNOutputPartContent';
import databind from 'redux/utils/databind';
import { actions, ValidationErrors } from 'redux/modules/compareAndSelect';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';
import { Tablet, Desktop } from 'styles/variables/breakpoints';
import PageSlider from 'components/PageSlider';
import mobilePanelStyles from '../mobilePanelStyles.less';

class ValidationModal extends React.Component {
    static propTypes = {
        validationError: PropTypes.string.isRequired,
        validationErrorInfo: PropTypes.array.isRequired,
        close: PropTypes.func.isRequired
    };

    static databind({ compareAndSelect }) {
        const { validationError, validationErrorInfo } = compareAndSelect;
        return { validationError, validationErrorInfo };
    };

    static actions = {
        close: actions.hideErrorDialog
    };

    onClose = () => {
        this.props.close();
    };

    render() {
        const open = (this.props.validationError !== ValidationErrors.NONE);

        let title = "";
        let content = (<div />);
        if (this.props.validationError === ValidationErrors.INVALID_N_OUTPUT_PART_SELECTION) {
            title = getText(contentKeys.INVALID_MULTIPLE_OUTPUT);
            content = <InvalidNOutputPartContent info={this.props.validationErrorInfo} />;
        }
        else if (this.props.validationError === ValidationErrors.DUP_PMBUS_ADDRESS) {
            title = getText(contentKeys.DUPLICATE_PMBUS);
            content = (<div>
                <p>{getText(contentKeys.PLEASE_UNIQUE_PMBUS)}</p>
            </div>);
        }
        else if (this.props.validationError === ValidationErrors.NO_SCHEMATIC_ENABLED_PARTS) {
            title = getText(contentKeys.NO_SCHEMATIC_ENABLED_PARTS);
            content = (<div>
                <p>{getText(contentKeys.AT_LEAST_ONE_SCHEMATIC_ENABLED)}</p>
                <p>{getText(contentKeys.PARTS_SCHEMATIC_ENABLED_ICON_1)}&nbsp;
                    <i className="enabled icon icon-schematic-enabled"></i>
                    &nbsp;{getText(contentKeys.PARTS_SCHEMATIC_ENABLED_ICON_2)}
                </p>
            </div>);
        }
        else {
            return null;
        }

        return (
            <React.Fragment>
                <Desktop>
                    <ModalWindow
                        actions={["Close"]}
                        close={this.onClose}
                        open={open}
                        width={600}
                        height={300}
                        center={true}
                        title={title}>
                        <ModalContent onSubmit={this.onClose}>
                            {content}
                        </ModalContent>
                    </ModalWindow>
                </Desktop>
                <Tablet>
                    <PageSlider showAuxPage={open}
                        content={(<MobileDialog
                            onClose={this.onClose}
                            header={
                                <span className="headline">{title}</span>
                            }
                            content={(<div className={classNames('layout vertical', mobilePanelStyles.content)}>
                                {content}
                            </div>)}
                            footer={
                                <React.Fragment>
                                    <Button onClick={this.onClose} className="flex"
                                        style={{ margin: '0 10px' }}>
                                        {getText(contentKeys.CLOSE)}
                                    </Button>
                                </React.Fragment>
                            } />)} />
                </Tablet>
            </React.Fragment>
        );
    }
}

export default databind(ValidationModal);
