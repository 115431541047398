import PropTypes from 'prop-types';
import React from 'react';
import UserPreferences from 'util/userPrefs';

export default class SkipPref extends React.Component {
    static propTypes = {
        skipModalPrefKey: PropTypes.string,
        children: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            prefChecked:
                !!props.skipModalPrefKey && UserPreferences[props.skipModalPrefKey]
        };
    }

    togglePref = () => {
        this.setState({
            prefChecked: !this.state.prefChecked
        });
    };

    updateSkip = () => {
        const { skipModalPrefKey } = this.props;
        if (skipModalPrefKey) {
            UserPreferences[skipModalPrefKey] = this.state.prefChecked;
        }
    };

    render() {
        return this.props.children({
            updateSkip: this.updateSkip,
            toggleSkip: this.togglePref,
            skipChecked: this.state.prefChecked
        });
    }
};
