import includes from 'lodash/includes';

export const isAValidProjectName = (name, names) => {
    const trimmedName = name.trim();
    const isUnique = !includes(names.map(n => n.trim()), trimmedName);
    const isNotEmpty = trimmedName !== '';
    let containsValidChars = (trimmedName.match(/[a-zA-Z0-9-_~!^ .]+/gi) || []).join('') === trimmedName;
    return isUnique && isNotEmpty && containsValidChars;
};

export const isAValidEmail = (email) => {
    if (email === '') {
        return false;
    }

    const fakeInput = document.createElement('input');

    fakeInput.type = 'email';
    fakeInput.value = email;

    return typeof fakeInput.checkValidity === 'function' ? fakeInput.checkValidity() : /\S+@\S+\.\S+/.test(email);
};
