import React from 'react';
import MobileDialog from 'components/MobileDialog';
import classNames from 'classnames';
import mobilePanelStyles from '../../mobilePanelStyles.less';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';
import css from './styles.less';
import SkipPref from '../../SkipPref';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

function ReadyToGenMobileModal(props) {
    return (
        <SkipPref skipModalPrefKey={props.skipModalPrefKey}>{
            ({updateSkip, toggleSkip, skipChecked}) => (
                <MobileDialog
                    onClose={props.onClose}
                    header={
                        <span className="headline">{getText(contentKeys.READY_TO_GENERATE_REFERENCE)}</span>
                    } content={
                        <div className={classNames('layout vertical', mobilePanelStyles.content, css.content)}>
                            <p>
                                {getText(contentKeys.ONCE_YOU_GENERATE_A)}
                            </p>

                            <p>{getText(contentKeys.CHOOSE_CONTINUE_TO_GENERATE)}</p>
                            {props.skipModalPrefKey && (
                                <div className="layout horizontal" onClick={toggleSkip}>
                                    <input
                                        type="checkbox"
                                        name="prefCheckbox"
                                        className="checkbox k-checkbox"
                                        checked={skipChecked && 'checked'}
                                    />
                                    <label htmlFor="prefCheckbox" className="k-checkbox-label" />
                                    <span className={css.checkboxLabel}>{getText(contentKeys.DONT_ASK_ME_AGAIN)}</span>
                                </div>
                            )}
                        </div>
                    } footer={
                        <React.Fragment>
                            <Button onClick={props.onClose} className="basic flex"
                                style={{margin: '0 10px'}}>
                                {getText(contentKeys.CANCEL)}
                            </Button>
                            <Button className="flex" onClick={() => {
                                props.onConfirm();
                                props.onClose();
                                updateSkip();
                            }} style={{margin: '0 10px'}}>
                                Continue
                            </Button>
                        </React.Fragment>
                    } />
            )
        }</SkipPref>
    );
};

ReadyToGenMobileModal.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func
};

export default ReadyToGenMobileModal;
