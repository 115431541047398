/* global kendo */
import PropTypes from 'prop-types';

import React from 'react';
import css from './styles.less';
import SourceRailSelectContainer from 'components/Editor/SourceRailSelect';
import SwitchOptions from 'components/Editor/switchOptions';
import classNames from 'classnames';
import BufferedInput, {filterAsNumber} from 'components/BufferedInput';
import SupplySequenceSelect from 'components/Editor/SupplySequenceSelect';
import MoreOptions from 'components/Editor/MoreOptions.js';
import {formatNumberWithExactly2Decimals, formatNumberVariable} from 'logic/formats';
import ModifiedCornerDetail from 'components/ModifiedCornerDetail';

const noFilter = (value) => value;

export default class SystemOutput extends React.PureComponent {
    static propTypes = {
        index: PropTypes.number.isRequired,
        output: PropTypes.object.isRequired,
        feedsAnother: PropTypes.bool.isRequired,
        supplySequenceMax: PropTypes.number.isRequired,
        changeOutputVoltsAmps: PropTypes.func.isRequired,
        confirmDelete: PropTypes.func.isRequired,
        changeSupplySequence: PropTypes.func.isRequired,
        changeOutputName: PropTypes.func.isRequired,
        sourceColor: PropTypes.string,
        isFed: PropTypes.bool.isRequired,
        moreOptionsModified: PropTypes.bool.isRequired
    };

    constructor() {
        super();
        this.onChangeVolts = this.onChangeVoltsAmps.bind(null, "volts");
        this.onChangeAmps = this.onChangeVoltsAmps.bind(null, "amps");
    }

    onChangeVoltsAmps = (fieldName, value) => {
        this.props.changeOutputVoltsAmps({ index: this.props.index, fieldName, value: kendo.parseFloat(value) });
    };

    onChangeSupplySequence = (value) => {
        this.props.changeSupplySequence({ index: this.props.index, value: parseInt(value) });
    };

    onDelete = () => {
        this.props.confirmDelete({output: true, index: this.props.index});
    };

    onChangeName = (value) => {
        this.props.changeOutputName({ index: this.props.index, value });
    };

    render() {
        const {output, feedsAnother, sourceColor, isFed, index, supplySequenceMax, moreOptionsModified} = this.props;
        const supplySeqError = (output.invalid) ? "supplySequenceError" : "";
        const negativeValueClass = (output.availableOutput < 0) ? css["negValue"] : "";
        const hasNoSourceClass = (output.sourceRail === "" || sourceColor === null) ? css.hasNoSourceClass : "";

        let isFedIcon = "";
        let classes = classNames("icon", "icon-rail-feed", css.feedGray, css["feed" + sourceColor]);
        if (isFed) {
            isFedIcon = (<span className={classes}></span>);
        }

        let feedsAnotherIcon = "";
        if (feedsAnother) {
            feedsAnotherIcon = (<span className={classes}></span>);
        }
        const tabColorClassNames = (isFed) ? classNames(css.coloredTab)
            : classNames(css.coloredTab, sourceColor);

        return (
            <tr className={classNames(hasNoSourceClass, sourceColor + "-background")}>
                <td className={classNames(tabColorClassNames, hasNoSourceClass)}>
                    <div className={css.extendedTapArea}></div>
                    <div className={classNames(css.dottedLine, css.dottedLeft)} />
                    <div className={classNames(css.dottedLine, css.dottedRight)} />
                </td>
                <td className={css.deleteBtn}>
                    <span data-test="delete_row" onClick={this.onDelete} className="fa fa-times-circle"></span>
                </td>
                <td className={css.sourceRail}>
                    <SourceRailSelectContainer
                        index={index}
                        selected={output.sourceRail}
                    />
                </td>
                <td data-test="source_rail_feed_icon" className={css.feedIcon}>
                    {isFedIcon}
                </td>
                <td className={css.outputName}>
                    <BufferedInput dataTest="output_name"
                        value={output.name} filter={noFilter} onBufferedChange={this.onChangeName}
                    />
                </td>
                <td data-test="output_name_feed_icon" className={css.feedIcon}>
                    {feedsAnotherIcon}
                </td>
                <td className={css.max}>
                    <BufferedInput dataTest="volts"
                        value={output.volts}
                        filter={filterAsNumber}
                        format={formatNumberVariable}
                        onBufferedChange={this.onChangeVolts} />
                </td>
                <td className={css.max}>
                    <BufferedInput dataTest="amps"
                        value={output.amps}
                        filter={filterAsNumber}
                        format={formatNumberVariable}
                        onBufferedChange={this.onChangeAmps} />
                </td>
                <td className={supplySeqError}>
                    <SupplySequenceSelect index={index} selected={output.supplySeq}
                        max={supplySequenceMax} onChange={this.onChangeSupplySequence} />
                </td>
                <td>
                    <span className={negativeValueClass}
                        data-test="available_output">{formatNumberWithExactly2Decimals(output.availableOutput)}</span>
                </td>
                <td>
                    <span data-test="power_needed">{formatNumberWithExactly2Decimals(output.powerNeeded)}</span>
                </td>
                <td className={css.more}>
                    <ModifiedCornerDetail show={moreOptionsModified} />
                    <MoreOptions>
                        <SwitchOptions index={index} />
                    </MoreOptions>
                </td>
            </tr>
        );
    }
}
