import PersistentValue from './localStorage';

class UserPrefs extends PersistentValue {
    constructor() {
        super('UserPrefs', {
            ignoreRefDesignWarning: false,
            seenDiagramFeature: false,
            seenSavedProjectsFeature: false,
            seenOfflineFeature: false,
            seenCharsWarning: false
        });
    }

    getPreference(key) {
        const prefs = this.value;

        return prefs[key];
    }

    setPreference(key, val) {
        let prefs = this.value;

        prefs[key] = val;

        this.value = prefs;
    }

    get ignoreRefDesignWarning() { return this.getPreference('ignoreRefDesignWarning'); }
    set ignoreRefDesignWarning(v) { this.setPreference('ignoreRefDesignWarning', v); }

    get seenDiagramFeature() { return this.getPreference('seenDiagramFeature'); }
    set seenDiagramFeature(v) { this.setPreference('seenDiagramFeature', v); }

    get seenSavedProjectsFeature() { return this.getPreference('seenSavedProjectsFeature'); }
    set seenSavedProjectsFeature(v) { this.setPreference('seenSavedProjectsFeature', v); }

    get seenOfflineFeature() { return this.getPreference('seenOfflineFeature'); }
    set seenOfflineFeature(v) { this.setPreference('seenOfflineFeature', v); }

    get seenCharsWarning() { return this.getPreference('seenCharsWarning'); }
    set seenCharsWarning(v) { this.setPreference('seenCharsWarning', v); }
}

export default new UserPrefs();
