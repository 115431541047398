import {Topology} from './suggestTopology';
import max from 'lodash/max';
import isArray from 'lodash/isArray';

export function numOutputsWeight(part) {
    if (part.numOutputs > 1) {
        return part.numOutputs;
    }

    return part.numDevicesinSolution;
}

export function topologyWeight(topology) {
    switch (topology) {
        case Topology.BUCK_FET:
        case Topology.BUCK_DIGITAL_FET:
        case Topology.BUCK_BOOST_FET:
        case Topology.BOOST_FET:
            return 0.5;
        case Topology.BUCK_CONTROLLER:
        case Topology.BUCK_DIGITAL_CONTROLLER:
        case Topology.BUCK_MULTIPHASE_CONTROLLER:
        case Topology.BUCK_BOOST_CONTROLLER:
            return 0.2;
    }
    return 1;
}

export function computeJunctionTemp(powerDiss, part, ambientTemp) {
    return (((powerDiss*part.package)/numOutputsWeight(part))*topologyWeight(part.topology)) + ambientTemp;
}

export function computeJunctionTemperatureReferenceCurve(powerDissipationRefCurve, part, ambientTemp) {
    if (powerDissipationRefCurve === null) return null;
    return {
        x: powerDissipationRefCurve.x,
        y: powerDissipationRefCurve.x.map((x, idx) => {
            const pDiss = powerDissipationRefCurve.y[idx];
            if (pDiss == null) {
                return null;
            }
            return computeJunctionTemp(pDiss, part, ambientTemp);
        })
    };
}

export function isCurve(obj) {
    return ((obj != null) && isArray(obj.x) && (obj.x.length > 1));
}

export function computeMaxJnTemp(selectedPartId, selectedPartIndex, computedData, outputIndex) {
    if (selectedPartId != null) {
        const jnTempCurve = computedData[outputIndex].parts[selectedPartIndex].referenceCurves.junctionTemp;
        if (isCurve(jnTempCurve)) {
            return max(jnTempCurve.y);
        }
    }

    return null;
}
