import { formatDeviceName, getSelectedPartInfo } from 'logic/compareParts';
import { formatPercent } from 'logic/formats';
import { CHAR_MDASH } from 'logic/constants';
import { computeMaxJnTemp } from 'logic/junctionTemperature';
import adjustSelectedMsrpForNOutputParts from 'logic/adjustSelectedMsrpForNOutputParts';
import memoize from 'util/memoize';

export default memoize(function createRowDataForRendering(
    results,
    computedData,
    efficiencies,
    selectedPartIds,
    selectedPmbusAddresses
) {
    let rows = results.map((output, idx) => {
        const { selectedIndex, selectedId } = getSelectedPartInfo(
            results,
            selectedPartIds,
            idx,
            output.id
        );
        const selectedPmbusAddress = selectedPmbusAddresses[output.id][selectedId];

        let newOutput = {
            ...output,
            items: output.items.map(part => {
                return {
                    deviceName: formatDeviceName(part),
                    id: part.id,
                    numOutputs: part.numOutputs
                };
            })
        };
        newOutput.selectedId = selectedId;
        newOutput.selectedIndex = selectedIndex;

        if (output.items.length > 0) {
            const selectedOutput = output.items[selectedIndex];

            newOutput.pmbusAddressList = selectedOutput.pmbusAddressList;
            newOutput.selectedPmbusAddress = selectedPmbusAddress;
            newOutput.selectedNumOutputs = selectedOutput.numOutputs;
            newOutput.selectedChildIds = selectedOutput.idsOfSuggestedOutputTuple;
        }
        else {
            newOutput.pmbusAddressList = [];
            newOutput.selectedPmbusAddress = '';
            newOutput.selectedNumOutputs = 0;
            newOutput.selectedChildIds = [];
        }

        const eff = efficiencies[output.id];

        newOutput.llEff =
            eff.lightLoad != null ? formatPercent(eff.lightLoad) : CHAR_MDASH;
        newOutput.tlEff =
            eff.typicalLoad != null ? formatPercent(eff.typicalLoad) : CHAR_MDASH;
        newOutput.mlEff = eff.maxLoad != null ? formatPercent(eff.maxLoad) : CHAR_MDASH;

        newOutput.selectedMsrp = 0;
        if (selectedId != null) {
            newOutput.selectedMsrp = output.items[selectedIndex].msrp;
        }

        newOutput.maxJnTemp = computeMaxJnTemp(
            selectedId,
            selectedIndex,
            computedData,
            idx
        );
        newOutput.uid = newOutput.id;
        return newOutput;
    });

    // we clear the selected msrp when a N-output part is appropriately selected
    // for a N-output grouping
    rows = adjustSelectedMsrpForNOutputParts(rows);

    let priceArray = rows.map(x => x.selectedMsrp);
    const highest = Math.max(...priceArray);
    for (let i = 0; i < rows.length; i++) {
        var priceItem = rows[i];
        if (typeof highest === 'number') {
            priceItem['costPercentage'] = Math.ceil(
                priceItem['selectedMsrp'] / highest * 100
            );
        }
    }

    return rows;
});
