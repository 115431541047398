import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const TotalMatches = function(props) {
    return (
        <div className={css.matches}>
            <span className={css.matchesLabel}>{getText(contentKeys.MATCHES)}</span>
            <span className={css.accentText}>{props.matches}</span>
        </div>);
};

TotalMatches.propTypes = {
    matches: PropTypes.number.isRequired
};

export default TotalMatches;
