/* global $, kendo */
import PropTypes from 'prop-types';

import React from "react";
import {findDOMNode} from 'react-dom';
import _ from 'lodash';
import colors from '../../styles/variables/colors';
import {formatInputValue, formatOutputValue} from '../../logic/matching';

const TOP_OFFSET = 75;
const LEFT_OFFSET = 50;
const NAME_SIZE = 20;
const VALUE_SIZE = 16;
const BORDER_WIDTH = 4;

const attachErrorMarker = (group, blockWidth) => {
    const dataviz = kendo.dataviz;

    const CIRCLE_RAD = 15;

    const circleRect = new dataviz.diagram.Rect(
        blockWidth - (CIRCLE_RAD * 2) - BORDER_WIDTH,
        BORDER_WIDTH,
        CIRCLE_RAD * 2,
        CIRCLE_RAD * 2
    );

    const error = new dataviz.diagram.Layout(circleRect, {
        alignContent: 'center',
        justifyContent: 'center',
        orientation: 'vertical'
    });

    error.append(new dataviz.diagram.TextBlock({
        text: '!',
        fill: colors['@error-red'],
        fontSize: NAME_SIZE + 8,
        fontWeight: 'bold'
    }));

    error.reflow();

    group.append(new dataviz.diagram.Circle({
        radius: CIRCLE_RAD,
        center: {
            x: blockWidth - CIRCLE_RAD - BORDER_WIDTH,
            y: CIRCLE_RAD + BORDER_WIDTH
        },
        fill: {
            color: colors['@error-container-yellow']
        },
        stroke: {
            width: 0
        }
    }));

    group.append(error);
};

const wrappedTextBlocks = (string, lineLength, breakChars, fontColor, fontSize, fontWeight) => {
    const dataviz = kendo.dataviz;
    const firstBreakPoint = _.findIndex(string, c => _.includes(breakChars, c));

    let allLines;

    if (firstBreakPoint < lineLength && firstBreakPoint > 0 && string.length > lineLength) {
        const firstLine = string.split('').slice(0, firstBreakPoint + 1);
        const restLines = _.chunk(string.slice(firstBreakPoint + 1), lineLength);

        allLines = [firstLine].concat(restLines);
    }
    else {
        allLines = _.chunk(string, lineLength);
    }

    let twoLines = allLines.slice(0, 2);

    if (twoLines.length === 2 && allLines.length > 2) {
        const newSecondLine = twoLines[1].slice(0, lineLength - 2).concat('...');

        twoLines = twoLines.slice(0, 1).concat([newSecondLine]);
    }

    return twoLines.map(l => {
        const textBlock = new dataviz.diagram.TextBlock({
            text: l.join(''),
            fill: fontColor,
            fontSize,
            fontWeight
        });

        if (allLines.length > 2) {
            textBlock.drawingElement.options.tooltip = {
                content: string,
                showAfter: 300
            };
        }

        return textBlock;
    });
};

const valueTextBlocks = (dataItem, isOutput, valueLineLen, fontColor) => {
    const value = isOutput ? formatOutputValue(dataItem)
        : formatInputValue(dataItem);

    return wrappedTextBlocks(value, valueLineLen, ['@'], fontColor, VALUE_SIZE);
};

const partNameTextBlocks = (partName, partNameLineLen, fontColor) => {
    return wrappedTextBlocks(partName, partNameLineLen, [' '], fontColor, VALUE_SIZE);
};

const nameTextBlocks = (name, nameLineLen, fontColor) => {
    return wrappedTextBlocks(name, nameLineLen, [' ', '-', '_'], fontColor, NAME_SIZE, 'bold');
};

const blockRectangle = (dataItem, width, height, isOutput, isDisconnectedOutput) => {
    const OUTPUT_COLOR = colors['@railGray'];
    const SELECTED_BORDER = colors['@border-neon-blue'];

    const dataviz = kendo.dataviz;
    const { railColor, isSelected } = dataItem;

    let squareColor = isOutput ? OUTPUT_COLOR : railColor;

    if (isDisconnectedOutput) {
        squareColor = '#FFF';
    }

    let squareBorderColor;

    if (isSelected) {
        squareBorderColor = SELECTED_BORDER;
    }
    else {
        squareBorderColor = isDisconnectedOutput ? '#000' : squareColor;
    }

    return new dataviz.diagram.Rectangle({
        width,
        height,
        stroke: {
            width: BORDER_WIDTH,
            color: squareBorderColor
        },
        fill: {
            color: squareColor
        }
    });
};

const spacer = (width, height) => {
    const dataviz = kendo.dataviz;

    return new dataviz.diagram.Rectangle({
        width: width,
        height: height,
        stroke: {
            width: 1,
            color: "transparent"
        }
    });
};

const referenceBlockTemplate = (dataItem) => {
    const dataviz = kendo.dataviz;
    const g = new dataviz.diagram.Group();

    const RECT_WIDTH = 200;
    const RECT_HEIGHT = 200;
    const NAME_LINE_LEN = 8;
    const VALUE_LINE_LEN = 20;
    const PART_LINE_LEN = 12;
    const FONT_COLOR = '#FFF';

    const isOutput = dataItem.hasOwnProperty('sourceRail');

    const layout = new dataviz.diagram.Layout(new dataviz.diagram.Rect(0, 0, RECT_WIDTH, RECT_HEIGHT), {
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'start',
        orientation: 'vertical'
    });

    layout.append(spacer(RECT_WIDTH, 40));

    const nameBlocks = nameTextBlocks(dataItem.name, NAME_LINE_LEN, FONT_COLOR);

    nameBlocks.forEach(t => layout.append(t));

    if (nameBlocks.length > 1) {
        layout.append(spacer(RECT_WIDTH, 22));
    }
    else {
        layout.append(spacer(RECT_WIDTH, 24 + NAME_SIZE));
    }

    if (isOutput) {
        const partNameBlocks = partNameTextBlocks(dataItem.partName, PART_LINE_LEN, FONT_COLOR);

        partNameBlocks.forEach(t => layout.append(t));

        if (partNameBlocks.length > 1) {
            layout.append(spacer(RECT_WIDTH, 14));
        }
        else {
            layout.append(spacer(RECT_WIDTH, 15 + VALUE_SIZE));
        }
    }
    else {
        layout.append(spacer(RECT_WIDTH, 20));
    }

    const valueBlocks = valueTextBlocks(dataItem, isOutput, VALUE_LINE_LEN, FONT_COLOR);

    valueBlocks.forEach(t => layout.append(t));

    layout.reflow();

    g.append(blockRectangle(dataItem, RECT_WIDTH, RECT_HEIGHT, isOutput));
    g.append(layout);

    return g;
};

const editorBlockTemplate = (dataItem) => {
    const dataviz = kendo.dataviz;
    const g = new dataviz.diagram.Group();

    const RECT_WIDTH = 160;
    const RECT_HEIGHT = 160;
    const NAME_LINE_LEN = 7;
    const VALUE_LINE_LEN = 16;

    const { power, invalid, availableOutput, sourceRail } = dataItem;
    const showError = power < 0 || availableOutput < 0 || invalid;
    const isOutput = dataItem.hasOwnProperty('sourceRail');
    const isDisconnectedOutput = isOutput && !sourceRail;
    const fontColor = isDisconnectedOutput ? '#000' : '#FFF';

    const layout = new dataviz.diagram.Layout(new dataviz.diagram.Rect(0, 0, RECT_WIDTH, RECT_HEIGHT), {
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'start',
        orientation: 'vertical'
    });

    layout.append(spacer(RECT_WIDTH, 40));

    const nameBlocks = nameTextBlocks(dataItem.name, NAME_LINE_LEN, fontColor);

    nameBlocks.forEach(t => layout.append(t));

    if (nameBlocks.length > 1) {
        layout.append(spacer(RECT_WIDTH, 28));
    }
    else {
        layout.append(spacer(RECT_WIDTH, 30 + NAME_SIZE));
    }

    const valueBlocks = valueTextBlocks(dataItem, isOutput, VALUE_LINE_LEN, fontColor);

    valueBlocks.forEach(t => layout.append(t));

    layout.reflow();

    g.append(blockRectangle(dataItem, RECT_WIDTH, RECT_HEIGHT, isOutput, isDisconnectedOutput));
    g.append(layout);

    showError && attachErrorMarker(g, RECT_WIDTH);

    return g;
};

const visualTemplate = (options, forReferenceDesign) => {
    const dataItem = options.dataItem;
    const g = forReferenceDesign ? referenceBlockTemplate(dataItem)
        : editorBlockTemplate(dataItem);

    // Block positioning is ever so slightly off by default. This nudges each block into place
    g.position(2, 2);

    return g;
};

const getOptions = (cssId, optionsOverrides, dataSource, onClickBlock,
    zoomBestFit, exportImage, forReferenceDesign) => {
    const diagramSelector = `#${cssId}`;

    const zoomOut = () => {
        const diagram = $(diagramSelector).data('kendoDiagram');
        const currentZoom = diagram.zoom();

        diagram.zoom(currentZoom - 0.1);
    };

    const zoomIn = () => {
        const diagram = $(diagramSelector).data('kendoDiagram');
        const currentZoom = diagram.zoom();

        diagram.zoom(currentZoom + 0.1);
    };

    const clickBlock = (e) => {
        onClickBlock && onClickBlock(e.item.dataItem);
    };

    let tools = [
        { type: 'button', icon: 'zoom-best-fit', click: zoomBestFit },
        { type: 'button', icon: 'zoom-out', click: zoomOut },
        { type: 'button', icon: 'zoom-in', click: zoomIn }
    ];

    if (!kendo.support.mobileOS.ios) {
        tools.push({ type: 'button', icon: 'image-export', click: exportImage });
    }

    const baseOptions = {
        dataSource,
        layout: {
            type: 'layered',
            subtype: 'right',
            grid: {
                width: 0,
                componentSpacingY: TOP_OFFSET,
                offsetX: LEFT_OFFSET,
                offsetY: 0
            },
            nodeDistance: 20
        },
        shapeDefaults: {
            visual: (options) => visualTemplate(options, forReferenceDesign),
            editable: false,
            connectors: [{ name: "right" }, { name: "left" }]
        },
        connectionDefaults: {
            fromConnector: 'right',
            toConnector: 'left',
            stroke: {
                color: "#000",
                width: 4
            },
            selectable: false,
            editable: false
        },
        click: clickBlock,
        select: ({selected, sender}) => {
            if (selected.length > 0) {
                sender.select([]);
            }
        },
        dataBound: ({sender}) => {
            sender.undoRedoService.clear();
        },
        editable: {
            drag: false,
            remove: false,
            resize: false,
            tools
        },
        zoomRate: 0,
        zoom: 0.5,
        zoomMax: 1.5,
        zoomMin: 0.05,
        pannable: {
            key: 'none'
        },
        selectable: false
    };

    return { ...baseOptions, optionsOverrides };
};

class BlockDiagram extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        className: PropTypes.string,
        forReferenceDesign: PropTypes.bool,
        onClickBlock: PropTypes.func,
        optionOverrides: PropTypes.object,
        dataSource: PropTypes.object.isRequired,
        selectedBlockId: PropTypes.string
    };

    static defaultProps = {
        optionOverrides: {}
    };

    zoomBestFit = () => {
        // Adding this to handle padding and such within the diagram
        const ZOOM_MODIFIER = 0.85;

        const diagram = this.$rootElement.data('kendoDiagram');
        const diagramBBox = diagram.boundingBox();
        const viewport = diagram.viewport();
        const diagWidth = diagramBBox.width + LEFT_OFFSET;
        const diagHeight = diagramBBox.height + TOP_OFFSET;
        const viewWidth = viewport.width;
        const viewHeight = viewport.height;
        const diagWidthFits = diagWidth <= viewWidth;
        const diagHeightFits = diagHeight <= viewHeight;
        const fitToHeight = (viewHeight / diagHeight) * ZOOM_MODIFIER;
        const fitToWidth = (viewWidth / diagWidth) * ZOOM_MODIFIER;

        let zoomFit;

        if (!diagWidthFits && diagHeightFits) {
            zoomFit = fitToWidth;
        }
        else if (diagWidthFits && !diagHeightFits) {
            zoomFit = fitToHeight;
        }
        else {
            // If neither dimension fits in the viewport, or if both fit, then use the smaller ratio to zoom in or out
            zoomFit = fitToWidth > fitToHeight ? fitToHeight : fitToWidth;
        }

        diagram.pan(new kendo.dataviz.diagram.Point(0, 0));
        diagram.zoom(zoomFit);
    };

    exportImage = () => {
        if (this.props.selectedBlockId) {
            this._shouldExportImage = true;
            this.props.onClickBlock && this.props.onClickBlock();
        }
        else {
            this.$rootElement.data('kendoDiagram').exportImage().done(uri => {
                kendo.saveAs({
                    dataURI: uri,
                    fileName: 'diagram.png'
                });
            });
        }
    };

    _create() {
        const { id, optionOverrides, dataSource, onClickBlock, forReferenceDesign } = this.props;
        const options = getOptions(id, optionOverrides, dataSource, onClickBlock,
            this.zoomBestFit, this.exportImage, forReferenceDesign);

        this.$rootElement = $(findDOMNode(this));
        this.$rootElement.kendoDiagram(options);
        this.$rootElement.unbind('mousewheel');
        this.$rootElement.unbind('DOMMouseScroll');
        this.zoomBestFit();
    }

    componentDidMount() {
        this._create();
    }

    componentWillUnmount() {
        const instance = this.$rootElement.data('kendoDiagram');
        instance.unbind();
        if (instance.dataSource) {
            instance.dataSource.unbind('change', instance._refreshHandler);
            instance.dataSource.unbind('error', instance._errorHandler);
        }
        instance.destroy();
    }

    componentDidUpdate() {
        if (this._shouldExportImage) {
            this.$rootElement.data('kendoDiagram').exportImage().done(uri => {
                kendo.saveAs({
                    dataURI: uri,
                    fileName: 'diagram.png'
                });
            });
            this._shouldExportImage = false;
        }
    }

    render() {
        return (<div id={this.props.id} className={this.props.className} style={{height: '550px'}} />);
    }
}

export default BlockDiagram;
