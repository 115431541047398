import find from 'lodash/find';
import { createLoadViewModel } from '../components/CompareAndSelect/helpers/loadWidget';
import { actions as compareAndSelectActions } from 'redux/modules/compareAndSelect';

/*
*
* Subscribes to the store and provides data to the detail views on teh compare and select grid
*
* */
export class CompareAndSelectDetailReduxConnector {
    constructor(store) {
        this.store = store;

        this.models = {
            lightLoad: null,
            typicalLoad: null,
            maxLoad: null
        };

        this.output = null;
    }

    getLatestOutputAndSelectedPartData(output) {
        const state = this.store.getState();
        const data = state.compareAndSelect.computedData;
        const loads = state.compareAndSelect.loads;
        const outputData = find(data, (x) => x.id === this.output.id);
        const partData = find(outputData.parts, (x) => x.id === output.selectedId);
        return { partData, outputLoads: loads[output.id] };
    }

    // this is called in detailInit and is the initialisation point
    // so we read from the state this one time
    createViewModels(output) {
        this.output = { ...output };
        const { partData, outputLoads } = this.getLatestOutputAndSelectedPartData(output);

        this.models.lightLoad = createLoadViewModel(outputLoads.lightLoad, output.amps,
            partData.referenceCurves.efficiency, (load) => {
                this.store.dispatch(compareAndSelectActions.updateLightLoad({
                    outputId: output.id, partId: output.selectedId, load
                }));
            });
        this.models.typicalLoad = createLoadViewModel(outputLoads.typicalLoad, output.amps,
            partData.referenceCurves.efficiency, (load) => {
                this.store.dispatch(compareAndSelectActions.updateTypicalLoad({
                    outputId: output.id, partId: output.selectedId, load
                }));
            });
        this.models.maxLoad = createLoadViewModel(outputLoads.maxLoad, output.amps,
            partData.referenceCurves.efficiency, (load) => {
                this.store.dispatch(compareAndSelectActions.updateMaxLoad({
                    outputId: output.id, partId: output.selectedId, load
                }));
            });

        return this.models;
    }
};
