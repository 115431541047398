import _ from 'lodash';

/* global $ */
import PropTypes from 'prop-types';

import React from 'react';
import { Row } from 'bootstrap-core.js';
import css from './styles.less';
import classNames from 'classnames';
import databind from 'redux/utils/databind';
import { actions as editorActions } from 'redux/modules/editor';
import BufferedInput, { filterAsPercentage } from 'components/BufferedInput';
import { formatPercent } from 'logic/formats';
import BlockDiagram from './BlockDiagram';
import OutputBlockEditPane from './OutputBlockEditPane';
import InputBlockEditPane from './InputBlockEditPane';
import OptimizeButton from 'components/Editor/OptimizeButton';
import Pages from 'components/Pages';
import WindowSize from 'components/WindowSize';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';
const PAGES_OVERFLOW_WIDTH = 1025;

const getSelectedBlock = (blockId, inputs, outputs) => {
    if (!blockId) {
        return false;
    }

    const selectedInputIdx = _.findIndex(inputs, i => i.id === blockId);
    const selectedOutputIdx = _.findIndex(outputs, o => o.id === blockId);

    if (selectedInputIdx >= 0) {
        return { ...inputs[selectedInputIdx], isInput: true, idx: selectedInputIdx };
    }
    else if (selectedOutputIdx >= 0) {
        return { ...outputs[selectedOutputIdx], isInput: false, idx: selectedOutputIdx };
    }

    return false;
};

class DiagramEditView extends React.Component {
    static propTypes = {
        addInputRail: PropTypes.func.isRequired,
        addSystemOutput: PropTypes.func.isRequired,
        changeMinEfficiency: PropTypes.func.isRequired,
        efficiency: PropTypes.number.isRequired,
        inputs: PropTypes.array.isRequired,
        isVisible: PropTypes.bool,
        outputs: PropTypes.array.isRequired,
        selectedBlockId: PropTypes.string,
        setSelectedBlock: PropTypes.func.isRequired,
        dataSource: PropTypes.object.isRequired,
        diagramSettingsClassName: PropTypes.string.isRequired
    };

    static databind({ editor }) {
        return {
            efficiency: editor.efficiency,
            inputs: editor.inputs,
            outputs: editor.outputs,
            selectedBlockId: editor.selectedBlockId
        };
    }

    static actions = {
        addInputRail: editorActions.addInputRail,
        addSystemOutput: editorActions.addSystemOutput,
        changeMinEfficiency: editorActions.changeMinEfficiency,
        setSelectedBlock: editorActions.setSelectedBlock
    };

    state = {
        currentPage: 0
    };

    constructor(props) {
        super(props);
        this.selectedBlock = getSelectedBlock(props.selectedBlockId, props.inputs, props.outputs);
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.selectedBlockId && this.props.selectedBlockId ||
            nextProps.selectedBlockId && !this.props.selectedBlockId) {
            this._diagramShouldResize = true;
        }

        this.selectedBlock = getSelectedBlock(nextProps.selectedBlockId, nextProps.inputs, nextProps.outputs);
        this.setState({
            currentPage: this.selectedBlock ? 1 : 0
        });
    }

    componentDidUpdate() {
        if (this._diagramShouldResize) {
            this.resizeDiagram();

            this._diagramShouldResize = false;
        }
    }

    hasMaxRails = () => {
        return (this.props.inputs.length > 9);
    };

    addSystemOutput = () => {
        this.props.addSystemOutput();
    };

    addInputRail = (e) => {
        if (!this.hasMaxRails()) {
            this.props.addInputRail();
        }
        e.preventDefault();
    };

    resizeDiagram = () => {
        const diagram = this.refs.blockDiagram;

        if (diagram) {
            diagram.$rootElement.data('kendoDiagram').resize();
        }
    };

    formatWholePercent = (value) => {
        return formatPercent(value, 0);
    };

    onChangeMinEff = (str) => {
        if (str) this.props.changeMinEfficiency({ efficiency: parseFloat(str) / 100 });
    };

    handlePaneClose = () => {
        if ($(window).width() >= PAGES_OVERFLOW_WIDTH) {
            this.props.setSelectedBlock();
        }

        this.setState({
            currentPage: 0
        });
    }

    handlePaneCloseWhenInPage = () => {
        this.setState({
            currentPage: 0
        });
    }

    pageTransitionEnd = () => {
        if (this.state.currentPage === 0) {
            this.props.setSelectedBlock();
        }
    }

    getPagesRef = (inst) => {
        this.pagesRef = inst;
    }

    render() {
        const addInputRailClass = this.hasMaxRails() ? classNames(css.addRail, css.disabled)
            : css.addRail;

        const getEditPane = (handlePaneClose, selectedBlock) => {
            if (!selectedBlock) {
                return null;
            }

            return selectedBlock.isInput
                ? (<InputBlockEditPane selectedBlock={this.selectedBlock}
                    onClose={handlePaneClose} />)
                : (<OutputBlockEditPane selectedBlock={this.selectedBlock}
                    onClose={handlePaneClose} />);
        };

        const style = this.props.isVisible ? {} : { height: 0, overflow: 'hidden', visibility: 'hidden' };
        const diagramPage = (
            <div className={css.diagramViewLeft}>
                <BlockDiagram
                    id="blockDiagram"
                    ref="blockDiagram"
                    className={css.diagramContainer}
                    dataSource={this.props.dataSource}
                    onClickBlock={this.props.setSelectedBlock}
                    selectedBlockId={this.props.selectedBlockId} />
            </div>
        );

        return (
            <Row className="content-padding" style={style}>
                <div className={classNames(css.diagramView, 'diagramViewContainer')}>
                    <div className={css.diagramControls}>
                        <a className={classNames(addInputRailClass, 'btn-link')}
                            onClick={this.addInputRail}>
                            + {getText(contentKeys.ADD_A_RAIL)}
                        </a>
                        <a className={classNames(css.addRail, 'btn-link')}
                            onClick={this.addSystemOutput}>
                            + {getText(contentKeys.ADD_AN_OUTPUT)}
                        </a>
                        <div className={this.props.diagramSettingsClassName}>
                            <div className={css.minEfficiency}>
                                <div className={css.minEffLabel}>{getText(contentKeys.ASSUMED_MIN_EFFICIENCY)}</div>
                                <div className={css.minEffField}>
                                    <BufferedInput
                                        name="minEfficiency"
                                        dataTest="min_efficiency"
                                        value={this.props.efficiency}
                                        filter={filterAsPercentage}
                                        format={this.formatWholePercent}
                                        onBufferedChange={this.onChangeMinEff}
                                        clearOnFocus={true} />
                                </div>
                            </div>
                            <div className={css.blockDiagramOptimize}><OptimizeButton /></div>
                        </div>
                    </div>
                    <WindowSize>
                        {({ width }) => {
                            return width < PAGES_OVERFLOW_WIDTH
                                ? (<Pages
                                    onTransitionEnd={this.pageTransitionEnd}
                                    className={css.pages}
                                    currentPage={this.state.currentPage}>
                                    {diagramPage}
                                    {getEditPane(this.handlePaneCloseWhenInPage, this.selectedBlock)}
                                </Pages>)
                                : (<div className={css.layoutContainer}>
                                    {diagramPage}
                                    {getEditPane(this.handlePaneClose, this.selectedBlock)}
                                </div>);
                        }}
                    </WindowSize>
                </div>
            </Row>
        );
    }
}

export default databind(DiagramEditView);
