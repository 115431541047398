import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import Kendo from 'kendo-ui-react';
import KendoTooltipWrapper from 'kendo-plugins/TooltipWrapper';
import classNames from 'classnames';

class PanelDropDownList extends React.Component {
    componentDidMount() {
        if (this.props.info) {
            KendoTooltipWrapper($(this.refs.infoIcon), this.props.info);
        }
    }

    render() {
        const component = this;
        const dropDownOptions = {
            dataTextField: "text",
            dataValueField: "value",
            dataSource: this.props.data,
            value: this.props.selected,
            change: function (e) {
                this.close();
                return component.props.onChange(this.value());
            }
        };

        return (
            <div className={classNames(css.panelItem, this.props.className)}>
                <span className={css.title}>
                    {this.props.title}
                    {(this.props.info)
                        ? <span>&nbsp;<i ref="infoIcon" className="fa fa-info-circle"></i></span> : null}
                </span>
                <Kendo.DropDownList className={css.panelDropDownList} options={dropDownOptions} />
            </div>);
    }
}

PanelDropDownList.propTypes = {
    title: PropTypes.string,
    info: PropTypes.string,
    data: PropTypes.array,
    selected: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string
};

export default PanelDropDownList;
