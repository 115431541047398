import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

function HiddenPartsWarning(props) {
    return (
        <div className={css.hiddenPartsWarning}>
            <span dangerouslySetInnerHTML={{__html:
                getText(contentKeys.OF_SELECTED_PARTS_ARE, {X: props.count, Y: props.total})}}>
            </span>
            <div className={css.unselect} onClick={props.onUnselect}>
                {getText(contentKeys.UNSELECT_HIDDEN_PARTS)}
            </div>
            <span className={css.close} onClick={props.onClose}><i className="fa fa-times"></i></span>
        </div>);
}

HiddenPartsWarning.propTypes = {
    count: PropTypes.number,
    total: PropTypes.number,
    onClose: PropTypes.func,
    onUnselect: PropTypes.func
};

export default HiddenPartsWarning;
