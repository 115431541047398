import {makeAction, makePromise, handleLoadingPromise, createReducer} from '../utils/redux-helpers';
import {getPathnameFromStatus, getStatusFroPathname, ProjectStatus} from 'logic/status';
import updateProjectForRemovedParts from 'logic/updateProjectForRemovedParts';
import api from 'api/projects';
import getHistory from 'util/history';
import {prepareDataForSave, registerBlacklistedKeys, serialize, forcePersist} from 'redux/persist';
import makeAppUrl from 'util/makeAppUrl';
import {actions as authActions} from 'redux/modules/auth';
import {actions as notificationActions} from 'redux/modules/notification';
import {actions as headerActions} from 'redux/modules/header';
import {actions as refDesignActions} from 'redux/modules/referenceDesign';
import {isAValidProjectName} from "logic/validation";

function prefix (type) { return `projects/${type}`; }
function thisModule(state) { return state.projects; }

export const actionTypes = {
    REFRESH_PROJECTS: prefix('REFRESH_PROJECTS'),
    REFRESH_LOCAL_PROJECTS: prefix('REFRESH_LOCAL_PROJECTS'),
    ERROR: prefix('ERROR'),
    RESET: prefix('RESET'),
    LOADING: prefix('SET_CLEAR_LOADING'),
    CLEAR_LOADING: prefix('CLEAR_LOADING'),
    SET_CURRENT_PROJECT_SUMMARY: prefix('SET_CURRENT_PROJECT_SUMMARY'),
    CLEAR_CURRENT_PROJECT_SUMMARY: prefix('CLEAR_CURRENT_PROJECT_SUMMARY'),
    SET_CURRENT_PROJECT_STATUS: prefix('SET_CURRENT_PROJECT_STATUS'),
    SET_CURRENT_PROJECT_NAME: prefix('SET_CURRENT_PROJECT_NAME'),
    TOGGLE_EDIT_NAME_MODAL: prefix('TOGGLE_EDIT_NAME_MODAL'),
    TOGGLE_COPY_EDIT_MODAL: prefix('TOGGLE_COPY_EDIT_MODAL'),
    TOGGLE_SEND_MODAL: prefix('TOGGLE_SEND_MODAL')
};

export const setPendingAction = (pendingActionData) => {
    if (__DEV__) {
        console.log(`set pending projects action ${pendingActionData.actionKey}`); // eslint-disable-line no-console
    }

    sessionStorage.setItem('pendingProjectsAction', serialize(pendingActionData));
};

export const removePendingAction = () => {
    if (__DEV__) {
        console.log('remove pending projects action'); // eslint-disable-line no-console
    }
    sessionStorage.removeItem('pendingProjectsAction');
};

export const runPendingAction = () => {
    const serializedAction = sessionStorage.getItem('pendingProjectsAction');

    if (!serializedAction) {
        return;
    }

    const {actionKey, args} = JSON.parse(decodeURI(serializedAction));

    if (__DEV__) {
        console.log(`run pending projects action ${actionKey}`); // eslint-disable-line no-console
    }

    window.store.dispatch(actions[actionKey].apply(null, args));

    removePendingAction();
};

function awaitWithCatch(dispatch, fn) {
    return async () => {
        try {
            await fn();
        }
        catch (message) {
            dispatch(actions.error(message));
        }
    };
}

export const actions = {
    tryProjectAction: (actionKey, ...args) => async (dispatch, getState) => {
        try {
            await dispatch(authActions.checkIsSignedInNow());
            const {auth, projects, referenceDesign} = getState();
            const {current} = projects;
            const status = current && current.status || '';
            const redirectUrl = makeAppUrl(getPathnameFromStatus(status, referenceDesign.designId || '') || 'projects');

            if (auth.isSignedIn) {
                return dispatch(actions[actionKey].apply(null, args));
            }
            else {
                setPendingAction({actionKey, args});
                dispatch(actions.loading(false));
                return dispatch(authActions.showSignInRedirectWarning(true, redirectUrl));
            }
        }
        catch (e) {
            dispatch(actions.error("Unable to complete request"));
            dispatch(actions.loading(false));
        }
    },
    newProject: (history) => dispatch => {
        dispatch(actions.clearCurrentProjectSummary());
        history.push('/');
    },
    refreshProjects: (promise = Promise.resolve()) =>
        makePromise(actionTypes.REFRESH_PROJECTS, promise.then(api.getAll)),
    refreshLocalProjects: () => async dispatch => {
        const projects = await api.getLocalProjects();

        dispatch(makeAction(actionTypes.REFRESH_LOCAL_PROJECTS, projects));
    },
    openProject: (id, shared, history = getHistory(), rehydrate = window.rehydrateStore) =>
        async (dispatch, getState) => {
            try {
                const project = shared ? await api.getSharedById(id)
                    : await api.getById(id);
                const {payload, ...summary} = project;
                const {updatedAt, ...sharedSummary} = summary; // eslint-disable-line no-unused-vars
                const partsDb = getState().partsDb;

                let decodedPayload = decodeURI(payload);

                // Check for removed parts if the parts DB has updated since this project was saved
                if (summary.partsDb !== partsDb.revision) {
                    const {updatedProject, removedPartIds} =
                        updateProjectForRemovedParts(JSON.parse(decodedPayload), partsDb);
                    decodedPayload = JSON.stringify(updatedProject);

                    if (removedPartIds.length > 0) {
                        dispatch(notificationActions.showError("Parts that were previously included in this project " +
                            "are no longer available and have been removed from this project."));
                    }
                }
                rehydrate(decodedPayload);

                const finalSummary = shared ? sharedSummary : summary;

                dispatch(actions.setCurrentProjectSummary({
                    ...finalSummary
                }));

                forcePersist(() => {
                    // navigate to screen based on project.status
                    history.replace(getPathnameFromStatus(summary.status, summary.referenceDesignId));
                });
            }
            catch (message) {
                dispatch(actions.error(message));
                history.push('/projects');
            }
        },
    editProject: (id, refDesignId) => dispatch => {
        if (refDesignId) {
            return dispatch(refDesignActions.retryRefDesign(refDesignId));
        }

        return getHistory().push(`/open/${id}`);
    },
    loading: (isLoading = true) => makeAction(actionTypes.LOADING, isLoading),
    error: (message) => (dispatch) => {
        dispatch(notificationActions.showError(message));
        return dispatch(makeAction(actionTypes.ERROR, message));
    },
    setCurrentProjectSummary: (projectSummary) => makeAction(actionTypes.SET_CURRENT_PROJECT_SUMMARY, projectSummary),
    clearCurrentProjectSummary: () => makeAction(actionTypes.CLEAR_CURRENT_PROJECT_SUMMARY),
    deleteProject: (id, isLocalProject) => (dispatch, getState) => {
        const deleteFn = isLocalProject ? api.deleteLocalProjectById : api.deleteById;

        const deleteFromServer = awaitWithCatch(dispatch, async () => {
            await deleteFn(id);
            const {current} = thisModule(getState());
            if (current && (id === current.id)) {
                dispatch(actions.clearCurrentProjectSummary());
            }
        });

        return dispatch(actions.refreshProjects(deleteFromServer()));
    },
    updateProject: (id, data) => async dispatch => {
        let updated;
        const updateOnServer = awaitWithCatch(dispatch, async () => {
            updated = await api.updateById(id, data);
        });
        const response = await dispatch(actions.refreshProjects(updateOnServer()));
        return response.value.filter((project) => project.id === updated.requestid)[0];
    },
    renameProject: (id, name) => async dispatch => {
        let renamed;
        const renameOnServer = awaitWithCatch(dispatch, async () => {
            renamed = await api.renameById(id, name);
        });
        const response = await dispatch(actions.refreshProjects(renameOnServer()));
        return response.value.filter((project) => project.id === renamed.requestid)[0];
    },
    renameTheLoadedProject: (name) => async dispatch => {
        dispatch(actions.loading());
        await dispatch(actions.setCurrentProjectName(name));
        dispatch(actions.toggleEditNameModal(false));

        return dispatch(actions.saveTheLoadedProject());
    },
    copyProject: (id, name) => async dispatch => {
        const copyOnServer = awaitWithCatch(dispatch, async () => {
            await api.copyById(id, name);
        });
        return dispatch(actions.refreshProjects(copyOnServer()));
    },
    copyAndEditProject: (id, name, shared = false) => async dispatch => {
        dispatch(actions.loading());

        const newCopy = shared ? await dispatch(actions.createNewProject(name, shared))
            : await api.copyById(id, name, ProjectStatus.COMPARE_AND_SELECT);

        await dispatch(actions.editProject(shared ? newCopy.id : newCopy.requestid));

        return dispatch(headerActions.toggleLinks(true));
    },
    createNewProject: (name, shared = false) => async (dispatch, getState) => {
        const data = prepareDataForSave(getState(), name);
        let created;
        const createOnServer = awaitWithCatch(dispatch, async () => {
            created = await api.createNew(shared ? {...data, status: ProjectStatus.COMPARE_AND_SELECT}
                : data);
        });
        const response = await dispatch(actions.refreshProjects(createOnServer()));
        return response.value.filter((project) => project.id === created.requestid)[0];
    },
    saveTheLoadedProject: (prepData = prepareDataForSave) => async (dispatch, getState) => {
        const {id, ...data} = prepData(getState());
        const updated = await dispatch(actions.updateProject(id, data));
        return dispatch(actions.setCurrentProjectSummary(updated));
    },
    trySavingProject: (skipNewProjectSave) => async (dispatch, getState) => {
        dispatch(actions.loading());
        const {projects} = getState();
        const isLoaded = selectors.isAProjectLoaded(projects);

        if (isLoaded) {
            return dispatch(actions.tryProjectAction('saveTheLoadedProject'));
        }
        else if (!skipNewProjectSave) {
            dispatch(actions.loading(false));
            getHistory().push('/save');
        }
        else {
            dispatch(actions.loading(false));
        }
    },
    saveNewProject: (name) => async (dispatch, getState) => {
        dispatch(actions.loading());
        try {
            await dispatch(authActions.checkIsSignedInNow());
        }
        catch (err) {
            dispatch(actions.loading(false));
            return dispatch(actions.error(err));
        }

        const {auth, projects} = getState();
        if (!auth.isSignedIn) {
            dispatch(actions.loading(false));
            return dispatch(authActions.showSignInRedirectWarning(true, makeAppUrl('save')));
        }

        // Double-check that the project name is valid after auth since an unauthenticated user would not have had
        // access to a project listing
        if (!isAValidProjectName(name, projects.listing.map(p => p.name))) {
            const status = projects.current['status'];

            dispatch(actions.loading(false));

            if (status === ProjectStatus.GENERATING_REFERENCE_DESIGN) {
                return getHistory().push('/reference');
            }

            return getHistory().push('/save');
        }

        let created;
        try {
            created = await dispatch(actions.createNewProject(name));
            dispatch(actions.loading()); // Need to kick off loading again since createNewProject calls refresh
        }
        catch (err) {
            dispatch(actions.loading(false));
            return dispatch(actions.error(err));
        }

        dispatch(actions.setCurrentProjectSummary(created));

        const pathname = getPathnameFromStatus(created.status);
        getHistory().push(pathname);
        dispatch(actions.loading(false));
        return created;
    },
    sendProject: (projectId, email) => async dispatch => {
        try {
            await api.shareById(projectId, email);
            return dispatch(notificationActions.showSuccess('The project was sent successfully.'));
        }
        catch (e) {
            return dispatch(notificationActions.showError('Sending the project failed. Please try again.'));
        }
    },
    setCurrentProjectStatus: (status) => makeAction(actionTypes.SET_CURRENT_PROJECT_STATUS, status),
    setCurrentProjectName: (name) => makeAction(actionTypes.SET_CURRENT_PROJECT_NAME, name),
    toggleEditNameModal: (toggleOn) => makeAction(actionTypes.TOGGLE_EDIT_NAME_MODAL, toggleOn),
    toggleCopyEditModal: (toggleOn) => makeAction(actionTypes.TOGGLE_COPY_EDIT_MODAL, toggleOn),
    toggleSendModal: (toggleOn) => makeAction(actionTypes.TOGGLE_SEND_MODAL, toggleOn),
    reset: () => makeAction(actionTypes.RESET)
};

const initialState = {
    listing: [],
    current: {},
    loading: false,
    showEditNameModal: false,
    showCopyEditModal: false,
    showSendModal: false
};

const ACTION_HANDLERS = {
    ...handleLoadingPromise(actionTypes.REFRESH_PROJECTS, (state, {loading, data, error}) => {
        if (loading) return { ...state, loading, error };
        if (error) {
            return { ...state, loading: false, error, listing: [] };
        }
        return { ...state, listing: data, error: false, loading: false };
    }),
    [actionTypes.REFRESH_LOCAL_PROJECTS]: (state, {payload}) => {
        const regularProjects = state.listing.filter(p => !p.isLocalProject);
        const sortedLocalProjects = api.sortProjects(payload);

        return { ...state, listing: sortedLocalProjects.concat(regularProjects) };
    },
    [actionTypes.RESET]: (state) => ({ ...initialState }),
    [actionTypes.LOADING]: (state, {payload}) => ({ ...state, loading: payload }),
    [actionTypes.SET_CURRENT_PROJECT_SUMMARY]: (state, {payload}) => {
        return { ...state, current: payload, loading: false, showEditNameModal: false };
    },
    [actionTypes.CLEAR_CURRENT_PROJECT_SUMMARY]: (state) => ({ ...state, current: {} }),
    [actionTypes.ERROR]: (state, {payload}) => {
        const error = (payload === 'OK') ? undefined : payload;
        return { ...state, error, loading: false };
    },
    [actionTypes.SET_CURRENT_PROJECT_STATUS]: (state, {payload}) => {
        const {current} = state;
        return { ...state, current: { ...current, status: payload } };
    },
    [actionTypes.SET_CURRENT_PROJECT_NAME]: (state, {payload}) => {
        const {current} = state;
        return {...state, current: { ...current, name: payload }};
    },
    [actionTypes.TOGGLE_EDIT_NAME_MODAL]: (state, {payload}) => {
        return { ...state, showEditNameModal: payload };
    },
    [actionTypes.TOGGLE_COPY_EDIT_MODAL]: (state, {payload}) => {
        return { ...state, showCopyEditModal: payload };
    },
    [actionTypes.TOGGLE_SEND_MODAL]: (state, {payload}) => {
        return { ...state, showSendModal: payload };
    },
    '@@router/LOCATION_CHANGE': (state, {payload}) => {
        const { current } = state;
        const { pathname } = payload;

        const statusFromPath = getStatusFroPathname(pathname);
        // don't change to an invalid status, remember last instead if one exists
        const status = statusFromPath || (current && current.status);

        if (status) {
            return { ...state, current: { ...current, status } };
        }

        // if no valid status can be derived from the current situation, don't change state
        return state;
    }
};

export const selectors = {
    currentProject: module => {
        return module['current'];
    },
    isAProjectLoaded: module => {
        return module['current'] && (module.current['id'] != null);
    },
    projectsListing: module => module.listing
};

registerBlacklistedKeys('projects', ['listing', 'showEditNameModal', 'showCopyEditModal', 'showSendModal', 'loading']);

export default createReducer(initialState, ACTION_HANDLERS);
