import {makeAction, createReducer} from '../utils/redux-helpers';
import {referenceDesignInitialState as initialState} from '../initialStates';
import {generateNewReferenceDesign, getDesignStatus,
    getDesignFiles, emailZipFile, regenerateDesign} from 'api/referenceDesign';
import getDesignInputParams from 'logic/getProjectInputParams';
import selectPartsWithNoSchematic from 'logic/selectedPartsWithNoSchematic';
import {ProjectStatus} from 'logic/status';
import getHistory from 'util/history';
import moment from 'moment';
import {actions as headerActions} from "./header";
import {actions as projectActions, selectors as projectSelectors} from 'redux/modules/projects';
import {actions as authActions} from 'redux/modules/auth';
import {actions as notificationActions} from 'redux/modules/notification';
import invariant from 'invariant';
import {registerBlacklistedKeys} from 'redux/persist';

const prefix = (type) => `referenceDesign/${type}`;
const REF_DESIGN_NOT_FOUND_ERROR_MSG = "Not logged in or Reference design not found";

const createFileEntry = (url, type, projname, prefix) => ({url, type, fileName: `${prefix}-${projname}`});

export const actionTypes = {
    QUEUE_NEW_DESIGN: prefix('QUEUE_NEW_DESIGN'),
    CREATE_NEW_DESIGN: prefix('CREATE_NEW_DESIGN'),
    DESIGN_EXCLUDES_PARTS: prefix('DESIGN_EXCLUDES_PARTS'),
    RECEIVE_DESIGN_ID: prefix('RECEIVE_DESIGN_ID'),
    FETCH_STATUS: prefix('FETCH_STATUS'),
    LOAD_STATUS: prefix('LOAD_STATUS'),
    RECEIVE_STATUS: prefix('RECEIVE_STATUS'),
    FETCH_FILES: prefix('FETCH_FILES'),
    LOAD_FILES: prefix('LOAD_FILES'),
    RECEIVE_FILES: prefix('RECEIVE_FILES'),
    RECEIVE_ERROR: prefix('RECEIVE_ERROR'),
    SHOW_EMAIL_ZIP_MODAL: prefix('SHOW_EMAIL_ZIP_MODAL'),
    CLOSE_EMAIL_ZIP_MODAL: prefix('CLOSE_EMAIL_ZIP_MODAL'),
    EMAIL_ZIP_FILE: prefix('EMAIL_ZIP_FILE'),
    SENDING_EMAIL: prefix('SENDING_EMAIL'),
    RECEIVE_SEND_EMAIL_SUCCESS: prefix('RECEIVE_SEND_EMAIL_SUCCESS'),
    RECEIVE_SEND_EMAIL_ERROR: prefix('RECEIVE_SEND_EMAIL_ERROR'),
    UPDATE_NAME: prefix('UPDATE_NAME'),
    RESET: prefix('RESET'),
    FETCH_AUTH_STATUS: prefix('FETCH_AUTH_STATUS'),
    STORE_SVG: prefix('STORE_SVG'),
    SET_REQUESTED: prefix('SET_REQUESTED')
};

export const actions = {
    startGeneratingReferenceDesign: (blockDiagram) => async (dispatch, getState) => {
        try {
            await dispatch(authActions.checkIsSignedInNow());
        }
        catch (err) {
            return dispatch(actions.error(err));
        }

        // This is necessary because we sometimes arrive in this function immediately after a login redirect before
        // initial project loading can occur. It would be better to change our asyncInit and init functions in
        // main.js to block until all preliminary loading is complete, but that will take more effort.
        await dispatch(projectActions.refreshProjects());

        const state = getState();
        const {projects} = state;

        if (projectSelectors.isAProjectLoaded(projects)) {
            await dispatch(projectActions.saveTheLoadedProject());
        }
        else {
            await dispatch(projectActions.saveNewProject(projects.current.name));
        }

        const projectsAfterSave = getState().projects;

        invariant(projectSelectors.isAProjectLoaded(projectsAfterSave),
            "a project should always be loaded ahead of generating a reference design");

        dispatch(actions.queueNewDesign());
        const currentProject = projectsAfterSave.current;
        const newDesignParams = getDesignInputParams(state, currentProject.name, currentProject.id);

        // bail if we got to this point without all the required inputs (ie refreshed in the middle of workflow)
        if (!newDesignParams) {
            getHistory().push('/');
            return;
        }

        const diagramBBox = blockDiagram.boundingBox();

        const svg = await blockDiagram.exportSVG({ raw: true });
        const svgWithViewBox = svg.replace('<svg', `<svg viewBox='0 0 ${diagramBBox.width} ${diagramBBox.height}'`);
        const svgWithDoubleQuotes = svgWithViewBox.replace(/'/g, '"');
        const encodedSvg = encodeURIComponent(svgWithDoubleQuotes);

        try {
            await dispatch(actions.storeSVG(encodedSvg));

            newDesignParams.diagramSVG = encodedSvg;

            const results = await generateNewReferenceDesign(newDesignParams);
            const designId = results.requestid;
            dispatch(actions.receiveDesignId(designId));
            // if this was successful the user must be signed in
            getHistory().push('/reference/new/' + designId);
            return results;
        }
        catch (err) {
            await dispatch(projectActions.setCurrentProjectStatus(ProjectStatus.GENERATING_ERROR));
            dispatch(projectActions.saveTheLoadedProject());
            return dispatch(actions.receiveError(err));
        }
    },
    retryRefDesign: (designId) => async dispatch => {
        const {requestid} = await regenerateDesign(designId);

        if (requestid) {
            // We set the project status to GENERATING as a safety measure since we do not re-fetch the project,
            // even though invoking the regenerate API should switch it in the backend
            await dispatch(projectActions.setCurrentProjectStatus(ProjectStatus.GENERATING_REFERENCE_DESIGN));
            await dispatch(actions.setRequested(false));
            return getHistory().push(`/reference/new/${requestid}`);
        }
        else {
            dispatch(notificationActions.showError('The reference design may no longer be available.'));
            return getHistory().push('/');
        }
    },
    queueNewDesign: () => makeAction(actionTypes.QUEUE_NEW_DESIGN),
    designExcludesParts: (data) => makeAction(actionTypes.DESIGN_EXCLUDES_PARTS, data),
    receiveDesignId: (data) => makeAction(actionTypes.RECEIVE_DESIGN_ID, data),
    fetchStatus: (designId) => {
        return (dispatch) => {
            dispatch(actions.loadStatus());
            return getDesignStatus(designId)
                .then((results) => dispatch(actions.receiveStatus(results)))
                .catch((message) => dispatch(actions.receiveError(message)));
        };
    },
    loadStatus: () => makeAction(actionTypes.LOAD_STATUS),
    receiveStatus: (data) => makeAction(actionTypes.RECEIVE_STATUS, data),
    fetchFiles: (designId) => {
        return (dispatch, getState) => {
            const state = getState();
            const noSchematicParts = selectPartsWithNoSchematic(state);
            if (noSchematicParts.length > 0) {
                dispatch(actions.designExcludesParts(noSchematicParts));
            }
            dispatch(actions.loadFiles());
            return getDesignFiles(designId)
                .then((results) => {
                    // If there is no zip size but the designId does return some data, then the design files probably
                    // expired. Go ahead and retry generation
                    if (results.zipsize === null && results.projname) {
                        dispatch(headerActions.setHeaderText(results.projname));
                        dispatch(projectActions.setCurrentProjectName(results.projname));
                        dispatch(actions.retryRefDesign(designId));
                    }
                    else if (results.zipsize === null && !results.projname) {
                        dispatch(actions.receiveError(REF_DESIGN_NOT_FOUND_ERROR_MSG));
                    }
                    else {
                        const { projname } = results;
                        dispatch(headerActions.setHeaderText(projname));
                        dispatch(projectActions.setCurrentProjectName(projname));
                        dispatch(actions.updateName(projname));
                        dispatch(projectActions.setCurrentProjectStatus(ProjectStatus.DESIGN_FILE_DOWNLOAD));
                        dispatch(actions.receiveFiles(designId, results));
                    }
                })
                .catch((message) => dispatch(actions.receiveError(message)));
        };
    },
    loadFiles: () => makeAction(actionTypes.LOAD_FILES),
    receiveFiles: (designId, files) => makeAction(actionTypes.RECEIVE_FILES, {designId, files}),
    receiveError: (data) => (dispatch) => {
        dispatch(notificationActions.showError(data));
        return dispatch(makeAction(actionTypes.RECEIVE_ERROR, data));
    },
    showEmailZipModal: () => makeAction(actionTypes.SHOW_EMAIL_ZIP_MODAL),
    closeEmailZipModal: () => makeAction(actionTypes.CLOSE_EMAIL_ZIP_MODAL),
    emailZipFile: (email, designId) => {
        if (!email || !designId) return; // defensive

        return (dispatch) => {
            dispatch(actions.sendingEmail());
            return emailZipFile(email, designId)
                .then((results) => dispatch(actions.receiveSendEmailSuccess(results)))
                .catch(() => dispatch(actions.receiveSendEmailError()));
        };
    },
    sendingEmail: () => makeAction(actionTypes.SENDING_EMAIL),
    receiveSendEmailSuccess: () => makeAction(actionTypes.RECEIVE_SEND_EMAIL_SUCCESS),
    receiveSendEmailError: () => makeAction(actionTypes.RECEIVE_SEND_EMAIL_ERROR),
    updateName: (projectName) => makeAction(actionTypes.UPDATE_NAME, projectName),
    reset: () => makeAction(actionTypes.RESET),
    storeSVG: (svg) => makeAction(actionTypes.STORE_SVG, svg),
    setRequested: (requested = true) => makeAction(actionTypes.SET_REQUESTED, requested)
};

const ACTION_HANDLERS = {
    [actionTypes.QUEUE_NEW_DESIGN]: (state) => {
        return { ...state, requested: true };
    },
    [actionTypes.DESIGN_EXCLUDES_PARTS]: (state, {payload}) => {
        return { ...state, excludedParts: payload };
    },
    [actionTypes.RECEIVE_DESIGN_ID]: (state, {payload}) => {
        return { ...state, designId: payload, requested: false };
    },
    [actionTypes.LOAD_STATUS]: (state) => {
        return { ...state, requested: true };
    },
    [actionTypes.RECEIVE_STATUS]: (state, {payload}) => {
        const queuePosition = parseInt(payload.queue, 10);
        const waitTime = queuePosition > 0 ? queuePosition * 45 : null;
        const processingStatus = parseInt(payload.status, 10);

        let status;
        let error = false;
        let errorMessage = "";
        if (processingStatus === 7) {
            status = "queueing";
        }
        else if (processingStatus === 6) {
            status = "waiting";
        }
        else if (processingStatus <=5 && processingStatus >= 1) {
            status = "generating";
        }
        else if (processingStatus === 0) {
            status = "done";
        }
        else {
            status = "done";
            error = true;
            errorMessage = payload.status === null ? REF_DESIGN_NOT_FOUND_ERROR_MSG : "Processing error";
        }

        return { ...state,
            ...payload,
            requested: false,
            status,
            waitTime,
            queuePosition,
            error,
            errorMessage };
    },
    [actionTypes.LOAD_FILES]: (state) => {
        return { ...state, requested: true, files: [], zipfile: false };
    },
    [actionTypes.RECEIVE_FILES]: (state, {payload}) => {
        const {designId, files} = payload;
        const {schematicpdf, schematicdsn, schematicbom, zipsize, h,
            projname, diagramSVG, diagramPNG, projectId} = files;
        const baseUrl = `https://${h}/api/pcompass/download/`;
        const fileUrl = `${baseUrl}file?id=${designId}`;

        const fileList = [];
        const zipfile = {
            size: zipsize,
            url: `${fileUrl}&type=zip`
        };

        if (schematicpdf) {
            fileList.push(createFileEntry(fileUrl + '&type=schematicpdf', 'pdf', projname, 'Schematic'));
        }

        if (schematicdsn) {
            fileList.push(createFileEntry(fileUrl + '&type=schematicdsn', 'dsn', projname, 'OrCAD'));
        }

        if (schematicbom) {
            fileList.push(createFileEntry(fileUrl + '&type=schematicbom', 'xslx', projname, 'BOMParts List'));
        }

        if (diagramPNG) {
            fileList.push(createFileEntry(fileUrl + '&type=diagramPNG', 'png', projname, 'Diagram'));
        }

        if (diagramSVG) {
            fileList.push(createFileEntry(fileUrl + '&type=diagramSVG', 'svg', projname, 'Diagram'));
        }

        return { ...state,
            requested: false,
            fileList,
            zipfile,
            error: false,
            errorMessage: "",
            referenceDesignSVG: diagramSVG,
            projectId,
            designId };
    },
    [actionTypes.RECEIVE_ERROR]: (state, {payload}) => {
        return { ...state, requested: false, error: true };
    },
    [actionTypes.SHOW_EMAIL_ZIP_MODAL]: (state) => {
        return { ...state, emailZipModalShown: true };
    },
    [actionTypes.CLOSE_EMAIL_ZIP_MODAL]: (state) => {
        return { ...state, emailZipModalShown: false, emailError: false };
    },
    [actionTypes.SENDING_EMAIL]: (state) => {
        return { ...state, sendingEmail: true };
    },
    [actionTypes.RECEIVE_SEND_EMAIL_SUCCESS]: (state) => {
        return { ...state, sendingEmail: false, emailError: false };
    },
    [actionTypes.RECEIVE_SEND_EMAIL_ERROR]: (state) => {
        return { ...state, sendingEmail: false, emailError: true };
    },
    [actionTypes.UPDATE_NAME]: (state, {payload}) => {
        // alert("update name " + payload)
        const lastModified = moment().format('D MMM YYYY h:mm A'); // Just setting to current time for now
        return { ...state, projectName: payload, lastModified };
    },
    [actionTypes.RESET]: (state) => ({ ...initialState, projectName: state.projectName }),
    [actionTypes.STORE_SVG]: (state, {payload}) => {
        return { ...state, referenceDesignSVG: payload };
    },
    [actionTypes.SET_REQUESTED]: (state, {payload}) => {
        return { ...state, requested: payload };
    }
};

registerBlacklistedKeys('referenceDesign',
    ['requested', 'error', 'errorMessage', 'sendingEmail', 'emailError']
);

export default createReducer(initialState, ACTION_HANDLERS);
