import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'bootstrap-core';
import classNames from 'classnames';
import css from './styles.less';

const PrettySquareToggleButton = (props) => {
    const classes = classNames(css.button, 'basic', {[css.selected]: props.isSelected});

    return (<div className={classNames(css.prettySquareToggleBtn, props.className)}>
        <Button
            className={classes}
            onClick={props.onClick}>
            {(props.showModified) ? <div className={css.modified}><div /></div> : null}
            <a>{props.children}</a>
        </Button>
    </div>);
};

PrettySquareToggleButton.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    isSelected: PropTypes.bool,
    showModified: PropTypes.bool,
    onClick: PropTypes.func
};

export default PrettySquareToggleButton;
