import {ALTERA_VENDOR_NAME} from 'logic/constants';

const format = {
    CELL_A1: 'Altera Corporation',
    CELL_A2: 'PowerPlay Early Power Estimator',
    CELL_A3: 'Stratix V',
    POWER_DATA_TABLE_FIRST_COL_HEADING: 'Power Supply Current (A)',
    POWER_DATA_OFFSET_FROM_HEADER: 2,
    NUM_ROWS_IN_POWER_TABLE: 51
};

function getCellValue(sheet, ref) {
    if (!sheet[ref]) return '';
    return sheet[ref].v;
}

export function findFirstPowerDataRow(report) {
    for (let i = 1; i < 100; i++) {
        const cell = getCellValue(report, 'A' + i.toString());
        if (cell === format.POWER_DATA_TABLE_FIRST_COL_HEADING) {
            return i + format.POWER_DATA_OFFSET_FROM_HEADER;
        }
    }
    throw new Error('Does not appear to be a valid Altera file');
}

export function extractValidRows(report, startAt, numRows) {
    let rows = [];
    const stopAt = startAt + numRows;
    for (let idx = startAt; idx <= stopAt; idx++) {
        const item = readPowerDataFromRow(report, idx);
        if (item) {
            rows.push(item);
        }
    }
    return rows;
}

export function readPowerDataFromRow(report, N) {
    const nameVolts = getCellValue(report, 'A' + N.toString());
    const amps = parseFloat(getCellValue(report, 'F' + N.toString()));
    const matches = nameVolts.match(/(.*)\((.*)V\)/);
    if (matches) {
        let name = matches[1].replace(/\s/g, '').replace(/^I/, 'V');
        const volts = parseFloat(matches[2]);
        return {
            name,
            volts,
            amps};
    }
    return null;
}

function isNotAVCCSubRow(r) {
    return ((r.name !== "VCCL") && (r.name !== "VCCP"));
}

function hasOutputCurrent(r) {
    return (r.amps > 0);
}

export function validateAsAltera(workbook) {
    const report = workbook.Sheets["Report"];
    return (!!report &&
    getCellValue(report, 'A1') === format.CELL_A1 &&
    getCellValue(report, 'A2') === format.CELL_A2 &&
    getCellValue(report, 'A3') === format.CELL_A3);
}

export function extractDesignSummary(workbook) {
    const report = workbook.Sheets['Report'];
    const startAt = findFirstPowerDataRow(report);
    let outputs = extractValidRows(report, startAt, format.NUM_ROWS_IN_POWER_TABLE);

    outputs = outputs
        .filter(isNotAVCCSubRow)
        .filter(hasOutputCurrent);

    return { name: ALTERA_VENDOR_NAME, outputs };
}

const parser = {
    format,
    validateAsAltera,
    extractDesignSummary
};

export default parser;
