import EditorCSVGenerator from 'logic/EditorCSVGenerator';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import max from 'lodash/max';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

export default class CompareAndSelectCSVGenerator {
    writeHeadlines(csv, state) {
        if (state.selectionsHavePartialEfficiencyData) {
            csv.write(["WARNING",
                "Summary values are calculated using incomplete efficiency data. " +
               "Review parts below to see missing data."]).endl().lineBreak();
        }

        csv.write(["Max Power Dissipation"]).gap()
            .write([state.systemPowerDissipation.reduce((M, p) => Math.max(M, p[1]), 0)])
            .endl()
            .write(["Light Load Efficiency"]).gap().write([state.systemLightLoadEfficiency]).endl()
            .write(["Typical Load Efficiency"]).gap().write([state.systemTypicalLoadEfficiency]).endl()
            .write(["Max Load Efficiency"]).gap().write([state.systemMaxLoadEfficiency]).endl()
            .write(["Ambient Temperature"]).gap().write([state.ambientTemperature]).endl();

        csv.lineBreak();

        csv.write(["System Curves"]).endl()
            .write(["System Efficiency (% Load Step)"]).gap(2)
            .write(state.systemEfficiency.map((p) => p[1])).endl()
            .write(["System Power Dissipation (% Load Step)"]).gap(2)
            .write(state.systemPowerDissipation.map((p) => p[1])).endl()
            .write(["Load Current (% of max for each output"]).gap(2)
            .write(state.systemEfficiency.map((p) => p[0])).endl();

        csv.lineBreak();
    }

    writeGrid(csv, state) {
        csv.write([getText(contentKeys.SOURCE_RAIL), "Output", "Select Device", "PMBus Address",
            "Light Load", "LL Eff", "Typical Load", "TL Eff", "Max Load", "ML Eff",
            "J(Max)", "Silicon Cost ($/1k)"]).endl();

        // side effects
        EditorCSVGenerator.resolveConnections({ inputs: state.inputs, outputs: state.results });

        state.results.forEach((output) => {
            const selectedPartId = state.selectedPartIds[output.id];
            const part = find(output.items, (part) => part.id === selectedPartId);
            const deviceName = (part) ? part.deviceName : "No Solutions";
            const partCost = (part) ? part.msrp : "";
            let pmBusAddress = state.selectedPmbusAddresses[output.id][selectedPartId];

            const loads = state.loads[output.id];
            const eff = state.efficiencies[output.id];

            const outputData = find(state.computedData, (o) => output.id === o.id);
            const partData = find(outputData.parts, (part) => part.id === selectedPartId);
            const tempCurve = (partData) ? partData.referenceCurves.junctionTemp : null;

            let jMax = "";
            if ((tempCurve != null) && isArray(tempCurve.y) && (tempCurve.y.length > 1)) {
                jMax = max(tempCurve.y);
            }

            csv.write([output.sourceRail, output.name, deviceName,
                pmBusAddress || "",
                loads.lightLoad,
                (eff.lightLoad != null) ? eff.lightLoad : "",
                loads.typicalLoad,
                (eff.typicalLoad != null) ? eff.typicalLoad : "",
                loads.maxLoad,
                (eff.maxLoad != null) ? eff.maxLoad : "",
                jMax,
                partCost]).endl();
        });
    }

    stringify(csv, state) {
        csv.write(["Compare and Select"]).gap(1).write(["Selections"]).endl();
        csv.lineBreak();
        this.writeHeadlines(csv, state);
        this.writeGrid(csv, state);
    }
};
