import {findIndex, range} from 'lodash';

export function linear(y1, y0, x1, x0, x) {
    if ((y1 === null) || (y0 === null)) {
        return null;
    }
    if ((x1 - x0) === 0) {
        return y0;
    }
    const slope = (y1 - y0)/(x1 - x0);
    return slope*(x - x0) + y0;
}

export function lookupFromReferenceCurve(refCurve, x) {
    if (x <= refCurve.x[0]) return refCurve.y[0];
    if (x >= refCurve.x[refCurve.x.length-1]) return refCurve.y[refCurve.x.length-1];
    const idx = findIndex(refCurve.x, (value) => value >= x);
    return linear(refCurve.y[idx], refCurve.y[idx-1], refCurve.x[idx], refCurve.x[idx-1], x);
}

export function extrapolateNextValue(refCurve, curve, threshold) {
    const firstNullIdx = curve.y.reduce((result, next, idx) => {
        if (next !== null) {
            return idx+1;
        }
        return result;
    }, 0);

    const lastDataIdx = refCurve.x.length - 1;
    const distance = (curve.x[firstNullIdx] - refCurve.x[lastDataIdx]);
    if ((firstNullIdx <= (curve.x.length-1)) &&
        (distance <= threshold)) {
        const extrapolated = linear(refCurve.y[lastDataIdx], refCurve.y[lastDataIdx-1],
            refCurve.x[lastDataIdx], refCurve.x[lastDataIdx-1],
            curve.x[firstNullIdx]);
        curve.y[firstNullIdx] = extrapolated;
    }

    return curve;
}

export function resampleReferenceCurve(refCurve, nSamples, maxIout, numDevicesinSolution) {
    // we should not render (extrapolate) data beyond the valid range of data points that we have
    // but we always need to render at the sample points across the full range that has been requested
    // returning null y values if point go out of range
    const step = maxIout/(nSamples-1);
    const xRange = [ ...range(0, maxIout, step), maxIout ];
    const ioutOfLastDataPoint = refCurve.x[refCurve.x.length-1];
    const resampled = xRange
        .map((iout) => iout/numDevicesinSolution) // take into account current sharing mode
        .map((iout) => {
            const idx = findIndex(refCurve.x, (value) => value >= iout, 1);
            if ((iout > ioutOfLastDataPoint) || (idx === -1)) {
                // we have gone past the end of the available data, clamp to the last real datapoint
                return null;
            }
            return linear(refCurve.y[idx], refCurve.y[idx - 1], refCurve.x[idx], refCurve.x[idx - 1], iout);
        });

    return extrapolateNextValue(refCurve, { y: resampled, x: xRange }, 0.3*step);
}
