import findKey from 'lodash/findKey';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

export const ProjectStatus = {
    START: "START",
    EDITOR: "EDITOR",
    SUGGESTED_PARTS: "SUGGEST",
    COMPARE_AND_SELECT: "COMPARE",
    ENTER_PROJECT_NAME_FOR_REF_DESIGN: "ENTER_PROJNAME",
    GENERATING_REFERENCE_DESIGN: "GENERATING",
    DESIGN_FILE_DOWNLOAD: "DESIGN_FILE_DOWNLOAD",
    GENERATING_ERROR: "GENERATING_ERROR"
};

const StatusMessageMap = {
    [ProjectStatus.START]: 'Starting New Project',
    [ProjectStatus.EDITOR]: getText(contentKeys.IN_PROGRESS_INPUTS_AND),
    [ProjectStatus.SUGGESTED_PARTS]: getText(contentKeys.IN_PROGRESS_REVIEWING_MATCHED),
    [ProjectStatus.COMPARE_AND_SELECT]: getText(contentKeys.IN_PROGRESS_COMPARE_AND),
    [ProjectStatus.ENTER_PROJECT_NAME_FOR_REF_DESIGN]: getText(contentKeys.IN_PROGRESS_NAMING_THE),
    [ProjectStatus.GENERATING_REFERENCE_DESIGN]: getText(contentKeys.REFERENCE_DESIGN_GENERATING_PLEASE),
    [ProjectStatus.DESIGN_FILE_DOWNLOAD]: getText(contentKeys.DONE_REFERENCE_DESIGN_FILES),
    [ProjectStatus.GENERATING_ERROR]: getText(contentKeys.ERROR_YOUR_REFERENCE_DESIGN)
};

const StatusLocationMap = {
    [ProjectStatus.START]: '/',
    [ProjectStatus.EDITOR]: '/editor',
    [ProjectStatus.SUGGESTED_PARTS]: '/editor/solutions',
    [ProjectStatus.COMPARE_AND_SELECT]: '/compare',
    [ProjectStatus.ENTER_PROJECT_NAME_FOR_REF_DESIGN]: '/reference',
    [ProjectStatus.GENERATING_REFERENCE_DESIGN]: '/reference/new',
    [ProjectStatus.DESIGN_FILE_DOWNLOAD]: '/reference/live/:referenceDesignId',
    [ProjectStatus.GENERATING_ERROR]: '/reference/error'
};

export const isReferenceDesignStatus = (status) => {
    return status === ProjectStatus.ENTER_PROJECT_NAME_FOR_REF_DESIGN ||
           status === ProjectStatus.GENERATING_REFERENCE_DESIGN ||
           status === ProjectStatus.GENERATING_ERROR ||
           status === ProjectStatus.DESIGN_FILE_DOWNLOAD;
};

export const mapStatusToMessage = (status) => {
    const message = StatusMessageMap[status];
    return message || "Invalid project status";
};

export const getStatusFroPathname = (location) => {
    if (location === null) {
        // perhaps this should throw an exception, it not possible for us to
        // generate a status from a location at this point as we cannot save from
        // this state we can only mark a project in this state when entering it
        return ProjectStatus.GENERATING_REFERENCE_DESIGN;
    }
    else if (location.match(/^\/reference\/live\/[\w\d]+/) != null) {
        return ProjectStatus.DESIGN_FILE_DOWNLOAD;
    }
    const status = findKey(StatusLocationMap, l => l === location);
    return status;
};

export const getPathnameFromStatus = (status, referenceDesignId) => {
    const location = StatusLocationMap[status];
    if (!location) return null;

    if (status === ProjectStatus.DESIGN_FILE_DOWNLOAD) {
        return location.replace(/:referenceDesignId/, referenceDesignId);
    }

    return location;
};

export default ProjectStatus;
