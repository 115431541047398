export default function adjustSelectedMsrpForNOutputParts(outputs) {
    return outputs.map((output, idx) => {
        if (!output.selectedChildIds) return output;

        const childIdx = output.selectedChildIds.indexOf(output.id);

        if ((childIdx > 0) &&
            (idx > 0) &&
            output.selectedId === outputs[idx - 1].selectedId &&
            output.selectedNumOutputs > 1) {
            output.selectedMsrp = null;
        }
        return output;
    });
}
