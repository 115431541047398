/* global __HELP_URL__ */
import locale from './locale';

export default function helpUrl() {
    if (__API_LOCALE__) {
        return '/' + locale() + __HELP_URL__;
    }

    return __HELP_URL__;
}
