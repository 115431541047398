import PropTypes from 'prop-types';
import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import ActionButton from 'components/ActionButton';
import Error from 'components/Error';
import EmailZipModal from './EmailZipModal';
import map from 'lodash/map';
import css from './styles.less';
import ModalWindow from 'components/ModalWindow';
import LoadingSpinner from "components/LoadingSpinner";
import classNames from 'classnames';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const renderFile = ({type, fileName, url}) => {
    return (
        <li key={url}>
            <a href={url}>{fileName}</a><span className={css.fileExtension}>.{type}</span>
        </li>);
};

renderFile.propTypes = {
    type: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default class FileListing extends React.Component {
    static propTypes = {
        designId: PropTypes.string.isRequired,
        fileList: PropTypes.arrayOf(PropTypes.shape({
            fileName: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired
        })).isRequired,
        zipfile: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        fetchFiles: PropTypes.func.isRequired,
        excludedParts: PropTypes.arrayOf(PropTypes.object),
        showEmailZipModal: PropTypes.func.isRequired,
        emailZipModalShown: PropTypes.bool,
        referenceDesignSVG: PropTypes.string,
        requested: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            diagramEnlarged: false
        };
    }

    componentDidMount() {
        this.props.fetchFiles(this.props.designId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.designId && nextProps.designId !== this.props.designId) {
            this.props.fetchFiles(nextProps.designId);
        }
    }

    downloadZipFile = () => {
        const zipUrl = this.props.zipfile.url;

        if (!zipUrl) return;

        window.location = zipUrl;
    };

    toggleEnlargedDiagram = () => {
        this.setState({
            diagramEnlarged: !this.state.diagramEnlarged
        });
    };

    render() {
        const {fileList, zipfile, excludedParts, referenceDesignSVG, requested} = this.props;

        if (requested) {
            return (
                <div className={css.loadingMessageContainer}>
                    <LoadingSpinner message={getText(contentKeys.LOADING_DESIGN_FILES)} />
                </div>
            );
        }

        const diagramDataUri = `data:image/svg+xml,${referenceDesignSVG}`;
        const hasSVG = !!referenceDesignSVG;

        let excludedPartsMsg = "";
        if (excludedParts.length > 0) {
            const partList = map(excludedParts, 'deviceName').join();
            excludedPartsMsg = getText(contentKeys.THE_FOLLOWING_PARTS_ARE, partList);
        }

        return (
            <Row className={css.fileListingContainer}>
                <h2>{getText(contentKeys.REFERENCE_DESIGN)}</h2>
                {excludedPartsMsg && <Error className={css.excludedPartsMsg}>{excludedPartsMsg}</Error>}
                <div className={css.fileListingFlexContainer}>
                    {hasSVG &&
                        <div className={css.diagramWrapper}>
                            <div className={css.diagramTitle}>
                                <span className={css.contentLabel}>{getText(contentKeys.BLOCK_DIAGRAM)}</span>
                                <a onClick={this.toggleEnlargedDiagram}>
                                    <i className="fa fa-search-plus" />{getText(contentKeys.ENLARGE)}
                                </a>
                            </div>
                            <img className={css.blockDiagramSVG} src={diagramDataUri} />
                        </div>
                    }
                    <div className={css.fileListWrapper}>
                        <div>
                            <span className={css.contentLabel}>{getText(contentKeys.CONTENTS)}</span>
                            <span className={css.fileSize}>{zipfile.size}</span>
                        </div>
                        <ul className={`${css.fileList} list-unstyled`}>{fileList.map(renderFile)}</ul>
                    </div>
                    <div className={css.buttonsWrapper}>
                        <ActionButton
                            id="Generate_DownloadZip"
                            label={getText(contentKeys.DOWNLOAD_ZIP_FILE)}
                            className={classNames(css.wideButton, 'large')}
                            onClick={this.downloadZipFile} />
                        <ActionButton
                            id="Generate_EmailZip"
                            label={getText(contentKeys.EMAIL_ZIP_FILE)}
                            className={`${classNames(css.wideButton, 'large')} basic`}
                            onClick={this.props.showEmailZipModal} />
                    </div>
                </div>
                <EmailZipModal {...this.props} />
                <ModalWindow
                    actions={['Close']}
                    close={this.toggleEnlargedDiagram}
                    open={this.state.diagramEnlarged}
                    width={'90%'}
                    height={'90%'}
                    center={true}
                    title="Block Diagram">
                    <img src={diagramDataUri} />
                </ModalWindow>
            </Row>
        );
    }
}
