import PropTypes from 'prop-types';
import React from 'react';
import debounce from 'lodash/debounce';
import { findDOMNode } from 'react-dom';
import PopupMenu from '../PopupMenu';
import classNames from 'classnames';
import css from './styles.less';
import find from 'lodash/find';

const measure = (containerInst) => {
    if (!containerInst) {
        return {};
    }

    const childrenArray = Array.prototype.slice.call(containerInst.children, 0);
    const childrenBounds = childrenArray.map(
        element => findDOMNode(element).getBoundingClientRect()
    );

    const containerBounds = containerInst.getBoundingClientRect();
    const maxRight = containerBounds.width + containerBounds.left;
    return {
        maxRight,
        childrenBounds
    };
};

const getOutOfBoundsIndex = ({ maxRight, childrenBounds }) => {
    const nodeOutOfBounds = find(childrenBounds,
        (nodeBounds) => {
            if (nodeBounds.left + nodeBounds.width > maxRight) {
                return true;
            }
        }
    );
    const outOfBoundsIndex = nodeOutOfBounds !== undefined
        ? childrenBounds.indexOf(nodeOutOfBounds)
        : null;

    return window.innerWidth > 1200 ? null: outOfBoundsIndex;
};

export default class ResponsiveMenu extends React.PureComponent {
    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.element),
        popupClassName: PropTypes.string.isRequired,
        className: PropTypes.string.isRequired
    }

    state = {
        outOfBoundsIndex: null
    }

    componentDidMount() {
        const outOfBoundsIndex = getOutOfBoundsIndex(measure(this.containerInst));
        this.setState({ outOfBoundsIndex });
        window.addEventListener('resize', this.handleWindowResize);
    }

    updateMenu() {
        this.setState(
            () => ({ outOfBoundsIndex: null }),
            () => {
                const outOfBoundsIndex = getOutOfBoundsIndex(measure(this.containerInst));
                this.setState({ outOfBoundsIndex });
            }
        );
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.children !== nextProps.children) {
            this.updateMenu();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = debounce(() => {
        this.updateMenu();
    }, 50);

    getContainerRef = (inst) => {
        this.containerInst = inst;
    }

    render() {
        const { className, popupClassName, children } = this.props;
        // const { className, children } = this.props;
        const { outOfBoundsIndex } = this.state;
        const visibleItems = outOfBoundsIndex === null
            ? children
            : children.slice(0, outOfBoundsIndex - 1)
                .concat(<div key="moreItemsMenu" className="moreItemsContainer">
                    <PopupMenu key="moreItemsMenu" className={popupClassName}>
                        {children.slice(outOfBoundsIndex - 1)}
                    </PopupMenu>
                </div>);

        return (
            <div ref={this.getContainerRef} className={classNames(css.container, className)}>
                {visibleItems}
            </div>
        );
    }
}
