import PropTypes from 'prop-types';
import React from 'react';
import databind from 'redux/utils/databind';
import {actions as headerActions} from 'redux/modules/header';
import {actions as authActions} from 'redux/modules/auth';
import ProjectNameForm from 'components/ReferenceDesign/ProjectNameForm';
import getText from 'util/translations';
import contentKeys from '../../translations/contentKeys';

class ProjectNameFormContainer extends React.Component {
    static propTypes = {
        // passed in
        title: PropTypes.string.isRequired,

        // from data bind
        initialProjectName: PropTypes.string.isRequired,
        showErrors: PropTypes.bool.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        checkedAuthStatus: PropTypes.bool.isRequired,
        projectNames: PropTypes.array.isRequired,
        projectLoading: PropTypes.bool.isRequired,

        // action
        toggleProjectHeader: PropTypes.func.isRequired,
        fetchAuthStatus: PropTypes.func.isRequired
    };

    static databind({auth, referenceDesign, projects, header, offline}) {
        const initialProjectName = (projects.current && projects.current.name) ? projects.current.name
            : header.headerText;
        const projectNames = projects.listing.map(p => p.name);
        return {
            initialProjectName,
            showErrors: referenceDesign.showErrors,
            isSignedIn: auth.isSignedIn,
            checkedAuthStatus: auth.checked,
            projectLoading: projects.loading,
            projectNames,
            isOffline: offline.isOffline
        };
    }

    static actions = {
        toggleProjectHeader: headerActions.toggleProjectHeader,
        fetchAuthStatus: authActions.checkIsSignedInNow
    };

    validateProjectName = (name) => {
        return true;
    };

    render() {
        const instructionText = this.props.isSignedIn
            ? getText(contentKeys.TO_ACCESS_YOUR_FILES_PLEASE) : getText(contentKeys.TO_ACCESS_YOUR_FILES);

        return (<ProjectNameForm {...this.props}
            title={this.props.title}
            instructions={instructionText}
            projectNames={this.props.projectNames}
        />);
    }
}

export default databind(ProjectNameFormContainer);
