import {PartsDetailDataSource} from 'redux/partsDetailDataSource';

export class PartsDetailDataSourceFactory {
    constructor(store) {
        this.store = store;
        this.unsubscribe = store.subscribe(this.listen);
        this.managedDataSources = [];
        this.lastResultsTick = null;
    }

    listen = () => {
        const state = this.store.getState();

        if (state.suggestedParts.tick !== this.lastResultsTick) {
            this.lastResultsTick = state.suggestedParts.tick;
            this.unsubscribeAndClearAllDataSources();
        }
    };

    create(data, ldo, outputId) {
        const detailDS = new PartsDetailDataSource(this.store, data, ldo, outputId);
        this.managedDataSources.push(detailDS);
        return detailDS;
    }

    unsubscribeAndClearAllDataSources() {
        this.managedDataSources.forEach((ds) => ds.unsubscribe());
        this.managedDataSources = [];
    }
};
