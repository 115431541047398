import React from 'react';
import {Route, IndexRoute, Redirect} from 'react-router';
import {actions as headerActions} from 'redux/modules/header';
import {actions as startOptionActions} from 'redux/modules/startOptions';
import {actions as referenceDesignActions} from 'redux/modules/referenceDesign';

// NOTE: here we're making use of the `resolve.root` configuration
// option in webpack, which allows us to specify import paths as if
// they were from the root of the ~/src directory. This makes it
// very easy to navigate to files regardless of how deeply nested
// your current file is.
import CoreLayout from 'layouts/CoreLayout/CoreLayout';
import StartView from 'views/StartView';
import EditorView from 'views/EditorView';
import SuggestedPartsView from 'views/SuggestedPartsView';
import ProjectNameView from 'views/ProjectNameView';
import ProjectNameSaveView from 'views/ProjectNameSaveView';
import ReferenceDesignView from 'views/ReferenceDesignView';
import CompareAndSelectView from 'views/CompareAndSelectView';
import ProjectsView from 'views/ProjectsView';
import OpenProjectView from 'views/OpenProjectView';

export default (store) => {
    const onEnterIndex = () => {
        store.dispatch(headerActions.reset());
        store.dispatch(startOptionActions.reset());
        store.dispatch(referenceDesignActions.reset());
    };

    const clearHeaderText = () => {
        store.dispatch(headerActions.toggleProjectName(true));
    };

    const resetHeaderButKeepName = () => {
        store.dispatch(headerActions.toggleProjectHeader(true));
        store.dispatch(headerActions.setLinkVisibility({
            showSaveProjectLink: true,
            showExportCsvLink: true,
            showBackLink: true,
            showHelpLink: true,
            showYourProjectsLink: true,
            showSendLink: true,
            showOfflineVersionLink: false }));
    };

    const onEnterProjects = () => {
        store.dispatch(headerActions.toggleProjectName(false));
    };

    return (
        <Route path="/" component={CoreLayout}>
            <IndexRoute component={StartView} onEnter={onEnterIndex} onLeave={clearHeaderText} />
            <Route path="/editor(.html)" component={EditorView} onEnter={resetHeaderButKeepName}>
                <Route path="solutions(.html)" component={SuggestedPartsView} />
            </Route>
            <Route path="/reference" component={ProjectNameView}>
                <Route path=":generateStep(/:designId)" component={ReferenceDesignView} />
            </Route>
            <Route path="/compare(.html)" component={CompareAndSelectView} onEnter={resetHeaderButKeepName} />
            <Route path="/projects(.html)" onEnter={onEnterProjects} component={ProjectsView}
                onLeave={clearHeaderText} />
            <Route path="/save(.html)" component={ProjectNameSaveView} />
            <Route path="/open/:projectId(.html)" component={OpenProjectView} />
            <Route path="/receive/:projectId(.html)" component={OpenProjectView} shared />

            <Redirect from="/editor/signedin(.html)" to="/projects" />
            <Redirect from="/editor/solutions/signedin(.html)" to="/editor/solutions" />
            <Redirect from="/compare/signedin(.html)" to="/compare" />
            <Redirect from="/projects/signedin(.html)" to="/projects" />

            <Redirect from="*" to="/" />
        </Route>);
};
