// function ajax(config) {
//     return new Promise((resolve, reject) => {
//         $.ajax(config).then(resolve, (error) => reject(error.statusText));
//     });
// }

const jqueryToFetchOptions = (config) => {
    const headers = new Headers();
    headers.set('Content-Type', config.contentType);

    return {
        method: config.type,
        body: config.data,
        cache: (config.cache) ? 'default' : 'no-cache',
        headers,
        credentials: (!!config.xhrFields && config.xhrFields.withCredentials) ? 'include' : 'same-origin'
    };
};

function fetchWrapper(config) {
    const request = new Request(config.url, jqueryToFetchOptions(config));

    return fetch(request).then((response) => {
        if (!response.ok) {
            return Promise.reject(response.statusText || `${response.status}`);
        }
        return response.json();
    });
}

const http = fetchWrapper;

const defaultConfig = {
    contentType: "application/json",
    dataType: 'json',
    cache: false,
    xhrFields: { withCredentials: true }
};

export function postJson(url, data) {
    return http({
        ...defaultConfig,
        type: 'POST',
        url,
        data: JSON.stringify(data)
    });
}

export function getJson(url) {
    return http({
        ...defaultConfig,
        type: "GET",
        url
    });
}

export function deleteJson(url, data) {
    return http({ type: "DELETE", url, data: JSON.stringify(data) });
}

export function putJson(url, data) {
    return http({ type: 'PUT', url, data: JSON.stringify(data) });
}

export default http;
