import PropTypes from 'prop-types';
import React from 'react';
import databind from 'redux/utils/databind';
import classNames from 'classnames';
import css from './styles.less';
import SystemOutput from 'components/Editor/SystemOutput';
import { Col } from 'bootstrap-core';
import {actions as editorActions, selectors} from 'redux/modules/editor';
import {actions as deleteActions} from 'redux/modules/confirmDelete';
import TableErrorComponent from "./TableError.js";
import {SortableTBody, getTableDragAndDropHint} from './SortableList';
import {movePosition} from './helpers/movePosition';
import {findRootInputRailId} from 'logic/editor.js';
import SystemOutputsHeader from 'components/Editor/SystemOutputsHeader';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const outputDragAndDropHint = getTableDragAndDropHint($(
    `<div class="${classNames('powercompass_app', css.cloneContainer)}"></div>`)
);

class SystemOutputs extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        // databind
        efficiency: PropTypes.number.isRequired,
        inputs: PropTypes.array.isRequired,
        outputs: PropTypes.array.isRequired,
        headerSettingsClassName: PropTypes.string,
        supplySequenceMax: PropTypes.number.isRequired,
        activeColors: PropTypes.object.isRequired,
        // actions
        addSystemOutput: PropTypes.func.isRequired,
        changeOutputsOrder: PropTypes.func.isRequired,
        changeOutputName: PropTypes.func.isRequired,
        deleteSystemOutput: PropTypes.func.isRequired,
        changeOutputVoltsAmps: PropTypes.func.isRequired,
        changeSupplySequence: PropTypes.func.isRequired,
        confirmDelete: PropTypes.func.isRequired
    };

    static databind ({editor, colorPalette}) {
        const {inputs, outputs, efficiency, supplySequenceMax} = editor;
        return {
            efficiency: efficiency,
            outputs: outputs,
            inputs: inputs,
            supplySequenceMax: supplySequenceMax,
            activeColors: colorPalette.activeColors
        };
    }
    validation = (idx) => {
        return (
            <TableErrorComponent colspan={12} error={getText(contentKeys.SUPPLY_SEQUENCE_CAN_NOT)} key={"error"+idx} />
        );
    };

    static actions = {
        addSystemOutput: editorActions.addSystemOutput,
        changeOutputsOrder: editorActions.changeOutputsOrder,
        changeOutputName: editorActions.changeOutputName,
        changeOutputVoltsAmps: editorActions.changeOutputVoltsAmps,
        changeSupplySequence: editorActions.changeSupplySequence,
        deleteSystemOutput: editorActions.deleteSystemOutput,
        confirmDelete: deleteActions.confirmDelete
    };

    addSystemOutput = () => {
        this.props.addSystemOutput();
    };

    handleRowMove = (newIndex, oldIndex) => {
        this.props.changeOutputsOrder(
            movePosition(this.props.outputs, newIndex, oldIndex)
                .map(input => input.id)
        );
    };

    isFed = (output) => {
        const connected = this.props.outputs.filter((x) => x.id === output.sourceRail);
        return (connected && connected.length === 1);
    };

    feedsAnother = (output) => {
        const connected = this.props.outputs.filter((x) => x.sourceRail === output.id);
        return (connected && connected.length > 0);
    };

    findRootColor = (output) => {
        // walk back to the root Input Rail if its there and return it's color
        const rootId = findRootInputRailId(this.props.outputs, output);
        if (!rootId) return null;
        return (this.props.activeColors[rootId]) ? this.props.activeColors[rootId].color : null;
    };

    render() {
        let className = classNames("table", css.systemOutputs, this.props.className);
        const systemOutputs = this.props.outputs
            .filter(output => !!output)
            .map((output, idx) => {
                const moreOptionsModified = this.props.outputs[idx]
                    ? selectors.outputHasMoreOptionsSet(this.props.outputs[idx])
                    : false;
                return (
                    <React.Fragment key={output.id}>
                        <SystemOutput
                            index={idx}
                            isFed={this.isFed(output)}
                            output={output}
                            changeOutputName={this.props.changeOutputName}
                            changeOutputVoltsAmps={
                                this.props.changeOutputVoltsAmps
                            }
                            changeSupplySequence={
                                this.props.changeSupplySequence
                            }
                            confirmDelete={this.props.confirmDelete}
                            moreOptionsModified={moreOptionsModified}
                            supplySequenceMax={this.props.supplySequenceMax}
                            sourceColor={this.findRootColor(output)}
                            feedsAnother={this.feedsAnother(output)}
                        />
                        {output.invalid && (
                            <TableErrorComponent
                                ignore={true}
                                colspan={12}
                                error={getText(
                                    contentKeys.SUPPLY_SEQUENCE_CAN_NOT
                                )}
                            />
                        )}
                        {output.availableOutput < 0 && (
                            <TableErrorComponent
                                ignore={true}
                                colspan={12}
                                error={getText(
                                    contentKeys.AVAILABLE_OUTPUT_POWER_IS
                                )}
                            />
                        )}
                    </React.Fragment>
                );
            });

        const tbody = (
            <SortableTBody data-test="system_outputs"
                hint={outputDragAndDropHint}
                move={this.handleRowMove}>
                {systemOutputs}
            </SortableTBody>
        );

        const efficiencyPercent = (100*this.props.efficiency).toFixed(0) + '%';

        return (
            <div>
                <SystemOutputsHeader headerSettingsClassName={this.props.headerSettingsClassName} />
                <div className={css.outputsContainer}>
                    <Col xs={12} className={css.noPadding}>
                        <table className={className}>
                            <thead>
                                <tr>
                                    <th rowSpan="2" className={css.coloredTab}></th>
                                    <th rowSpan="2" className={css.deleteBtn}></th>
                                    <th rowSpan="2" className={css.sourceRail}>{getText(contentKeys.SOURCE_RAIL)}</th>
                                    <th rowSpan="2" className={css.feedIcon}></th>
                                    <th rowSpan="2" className={css.outputName}>{getText(contentKeys.OUTPUT_NAME)}</th>
                                    <th rowSpan="2" className={css.feedIcon}></th>
                                    <th rowSpan="2">{getText(contentKeys.VOLTS)}</th>
                                    <th rowSpan="2">{getText(contentKeys.MAX_AMPS)}</th>
                                    <th rowSpan="2" className={css.supplySequence}>
                                        {getText(contentKeys.SUPPLY_SEQUENCE)}
                                    </th>
                                    <th rowSpan="2" className={css.availOutput}>
                                        {getText(contentKeys.AVAIL_OUTPUT_W)}
                                    </th>
                                    <th data-test="input_power_needed_header" rowSpan="2"
                                        className={css.inputPowerNeeded}>
                                        {getText(contentKeys.EFF_INPUT_POWER_NEEDED, efficiencyPercent)}
                                    </th>
                                    <th rowSpan="2" className={css.more}>{getText(contentKeys.MORE)}</th>
                                </tr>
                            </thead>
                            {tbody}
                        </table>
                    </Col>
                </div>
                <div>
                    <Col className={css.noPadding} xs={6}>
                        <a data-test="add_a_system_output" className={classNames(css.addRail, 'btn-link')}
                            onClick={this.addSystemOutput}>+ {getText(contentKeys.ADD_AN_OUTPUT)}</a>
                    </Col>
                    <Col xs={6} className={classNames("text-right", css.noPadding)}>
                        <span className={css.feedGray}>
                            <span className="icon icon-rail-feed"></span>
                            {getText(contentKeys.FEEDS_SECOND_REGULATOR)}
                        </span>
                    </Col>
                </div>
            </div>
        );
    }
}

export default databind(SystemOutputs);
