import _ from 'lodash';
import {suggestTopologySteppingType, suggestTopologies} from './suggestTopology';
import {meetsSpec, ioutMaxMultiplier} from './colorWeights';
import {findRootInputRailId} from './editor.js';
import {formatNumberVariable} from 'logic/formats';
import contentKeys from 'translations/contentKeys';
import getText from 'util/translations';

export const computeTotalWeightOfPart = function(part) {
    let initial = part.vinMin + part.vinMax + part.outputs.length;
    return part.outputs.reduce((a, x) => a + (x.voutMin + x.voutMax + x.ioutMax), initial);
};

export const computeConfidence = function(part, totalWeight) {
    let divisors = [1, 12, 18, 24, 30];
    if (part.outputs.length === 0) return 0;
    return totalWeight / divisors[part.outputs.length];
};

const dx = function(a, b, field) { return Math.abs(a[field] - b[field]); };

export const computePartDeltas = function(design, part) {
    if (design.outputs.length !== part.outputs.length) {
        throw new Error("Num Output mismatch for part ", part.deviceName);
    }

    let deltas = {};
    deltas.numOutputs = dx(design, part, 'numOutputs');
    deltas.vinMin = dx(design, part, 'vinMin');
    deltas.vinMax = dx(design, part, 'vinMax');
    deltas.outputs = part.outputs.map((x, i) => {
        let out = {};
        const d = design.outputs[i];
        out.voutMin = dx(d, x, 'voutMin');
        out.voutMax = dx(d, x, 'voutMax');
        out.ioutMax = dx(d, x, 'ioutMax');
        return out;
    });

    return deltas;
};

function groupByTopology(parts) {
    let obj = {};
    obj.parts = _.groupBy(parts, "topology");
    obj.topologies = _.uniqBy(parts, "topology").map((x) => x.topology);
    return obj;
}

export function groupParts(parts) {
    let db = _.groupBy(parts, "numOutputs");
    for (let key in db) {
        db[key] = groupByTopology(db[key]);
    }
    return db;
}

export function formatOutputValue(output) {
    if (!output) {
        return '';
    }

    return formatNumberVariable(output.volts) + "V @ " + formatNumberVariable(output.amps) + "A";
}

export function formatInputValue(input) {
    if (!input) {
        return '';
    }

    return `${formatNumberVariable(input.vmin)} to ${formatNumberVariable(input.vmax)}V
            @ ${formatNumberVariable(input.imax)}A`;
}

export function extractSingleOutputsFromEditorState(state) {
    let outputs = state.outputs.map((o) => {
        // look for a connected source rail
        let input = state.inputs.filter((x) => x.id === o.sourceRail);
        let output = {...o, numOutputs: 1};

        if (input.length === 0) {
            input = state.outputs.filter((x) => x.id === o.sourceRail);
            if (input.length === 0) throw new Error(o.name + " is not connected to a Source Rail");
            output.vinMin = output.vinMax = input[0].volts;
            output.isFed = true;
        }
        else {
            output.vinMin = input[0].vmin;
            output.vinMax = input[0].vmax;
            output.isFed = false;
        }

        output.sourceRailName = input[0].name;
        output.outputs = [{voutMin: o.volts, voutMax: o.volts, ioutMax: o.amps}];
        output.topologyType = suggestTopologySteppingType(output);
        output.topologies = suggestTopologies(output);
        output.numOutputs = 1;
        output.type = getText(contentKeys["1_OUTPUT"]);
        output.nameDecoration = formatOutputValue(output);
        output.decoratedName = output.name + " (" + output.nameDecoration + ")";

        // eslint-disable-next-line no-debugger
        // debugger;

        return output;
    });
    return outputs;
}

/*
* Clones the objects taken from the partsdb so we can safely mutate later
* */
export function getCompatibleParts(outputs, partsDb) {
    outputs.forEach(function(output, idx) {
        const items = _.pick(partsDb, output.topologies);
        _.each(items, function(parts, topology) {
            items[topology] = parts.filter((p) => {
                output.topology = topology;
                const multiplier = ioutMaxMultiplier(topology, output.amps);
                return meetsSpec(output, p, multiplier);
            });
        });
        // we are going to be appending state and otherwise manipulating
        // the parts that we attach to the results set hence we need a full
        // copy of these. We don;t want to affect our original db
        output.items = _.cloneDeep(items);
    });
    return outputs;
}

export function sortGroupedResultsBy(results, order) {
    results.forEach(function(output) {
        _.each(output.items, function(parts, topology) {
            output.items[topology] = _.sortBy(parts, order);
        });
    });
    return results;
}

export function sortResultsByPrice(results) {
    const SORT_CRITERIA_PRICE = 'msrp';
    return sortGroupedResultsBy(results, SORT_CRITERIA_PRICE);
}

export function sortResultsByClosestSpec(results) {
    const SORT_CRITERIA_CLOSEST = [
        'deltas.outputs[0].ioutMax',
        'deltas.vinMax',
        'deltas.vinMin',
        'deltas.outputs[0].voutMin',
        'deltas.outputs[0].voutMax',
        'msrp'
    ];
    results.forEach(function(output) {
        _.each(output.items, function(parts, topology) {
            let partsWithDeltas = parts.map((part) => computePartDeltas(output, part));
            output.items[topology] = _.sortBy(partsWithDeltas, SORT_CRITERIA_CLOSEST);
        });
    });
    return results;
}

export function stripEmptyOutputs(editorState) {
    const freshState = _.cloneDeep(editorState); // don't want to mess up the editor state
    freshState.outputs = freshState.outputs.filter((x) => x.amps !== 0);
    return freshState;
}

/*
*  mutates outputs argument
* */
export function appendRailColor(outputs, activeColors) {
    return outputs.map((output) => {
        const rootId = findRootInputRailId(outputs, output);
        const color = (activeColors[rootId]) ? activeColors[rootId].color : null;
        output.railColor = [color];
    });
}

export function flattenItems(outputs) {
    return outputs.map((output) => {
        return { ...output, items: _.flatten(_.values(output.items)) };
    });
}

export function runSinglePartsMatch(partsdb, outputs, activeColors) {
    let results = getCompatibleParts(outputs, partsdb["1"].parts);
    appendRailColor(results, activeColors);
    return results;
}

function canBeNOutput(tuple, n) {
    // If we somehow ended up with a smaller tuple than expected, it can't be an N-tuple output
    if (tuple.length !== n) return false;

    const hasEmptyValue = _.some(tuple, t => t.topologyType == null || t.sourceRail === "");

    if (hasEmptyValue) return false;

    const first = tuple[0];
    const matchingOutputs =
        tuple.filter(t => t.topologyType === first.topologyType && t.sourceRail === first.sourceRail);

    return matchingOutputs.length === tuple.length;
}

function buildNOutput(tuple) {
    const withJoinedValues = tuple.reduce((acc, output, idx) => {
        if (Object.keys(acc).length === 0) {
            return {
                id: output.id,
                name: output.name,
                decoratedName: output.decoratedName,
                outputs: output.outputs || [],
                ldo: output.ldo,
                powerGoodOutput: output.powerGoodOutput,
                enablePinInput: output.enablePinInput,
                syncInput: output.syncInput,
                syncOutput: output.syncOutput
            };
        }

        let nameJoiner = ", ";

        if (idx === tuple.length - 1 && tuple.length > 2) {
            nameJoiner = ", and ";
        }
        else if (idx === tuple.length - 1) {
            nameJoiner = " and ";
        }

        return {
            id: acc.id + "+" + output.id,
            name: acc.name + " and " + output.name,
            decoratedName: acc.decoratedName + nameJoiner + output.decoratedName,
            outputs: acc.outputs.concat(output.outputs || []),
            ldo: acc.ldo || output.ldo,
            powerGoodOutput: acc.powerGoodOutput || output.powerGoodOutput,
            enablePinInput: acc.enablePinInput || output.enablePinInput,
            syncInput: acc.syncInput || output.syncInput,
            syncOutput: acc.syncOutput || output.syncOutput
        };
    }, {});

    const first = tuple[0];

    return {
        ids: tuple.map(o => o.id),
        ...withJoinedValues,
        sourceRail: first.sourceRail,
        isFed: first.isFed,
        type: getText(contentKeys[`${tuple.length}_OUTPUT`]),
        numOutputs: tuple.length,
        topologies: first.topologies,
        vinMin: _.minBy(tuple, "vinMin").vinMin,
        vinMax: _.maxBy(tuple, "vinMax").vinMax,
        railColor: first.railColor
    };
}

export function extractNTupleOutputsFromSingleOutputs(outputs, n = 2) {
    if (outputs.length < n) return [];

    let tuples = [];

    for (let i = 0; i < outputs.length - 1; i++) {
        let tuple = [];

        for (let j = 0; j < n; j++) {
            // If our index would extend beyond the number of outputs, we should not try
            // to add a tuple
            if (i + j >= outputs.length) break;

            tuple.push(outputs[i + j]);
        }

        if (canBeNOutput(tuple, n)) {
            tuples.push(buildNOutput(tuple));
        }
    }

    return tuples;
}

export function excludeCasesWithNoParts(cases) {
    return cases.filter((d) => _.reduce(d.items, (a, x) => a + x.length, 0) > 0);
}

export function excludeOverlappingParts(parts) {
    if (parts.length < 2) {
        return parts;
    }

    return parts.reduce((acc, part) => {
        if (acc.length === 0) {
            return [part];
        }

        const currentIds = _.flatten(acc.map(a => a.ids));

        if (_.intersection(currentIds, part.ids).length === 0) {
            acc.push(part);
        }

        return acc;
    }, []);
}

export function runNOutputsPartsMatch(partsDb, singleOutputs, n) {
    const nOutputs = extractNTupleOutputsFromSingleOutputs(singleOutputs, n);

    // OPTIMIZE we don't need to search on each pair, we can skip some if we already
    // have a match on an overlapping pair
    const compatibleResults = getCompatibleParts(nOutputs, partsDb[n].parts);

    const validResults = excludeCasesWithNoParts(compatibleResults);
    return excludeOverlappingParts(validResults);
}

function isSingle(output) {
    return !output.ids;
}

export function matchAllParts(partsDb, editorOutputs, activeColors) {
    const resultSets = Object.keys(partsDb).reduce((acc, key) => {
        const numOutputs = +key;

        if (isNaN(numOutputs)) return acc;

        if (numOutputs === 1) {
            acc[numOutputs] = runSinglePartsMatch(partsDb, editorOutputs, activeColors);
            return acc;
        }

        const nOutputMatches = runNOutputsPartsMatch(partsDb, editorOutputs, numOutputs);

        if (nOutputMatches.length > 0) {
            acc[numOutputs] = nOutputMatches;
            return acc;
        }

        return acc;
    }, {});

    const maxOutputs = _.max(Object.keys(resultSets));

    return mergeResultSets(resultSets, maxOutputs);
}

export function mergeResultSets(resultSets, maxOutputs) {
    let mergedSets = resultSets[1];

    for (var numOutputs = 2; numOutputs <= maxOutputs; numOutputs++) {
        const resultSet = resultSets[numOutputs];

        if (!resultSet) {
            continue;
        }

        resultSet.forEach(parentSet => {
            let newMergedSets = [];
            let setPushed = false;

            mergedSets.forEach(childSet => {
                const childIds = parentSet.ids;
                const isSingleOutput = isSingle(childSet);
                const isChildOfSet = isSingleOutput ? _.includes(childIds, childSet.id)
                    : childIds.length > childSet.ids.length && _.intersection(childIds, childSet.ids).length > 0;

                if (isChildOfSet) {
                    if (!setPushed) {
                        newMergedSets.push(parentSet);
                        setPushed = true;
                    }

                    newMergedSets.push({
                        ...childSet,
                        parentId: childSet.parentId || parentSet.id
                    });
                }
                else {
                    newMergedSets.push(childSet);
                }
            });

            mergedSets = [ ...newMergedSets ];
        });
    }

    // mark single output parts that precede a multi-output part
    for (let current = 0, next = 1; next < mergedSets.length; current++, next++) {
        const currentOutput = mergedSets[current];
        const nextOutput = mergedSets[next];

        const lastChildInGroup = currentOutput.parentId && !nextOutput.parentId;

        mergedSets[current].lastOutputInGroup = lastChildInGroup ||
            (isSingle(currentOutput) && !currentOutput.parentId && !isSingle(nextOutput));
    }

    return mergedSets;
}
