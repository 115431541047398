/* global kendo */
/* global $*/

/**
 * Created by bwallace on 6/15/2016.
 */
import PropTypes from 'prop-types';

import React, { Children } from 'react';
import {findDOMNode, createPortal} from 'react-dom';
import {renderToStaticMarkup} from 'react-dom/server';
import shallowEqual from 'react-redux/lib/utils/shallowEqual';

const customWidgetOptions = {
    kendoPopup: {
        reactiveContent: true,
        shouldRenderContent() {
            return true;
        },
        applyOperations(props, instance) {
            const isVisible = instance.visible();
            if (props.open && !isVisible) {
                instance.open();
            }
            if (!props.open && isVisible) {
                instance.close();
            }
        }
    },
    kendoWindow: {
        reactiveContent: true,
        shouldRenderContent(props) {
            return !!props.open;
        },
        applyOperations(props, instance) {
            if (props.center) {
                instance.center();
            }

            const isVisible = instance.options.visible;
            if (props.open && !isVisible) {
                instance.open();
            }
            if (!props.open && isVisible) {
                instance.close();
            }
        }
    },
    kendoMobileSwitch: {
        reactiveContent: false,
        shouldRenderContent() {
            return true;
        },
        applyOperations(props, instance, optionsChanged = true) {
            if (instance.checked !== props.checked) {
                instance.check(!!props.checked);
            }

            // Kendo Switch doesn't expose an 'enabled' state so jut always update the enable state if options changed
            if (optionsChanged) {
                instance.enable(!!props.options.enable);
            }
        }
    },
    "default": {
        reactiveContent: false,
        shouldRenderContent() { return true; },
    }
};

function renderStaticContent($element, children) {
    const DATA_KEY = 'KendoWidgetWrapperStaticMarkup';

    // Generate the markup from 'children'
    let markup = '';
    Children.forEach(children, child => markup += renderToStaticMarkup(child));

    // If the markup is different than what we had last time, then apply the new markup
    if (markup !== $element.data(DATA_KEY)) {
        $element.data(DATA_KEY, markup);
        $element.html(markup);
    }
}

export default function createWrapper(widgetName) {
    const widgetOptions = customWidgetOptions[widgetName] || customWidgetOptions["default"];

    class Wrapper extends React.Component {
        constructor(props) {
            super(props);
            this._updateElement();
        }
        static propTypes = {
            options: PropTypes.object,
            rootElement: PropTypes.element,
        };

        static defaultProps = {
            options: {},
        };

        render() {
            let renderedContent = null;
            if (widgetOptions.reactiveContent &&
                widgetOptions.shouldRenderContent(this.props) &&
                this._element) {
                renderedContent = createPortal(
                    this.props.children,
                    this._element,
                );
            };

            return (
            <div>
                {renderedContent}
            </div>);
        }

        componentDidMount() {
            this._create();
        }

        _updateElement() {
            const {className, style} = this.props;
            const rootElement = this.props.rootElement || (<div className={className} style={style}></div>);
            const rootElementMarkup = renderToStaticMarkup(rootElement);
            this._element = $(rootElementMarkup)[0];
        }

        _create() {
            // render rootElement (or a DIV) inside our rootElement
            this.$rootElement = $(this._element).appendTo($(findDOMNode(this)));

            // Render static content that goes inside the widget
            if (!widgetOptions.reactiveContent && widgetOptions.shouldRenderContent(this.props)) {
                renderStaticContent(this.$rootElement, this.props.children);
            }

            // Create the widget
            this._appliedOptions = this.props.options;
            this.$rootElement[widgetName](this.props.options);

            // Apply custom operations
            if (widgetOptions.applyOperations) {
                const instance = this.$rootElement.data(widgetName);
                widgetOptions.applyOperations(this.props, instance);
            }
        }

        componentDidUpdate() {
            const instance = this.$rootElement.data(widgetName);
            // Kendo acts weird if we just update its options via setOptions and it has a dataSource
            const areOptionsChanged = !shallowEqual(this._appliedOptions, this.props.options);
            if (instance.dataSource && areOptionsChanged) {
                this._destroy();
                $(findDOMNode(this)).empty();
                this._updateElement();
                this._create();
            }
            else {
                // Update the content
                if (widgetOptions.shouldRenderContent(this.props)) {
                    if (!widgetOptions.reactiveContent) {
                        renderStaticContent(this.$rootElement, this.props.children);
                    }
                }

                // apply the new options
                if (areOptionsChanged) {
                    this._appliedOptions = this.props.options;
                    instance.setOptions(this.props.options);
                }

                // Apply custom operations
                if (widgetOptions.applyOperations) {
                    widgetOptions.applyOperations(this.props, instance, areOptionsChanged);
                }
            }
        }

        _destroy() {
            const instance = this.$rootElement.data(widgetName);
            if (instance) {
                 // do some cleanup
                instance.unbind();
                if (instance.dataSource) {
                    instance.dataSource.unbind('change', instance._refreshHandler);
                    instance.dataSource.unbind('error', instance._errorHandler);
                }
                instance.destroy();
            }
        }

        componentWillUnmount() {
            this._destroy();
        }
    }

    return Wrapper;
}
