import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import css from './styles.less';
import classNames from 'classnames';
import databind from 'redux/utils/databind';
import {actions as editorActions} from 'redux/modules/editor';
import {actions as deleteActions} from 'redux/modules/confirmDelete';
import BufferedInput, {filterAsNumber} from 'components/BufferedInput';
import ActionButton from 'components/ActionButton';
import {formatNumberWithUpTo2Decimals, formatNumberVariable} from 'logic/formats';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const noFilter = (value) => value;

class InputBlockEditPane extends React.Component {
    static propTypes = {
        changeInputRail: PropTypes.func.isRequired,
        changeInputRailName: PropTypes.func.isRequired,
        confirmDelete: PropTypes.func.isRequired,
        selectedBlock: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    static databind() {
        return {};
    }

    static actions = {
        changeInputRailName: editorActions.changeInputRailName,
        changeInputRail: editorActions.changeInputRail,
        confirmDelete: deleteActions.confirmDelete
    };

    onChangeInputRailName = (value) => {
        this.props.changeInputRailName({index: this.props.selectedBlock.idx, value});
    };

    onChangeVmin = (value) => {
        this.onChangeVoltsAmps("vmin", value);
    };

    onChangeVmax = (value) => {
        this.onChangeVoltsAmps("vmax", value);
    };

    onChangeImax = (value) => {
        this.onChangeVoltsAmps("imax", value);
    };

    onChangeVoltsAmps = (fieldName, value) => {
        const { idx } = this.props.selectedBlock;

        this.props.changeInputRail({
            index: idx,
            fieldName,
            value: kendo.parseFloat(value) });
    };

    formFields = () => {
        const block = this.props.selectedBlock;
        const errorStyle = (block.invalid) ? css.inputError : "";
        const negativeValueClass = (block.power < 0) ? css.negValue : "";

        return (
            <div className={classNames(css.editPaneInputs, css.tableLayout)}>
                <div className={css.row}>
                    <div className={css.fieldName}>{getText(contentKeys.RAIL_NAME)}</div>
                    <div>
                        <BufferedInput
                            dataTest="name"
                            value={block.name}
                            filter={noFilter}
                            onBufferedChange={this.onChangeInputRailName} />
                    </div>
                </div>
                <div className={classNames(errorStyle, css.row)}>
                    <div className={css.fieldName}>V(Min)</div>
                    <div>
                        <BufferedInput
                            dataTest="vmin"
                            value={block.vmin}
                            filter={filterAsNumber}
                            format={formatNumberVariable}
                            onBufferedChange={this.onChangeVmin} />
                    </div>
                </div>
                <div className={classNames(errorStyle, css.row)}>
                    <div className={css.fieldName}>V(Max)</div>
                    <div>
                        <BufferedInput
                            dataTest="vmax"
                            value={block.vmax}
                            filter={filterAsNumber}
                            format={formatNumberVariable}
                            onBufferedChange={this.onChangeVmax} />
                    </div>
                </div>
                <div className={css.row}>
                    <div className={css.fieldName}>A(Max)</div>
                    <div>
                        <BufferedInput
                            dataTest="imax"
                            value={block.imax}
                            filter={filterAsNumber}
                            format={formatNumberVariable}
                            onBufferedChange={this.onChangeImax} />
                    </div>
                </div>
                <div className={css.row}>
                    <div className={css.fieldName}>{getText(contentKeys.AVAILABLE_POWER_W)}</div>
                    <div>
                        <div className={classNames(css.staticField, negativeValueClass)}>
                            {formatNumberWithUpTo2Decimals(block.power)}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    instructions = () => {
        const { name } = this.props.selectedBlock;
        const title = `${getText(contentKeys.EDIT_SYSTEM_INPUT_RAIL)} ${name}`;

        return (
            <div className={css.editPaneInstructions}>
                <h5 title={name}>{title}</h5>
                <span>{getText(contentKeys.MAKE_DESIRED_EDITS_AND)}</span>
            </div>
        );
    };

    deleteBlock = () => {
        const { idx, id } = this.props.selectedBlock;

        this.props.confirmDelete({ input: true, index: idx, id });
    };

    buttons = () => {
        return (
            <div className={css.editPaneButtons}>
                <Button className={'basic'} onClick={this.deleteBlock}>
                    {getText(contentKeys.DELETE_INPUT_RAIL)}
                </Button>
                <ActionButton
                    label={getText(contentKeys.DONE)}
                    className={css.editPaneDoneBtn}
                    onClick={this.props.onClose}
                />
            </div>
        );
    };

    errors = () => {
        const isInvalid = this.props.selectedBlock.invalid;
        const negativeOutput = this.props.selectedBlock.power < 0;

        return (
            <div className={css.editPaneErrors}>
                {isInvalid &&
                    <div className={css.editPaneError}>
                        <span className={classNames(css.icon, "icon", "icon-exclaim-solid")}></span>
                        Vmin can't be higher than Vmax
                    </div>}
                {negativeOutput &&
                    <div className={css.editPaneError}>
                        <span className={classNames(css.icon, "icon", "icon-exclaim-solid")}></span>
                        {getText(contentKeys.AVAILABLE_INPUT_POWER_IS)}
                    </div>}
            </div>
        );
    };

    render() {
        return (
            <div className={classNames(css.diagramEditPane, css.diagramViewRight)}>
                <div className={css.closeEditPane}>
                    <i className="fa fa-close" onClick={this.props.onClose} />
                </div>
                {this.instructions()}
                {this.errors()}
                {this.formFields()}
                {this.buttons()}
            </div>
        );
    }
}

export default databind(InputBlockEditPane);
