import 'core-js/fn/promise';
import 'core-js/fn/object/assign';
import 'core-js/fn/string/starts-with';
import {persistStore} from 'redux-persist';
import {asyncSessionStorage as storageMethod} from 'redux-persist/storages';
import {getStatusFroPathname} from 'logic/status';
import {APP_STATE_VERSION, CLIENT_VERSION} from 'version';
import omit from 'lodash/omit';
import forEach from 'lodash/forEach';
import {createBlacklistFilter} from 'redux-persist-transform-filter';

const PCW_STORE_KEY_PREFIX = "powercompass";
export const BLACKLIST = ['efficiencyDb', 'partsDb', 'router', 'notification', 'offline'];

let PERSISTOR_TRANSFORMS = {};

export const registerBlacklistedKeys = (reducerName, keys) => {
    PERSISTOR_TRANSFORMS[reducerName] = createBlacklistFilter(reducerName, keys);
};

export const clearBlacklistedKeys = () => {
    PERSISTOR_TRANSFORMS = {};
};

export const getPersistorConfig = (config) => {
    return {
        storage: storageMethod,
        blacklist: BLACKLIST,
        keyPrefix: PCW_STORE_KEY_PREFIX,
        transforms: Object.values(PERSISTOR_TRANSFORMS),
        ...config
    };
};

export const serialize = (obj) => {
    return encodeURI(JSON.stringify(obj));
};

export const serializeAppState = (state, blacklist) => {
    const serializableState = omit(state, blacklist);
    forEach(PERSISTOR_TRANSFORMS, (transformer, key) => {
        serializableState[key] = transformer.in(state[key], key);
    });

    return serialize(serializableState);
};

export const getStatusFromRouting = (router) => {
    return getStatusFroPathname(router.locationBeforeTransitions.pathname);
};

export const forcePersist = (onDone) => {
    const state = window.store ? window.store.getState() : {}; // tests will not have window.store
    const reducers = omit(state, BLACKLIST);

    Object.keys(reducers).forEach(k => {
        const transformer = PERSISTOR_TRANSFORMS[k];

        if (transformer) {
            const transformed = transformer.in(state[k], k);
            window.sessionStorage.setItem(`${PCW_STORE_KEY_PREFIX}${k}`, JSON.stringify(transformed));
        }
        else {
            window.sessionStorage.setItem(`${PCW_STORE_KEY_PREFIX}${k}`, JSON.stringify(state[k]));
        }
    });

    onDone();
};

export const prepareDataForSave = (state, name) => {
    const {projects} = state;

    let projectName = name;
    if (!projectName) {
        projectName = (projects.current) ? projects.current['name'] : undefined;
    }

    const clientVersion = {
        client: CLIENT_VERSION,
        appState: APP_STATE_VERSION,
        partsDb: state.partsDb['version'] || '',
        efficiencyDb: state.efficiencyDb['version'] || ''
    };

    const {current} = projects;
    return {
        clientVersion: serialize(clientVersion),
        id: current['id'],
        name: projectName,
        status: current['status'] || '',
        referenceDesignId: current['referenceDesignId'] || '',
        payload: serializeAppState(state, [...BLACKLIST, 'header'])
    };
};

export default function initPersistor(store, config, onRehydrated) {
    return persistStore(store, getPersistorConfig(config), onRehydrated);
}
