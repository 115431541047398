import PropTypes from 'prop-types';
import React from 'react';
import '../../styles/core.less';
import {Grid, Row, Col} from 'bootstrap-core.js';
import Header from 'components/Header';
import NotificationContainer from 'containers/NotificationContainer';
import css from './styles.less';
import SignInRedirectWarningModal from 'components/SignInRedirectWarningModal';

// Note: Stateless/function components *will not* hot reload!
// react-transform *only* works on component classes.
//
// Since layouts rarely change, they are a good place to
// leverage React's new Stateless Functions:
// https://facebook.github.io/react/docs/reusable-components.html#stateless-functions
//
// CoreLayout is a pure function of its props, so we can
// define it with a plain javascript function...
const CoreLayout = ({children, location}) => {
    return (
        <div>
            <Grid className={css.fluidHeaderWrapper}>
                <Row className={"content-padding"}>
                    <Col className="container-par parsys">
                        <Header className={css.header} location={location} />
                    </Col>
                </Row>
            </Grid>
            <div className={css.appInnerContainer}>
                <Grid>
                    <NotificationContainer />
                    {children}
                </Grid>
            </div>
            <SignInRedirectWarningModal />
        </div>
    );
};

CoreLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element,
    location: PropTypes.object.isRequired
};

export default CoreLayout;
