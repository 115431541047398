/* global kendo */
import React from 'react';
import {render} from 'react-dom';
import getHistory from 'util/history';
import makeRoutes from './routes';
import Root from './containers/Root';
import configureStore, {rehydrateStore} from './redux/configureStore';
import { syncHistoryWithStore } from 'react-router-redux';
import {actions as partsDbActions} from 'redux/modules/partsDb.js';
import {actions as efficiencyDbActions} from 'redux/modules/efficiencyDb.js';
import {actions as templatesDbActions} from 'redux/modules/templatesDb.js';
import {actions as startOptionActions} from 'redux/modules/startOptions';
import {actions as offlineActions} from 'redux/modules/offline';
import {actions as projectActions, runPendingAction as runPendingProjectsAction} from 'redux/modules/projects';
import {actions as authActions} from 'redux/modules/auth';
import {loadState} from 'redux/utils/localStorage';
import {mapCultureCodeToUSorES} from 'util/culture';
import locale from 'util/locale';
import {serialize, prepareDataForSave} from 'redux/persist';
import swRuntime from 'serviceworker-webpack-plugin/lib/runtime';

if (__DEV__) {
    window.serialize = serialize;
    window.prepareDataForSave = prepareDataForSave;
}

// App Window Object Setup
// ------------------------------------
window.powercompass = {
    version: __VERSION__,
    commit: __COMMIT__,
};

// making this global for access from kendo templates
import imageUrl from 'util/imageUrl';
window.imageUrl = imageUrl;

// jquery plugins
import 'sticky-table-headers';

async function asyncInit (store) {
    // This is async not only because I don't want to block the page load, but also so
    // that the promises below can be awaited
    const {value} = await store.dispatch(authActions.checkIsSignedInNow());

    if (value.isSignedIn) {
        await store.dispatch(projectActions.refreshProjects());

        if ('serviceWorker' in navigator && !window.syncingProjects) {
            await store.dispatch(offlineActions.syncOfflineProjects());
        }
    }

    runPendingProjectsAction();
}

function init() {
    window.localStorage.setItem('locale', locale());

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', function(event) {
            switch (event.data.command) {
                case 'reportMode':
                    window.store.dispatch(offlineActions.toggleOfflineMode(event.data.message.offline));
                    window.store.dispatch(projectActions.refreshProjects());
                    break;
            }
        });

        const sendMessageFn = (message, serviceWorker) => {
            return new Promise(function(resolve, reject) {
                const messageChannel = new MessageChannel();
                messageChannel.port1.onmessage = function(event) {
                    if (event.data.error) {
                        reject(event.data.error);
                    }
                    else {
                        resolve(event.data);
                    }
                };

                serviceWorker.postMessage(message,
                    [messageChannel.port2]);
            });
        };

        // swRuntime.register({scope: '/'}).then(function (registration) {
        swRuntime.register({scope: './'}).then(function (registration) {
            let serviceWorker;

            if (registration.installing) {
                serviceWorker = registration.installing;
                window.store.dispatch(offlineActions.togglePreparing());
            }
            else if (registration.waiting) {
                serviceWorker = registration.waiting;
                window.store.dispatch(offlineActions.togglePreparing());
            }
            else if (registration.active) {
                serviceWorker = registration.active;
            }

            if (serviceWorker) {
                if (serviceWorker.state === 'activated') {
                    sendMessageFn({ command: 'setLocale', locale: locale() }, serviceWorker).then(() => {
                        window.sendMessage = (message) => sendMessageFn(message, serviceWorker);
                        window.store.dispatch(offlineActions.toggleReady());
                    });
                }

                serviceWorker.addEventListener('statechange', function (e) {
                    if (e.target.state === 'activated') {
                        sendMessageFn({ command: 'setLocale', locale: locale() }, serviceWorker).then(() => {
                            window.sendMessage = (message) => sendMessageFn(message, serviceWorker);
                            window.store.dispatch(offlineActions.toggleReady());
                        });
                    }
                });
            }
        });
    }

    // Create the Redux store, checking for a persisted state before creating a new empty state
    // const initialState = loadState() || {};
    const initialState = {partsDb: {}, efficiencyDb: {}};
    const store = configureStore(initialState);
    window.store = store;
    window.rehydrateStore = rehydrateStore;

    window.dumpEditorJSON = function() {
        const editor = window.store.getState().editor;
        return JSON.stringify(editor, null, 4);
    };

    // if we have state stored in local storage fire an action to
    // have the app render using the loaded state
    const loadedState = loadState();
    if (loadedState) {
        store.dispatch({ type: "REBOOT", payload: loadedState });
    }

    // Kick off parts and efficiency DB fetch now before we need it.
    // Intentionally not waiting for the fetch actions to complete
    // instead it will be checked before the data is actually needed
    const {partsDb, efficiencyDb} = store.getState();
    // Check if parts and efficiency data was restored before fetching
    if (!templatesDbActions.received) store.dispatch(templatesDbActions.fetchData());
    if (!partsDb.received) store.dispatch(partsDbActions.fetchData());
    if (!efficiencyDb.received) store.dispatch(efficiencyDbActions.fetchData());

    // enable full page file dropping for FPGA vendor files
    window.addEventListener("drop", function(e) {
        e = e || event;
        e.preventDefault();
        store.dispatch(startOptionActions.handleFullPageFileDrop(e));
        return false;
    }, false);

    // // disable full page laod of dropped files
    window.addEventListener("dragover", function(e) {
        e = e || event;
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
        return false;
    }, false);

    // Create an enhanced history that syncs with the Redux store
    const history = getHistory();
    const syncedHistory = syncHistoryWithStore(history, store, {selectLocationState: state => state.router});

    const routes = makeRoutes(store);

    // Render the React application to the DOM
    const root = document.getElementById("root");
    if (!root) {
        throw new Error("#root element not found in DOM");
    }

    asyncInit(store);

    render(<Root history={syncedHistory} routes={routes} store={store} />, root);
}

// as long as kendo is outside of the app, its better to keep these globally changeable
window.getUserLanguagePreference = function() {
    if ((window.navigator.languages) && (window.navigator.languages.length > 0)) {
        return window.navigator.languages[0];
    }
    return window.navigator.language || window.navigator.userLanguage;
};

window.getAppCulture = function() {
    return kendo.culture();
};

window.setAppCultureByCultureCode = function(code) {
    return kendo.culture(code);
};

window.setupNumberFormatSupport = function() {
    var lang = window.getUserLanguagePreference();
    var nearestCulture = mapCultureCodeToUSorES(lang);
    window.setAppCultureByCultureCode(nearestCulture);
    console.log("User Language", lang, "mapping to", nearestCulture); // eslint-disable-line no-console
    console.log("App Culture now set to", window.getAppCulture().name); // eslint-disable-line no-console
};

console.log("kendo.support", kendo.support); // eslint-disable-line no-console
console.log("navigator", navigator); // eslint-disable-line no-console

// Wait until the DOM has finished loading before initializing the application.
// Note: Some browsers do not fire DOMContentLoaded if it has already loaded, so check if we are already loaded first
if (document.readyState === "loading") {
    window.setupNumberFormatSupport();
    document.addEventListener('DOMContentLoaded', init);
}
else {
    window.setupNumberFormatSupport();
    init();
}
