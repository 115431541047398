/* global kendo, $ */
import isEqual from 'lodash/isEqual';
import {formatPercent} from 'logic/formats';
import {CHAR_MDASH} from 'logic/constants';
import {getSelectedPartInfo} from 'logic/compareParts';
import createRowDataForRendering from 'logic/createRowDataForRendering';

export class CompareAndSelectDataSource extends kendo.data.DataSource {
    constructor(store) {
        const options = {
            schema: {
                model: {
                    id: "id",
                    fields: {
                        sourceRail: {type: "string"},
                        sourceRailName: { type: "string" },
                        name: { type: "string" },
                        selectedMsrp: {type: 'number'},
                        items: {type: 'array'}
                    }
                }
            },
            transport: {
                read: (options) => {
                    let rowsForRendering = [];
                    if (this.local.results.length > 0) {
                        rowsForRendering = createRowDataForRendering(
                            this.local.results,
                            this.local.computedData,
                            this.local.efficiencies,
                            this.local.selectedPartIds,
                            this.local.selectedPmbusAddresses
                        );
                    }
                    options.success(rowsForRendering);
                },
                update: (options) => {
                    console.log("data source update"); // eslint-disable-line no-console
                }
            },
            aggregate: [
                { field: "selectedMsrp", aggregate: "sum" }
            ]
        };

        super(options);

        this.store = store;
        this.local = {
            results: [],
            selectedPartIds: [],
            computedData: [],
            loads: {},
            efficiencies: {}
        };

        this.unsubscribe = store.subscribe(this.onStoreChange);
    }

    getSelectedPartInfo(outputIndex, outputId) {
        return getSelectedPartInfo(this.local.results, this.local.selectedPartIds, outputIndex, outputId);
    }

    getCurrentEfficiencyRefCurve(outputIndex, outputId) {
        const { selectedIndex } = this.getSelectedPartInfo(outputIndex, outputId);
        return this.local.computedData[outputIndex].parts[selectedIndex].referenceCurves.efficiency;
    }

    updateEfficiency(el, value) {
        const theText = (value != null) ? formatPercent(value) : CHAR_MDASH;
        el.text(theText);
    }

    updateEfficienciesOnRow(rowEl, eff) {
        this.updateEfficiency(rowEl.find('.llEffValue'), eff.lightLoad);
        this.updateEfficiency(rowEl.find('.tlEffValue'), eff.typicalLoad);
        this.updateEfficiency(rowEl.find('.mlEffValue'), eff.maxLoad);
    }

    directUpdateForEfficiencies(changedOutputIds) {
        const gridElement = $('#compareGrid');
        changedOutputIds.forEach((outputId) => {
            const eff = this.local.efficiencies[outputId];
            const selector = ".k-master-row:eq(" + eff.index + ")";
            const rowElement = gridElement.find(selector);
            this.updateEfficienciesOnRow(rowElement, eff);
        });
    }

    getOutputIdsOfChanges(newData, localData) {
        const outputIds = Object.keys(newData);
        return outputIds.filter((id) => !isEqual(newData[id], localData[id]));
    }

    onStoreChange = () => {
        const state = this.store.getState().compareAndSelect;
        if (!isEqual(state.results, this.local.results)) {
            // new result data, update everything
            this.local.results = state.results;
            this.local.computedData = state.computedData;
            this.local.selectedPartIds = state.selectedPartIds;
            this.local.selectedPmbusAddresses = state.selectedPmbusAddresses;
            this.local.loads = state.loads;
            this.local.efficiencies = state.efficiencies;
            this.read();
        }
        else if (!isEqual(state.selectedPartIds, this.local.selectedPartIds)) {
            // the source of this action is a ui component outside of the detail view
            // so we read() and allow the grid to re-render
            this.local.selectedPartIds = state.selectedPartIds;
            this.local.selectedPmbusAddresses = state.selectedPmbusAddresses;
            this.local.efficiencies = state.efficiencies;
            this.local.computedData = state.computedData;
            this.read();
        }
        else if (!isEqual(state.ambientTemperature, this.local.ambientTemperature)) {
            // one of the ambient tempurature sliders ha been changed
            this.local.computedData = state.computedData;
            this.local.ambientTemperature = state.ambientTemperature;
            this.read();
        }
        else if (!isEqual(state.loads, this.local.loads)) {
            // we avoid a grid re-render here because we cannot let the detail view be re-rendered
            // as user willbe using controls in that area
            const changedOutputIds = this.getOutputIdsOfChanges(state.loads, this.local.loads);
            this.local.loads = state.loads;
            this.local.efficiencies = state.efficiencies;
            this.directUpdateForEfficiencies(changedOutputIds);
        }
    };

    disconnect() {
        this.unsubscribe();
    }
}
