import PropTypes from 'prop-types';
import React from 'react';
import debounce from 'lodash/debounce';

const MOBILE_ACTIVATE_DRAG = 400;

const SortableList = ListContainer => class SortableList extends React.PureComponent {
    static propTypes = {
        hint: PropTypes.func.isRequired,
        move: PropTypes.func.isRequired
    };

    static defaultProps = {
        ignore: 'input, td > span'
    }

    $draggedRow = null;
    dragStarted = false;
    $hint = null;

    handleRowTouchStart = (e) => {
        window.getSelection().removeAllRanges();
        this.$list.css({
            userSelect: 'none',
            webkitUserSelect: 'none'
        });

        this.$draggedRow = $(e.currentTarget);
        this.$draggedRow.bind('touchmove', this.handleTouchMove);

        this.mobileStartDrag(e.currentTarget);
    }

    handleTouchMove = (e) => {
        this.mobileStartDrag.cancel();
        this.$draggedRow.unbind('touchmove', this.handleTouchMove);
    }

    mobileStartDrag = debounce((row) => {
        this.dragStarted = true;
        this.addHint(row);
    }, MOBILE_ACTIVATE_DRAG);

    afterDragEnd() {
        this.dragStarted = false;
        this.$hint.remove();
        this.$hint = null;
    }

    handleRowTouchEnd = (e) => {
        this.$list.css({
            userSelect: '',
            webkitUserSelect: ''
        });

        this.$draggedRow.unbind('touchmove', this.handleTouchMove);

        if (this.dragStarted) {
            this.afterDragEnd();
        }
        else {
            this.mobileStartDrag.cancel();
        }
    }

    addHint(row) {
        const $hint = $(this.props.hint(row));
        const rowBounds = row.getBoundingClientRect();
        const $body = $('body');
        $hint.css({
            left: `${rowBounds.left}px`,
            top: `${rowBounds.top + $body.scrollTop()}px`,
            position: 'absolute'
        });
        $('body').append($hint);
        this.$hint = $hint;
    };

    enableDragAndDrop() {
        const isMobile = kendo.support.mobileOS;
        const $list = $(this.listRef);
        this.$list = $list;

        this.sortable = $list.kendoSortable({
            ignore: this.props.ignore,
            hint: (row) => {
                // on mobile hint is manually added because kendoSortable add it only after touchmove
                // and we need to show hint after MOBILE_ACTIVATE_DRAG ms after touchstart
                if (this.dragStarted) {
                    return this.$hint;
                }

                return this.props.hint(row);
            },
            holdToDrag: isMobile,
            end: (e) => {
                const { newIndex, oldIndex } = e;
                // fix accidentally tap on input when drop
                e.draggableEvent.originalEvent.preventDefault();
                // setTimeout to allow DOM to refresh before state update occur
                setTimeout(() => this.props.move(newIndex, oldIndex));
            },
            cancel: () => {
                this.afterDragEnd();
            }
        }).data('kendoSortable');

        if (isMobile) {
            // set long tap hold time
            this.sortable.draggable.userEvents.minHold = MOBILE_ACTIVATE_DRAG;
        }
    }

    removeRowsListeners() {
        const $rows = this.$list.find('> *');
        $rows.unbind('touchstart', this.handleRowTouchStart);
        $rows.unbind('touchend', this.handleRowTouchEnd);
    }

    addRowsListeners() {
        const $rows = this.$list.find('> *');
        $rows.bind('touchstart', this.handleRowTouchStart);
        $rows.bind('touchend', this.handleRowTouchEnd);
    }

    componentDidUpdate() {
        this.removeRowsListeners();
        this.addRowsListeners();
    }

    componentDidMount() {
        this.addRowsListeners();
    }

    componentWillUnmount() {
        this.removeRowsListeners();
        if (this.sortable) {
            this.sortable.destroy();
        }
    }

    onListRef = inst => {
        this.listRef = inst;
        if (this.sortable) {
            this.sortable.destroy();
        }

        if (inst) {
            this.enableDragAndDrop();
        }
    }

    render() {
        const { hint, move, ignore, ...rest } = this.props; // eslint-disable-line no-unused-vars
        return <ListContainer ref={this.onListRef} {...rest} />;
    }
};

export default SortableList;
export const SortableTBody = SortableList('tbody');
export const getTableDragAndDropHint = container => row => {
    const $row = $(row);
    container.css('width', $row.css('width'));
    const $table = $($row.parents('table')[0]);
    const $tableClone = $table.clone();
    const thead = $table.find('thead')[0];

    if (thead) {
        const headerHeight = $table.find('thead')[0].getBoundingClientRect().height;
        // hide header
        $tableClone.css({
            position: 'absolute',
            top: `-${headerHeight * 1.4}px`
        });
    }

    $tableClone.find('tbody').empty().append($row.clone());
    return container.empty().append($tableClone);
};
