import React from 'react';
import PropTypes from 'prop-types';
import css from './styles.less';
import classNames from 'classnames';

function MobileDialog(props) {
    return (
        <div className={css.rootDialog}>
            <div className={css.header}>
                {props.header}
                <i onClick={props.onClose}
                    className={classNames('fa fa-times btn-icon', css.closeBtn)} />
            </div>
            <div className={css.content}>
                {props.content}
            </div>
            <div className={css.footer}>
                {props.footer}
            </div>
        </div>
    );
}

MobileDialog.propTypes = {
    onClose: PropTypes.func,
    header: PropTypes.node,
    content: PropTypes.node,
    footer: PropTypes.node
};

export default MobileDialog;
