/* global $ */
import lodashFind from 'lodash/find';
import {OTHER_VENDOR_NAME} from 'logic/constants';
import makeApiUrl from './makeApiUrl';
import {getJson} from './ajax';
import projectsApi from './projects';

export const PARTS_URL_STUB = "designs.json/parts/";
export const DESIGN_URL_STUB = "designs.json/design/";

export async function requestPartsForPlatform(vendor, platform) {
    const offline = await projectsApi.isOffline();
    if (!offline) {
        const url = makeApiUrl(PARTS_URL_STUB) + vendor + "/" + platform;
        return new Promise((resolve, reject) => {
            getJson(url).then(resolve, (error) => reject(error.statusText));
        });
    }

    let allData;

    try {
        allData = await getJson(makeApiUrl('designs.json'));
    }
    catch (err) {
        return Promise.reject(err.message);
    }

    const vendorData = lodashFind(allData.designs, d => d.vendor === vendor);
    const platformData = lodashFind(vendorData.platforms, p => p.platform === platform);

    return {
        designs: [
            {
                platforms: [
                    {
                        parts: platformData.parts,
                        platform
                    }
                ],
                vendor
            }
        ],
        filter: `parts/${vendor}/${platform}`,
        revision: allData.revision
    };
}

export async function requestDesign(vendorName, designName) {
    const fullDesignName = vendorName === OTHER_VENDOR_NAME ? `Other_${designName}` : designName;
    const offline = await projectsApi.isOffline();

    if (!offline) {
        let url = makeApiUrl(DESIGN_URL_STUB);
        url += fullDesignName;

        return new Promise((resolve, reject) => {
            getJson(url).then(resolve, (error) => reject(error.statusText));
        });
    }

    let allData;

    try {
        allData = await getJson(makeApiUrl('designs.json'));
    }
    catch (err) {
        return Promise.reject(err.message);
    }

    const vendorData = lodashFind(allData.designs, d => d.vendor === vendorName);
    const designWithoutVendor = fullDesignName.split(vendorName+'_')[1];
    const [platformName] = designWithoutVendor.split('_');
    const platformData = lodashFind(vendorData.platforms, p => p.platform === platformName);
    const partData = lodashFind(platformData.parts, p => p.designName === designName);

    return {
        designs: [partData],
        filter: `design/${designName}`,
        revision: allData.revision
    };
}
