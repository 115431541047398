import _ from 'lodash';
import {Topology} from 'logic/suggestTopology';
import SolutionType from 'logic/SolutionType';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';
import {SchematicFilterType, AECQFilterType, SyncFilterType} from 'logic/constants';

export function getSolutionFilterRegEx(filterType) {
    let str;
    switch (filterType) {
        case SolutionType.ANALOG:
            str = /Buck \(Controller\)|Linear Regulator|Buck \(Integrated FET\)|Boost \(Integrated FET\)|Buck-Boost \(Integrated FET\)|Buck-Boost \(Controller\)|Buck \(Power Module\)|Buck \(Multiphase Controller\)|Boost \(Multiphase Controller\)/; // eslint-disable-line max-len
            break;
        case SolutionType.ANALOG_IFET:
            str = /Buck \(Integrated FET\)|Boost \(Integrated FET\)|Buck-Boost \(Integrated FET\)/;
            break;
        case SolutionType.ANALOG_MODULES:
            str = /Buck \(Power Module\)/;
            break;
        case SolutionType.DIGITAL:
            str = /Buck \(Digital Integrated FET\)|Buck \(Digital Power Module\)|Buck \(Digital Controller\)/;
            break;
        case SolutionType.DIGITAL_IFET:
            str = /Buck \(Digital Integrated FET\)/;
            break;
        case SolutionType.DIGITAL_MODULES:
            str = /Buck \(Digital Power Module\)/;
            break;
    }
    return str;
}

export const applyAllSolutionFilters = function(
    items, solutionTypes, schematicFilters, aecqFilter, syncFilter, ldoEssential) {
    let filtered = filterBySolutionTypes(items, solutionTypes, ldoEssential);
    filtered = filterBySchematicStatusAndMore(filtered, schematicFilters);
    filtered = filterByAECQStatus(filtered, aecqFilter);
    filtered = filterBySyncStatus(filtered, syncFilter);
    return filtered;
};

export function filterBySolutionTypes(parts, filterTypes, ldoEssential) {
    if (filterTypes.length === 1 && filterTypes[0] === SolutionType.ALL) {
        return parts;
    }

    let ldos = [];
    if ((ldoEssential) && !includes(filterTypes, SolutionType.ANALOG)) {
        ldos = parts.filter((x) => (x.topology === Topology.LINEAR_REGULATOR));
    }

    const listOfRegExps = filterTypes.map(ft => getSolutionFilterRegEx(ft));

    const filtered = listOfRegExps.reduce((cuml, regex) => {
        return [ ...cuml, ...parts.filter(p => (p.topology.match(regex) !== null)) ];
    }, []);

    return [ ...uniq(filtered), ...ldos ];
}

export function filterBySelectedParts(resultId, parts, selectedParts) {
    const selectedPartsForOutputId = selectedParts[resultId];

    if (!selectedPartsForOutputId) {
        return [];
    }

    const selectedPartsHash = selectedParts[resultId].reduce((acc, id) => {
        acc[id] = true;
        return acc;
    }, Object.create(null));

    return parts.filter(p => selectedPartsHash[p.id]);
}

export function filterByStatus(parts, filterType, fieldName, ALL_FLAG, TRUE_FLAG) {
    const status = (filterType === TRUE_FLAG);
    return (filterType === ALL_FLAG) ? parts : parts.filter(p => (p[fieldName] === status));
}

const filtersFnMap = {
    [SchematicFilterType.SCHEMATIC_AVAILABLE]: part => part.orcadEnabled === true,
    [SchematicFilterType.SAMPLES_AVAILABLE]: part => part.canbesampled === true,
    [SchematicFilterType.ISIM_SIMULATION_AVILABLE]: part => part.isimmodel === true
};

export function filterBySchematicStatusAndMore(parts, filters) {
    if (filters.indexOf(SchematicFilterType.ALL) !== -1) {
        return parts;
    }

    const filterFunctions = filters.map(filter => filtersFnMap[filter]);
    const filterFn = part => filterFunctions.reduce((accVal, f) => accVal && f(part), true);

    return parts.filter(filterFn);
}

export function filterByAECQStatus(parts, filterType) {
    return filterByStatus(parts, filterType, 'aeqcQual', AECQFilterType.ALL, AECQFilterType.QUALIFIED);
}

export function filterBySyncStatus(parts, filterType) {
    switch (filterType) {
        case SyncFilterType.SYNC_IN:
            return parts.filter(p => (p.syncIn));
        case SyncFilterType.SYNC_IN_OUT:
            return parts.filter(p => (p.syncIn && p.syncOut));
    }
    return parts;
}

export function flattenItems(outputs) {
    return outputs.map((output) => {
        return { ...output, items: _.flatten(_.values(output.items)) };
    });
}

export function limitResults(data, limit) {
    let topologyCount = {};
    let newLimitedArr = [];
    for (let i = 0; i < data.length; i++) {
        const topology = data[i].topology;
        topologyCount[topology] = topologyCount[topology] + 1 || 1;
        if (topologyCount[topology] <= limit) {
            newLimitedArr.push(data[i]);
        }
    }
    return newLimitedArr;
};
