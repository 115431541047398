/* global $ */
import makeApiUrl from './makeApiUrl';
import {getJson, postJson} from './ajax';

export const generateNewReferenceDesign = (data) => {
    return new Promise((resolve, reject) => {
        if (!data.projname) return reject("Design name is missing"); // Failing to send a project name crashes backend

        postJson(makeApiUrl("inputparams"), data).then(resolve, (error) => {
            reject(error.statusText);
        });
    });
};

export const regenerateDesign = (designId) => {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: makeApiUrl(`regenerate.design/${designId}`),
            contentType: "application/json",
            dataType: 'json',
            cache: false,
            xhrFields: { withCredentials: true }
        }).then(resolve, (error) => {
            reject(error.statusText);
        });
    });
};

export const getDesignStatus = (designId) => {
    return new Promise((resolve, reject) => {
        const cacheBuster = '?z=' + Date.now().toString();
        getJson(makeApiUrl(`status.json/${designId}${cacheBuster}`))
            .then(resolve, (error) => reject(error.statusText));
    });
};

export const getDesignFiles = (designId) => {
    return new Promise((resolve, reject) => {
        getJson(makeApiUrl(`downloaddetails.json/${designId}`))
            .then(resolve, (error) => reject(error.statusText));
    });
};

export const emailZipFile = (email, designId) => {
    return new Promise((resolve, reject) => {
        postJson(makeApiUrl("sendzip"),
            {email: email, requestid: designId}).then(resolve, (error) => reject(error.statusText));
    });
};

// Returns true is the user is authenticated else false
export const isUserSignedIn = () => {
    // Sending the reference design status API a dummy id just for it's auth response
    // When not logged in, backend returns in {"status": null}, otherwise {} when authenticated
    // TODO: Ask Eric for a better way to do this
    return new Promise((resolve, reject) => {
        getJson(makeApiUrl("status.json/checklogin"))
            .then((result) => {
                const isSignedIn = (result.status !== null);
                if (!isSignedIn && (window.resetname !== undefined)) {
                    window.resetname();
                }
                resolve(isSignedIn);
            },
            (error) => {
                if (window.resetname) {
                    window.resetname();
                }
                reject(error.statusText);
            });
    });
};
