import PropTypes from 'prop-types';
import React from 'react';
import {Row, Col} from 'bootstrap-core';
import databind from 'redux/utils/databind';
import classNames from 'classnames';
import {actions as suggestedPartsActions, selectors} from 'redux/modules/suggestedParts';
import css from './styles.less';
import TotalMatches from 'components/SuggestedParts/TotalMatches';
import PartsGrid from 'components/SuggestedParts/PartsGrid';
import ActionButton from 'components/ActionButton';
import PrettySquareToggleButton from 'components/PrettySquareToggleButton';
import FiltersPanel from "./FiltersPanel";
import SettingsPanel from "./SettingsPanel";
import HiddenPartsWarning from './HiddenPartsWarning';
import {mapSortInfoToOrderBy} from "../../logic/orderByMappings";
import PageSlider from 'components/PageSlider';
import SelectionIndicator, {SHOW_SELECTED_FILTER, SHOW_ALL_FILTER} from './SelectionIndicator';
import MobileFiltersPanel from './MobileFiltersPanel';
import MobileSettingsPanel from './MobileSettingsPanel';
import {Tablet, Desktop} from 'styles/variables/breakpoints';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';
import {
    hasValidQuerystringParamsInCurrentCycle,
    closeQuerystringParamsCycle,
    waitTime,
} from 'redux/modules/startOptions';

const ExpandCollapseAllComponent = (props) => {
    let expandClass = (props.allAreExpanded || props.showSelectedParts) ? css.linkDisabled : "";
    let collapseClass = (props.allAreCollapsed || props.showSelectedParts) ? css.linkDisabled : "";

    const enExpand = (!props.showSelectedParts && !props.allAreExpanded);
    const enCollapse = (!props.showSelectedParts && !props.allAreCollapsed);
    return (<div className={classNames(css.expandCollapseHeader)}>
        <div className={css.collapse}>
            <ul>
                <li onClick={(enExpand) ? props.expandAll : () => {}}>
                    <span className={css.expandCollapseWrapper}>
                        <span className={classNames("fa collapseControl fa-angle-down", expandClass)}></span>
                        <a className={expandClass}>{getText(contentKeys.EXPAND_ALL)}</a>
                    </span>
                </li>
                <li onClick={(enCollapse) ? props.collapseAll : () => {}}>
                    <span className={css.expandCollapseWrapper}>
                        <span className={classNames("fa collapseControl fa-angle-up", collapseClass)}></span>
                        <a className={collapseClass}>{getText(contentKeys.COLLAPSE_ALL)}</a>
                    </span>
                </li>
            </ul>
        </div>
    </div>);
};

ExpandCollapseAllComponent.propTypes = {
    showSelectedParts: PropTypes.bool.isRequired,
    allAreExpanded: PropTypes.bool.isRequired,
    allAreCollapsed: PropTypes.bool.isRequired,
    expandAll: PropTypes.func.isRequired,
    collapseAll: PropTypes.func.isRequired
};

class SuggestedPartsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            showSettings: false
        };
    }

    static propTypes = {
        // databind state
        error: PropTypes.bool.isRequired,
        errorMessage: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
        canContinue: PropTypes.bool.isRequired,
        filtersAreModified: PropTypes.bool,
        settingsAreModified: PropTypes.bool,
        showWarning: PropTypes.bool,
        numHiddenSelections: PropTypes.number,
        totalSelections: PropTypes.number,
        // databind actions
        changeSolutionType: PropTypes.func,
        reloadResults: PropTypes.func,
        continue: PropTypes.func,
        hideWarning: PropTypes.func,
        unselectHiddenParts: PropTypes.func,
        setOrderBy: PropTypes.func.isRequired,
        results: PropTypes.array.isRequired,
        nrOutputsWithParts: PropTypes.number.isRequired,
        showSelectedParts: PropTypes.bool.isRequired,
        changeShowSelectedParts: PropTypes.func.isRequired,
        nrOutputs: PropTypes.number.isRequired,
        selectedParts: PropTypes.object.isRequired,
        expandedState: PropTypes.shape({
            allAreExpanded: PropTypes.bool,
            allAreCollapsed: PropTypes.bool
        }).isRequired,
        allAreExpanded: PropTypes.bool.isRequired,
        allAreCollapsed: PropTypes.bool.isRequired,
        setExpandedState: PropTypes.func.isRequired
    };

    static databind({suggestedParts}) {
        const { error, message, total, canContinue, showWarning, results } = suggestedParts;
        return {
            error,
            errorMessage: message,
            total,
            canContinue,
            filtersAreModified: selectors.areFiltersModified(suggestedParts),
            settingsAreModified: selectors.areSettingsModified(suggestedParts),
            showWarning,
            numHiddenSelections: selectors.countHiddenParts(suggestedParts),
            totalSelections: selectors.getTotalSelections(suggestedParts),
            results,
            nrOutputsWithParts: selectors.getOutputsWithSelectedParts(suggestedParts).length,
            nrOutputs: selectors.getUniqueOutputIds(suggestedParts.results).length,
            showSelectedParts: suggestedParts.showSelectedParts,
            selectedParts: suggestedParts.selectedParts,
            expandedState: suggestedParts.expandedState,
            allAreCollapsed: suggestedParts.expandedState.allAreCollapsed,
            allAreExpanded: suggestedParts.expandedState.allAreExpanded
        };
    }
    static actions = {
        reloadResults: suggestedPartsActions.reloadResults,
        changeSolutionType: suggestedPartsActions.filterSolutionsType,
        continue: suggestedPartsActions.continue,
        hideWarning: suggestedPartsActions.hideWarning,
        unselectHiddenParts: suggestedPartsActions.unselectHiddenParts,
        setOrderBy: suggestedPartsActions.setOrderBy,
        changeShowSelectedParts: suggestedPartsActions.changeShowSelectedParts,
        setExpandedState: suggestedPartsActions.setExpandedState
    };

    componentDidMount() {
        if (hasValidQuerystringParamsInCurrentCycle()) {
            // Ensures page scroll to the 'Suggested Parts' block.
            const findSolutionsContainer = $("#findSolutionsContainer");
            if (findSolutionsContainer !== null) {
                const scrollTo = findSolutionsContainer.offset().top + findSolutionsContainer.height();
                $('html, body').animate({scrollTop: scrollTo}, 200);
            }

            // Expands all search result blocks.
            this.expandAll();

            // Deactivates actions based on querystring parameters data.
            setTimeout(() => {
                closeQuerystringParamsCycle();
            }, waitTime);
        }
        this.props.reloadResults();
    }

    componentDidUpdate(oldProps) {
    }

    expandAll = () => {
        this.props.setExpandedState({allAreExpanded: true, allAreCollapsed: false});
    };

    collapseAll = () => {
        this.props.setExpandedState({allAreCollapsed: true, allAreExpanded: false});
    };

    updateExpandedRows = (expandedState) => {
        this.props.setExpandedState(expandedState);
    };

    onSolutionTypeChange = (e) => {
        this.props.changeSolutionType({solutionType: e.sender.element[0].value});
    };

    onClick = () => {
        this.props.continue();
    };

    toggleFilters = () => {
        this.setState({ showFilters: !this.state.showFilters });
    };

    toggleSettings = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onCloseWarning = () => {
        this.props.hideWarning();
    };

    onUnselectHidden = () => {
        this.props.unselectHiddenParts();
    };

    handleSortChange = (sort) => {
        this.props.setOrderBy(mapSortInfoToOrderBy(sort));
    };

    handleFilterClick = (filter) => {
        const showSelectedParts = filter === SHOW_SELECTED_FILTER;
        this.props.changeShowSelectedParts(showSelectedParts);
    }

    getPartsGridRef = (inst) => {
        this.partsGrid = inst;
    }

    render() {
        const error = (this.props.error) ? <span>Error: {this.props.errorMessage}</span> : "";

        return (
            <div className={"spGridContainer"}>
                <Row className={classNames(css.headerContainer)}>
                    <div className={css.controlHeader}>
                        <TotalMatches matches={this.props.total} />
                        <div className={css.buttonsContainer}>
                            <PrettySquareToggleButton className={css.toggleBtn}
                                onClick={this.toggleFilters}
                                isSelected={this.state.showFilters}
                                showModified={this.props.filtersAreModified}>
                                <i className="fa fa-filter"> </i>
                                <span className={css.squareBtnLabel}>
                                    {(this.state.showFilters)
                                        ? getText(contentKeys.HIDE_FILTERS) : getText(contentKeys.SHOW_FILTERS)}
                                </span>
                            </PrettySquareToggleButton>
                            <PrettySquareToggleButton className={classNames(css.toggleBtn, css.settingsButton)}
                                onClick={this.toggleSettings}
                                isSelected={this.state.showSettings}
                                showModified={this.props.settingsAreModified}>
                                <i className="fa fa-cog"></i>
                                <span className={css.squareBtnLabel}>
                                    {(this.state.showSettings)
                                        ? getText(contentKeys.HIDE_SETTINGS) : getText(contentKeys.SHOW_SETTINGS)}
                                </span>
                            </PrettySquareToggleButton>
                        </div>
                    </div>
                    <ExpandCollapseAllComponent
                        showSelectedParts={this.props.showSelectedParts}
                        allAreExpanded={this.props.allAreExpanded}
                        allAreCollapsed={this.props.allAreCollapsed}
                        expandAll={this.expandAll} collapseAll={this.collapseAll} />
                </Row>
                <Tablet>
                    <PageSlider
                        showAuxPage={this.state.showFilters || this.state.showSettings}
                        content={
                            this.state.showFilters ? (
                                <MobileFiltersPanel
                                    onClose={() => this.setState({showFilters: false})}
                                />
                            ) : (
                                <MobileSettingsPanel
                                    onClose={() => this.setState({showSettings: false})}
                                />
                            )
                        } />
                </Tablet>
                <Row className={classNames(css.noPadding)}>
                    <Col xs={12}>
                        {(this.state.showFilters) ? (
                            <Desktop>
                                <FiltersPanel />
                            </Desktop>
                        ) : null}
                    </Col>
                </Row>
                <Row className={classNames(css.noPadding)}>
                    <Col xs={12}>
                        {(this.state.showSettings) ? (
                            <Desktop>
                                <SettingsPanel />
                            </Desktop>
                        ) : null}
                    </Col>
                </Row>
                <Row className={css.noPadding}>
                    <Col xs={12}>
                        {(this.props.showWarning && this.props.numHiddenSelections > 0)
                            ? <HiddenPartsWarning
                                count={this.props.numHiddenSelections}
                                total={this.props.totalSelections}
                                onClose={this.onCloseWarning}
                                onUnselect={this.onUnselectHidden} /> : null}
                    </Col>
                </Row>
                <Row className="gridRow">
                    <Col className="topGrid">
                        <PartsGrid
                            ref={this.getPartsGridRef}
                            onSortChange={this.handleSortChange}
                            updateExpandedRows={this.updateExpandedRows}
                            expandAll={this.props.allAreExpanded}
                            collapseAll={this.props.allAreCollapsed}
                            disableRowExpandCollapse={this.props.showSelectedParts} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {error}
                    </Col>
                </Row>
                <Row>
                    <Col className={css.continueContainer}>
                        <SelectionIndicator className={css.selectionIndicator}
                            activeFilter={this.props.showSelectedParts ? SHOW_SELECTED_FILTER : SHOW_ALL_FILTER}
                            onFilterClick={this.handleFilterClick}
                            nrOutputs={this.props.nrOutputs}
                            nrOutputsWithParts={this.props.nrOutputsWithParts}
                            nrSelectedParts={this.props.totalSelections} />
                        <ActionButton
                            id="SuggestedParts_Continue"
                            className={classNames("large", css.suggestedPartsContinue)}
                            label={getText(contentKeys.CONTINUE)}
                            testHook="continue_btn"
                            disabled={!this.props.canContinue}
                            onClick={this.onClick} />
                    </Col>
                </Row>
            </div>);
    }
}

export default databind(SuggestedPartsComponent);
