const DEFAULT_TEMPLATE = {
    designName: "Default From Vendor File",
    efficiency: 0.85,
    optimized: false,
    inputs: [{ name: "System Rail 1", vmin: 5, vmax: 5, imax: 20 }],
    outputs: []
};

const DEFAULT_OUTPUT_TEMPLATE_ENTRY = {
    "name": "",
    "sourceRail": "System Rail 1",
    "volts": 0,
    "amps": 0,
    "onSeq": 1,
    "offSeq": null,
    "ldo": false,
    "sync": false
};

/*
 * summary:
 *   name
 *   outputs: [
 *       {name: "VCC_5", volts: 5, amps: 10},
 *       {name: "VCC_12", volts: 512 amps: 10}
 *   ]
 */
export default function buildTemplateFromVendorDesignSummary(summary) {
    let template = {
        ...DEFAULT_TEMPLATE,
        designName: summary.name,
        filename: summary.filename,
        inputs: summary.inputs || DEFAULT_TEMPLATE.inputs,
        templateWarning: summary.templateWarning,
        optimized: summary.optimized
    };

    template.outputs = summary.outputs.map((output) => {
        const { exactName, name, volts, amps, onSeq } = output;

        const outputName = exactName || `${name}_${volts}V`;

        return { ...DEFAULT_OUTPUT_TEMPLATE_ENTRY, name: outputName, volts, amps, onSeq };
    });

    return template;
}
