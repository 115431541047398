import _ from 'lodash';

function isFed(outputs, output) {
    const connected = outputs.filter((x) => x.id === output.sourceRail);
    return (connected && connected.length === 1);
}

function appendIsFed(outputs) {
    return outputs.map((output) => {
        output.isFed = isFed(outputs, output);
    });
}

function feedsAnother(outputs, output) {
    const connected = outputs.filter((x) => x.sourceRail === output.id);
    return (connected && connected.length > 0);
};

function appendFeedsAnother(outputs) {
    return outputs.map((output) => {
        output.feeds = feedsAnother(outputs, output);
    });
}

export function generateResultsForCompareAndSelect(outputRails, selections) {
    let outputsForComparison = outputRails.map((o) => {
        let y = _.cloneDeep(o);
        y.items = [];
        return y;
    });

    const selectedParts = outputRails.map((output) => {
        // flatten these out of their topology groupings
        const parts = _.flatten(_.values(output.items));
        return parts.filter((part) => {
            const found = (_.findIndex(selections[output.id], (sel) => sel === part.id) !== -1);
            return found;
        });
    });

    outputsForComparison.forEach((output, idx) => {
        output.items = selectedParts[idx];
    });

    const singleOutputRails = outputsForComparison.filter((o) => o.numOutputs === 1);
    const nOutputRails = outputsForComparison.filter((o) => o.numOutputs > 1);

    // concat the N-output selections onto their respective single output rails
    // keeping higher output count selections at the front
    _.sortBy(nOutputRails, 'numOutputs').forEach((output, idx) => {
        output.items = output.items.map((part) => {
            return { ...part, idsOfSuggestedOutputTuple: output.ids };
        });

        output.ids.forEach((singleId) => {
            // side effects - we update the singleOutputRail array items via target here
            const target = _.find(singleOutputRails, (s) => s.id === singleId);
            target.items = output.items.concat(target.items);
        });
    });

    appendIsFed(singleOutputRails);
    appendFeedsAnother(singleOutputRails);

    return singleOutputRails;
}
