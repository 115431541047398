export const movePosition = (items, newIndex, oldIndex) => {
    const nrItems = items.length;
    if (newIndex > nrItems - 1 || oldIndex > nrItems - 1) {
        return items;
    }

    const movedItem = items[oldIndex];
    let withoutItem = [...items.slice(0, oldIndex), ...items.slice(oldIndex + 1)];
    return [...withoutItem.slice(0, newIndex), movedItem, ...withoutItem.slice(newIndex)];
};
