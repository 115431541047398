import _ from 'lodash';

export function isAtLeastOnePartPerOutputSelected(results, selectedParts) {
    const singleOutputs = results.filter((x) => x.numOutputs === 1);
    const outputsWithSelections = _.keys(selectedParts);
    if (outputsWithSelections.length === 0) return false;

    const singleFlags = _.keyBy(singleOutputs.map((output) => {
        let flag = { outputId: output.id, valid: false };
        flag.valid = ((_.flatten(_.values(output.items)).length === 0) ||
                    (selectedParts[output.id] != null) &&
                    (selectedParts[output.id].length > 0));

        return flag;
    }), 'outputId');

    const nOutputs = results.filter(x => x.numOutputs > 1);

    nOutputs.forEach(output => {
        if (selectedParts[output.id] != null) {
            output.ids.forEach(i => { singleFlags[i].valid = true; });
        }
    });

    return _.values(singleFlags).map((x) => x.valid).reduce((a, x) => a && x, true);
}
