/* global kendo, $ */

import _ from 'lodash';
import {buildDiagramData} from 'logic/editor';

export default class BlockDiagramDataSource extends kendo.data.HierarchicalDataSource {
    constructor(store, forReferenceDesign) {
        const options = {};

        options.transport = {
            read: (options) => {
                options.success(buildDiagramData(this.local, this.forReferenceDesign));
            }
        };

        options.data = [];
        options.schema = {
            model: {
                idField: 'id',
                fields: {
                    name: {type: 'string'},
                    volts: {type: 'number'},
                    amps: {type: 'number'},
                    vmin: {type: 'number'},
                    vmax: {type: 'number'},
                    imax: {type: 'number'},
                    power: {type: 'number'},
                    availableOutput: {type: 'number'},
                    invalid: {type: 'boolean'},
                    railColor: {type: 'string'}
                },
                children: 'childRails'
            }
        };

        super(options);

        this.local = {};
        this.store = store;
        this.forReferenceDesign = forReferenceDesign;

        // Call onStoreChange initially to use the existing state of the store to fill out this.local
        this.onStoreChange();

        this.unsubscribe = store.subscribe(this.onStoreChange);
    }

    onStoreChange = () => {
        const state = this.store.getState();
        const railsHaveChanged = !_.isEqual(this.local.inputs, state.editor.inputs) ||
                                 !_.isEqual(this.local.outputs, state.editor.outputs);
        const partsHaveChanged = this.forReferenceDesign &&
                                 !_.isEqual(this.local.selectedPartIds, state.compareAndSelect.selectedPartIds);

        if (this.local.selectedBlockId !== state.editor.selectedBlockId || railsHaveChanged || partsHaveChanged) {
            this.local.inputs = state.editor.inputs;
            this.local.outputs = this.forReferenceDesign ? state.compareAndSelect.results
                : state.editor.outputs;
            this.local.selectedBlockId = state.editor.selectedBlockId;
            this.local.activeColors = state.colorPalette.activeColors;
            this.local.selectedPartIds = state.compareAndSelect.selectedPartIds;
            this.read();
        }
    };
}
