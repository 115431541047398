const LOAD_THIS = "loadthis";
const ERROR = "error";
const SUCCESS = "success";

export function createLoadThisMessage(fileObj) {
    return { type: LOAD_THIS, payload: fileObj };
}

export function createErrorMessage(error) {
    return { type: ERROR, payload: error };
}

export function createSuccessMessage(payload) {
    return { type: SUCCESS, payload };
}

export function isError(m) {
    return (m.type === ERROR);
}

export function isLoadThis(m) {
    return (m.type === LOAD_THIS);
}

export default { createLoadThisMessage, createErrorMessage, createSuccessMessage, isError, isLoadThis };
