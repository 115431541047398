import PropTypes from 'prop-types';
import React from 'react';
import Kendo from 'kendo-ui-react';
import _ from 'lodash';
import {rectLegend} from './helpers/createChart';
import colors from 'styles/variables/colors';

// Generic component for rendering the line charts in the system validation banner section of compare and select screen
const kendoChartOptions = {
    title: {
        text: "",
        font: "600 16px 'Open Sans'",
        color: "#333333"
    },
    chartArea: {
        background: "none"
    },
    plotArea: {
        background: "#fff"
    },
    series: [{
        name: "",
        type: "scatterLine",
        style: "smooth",
        color: colors['@primary-color'],
        markers: { visible: false },
        data: []
    }],
    yAxis: {
        title: {
            text: "",
            font: "600 11px 'Open Sans'",
            color: "#333333",
            position: "left",
            padding: {
                top: 10
            }
        },
        labels: {
            font: "10px 'Open Sans'",
            color: "#333333"
        },
        line: {
            visible: false
        }
    },
    xAxis: {
        title: {
            text: "",
            font: "600 11px 'Open Sans'",
            color: "#333333",
            position: "left",
            padding: {
                top: 10
            }
        },
        labels: {
            font: "10px 'Open Sans'",
            color: "#333333"
        },
        line: {
            visible: false
        },
        majorUnit: 20,
        max: 100,
        min: 0
    }
};

const SystemBannerChart = (props) => {
    let options = _.cloneDeep(kendoChartOptions);

    if (props.compact) {
        options.chartArea.width = 234;
        options.chartArea.height = 120;
        options.chartArea.margin = { top: -5 };
        options.plotArea.margin = { top: -8 };
        // options.plotArea.width = 200;
        // options.plotArea.height = 65;
        options.title.font = "600 11px 'Open Sans'";
        options.legend = {visible: false};
        options.yAxis.majorGridLines = {visible: false};
        options.yAxis.majorUnit = props.yMax;
        options.xAxis.majorGridLines = {visible: false};
        options.xAxis.majorUnit = 100;
    }
    else {
        options.chartArea.width = props.width;
        options.chartArea.height = 240;
        options.plotArea.height = 96;
        options.legend = {
            position: "top",
            labels: {
                font: "11px 'Open Sans'",
                color: "#333333"
            },
            item: {
                visual: rectLegend
            }
        };
        options.yAxis.majorUnit = props.yStep;
        options.xAxis.title.text = props.xAxisTitle;
        options.yAxis.title.text = props.yAxisTitle;
    }

    options.title.text = props.chartTitle;
    options.yAxis.min = props.yMin;
    options.yAxis.max = props.yMax;
    options.series[0].name = props.seriesName;
    options.series[0].data = props.data;

    return (
        <Kendo.dataviz.Chart options={options} />
    );
};

SystemBannerChart.propTypes = {
    chartTitle: PropTypes.string.isRequired,
    seriesName: PropTypes.string.isRequired,
    xAxisTitle: PropTypes.string.isRequired,
    yAxisTitle: PropTypes.string.isRequired,
    yMin: PropTypes.number.isRequired,
    yMax: PropTypes.number.isRequired,
    yStep: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    compact: PropTypes.bool,
    width: PropTypes.number.isRequired
};

export default SystemBannerChart;
