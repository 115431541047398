import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import databind from 'redux/utils/databind';
import {actions as editorActions} from 'redux/modules/editor';
import SwitchOption from 'components/SwitchOption';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class SwitchOptions extends React.Component {
    static propTypes = {
        index: PropTypes.number,
        className: PropTypes.string,
        powerGoodOutput: PropTypes.func,
        syncInput: PropTypes.func,
        syncOutput: PropTypes.func,
        enablePinInput: PropTypes.func,
        ldo: PropTypes.func,
        ldoValue: PropTypes.bool,
        powerOutputValue: PropTypes.bool,
        enablePinInputValue: PropTypes.bool,
        syncInputValue: PropTypes.bool,
        syncOutputValue: PropTypes.bool
    };

    static actions = {
        powerGoodOutput: editorActions.powerGoodOutput,
        syncInput: editorActions.syncInput,
        syncOutput: editorActions.syncOutput,
        enablePinInput: editorActions.enablePinInput,
        ldo: editorActions.ldo
    };

    static databind({editor}, props) {
        return {
            ldoValue: editor.outputs[props.index] && editor.outputs[props.index].ldo,
            powerOutputValue: editor.outputs[props.index] && editor.outputs[props.index].powerGoodOutput,
            enablePinInputValue: editor.outputs[props.index] && editor.outputs[props.index].enablePinInput,
            syncInputValue: editor.outputs[props.index] && editor.outputs[props.index].syncInput,
            syncOutputValue: editor.outputs[props.index] && editor.outputs[props.index].syncOutput
        };
    }

    powerOutput = (e) => {
        this.props.powerGoodOutput({index: this.props.index, switch: e.checked});
    };
    syncInput = (e) => {
        this.props.syncInput({index: this.props.index, switch: e.checked});
    };
    syncOutput = (e) => {
        this.props.syncOutput({index: this.props.index, switch: e.checked});
    };
    enablePinInput = (e) => {
        this.props.enablePinInput({index: this.props.index, switch: e.checked});
    };
    ldo = (e) => {
        this.props.ldo({index: this.props.index, switch: e.checked});
    };
    render() {
        const enabledConditions = !this.props.ldoValue;

        return (
            <table className={css.moreOptions}>
                <thead>
                    <tr>
                        <th>{getText(contentKeys.REQUIRED)}?</th>
                        <th></th>
                        <th className={css.switchOptionTitle}>
                            <span>{getText(contentKeys.NO)}</span>
                            <span>{getText(contentKeys.YES)}</span>
                        </th>
                    </tr>
                </thead>
                <tbody data-cy="moreOptionsTable">
                    <SwitchOption
                        className={css.options}
                        title={getText(contentKeys.POWER_GOOD_OUTPUT)}
                        enabled={true}
                        checked={!!this.props.powerOutputValue}
                        change={this.powerOutput}
                    />
                    <SwitchOption
                        className={css.options}
                        title={getText(contentKeys.ENABLE_PIN_INPUT)}
                        enabled={true}
                        checked={!!this.props.enablePinInputValue}
                        change={this.enablePinInput}
                    />
                    <SwitchOption
                        className={css.options}
                        title={getText(contentKeys.LDO)}
                        enabled={true}
                        checked={!!this.props.ldoValue}
                        change={this.ldo}
                    />
                    <SwitchOption
                        className={css.options}
                        title={getText(contentKeys.SYNC_INPUT)}
                        enabled={enabledConditions}
                        checked={!!this.props.syncInputValue}
                        change={this.syncInput}
                    />
                    <SwitchOption
                        className={css.options}
                        title={getText(contentKeys.SYNC_OUTPUT)}
                        enabled={enabledConditions}
                        checked={!!this.props.syncOutputValue}
                        change={this.syncOutput}
                    />
                </tbody>
            </table>
        );
    }
}

export default databind(SwitchOptions);
