import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import databind from 'redux/utils/databind';
import LimitPerTopology from 'components/SuggestedParts/LimitPerTopology';
import RestoreDefaultsItem from './RestoreDefaultsItem';
import ColumnSettingsItem from './ColumnSettingsItem';
import OrderResultsByItem from './OrderResultsByItem';
import {actions, selectors} from 'redux/modules/suggestedParts';

class SettingsPanel extends React.Component {
    static propTypes = {
        limit: PropTypes.number,
        orderBy: PropTypes.objectOf(PropTypes.string),
        showPrice: PropTypes.bool,
        showingAdditionalColumn: PropTypes.bool,
        applyLimit: PropTypes.func,
        orderResultsBy: PropTypes.func,
        setShowPriceAndUpdateOrderBy: PropTypes.func,
        resetSettingsAndAdditionalColumn: PropTypes.func,
        modified: PropTypes.bool
    };

    static actions = {
        applyLimit: actions.limitTopologies,
        orderResultsBy: actions.orderResultsBy,
        setShowPriceAndUpdateOrderBy: actions.setShowPriceAndUpdateOrderBy,
        resetSettingsAndAdditionalColumn: actions.resetSettingsAndAdditionalColumn
    };

    static databind({suggestedParts}) {
        const { limit, orderBy, showPrice, additionalOrderByColumn } = suggestedParts;
        return { limit,
            orderBy,
            showPrice,
            showingAdditionalColumn: additionalOrderByColumn != null,
            modified: selectors.areSettingsModified(suggestedParts) };
    }

    onLimit = (e) => {
        this.props.applyLimit(e);
    };

    onOrderBy = (orderBy) => {
        this.props.orderResultsBy(orderBy);
    };

    onColumnSettings = (showPrice) => {
        this.props.setShowPriceAndUpdateOrderBy(showPrice);
    }

    onRestoreDefaults = () => {
        this.props.resetSettingsAndAdditionalColumn();
    }

    render() {
        return (
            <div className={css.flexCenter}>
                <div className={css.settingsPanel}>
                    <LimitPerTopology limit={this.props.limit} onLimit={this.onLimit} />
                    <OrderResultsByItem
                        selected={this.props.orderBy}
                        includePriceOption={this.props.showPrice}
                        onChange={this.onOrderBy} />
                    <ColumnSettingsItem checked={this.props.showPrice} onChange={this.onColumnSettings} />
                    <div className={css.panelItem} />
                    <RestoreDefaultsItem
                        disabled={!(this.props.modified || this.props.showingAdditionalColumn)}
                        onRestore={this.onRestoreDefaults} />
                </div>
            </div>);
    }
}

export default databind(SettingsPanel);
