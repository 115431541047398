const colors = {
    '@primary-color': '#2a289d',
    '@error-container-yellow': '#ffffc0',
    '@error-red': '#990000',
    '@backgroundGray': '#e8eaec',
    '@borderLightGrey': '#e8eaec',
    '@borderGray': '#dddddd',
    '@border-bright-blue': '#328dcc',
    '@border-neon-blue': '#48c9cc',
    '@list-selected-dark-blue': '#c1dce0',
    '@headingDarkGray': '#333',
    '@textGray': '#333333',
    '@highlightLightBlue': '#e4f8fb',
    '@dropdownBorderGray': '#d5d5d5',
    '@disabledText': '#aaa',
    '@default-link-color': '#0085b2',
    '@default-color': 'grey',
    '@default-link-hover-color': '#03688a',
    '@default-caret-color': '#313131',
    '@page-background-light-grey': '#f6f8fa',
    '@railGray': '#798292',
    '@inputColor1': '#5F8CC4',
    '@backgroundColor1': 'rgba(95,140,196,0.15)',
    '@inputColor2': '#E47A00',
    '@backgroundColor2': 'rgba(288,122,0,0.15)',
    '@inputColor3': '#9A413C',
    '@backgroundColor3': 'rgba(154,65,60,0.15)',
    '@inputColor4': '#00BBDD',
    '@backgroundColor4': 'rgba(0,187,221,0.15)',
    '@inputColor5': '#383D75',
    '@backgroundColor5': 'rgba(56,61,117,0.15)',
    '@inputColor6': '#32B28C',
    '@backgroundColor6': 'rgba(50,178,140,0.15)',
    '@inputColor7': '#B2304C',
    '@backgroundColor7': 'rgba(178,48,76,0.15)',
    '@inputColor8': '#604AB2',
    '@backgroundColor8': 'rgba(96,74,178,0.15)',
    '@inputColor9': '#679E30',
    '@backgroundColor9': 'rgba(103,158,48,0.15)',
    '@inputColor10': '#9E5E45',
    '@backgroundColor10': 'rgba(158,94,69,0.15)'
};

export default colors;
