/* global kendo */
import PropTypes from 'prop-types';

import React from 'react';
import databind from 'redux/utils/databind';
import {actions as editorActions} from 'redux/modules/editor';
import {actions as deleteActions} from 'redux/modules/confirmDelete';
import classNames from 'classnames';
import css from './styles.less';
import BufferedInput, {filterAsNumber} from 'components/BufferedInput';
import {formatNumberWithUpTo2Decimals, formatNumberVariable} from 'logic/formats';

const noFilter = (value) => value;

class InputRail extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        // databind items below
        rail: PropTypes.object.isRequired,
        isFed: PropTypes.bool.isRequired,
        index: PropTypes.number.isRequired,
        changeInputRailName: PropTypes.func.isRequired,
        changeInputRail: PropTypes.func.isRequired,
        deleteInputRail: PropTypes.func.isRequired,
        confirmDelete: PropTypes.func.isRequired,
        colors: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            dirty: false
        };
        // this.onChangeVmin = this.onChangeVoltsAmps.bind(null, "vmin");
        // this.onChangeVmax = this.onChangeVoltsAmps.bind(null, "vmax");
        // this.onChangeImax = this.onChangeVoltsAmps.bind(null, "imax");
    }

    static databind ({editor, colorPalette}, props) {
        return {
            rail: editor.inputs[props.index],
            colors: colorPalette.activeColors,
            isFed: true
        };
    }

    static actions = {
        changeInputRailName: editorActions.changeInputRailName,
        changeInputRail: editorActions.changeInputRail,
        deleteInputRail: editorActions.deleteInputRail,
        confirmDelete: deleteActions.confirmDelete
    };

    onChangeName = (value) => {
        this.props.changeInputRailName({index: this.props.index, value});
    };

    onChangeVmin = (value) => {
        this.onChangeVoltsAmps("vmin", value);
        if (!this.state.dirty) {
            this.onChangeVoltsAmps("vmax", value);
            this.setState({ ...this.state, dirty: true });
        }
    };

    onChangeVmax = (value) => {
        this.onChangeVoltsAmps("vmax", value);
        if (!this.state.dirty) {
            this.onChangeVoltsAmps("vmin", value);
            this.setState({ ...this.state, dirty: true });
        }
    };

    onChangeImax = (value) => {
        this.onChangeVoltsAmps("imax", value);
    };

    onChangeVoltsAmps = (fieldName, value) => {
        this.props.changeInputRail({
            index: this.props.index,
            fieldName,
            value: kendo.parseFloat(value) });
    };

    onDelete = () => {
        this.props.confirmDelete({ input: true, index: this.props.index, id: this.props.rail.id });
    };

    render() {
        const errorStyle = (this.props.rail.invalid) ? css.inputError : "";
        const rail = this.props.rail;
        const myColor = (this.props.colors[rail.id]) ? this.props.colors[rail.id].color : null;
        const className = classNames(css.inputRail, this.props.className, myColor + "-background");
        const negativeValueClass = (rail.power < 0) ? css["negValue"] : "";

        let power = formatNumberWithUpTo2Decimals(this.props.rail.power);
        return (
            <tr className={className}>
                <td className={classNames(css.coloredTab, myColor)} >
                    <div className={css.extendedTapArea}></div>
                    <div className={classNames(css.dottedLine, css.dottedLeft)} />
                    <div className={classNames(css.dottedLine, css.dottedRight)} />
                </td>
                <td className={css.deleteBtn}>
                    <span data-test="delete_row" onClick={this.onDelete} className="fa fa-times-circle"></span>
                </td>
                <td className={classNames(css.inputName)}>
                    <BufferedInput
                        dataTest="name"
                        value={rail.name}
                        filter={noFilter}
                        onBufferedChange={this.onChangeName} />
                </td>
                <td className={errorStyle}>
                    <BufferedInput
                        dataTest="vmin"
                        value={rail.vmin}
                        filter={filterAsNumber}
                        format={formatNumberVariable}
                        onBufferedChange={this.onChangeVmin} />
                </td>
                <td className={errorStyle}>
                    <BufferedInput
                        dataTest="vmax"
                        value={rail.vmax}
                        filter={filterAsNumber}
                        format={formatNumberVariable}
                        onBufferedChange={this.onChangeVmax} />
                </td>
                <td>
                    <BufferedInput
                        dataTest="imax"
                        value={rail.imax}
                        filter={filterAsNumber}
                        format={formatNumberVariable}
                        onBufferedChange={this.onChangeImax} />
                </td>
                <td className={errorStyle}>
                    <span className={negativeValueClass} data-test="power">{power}</span>
                </td>
            </tr>
        );
    }
}

export default databind(InputRail);
