function colIdxToChar(idx) {
    return String.fromCharCode(65 + idx);
}

function csvToWorksheet(csvString) {
    const lines = csvString.replace(/\r\n/g, '\n').split(/\r|\n/);
    const linesOfCells = lines.map((line) => (line.split(",")));

    let sheet = {};

    linesOfCells.forEach((cells, row) => {
        cells.forEach((cell, col) => {
            const address = colIdxToChar(col) + (row + 1).toString();
            if (cell !== "") {
                sheet[address] = { v: cell };
            }
        });
    });

    return sheet;
}

export default csvToWorksheet;
