import PropTypes from 'prop-types';
import React from 'react';
import css from 'components/CompareAndSelect/styles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const InvalidNOutputPartContent = function(props) {
    const outputGroupsList = props.info.map((group, idx) => {
        return <li key={idx}><span>
            {group.outputNames.join(', ')}
        </span></li>;
    });

    return (<div>
        <p>
            {getText(contentKeys.PART_SELECTIONS_FOR_THE)}
        </p>
        <ul className={css.errorInfoList}>
            {outputGroupsList}
        </ul>
        <p>
            {getText(contentKeys.PLEASE_SELECT_A_COMBO)}
        </p>
        <p>
            {getText(contentKeys.THE_DEVICE_NAMES_OF)}
        </p>
    </div>);
};

InvalidNOutputPartContent.propTypes = {
    info: PropTypes.array.isRequired
};

export default InvalidNOutputPartContent;
