/* eslint max-len: 0 */
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

export default
`<div style="position:relative; height:364px; width:1110px;">
    <div class="loading" style="display:none;">
        <div class="content" style="">
            <p><i class="fa fa-spinner fa-spin fa-3x"></i></p>
            <p>${getText(contentKeys.LOADING)}</p>
        </div>
    </div>
    <div class="slidersAndCharts" style="z-index:100;">
        <div>
            <div class="no-gutter" style="position:relative;">
                <div class="detailSectionInputs ">
                    <div class="dtiMaxVoltsAmps">
                        <div class="inputVolts"><b></b></div><div class="inputVoltsLabel"></div>
                        <div class="outputVoltsAmps"><b>${getText(contentKeys.OUTPUT)}</b></div><div class="outputVoltsAmpsLabel"></div>
                    </div>
                    <div class="effSection lightLoadSection"></div>
                    <div class="effSection typicalLoadSection"></div>
                    <div class="effSection maxLoadSection"></div>
                    <div class="dtiLegend">
                        <div class="dtiSection">
                            <div><b>F<sub>sw</sub></b></div>
                            <div class="fswLabel"></div>
                        </div>
                        <div class="dtiSection">
                            <div><b>${getText(contentKeys.MODE)}</b></div>
                            <div class="modeLabel"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chartSection no-gutter">
                <div class="detailSection">
                    <div id="chart1#:data.uid#" class="chart efficiencyChartContainer" ></div>
                </div>
                <div class="detailSection">
                    <div id="chart2#:data.uid#" class="chart powerDissipationChartContainer" ></div>
                </div>
                <div class="detailSection">
                    <div id="chart3#:data.uid#" class="chart junctionTempChartContainer" ></div>
                </div>
            </div>
        </div>
    </div>
</div>`;
