import PropTypes from 'prop-types';
import React from 'react';
import {Row} from 'bootstrap-core.js';
import css from './styles.less';
import classNames from 'classnames';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const LoadingSpinner = (props) => {
    const message = props.message || getText(contentKeys.LOADINGJUST_A_MOMENT_PLEASE);

    const classes = classNames(css.loadSpinner, props.className);

    return (
        <Row className={classes}>
            <div><i className={`fa fa-spinner ${props.spin ? 'fa-spin' : ''}`} /></div>
            <div>{message}</div>
        </Row>
    );
};

LoadingSpinner.propTypes = {
    message: PropTypes.string,
    className: PropTypes.string,
    spin: PropTypes.bool
};

LoadingSpinner.defaultProps = {
    spin: true
};

export default LoadingSpinner;
