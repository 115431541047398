import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import resizeListener from 'element-resize-detector';
import debounce from 'lodash/debounce';

export class AutoResizeContainer extends React.Component {
    static propTypes = {
        onResize: PropTypes.func,
        child: PropTypes.func.isRequired,
        debounce: PropTypes.number.isRequired
    };
    static defaultProps = {
        onResize: () => {},
        debounce: 0
    };

    state = {
        width: 0,
        height: 0
    };

    handleOnResize = debounce((width, height) => {
        if (!this._unmounted) {
            this.setState({ width, height }, () => {
                this.props.onResize({ width, height });
            });
        }
    }, this.props.debounce);

    componentWillUnmount() {
        this._unmounted = true;
    }

    componentDidMount() {
        // listen for element size changes
        const element = findDOMNode(this);
        resizeListener({ strategy: 'scroll' }).listenTo(element, ev => {
            this.handleOnResize(element.clientWidth, element.clientHeight);
        });

        this.handleOnResize(element.clientWidth, element.clientHeight);
    }

    render() {
        return this.props.child({ ...this.state });
    }
}

export default Component => props => (
    <AutoResizeContainer
        child={({ width, height }) => (
            <Component {...props} width={width} height={height} />
        )}
    />
);
