import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import css from './styles.less';
import classNames from 'classnames';
import databind from 'redux/utils/databind';
import {actions as editorActions} from 'redux/modules/editor';
import {actions as deleteActions} from 'redux/modules/confirmDelete';
import BufferedInput, {filterAsNumber} from 'components/BufferedInput';
import ActionButton from 'components/ActionButton';
import {formatNumberWithUpTo2Decimals, formatNumberVariable} from 'logic/formats';
import SourceRailSelectContainer from 'components/Editor/SourceRailSelect';
import SupplySequenceSelect from 'components/Editor/SupplySequenceSelect';
import SwitchOptions from 'components/Editor/switchOptions';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const noFilter = (value) => value;

class OutputBlockEditPane extends React.Component {
    static propTypes = {
        changeOutputName: PropTypes.func.isRequired,
        changeOutputVoltsAmps: PropTypes.func.isRequired,
        changeSupplySequence: PropTypes.func.isRequired,
        confirmDelete: PropTypes.func.isRequired,
        efficiency: PropTypes.number.isRequired,
        inputs: PropTypes.array.isRequired,
        outputs: PropTypes.array.isRequired,
        selectedBlock: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        supplySequenceMax: PropTypes.number.isRequired
    };

    static databind({editor}) {
        return {
            efficiency: editor.efficiency,
            inputs: editor.inputs,
            outputs: editor.outputs,
            supplySequenceMax: editor.supplySequenceMax
        };
    }

    static actions = {
        setSelectedBlock: editorActions.setSelectedBlock,
        changeOutputName: editorActions.changeOutputName,
        changeSupplySequence: editorActions.changeSupplySequence,
        changeOutputVoltsAmps: editorActions.changeOutputVoltsAmps,
        confirmDelete: deleteActions.confirmDelete
    };

    constructor(props) {
        super(props);
        this.state = {
            expandOptions: false
        };
    }

    onChangeVolts = (value) => {
        this.onChangeVoltsAmps('volts', value);
    };

    onChangeAmps = (value) => {
        this.onChangeVoltsAmps('amps', value);
    };

    onChangeVoltsAmps = (fieldName, value) => {
        const { idx } = this.props.selectedBlock;

        this.props.changeOutputVoltsAmps({
            index: idx,
            fieldName,
            value: kendo.parseFloat(value) });
    };

    onChangeSupplySequence = (value) => {
        this.props.changeSupplySequence({ index: this.props.selectedBlock.idx, value: parseInt(value) });
    };

    onChangeOutputName = (value) => {
        this.props.changeOutputName({ index: this.props.selectedBlock.idx, value });
    };

    toggleMoreOptions = () => {
        this.setState({ expandOptions: !this.state.expandOptions });
    };

    moreOptions = () => {
        const expanded = this.state.expandOptions;
        const block = this.props.selectedBlock;
        const icon = expanded ? 'fa-angle-up' : 'fa-angle-down';
        const label = expanded ? getText(contentKeys.COLLAPSE) : getText(contentKeys.EXPAND);

        return (
            <div className={css.tableLayout}>
                <div className={css.row}>
                    <div className={css.fieldName}>{getText(contentKeys.MORE_OPTIONS)}</div>
                    <div className={css.expandOptions} onClick={this.toggleMoreOptions}>
                        <span className={classNames('fa', icon, css.glyph)} />
                        <a>{label}</a>
                    </div>
                </div>
                {expanded && <div className={css.row}>
                    <SwitchOptions index={block.idx} />
                </div>}
            </div>
        );
    };

    formFields = () => {
        const block = this.props.selectedBlock;
        const supplySeqError = (block.invalid) ? "supplySequenceError" : "";
        const negativeValueClass = (block.availableOutput < 0) ? css.negValue : "";
        const efficiencyPercent = (100*this.props.efficiency).toFixed(0) + '%';

        return (
            <div className={classNames(css.editPaneInputs, css.tableLayout)}>
                <div className={css.row}>
                    <div className={css.fieldName}>{getText(contentKeys.SOURCE_RAIL)}</div>
                    <div>
                        <SourceRailSelectContainer
                            index={block.idx}
                            className={css.editPaneDropdown}
                            selected={block.sourceRail} />
                    </div>
                </div>
                <div className={css.row}>
                    <div className={css.fieldName}>{getText(contentKeys.OUTPUT_NAME)}</div>
                    <div>
                        <BufferedInput
                            dataTest="output_name"
                            value={block.name}
                            filter={noFilter}
                            onBufferedChange={this.onChangeOutputName} />
                    </div>
                </div>
                <div className={css.row}>
                    <div className={css.fieldName}>{getText(contentKeys.VOLTS)}</div>
                    <div>
                        <BufferedInput
                            dataTest="volts"
                            value={block.volts}
                            filter={filterAsNumber}
                            format={formatNumberVariable}
                            onBufferedChange={this.onChangeVolts} />
                    </div>
                </div>
                <div className={css.row}>
                    <div className={css.fieldName}>{getText(contentKeys.MAX_AMPS)}</div>
                    <div>
                        <BufferedInput
                            dataTest="amps"
                            value={block.amps}
                            filter={filterAsNumber}
                            format={formatNumberVariable}
                            onBufferedChange={this.onChangeAmps} />
                    </div>
                </div>
                <div className={classNames(supplySeqError, css.row)}>
                    <div className={css.fieldName}>{getText(contentKeys.SUPPLY_SEQUENCE)}</div>
                    <div>
                        <SupplySequenceSelect
                            className={css.editPaneDropdown}
                            index={block.idx}
                            max={this.props.supplySequenceMax}
                            selected={block.supplySeq}
                            onChange={this.onChangeSupplySequence} />
                    </div>
                </div>
                <div className={css.row}>
                    <div className={css.fieldName}>{getText(contentKeys.AVAIL_OUTPUT_W)}</div>
                    <div>
                        <div className={classNames(css.staticField, negativeValueClass)}>
                            {formatNumberWithUpTo2Decimals(block.availableOutput)}
                        </div>
                    </div>
                </div>
                <div className={css.row}>
                    <div className={css.fieldName}>
                        {getText(contentKeys.EFF_INPUT_POWER_NEEDED, efficiencyPercent)}
                    </div>
                    <div>
                        <div className={css.staticField}>
                            {formatNumberWithUpTo2Decimals(block.powerNeeded)}
                        </div>
                    </div>
                </div>
                {this.moreOptions()}
            </div>
        );
    };

    instructions = () => {
        const { name } = this.props.selectedBlock;
        const title = `${getText(contentKeys.EDIT_REGULATED_OUTPUT)} ${name}`;

        return (
            <div className={css.editPaneInstructions}>
                <h5 title={name}>{title}</h5>
                <span>{getText(contentKeys.MAKE_DESIRED_EDITS_AND)}</span>
            </div>
        );
    };

    deleteBlock = () => {
        const { idx, id } = this.props.selectedBlock;

        this.props.confirmDelete({ output: true, index: idx, id });
    };

    buttons = () => {
        return (
            <div className={css.editPaneButtons}>
                <Button className="basic" onClick={this.deleteBlock}>
                    {getText(contentKeys.DELETE_SYSTEM_OUTPUT)}
                </Button>
                <ActionButton
                    label={getText(contentKeys.DONE)}
                    className={css.editPaneDoneBtn}
                    onClick={this.props.onClose}
                />
            </div>
        );
    };

    errors = () => {
        const isInvalid = this.props.selectedBlock.invalid;
        const negativeOutput = this.props.selectedBlock.availableOutput < 0;

        return (
            <div className={css.editPaneErrors}>
                {isInvalid &&
                    <div className={css.editPaneError}>
                        <span className={classNames(css.icon, "icon", "icon-exclaim-solid")}></span>
                        {getText(contentKeys.SUPPLY_SEQUENCE_CAN_NOT)}
                    </div>}
                {negativeOutput &&
                    <div className={css.editPaneError}>
                        <span className={classNames(css.icon, "icon", "icon-exclaim-solid")}></span>
                        {getText(contentKeys.AVAILABLE_OUTPUT_POWER_IS)}
                    </div>}
            </div>
        );
    };

    render() {
        return (
            <div className={classNames(css.diagramEditPane, css.diagramViewRight)}>
                <div className={css.closeEditPane}>
                    <i className="fa fa-close" onClick={this.props.onClose} />
                </div>
                {this.instructions()}
                {this.errors()}
                {this.formFields()}
                {this.buttons()}
            </div>
        );
    }
}

export default databind(OutputBlockEditPane);
