/* global kendo */
import loadWidgetTemplate from './loadWidget.template';
import {lookupFromReferenceCurve} from '../../../logic/resampling';
import {formatPercent, formatNumberWithAtLeast2Decimals} from '../../../logic/formats';
import {CHAR_MDASH, CHAR_NBSP} from 'logic/constants';

export function createLoadWidget(jqElement, model, title) {
    jqElement.append(kendo.template(loadWidgetTemplate)({title}));

    // todo we need to unbind but where? in the containing react component's
    // todo willUnmount but is there a jquery event we can use?
    kendo.bind(jqElement, model);

    const max = model.get("maxLoad");
    const min = 0.0;
    const smallStep = (max < 0.1) ? (max / 100) : 0.01;
    const largeStep = (max < 0.1) ? (max / 10) : 0.1;

    jqElement.find('.sliderContainer input').kendoSlider({
        showButtons: false,
        dragHandleTitle: "",
        tooltip: { enabled: false },
        min,
        max,
        smallStep,
        largeStep
    }).data("kendoSlider");
}

export function createLoadViewModel(load, maxLoad, efficiencyRefCurve, action) {
    const NULL_EFF_LABEL = CHAR_MDASH+CHAR_NBSP+CHAR_NBSP+CHAR_NBSP+CHAR_NBSP;
    const model = kendo.observable({
        efficiencyRefCurve,
        load,
        inputValue: "",
        sliderValue: load,
        efficiency: "",
        maxLoad: maxLoad,
        setIfInRangeElseReset: function(str) {
            const value = kendo.parseFloat(str);
            if ((value < 0) || (value > this.maxLoad)) {
                this.refresh();
            }
            else {
                this.set("load", value);
            }
        },
        update: function(load) {},
        refresh: function() {
            const load = this.get("load");
            this.set("inputValue", formatNumberWithAtLeast2Decimals(load, "###.00##"));
            this.set("sliderValue", load);
            if (this.efficiencyRefCurve === null) {
                this.set("efficiency", NULL_EFF_LABEL);
            }
            else {
                const eff = lookupFromReferenceCurve(this.efficiencyRefCurve, load);
                const effLabel = (eff === null) ? NULL_EFF_LABEL : formatPercent(eff);
                this.set("efficiency", effLabel);
            }
        },
        onInputBlur: function(e) {
            this.setIfInRangeElseReset(e.currentTarget.value);
        },
        onInputKeyUp: function(e) {
            if (e.keyCode === 13) {
                this.setIfInRangeElseReset(e.currentTarget.value);
            }
        },
        onSliderChange: function(e) {
            this.set("load", e.value);
        }
    });

    // bind some events so ui bound fields update on set("load")
    model.bind("change", function(e) {
        if (e.field === "load") {
            model.refresh();
            action(this.get("load"));
        }
    });

    model.refresh();

    return model;
}
