import PropTypes from 'prop-types';
import React from 'react';
import databind from 'redux/utils/databind';
import classNames from 'classnames';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Shepherd from 'tether-shepherd';
import 'tether-shepherd/dist/css/shepherd-theme-arrows.css';
import DocumentTitle from 'components/DocumentTitle';
import {
    closeQuerystringParamsCycle,
    openQuerystringParamsCycle,
    setConfigTriggerFindSolutions,
    setSortColumn,
    setSortDirection,
    actions as startOptionActions
} from 'redux/modules/startOptions';
import {actions as projectActions} from 'redux/modules/projects';
import ActionButton from 'components/ActionButton';
import css from './styles.less';
import SelectStartOptions from 'components/StartSelectTemplates/SelectStartOptions';
import Error from 'components/Error';
import IE_VERSION from 'util/ieVersion';
import LoadCSVModal from 'components/StartSelectTemplates/LoadCSVModal';
import UserPreferences from 'util/userPrefs';
import offlineVersionUrl from "util/offlineVersionUrl";
import {WithTablet, WithPhone} from 'styles/variables/breakpoints';
import flowRight from 'lodash/flowRight';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';
import {
    hasValidQuerystringParams,
    getConfigTriggerFindSolutions,
    getSortColumn,
    getSortDirection
} from 'logic/querystringParser';

class StartView extends React.Component {
    static propTypes = {
        canContinue: PropTypes.bool.isRequired,
        onContinue: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
        loadingAlteraXLS: PropTypes.bool.isRequired,
        onCloseModal: PropTypes.func.isRequired,
        phoneMatch: PropTypes.bool,
        tabletMatch: PropTypes.bool
    };

    static actions = {
        onContinue: startOptionActions.onContinue,
        onCloseModal: startOptionActions.hideLoadCSVModal
    };

    static databind ({startOptions}) {
        const {canContinue, showLoadCSVModal, loadingAlteraXLS} = startOptions;
        return {canContinue, showModal: showLoadCSVModal, loadingAlteraXLS};
    }

    componentWillMount() {
        store.dispatch(projectActions.refreshProjects());
    }

    componentDidMount() {
        if (!UserPreferences.seenOfflineFeature) {
            window.scrollTo(0, 0);

            const tour = new Shepherd.Tour({
                defaults: {
                    classes: 'shepherd-theme-arrows',
                    tetherOptions: {
                        targetOffset: '15px 0',
                        optimizations: {
                            moveElement: false
                        },
                        constraints: [{
                            to: 'scrollParent',
                            pin: false
                        }]
                    },
                    showCancelLink: true,
                    when: {
                        hide: () => {
                            if (!this._destroyTour) {
                                UserPreferences.seenOfflineFeature = true;
                            }
                        }
                    }
                }
            });

            const phoneOptions = this.props.phoneMatch ? {
                targetOffset: '30px 0',
                constraints: [{
                    to: 'scrollParent',
                    attachment: 'together',
                    pin: true
                }]
            } : {};

            if (!UserPreferences.seenOfflineFeature && 'serviceWorker' in navigator) {
                tour.addStep('offline-status', {
                    text: getText(contentKeys.YOU_CAN_SWITCH_TO),
                    classes: 'shepherd-theme-arrows offline-status-step',
                    attachTo: `.new-feature-offline ${this.props.phoneMatch
                        ? 'bottom'
                        : this.props.tabletMatch
                            ? 'left'
                            : 'right'}`,
                    tetherOptions: {...tour.options.defaults.tetherOptions, ...phoneOptions},
                    buttons: [
                        { classes: 'shepherd-link',
                            text: getText(contentKeys.LEARN_MORE),
                            action: () => {
                                window.open(offlineVersionUrl(), '_blank');
                            }},
                        { text: 'OK', action: () => tour.next() }
                    ]
                });
            }
            else if (!UserPreferences.seenOfflineFeature) {
                tour.addStep('offline-status', {
                    text: getText(contentKeys.PLEASE_USE_CHROME_OR),
                    attachTo: '.new-feature-offline-link bottom',
                    tetherOptions: {...tour.options.defaults.tetherOptions, ...phoneOptions},
                    buttons: [
                        { classes: 'shepherd-link',
                            text: getText(contentKeys.LEARN_MORE),
                            action: () => {
                                window.open(offlineVersionUrl(), '_blank');
                            }},
                        { text: 'OK', action: () => tour.next() }
                    ]
                });
            }

            tour.start();

            this._tour = tour;
        }
    }

    onContinue = () => {
        this.props.onContinue();
    };

    onCloseModal = () => {
        this.props.onCloseModal();
    };

    render() {
        // Forwards user to EditorView if valid querystring params exist.
        if (hasValidQuerystringParams()) {
            openQuerystringParamsCycle();
            setConfigTriggerFindSolutions(getConfigTriggerFindSolutions());
            setSortColumn(getSortColumn());
            setSortDirection(getSortDirection());
            this.setState({ hasValidQuerystringParams: false });
            this.onContinue();
        }

        // Tells the application there will be no parsing of querystring params.
        else {
            closeQuerystringParamsCycle();
        }

        const showBrowserWarning = IE_VERSION && IE_VERSION < 11;
        const canContinue = this.props.canContinue;

        return (
            <DocumentTitle title="Starting Screen">
                <div>
                    <div className={css.startView}>
                        <Row className={"content-padding"}>
                            {showBrowserWarning &&
                                <Error className={css.browserWarning}>
                                    This tool may not work properly in this version of your web browser.
                                    Consider using a newer version.
                                </Error>
                            }
                            <Col xs={12} className={css.noPadding}>
                                <SelectStartOptions />
                            </Col>
                        </Row>
                        <Row className={"content-padding"}>
                            <div className={classNames(css.noPadding, css.actionButtonContainer)}>
                                <ActionButton
                                    id="StartSelect_ContinueBtn"
                                    label={getText(contentKeys.CONTINUE)}
                                    className={`${css.continueBtn}${!canContinue && " disabled"} large mainButton`}
                                    testHook="continue_from_start"
                                    onClick={this.onContinue}
                                    disabled={!canContinue}
                                />
                            </div>
                        </Row>
                    </div>
                    <LoadCSVModal open={this.props.showModal}
                        onClose={this.onCloseModal}
                        showAlternativeMessage={this.props.loadingAlteraXLS} />
                </div>
            </DocumentTitle>
        );
    }
}

export default flowRight([WithTablet, WithPhone, databind])(StartView);
