import PropTypes from 'prop-types';
import React from 'react';
import ModalWindow from 'components/ModalWindow';
import ModalContent from 'components/ModalContent';
import css from './styles.less';
import imageUrl from 'util/imageUrl';

function dotForDot() {
    return ((window["devicePixelRatio"]) && (window.devicePixelRatio === 1));
}

class LoadCSVPleaseModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        showAlternativeMessage: PropTypes.bool.isRequired
    };

    onClose = () => {
        this.props.onClose();
    };

    render() {
        const powerEstimatorImg = dotForDot()
            ? imageUrl("1_powerestimator@1x.png") : imageUrl("1_powerestimator@2x.png");
        const reportTabImg = dotForDot()
            ? imageUrl("2_report_tab@1x.png") : imageUrl("2_report_tab@2x.png");
        const saveAsImg = dotForDot()
            ? imageUrl("3_save_as@1x.png") : imageUrl("3_save_as@2x.png");

        const title = "How to Upload an Altera Design File";

        const message = (this.props.showAlternativeMessage) ? (
            <p>
                If the browser is very slow when uploading .xls or .xlsx files, please save your Power Estimator
                file as a CSV file by following these instructions:
            </p>) : (
            <p>The browser we’ve detected can be very slow when uploading .xls or .xlsx files.
                Please try using <strong>Chrome</strong> or <strong>Microsoft Edge</strong> instead or you can
                    save your Power Estimator file as a CSV file by following these instructions:
            </p>
        );

        const content = (<ModalContent onSubmit={this.onClose}>
            <div className={css.loadCSVModalContent}>
                {message}
                <div className={css.container}>
                    <div className={css.boxone}>
                        <ol className={css.lhs}>
                            <li>
                                <div>
                                    Open the Power Estimator file in Excel
                                    <div>
                                        <img src={powerEstimatorImg} />
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </div>
                    <div className={css.boxtwo}>
                        <ol className={css.rhs}>
                            <li>
                                <div>
                                    Select the <em>Report</em> tab to make it the active tab
                                    <div>
                                        <img src={reportTabImg} />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div>
                                    On the File menu, select <em>Save As</em> and choose to save as
                                    a CSV (Comma Separated Value) file.
                                    Choose <em>Save Active Sheet</em> if prompted.
                                    <div>
                                        <img src={saveAsImg} />
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </ModalContent>);

        return (<ModalWindow
            className="loadCSVModal"
            actions={["Close"]}
            close={this.onClose}
            open={this.props.open}
            width={1000}
            height={500}
            center={true}
            title={title}>
            {content}
        </ModalWindow>);
    };
}

export default LoadCSVPleaseModal;
