/* global $ */
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

export function rectLegend(e) {
    const color = e.options.markers.background;
    const labelColor = e.options.labels.color;
    const label = new kendo.drawing.Text(e.series.name, [0, 0], {
        fill: {
            color: labelColor
        }
    });
    const textWidth = label.rawBBox().size.width;
    const rect = new kendo.geometry.Rect([0, 0], [textWidth + 20, 20]);
    const layout = new kendo.drawing.Layout(rect, {
        spacing: 5,
        alignItems: "center"
    });

    const marker = new kendo.drawing.Rect(new kendo.geometry.Rect([0, 0], [10, 10]), {
        fill: {
            color
        },
        stroke: {
            color
        }
    });

    layout.append(marker, label);
    layout.reflow();

    return layout;
};

export const createChart = (jqElement, data, chartInfo) => {
    const chartElement = $('<div/>').kendoChart({
        seriesColors: ["#7E57C2", "#72AC4D", "#EB7D3C", "#5E9CD3", "#9E5E45", "#EF5350", "#FDC02E", "#009688",
            "#4774C1", "#8C8C8C"],
        chartArea: {
            height: 300,
            width: 270
        },
        title: {
            text: chartInfo.title,
            font: "600 16px Open Sans, sans-serif",
            color: "#333333"
        },
        legend: {
            position: "top",
            labels: {
                font: "11px 'Open Sans'",
                color: "#333333"
            },
            visible: true,
            item: {
                visual: rectLegend
            }
        },
        seriesDefaults: {
            type: "line",
            style: "smooth",
            markers: {
                type: "circle",
                opacity: 1,
                size: 4
            }
        },
        series: data.series,
        valueAxis: {
            labels: {
                format: chartInfo.yAxisFormat,
                font: "7pt Open Sans, sans-serif",
            },
            title: {
                text: chartInfo.yAxisText,
                font: "bold 11px Open Sans, sans-serif",
                color: "#333333"
            },
            line: {
                visible: true
            },
            narrowRange: true,
            axisCrossingValue: -1,
            min: chartInfo["yAxisMin"] || undefined,
            max: chartInfo["yAxisMax"] || undefined
        },
        categoryAxis: {
            categories: data.x,
            justified: true,
            majorGridLines: {
                visible: true
            },
            title: {
                text: getText(contentKeys.LOAD_CURRENT)+" (A)",
                font: "bold 11px Open Sans, sans-serif",
                color: "#333333",
            },
            labels: {
                font: "7pt Open Sans, sans-serif",
                rotation: chartInfo.xAxisRotation,
                template: chartInfo.xAxisTemplate
            }
        },
        tooltip: {
            visible: true,
            format: chartInfo.tooltipFormat
        }
    });

    return chartElement;
};
