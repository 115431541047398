/* global $ */

import PropTypes from 'prop-types';

import React from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import moment from 'moment';
import css from './styles.less';
import BlockDiagram from '../Editor/BlockDiagram';
import BlockDiagramDataSource from "../../redux/blockDiagramDataSource";
import makeSignInUrl from 'util/makeSignInUrl';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const QUEUEING= 'queueing';
const WAITING = 'waiting';
const GENERATING = 'generating';
const DONE = 'done';
const NEW_PROJECT_STEP = 'new';
const LIVE_PROJECT_STEP = 'live';

export default class JobStatus extends React.Component {
    constructor() {
        super();
        this._pollIntervalHandle = null;
        this.diagramDataSource = null;
    }

    static propTypes = {
        retryRefDesign: PropTypes.func.isRequired,
        designId: PropTypes.string,
        generateStep: PropTypes.string.isRequired,
        requested: PropTypes.bool.isRequired,
        status: PropTypes.string.isRequired,
        queuePosition: PropTypes.number,
        toggleProjectHeader: PropTypes.func.isRequired,
        toggleHeaderLinks: PropTypes.func.isRequired,
        fetchJobStatus: PropTypes.func.isRequired,
        fetchAuthStatus: PropTypes.func.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        checkedAuthStatus: PropTypes.bool.isRequired,
        generateReferenceDesign: PropTypes.func.isRequired,
        waitTime: PropTypes.number,
        error: PropTypes.bool
    };

    pollStatus = () => {
        // skip polling if project ID hasn't been received yet
        if (this.props.generateStep === NEW_PROJECT_STEP && !this.props.designId) return;
        if (this.props.requested) return; // skip polling if we already have an open request
        if (this.props.error) return; // skip polling on errors
        this.props.fetchJobStatus(this.props.designId);
    };

    componentWillMount() {
        this.diagramDataSource = new BlockDiagramDataSource(window.store, true);
    }

    componentDidMount() {
        const props = this.props;

        /*
        * The logic below is not ideal but has to handle 3 different incoming routes
        *
        * #/reference/new  - user signed in for the first time and we are loading based on server redirect.
        *   - generate = 'new'
        *   - designId = undefined
        *
        * #/reference/new/abcd1234 - user was already scigned in and we are about to push a job into the queue
        *   - generateStep = 'new'
        *   - designId = a valid id
        *
        * #/reference/live/abcd1234 - user's job is complete and files are ready for download
        *   - generateStep - 'live'
        *   - designId = a valid id
        *
        * #/reference/abcd1234 - user has clicked a link from an email.
        *  - generateStep = abcd1234 - this is actually the project Id and we need to compensate for that
        *  - designId = abcd1234
        *
        * */

        props.toggleProjectHeader(true);
        props.toggleHeaderLinks(false);
        if (props.generateStep === NEW_PROJECT_STEP && !this.props.designId) {
            props.generateReferenceDesign($('#blockDiagram').data('kendoDiagram'));
        }
        else if (props.generateStep === NEW_PROJECT_STEP && !this._pollIntervalHandle) {
            this._pollIntervalHandle = setInterval(this.pollStatus, 2000);
        }
    }

    componentWillUpdate(nextProps) {
        // console.log("nextProps willUpdate", nextProps, this._pollIntervalHandle)
        if ((nextProps.generateStep !== LIVE_PROJECT_STEP) &&
            (nextProps.generateStep !== NEW_PROJECT_STEP) &&
            (nextProps.checkedAuthStatus) && (!nextProps.isSignedIn)) {
            const currentUrl = window.location.toString().split('#');
            const resource = `${currentUrl[0]}#/reference/${nextProps.designId}`;
            window.location = makeSignInUrl(window.encodeURIComponent(resource));
        }

        if (!this._pollIntervalHandle) {
            this._pollIntervalHandle = setInterval(this.pollStatus, 2000);
        }
    }

    componentWillUnmount() {
        clearInterval(this._pollIntervalHandle);
        this.props.toggleHeaderLinks(true);
        this.diagramDataSource.unsubscribe();
        this.diagramDataSource = null;
    }

    regenerateDesign = () => {
        this.props.retryRefDesign(this.props.designId);
    };

    render() {
        const {queuePosition, status, waitTime, error, designId} = this.props;

        const queueing = status === QUEUEING;
        const waiting = status === WAITING;
        const generating = status === GENERATING;
        const done = status === DONE;

        let loadingMsg;

        if (error) {
            loadingMsg = getText(contentKeys.YOUR_REFERENCE_DESIGN_ENCOUNTERED);
        }
        else if (queueing) {
            loadingMsg = getText(contentKeys.QUEUEING_UP_YOUR_REFERENCE);
        }
        else if (waiting) {
            const waitTimeDisplay = moment.duration(waitTime, "seconds").humanize();
            loadingMsg = getText(contentKeys.YOU_ARE_CURRENTLY_BEHIND, queuePosition, waitTimeDisplay);
        }
        else if (generating) {
            loadingMsg = getText(contentKeys.YOUR_DESIGN_IS_NOW);
        }
        else if (done) {
            loadingMsg = getText(contentKeys.YOUR_DESIGN_IS_DONE);
        }

        return (
            <div className={css.jobStatusContainer}>
                <div className={css.loadingMessageContainer}>
                    <LoadingSpinner message={loadingMsg} spin={!done && !error} />
                    {designId && error && <a onClick={this.regenerateDesign}>{getText(contentKeys.RETRY)}</a>}
                </div>
                <div className="layout justify-center">
                    <ul>
                        <li className={(waiting || queueing) ? css.active : ''}>{getText(contentKeys.WAITING)}</li>
                        <li className={generating ? css.active : ''}>{getText(contentKeys.GENERATING)}</li>
                        <li className={done ? css.active : ''}>{getText(contentKeys.DONE_2)}</li>
                    </ul>
                </div>
                <BlockDiagram id="blockDiagram"
                    className={css.hiddenBlockDiagram}
                    dataSource={this.diagramDataSource}
                    forReferenceDesign={true} />
            </div>
        );
    }
}
