export function computePowerDissipationReferenceCurve(efficiencyRefCurve, vout) {
    if (efficiencyRefCurve === null) { return null; }
    return {
        x: efficiencyRefCurve.x,
        y: efficiencyRefCurve.x.map((iout, idx) => {
            const eff = efficiencyRefCurve.y[idx];
            if (eff == null) {
                return null;
            }
            return (eff !== 0) ? ((vout*iout)/eff)-(vout*iout) : 0;
        })
    };
}
