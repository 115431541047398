/* global $ */
import makeApiUrl from './makeApiUrl';
import {getJson} from './ajax';

const loadPartsDb = function() {
    return new Promise((resolve, reject) => {
        getJson(makeApiUrl("powerparts.json"))
            .then(resolve, (error) => reject(error.statusText));
    });
};

export default loadPartsDb;
