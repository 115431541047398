import PropTypes from 'prop-types';
import React from 'react';
import DocumentTitle from 'components/DocumentTitle';
import ProjectNameFormContainer from 'components/ReferenceDesign/ProjectNameFormContainer';
import {actions as projectActions} from 'redux/modules/projects';
import {actions as referenceDesignActions} from 'redux/modules/referenceDesign';
import databind from 'redux/utils/databind';
import getHistory from 'util/history';
import ProjectStatus from "logic/status";
import makeSignInUrl from "util/makeSignInUrl";
import makeRegisterUrl, {REGISTER_PAGE} from "util/makeRegisterUrl";
import {forcePersist} from "redux/persist";
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const returnUrl = (newUrlSegment) => {
    const baseAndQuery = location.href.split('?');
    const base = baseAndQuery[0];
    const queryParams = '?' + baseAndQuery[1] || '';

    return window.encodeURIComponent(`${base}/${newUrlSegment}${queryParams}`);
};

class ProjectNameView extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        setCurrentProjectStatus: PropTypes.func.isRequired,
        setCurrentProjectName: PropTypes.func.isRequired,
        startGeneratingReferenceDesign: PropTypes.func.isRequired
    };

    static actions = {
        setCurrentProjectStatus: projectActions.setCurrentProjectStatus,
        setCurrentProjectName: projectActions.setCurrentProjectName,
        startGeneratingReferenceDesign: referenceDesignActions.startGeneratingReferenceDesign
    };

    static databind = () => {
        return {};
    };

    onContinue = async (name) => {
        // we skip this page entirely for existing projects so we know we always have to save a new project here
        this.props.setCurrentProjectName(name);
        getHistory().push('/reference/new');
    };

    onClickAuthButton = (page, name) => {
        this.props.setCurrentProjectName(name);
        this.props.setCurrentProjectStatus(ProjectStatus.GENERATING_REFERENCE_DESIGN);

        forcePersist(() => {
            const resourceUrl = returnUrl('new');
            window.location = (page === REGISTER_PAGE)
                ? makeRegisterUrl(resourceUrl) : makeSignInUrl(resourceUrl);
        });
    };

    render() {
        const title = getText(contentKeys.WE_ARE_GENERATING_YOUR);
        return (
            <DocumentTitle title="Reference Design">
                {this.props.children ||
                    <ProjectNameFormContainer
                        title={title}
                        onClickAuthButton={this.onClickAuthButton}
                        onContinue={this.onContinue}
                    />}
            </DocumentTitle>
        );
    }
}

export default databind(ProjectNameView);
