import {makeAction, makePromise, handleLoadingPromise, createReducer} from '../utils/redux-helpers';
import api from 'api/auth';

function prefix (type) { return `editor/${type}`; }

const actionTypes = {
    SET_IS_SIGNED_IN: prefix('SET_IS_SIGNED_IN'),
    CLEAR_CHECKED: prefix('CLEAR_CHECKED'),
    SHOW_SIGN_IN_REDIRECT_WARNING: prefix('SHOW_SIGNED_IN_WARNING_MODAL')
};

export const actions = {
    checkIsSignedInNow: () => (dispatch, getState) => {
        const {offline} = getState();

        if (offline.isOffline) {
            return dispatch(makePromise(actionTypes.SET_IS_SIGNED_IN, Promise.resolve({ isSignedIn: true })));
        }

        dispatch(actions.clearChecked());
        return dispatch(makePromise(actionTypes.SET_IS_SIGNED_IN, api.isUserSignedIn()));
    },
    showSignInRedirectWarning: (show = true, returnUrl = '') =>
        makeAction(actionTypes.SHOW_SIGN_IN_REDIRECT_WARNING, { show, returnUrl }),
    clearChecked: () => makeAction(actionTypes.CLEAR_CHECKED)
};

const ACTION_HANDLERS = {
    ...handleLoadingPromise(actionTypes.SET_IS_SIGNED_IN, (state, {loading, data, error}) => {
        if (loading) return { ...state, loading, checked: false };
        const isSignedIn = (data) ? data.isSignedIn : false;
        return { ...state, isSignedIn, loading, error, checked: true };
    }),
    [actionTypes.SHOW_SIGN_IN_REDIRECT_WARNING]: (state, {payload}) => {
        const {show, returnUrl} = payload;
        return { ...state, showSignInRedirectWarning: show, returnUrl };
    },
    [actionTypes.CLEAR_CHECKED]: (state) => {
        return { ...state, checked: false };
    }
};

const initialState = {
    isSignedIn: false,
    checked: false,
    showSignInRedirectWarning: false,
    returnUrl: ''
};

export const selectors = {
    isSignedIn: module => module.isSignedIn
};

export default createReducer(initialState, ACTION_HANDLERS);
