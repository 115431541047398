
export const TopologyType = {
    BUCK: "Buck",
    BOOST: "Boost",
    BUCK_BOOST: "Buck-Boost"
};

export function suggestTopologySteppingType(p) {
    if ((p.vinMin >= p.outputs[0].voutMin) && (p.vinMax >= p.outputs[0].voutMax)) return TopologyType.BUCK;
    if ((p.outputs[0].voutMin > p.vinMin) && (p.outputs[0].voutMax > p.vinMin) &&
        (p.outputs[0].voutMin > p.vinMax) && (p.outputs[0].voutMax > p.vinMin)) return TopologyType.BOOST;
    return TopologyType.BUCK_BOOST;
}

export const NUM_REGULATED_OUTPUTS = 1;
export const RECOMMEND_FET_LIMIT = 30; // (A) amps

export const Topology = {
    NULL: "-",
    LINEAR_REGULATOR: "Linear Regulator",
    BUCK_DIGITAL_FET: "Buck (Digital Integrated FET)",
    BUCK_DIGITAL_POWER_MODULE: "Buck (Digital Power Module)",
    BUCK_DIGITAL_CONTROLLER: "Buck (Digital Controller)",
    BUCK_FET: "Buck (Integrated FET)",
    BUCK_POWER_MODULE: "Buck (Power Module)",
    BUCK_CONTROLLER: "Buck (Controller)",
    BUCK_MULTIPHASE_CONTROLLER: "Buck (Multiphase Controller)",
    BOOST_FET: "Boost (Integrated FET)",
    BUCK_BOOST_FET: "Buck-Boost (Integrated FET)",
    BUCK_BOOST_CONTROLLER: "Buck-Boost (Controller)"
};

export const TopologySet = {
    EMPTY: "",
    NULL: Topology.NULL,
    LINEAR_REGULATOR: [Topology.LINEAR_REGULATOR],
    BUCK_DFET_DPM: [Topology.BUCK_DIGITAL_FET, Topology.BUCK_DIGITAL_POWER_MODULE],
    BUCK_LIN_FET_PM: [Topology.LINEAR_REGULATOR, Topology.BUCK_FET, Topology.BUCK_POWER_MODULE],
    BUCK_LIN_FET: [Topology.LINEAR_REGULATOR, Topology.BUCK_FET],
    BUCK_FET_PM: [Topology.BUCK_FET, Topology.BUCK_POWER_MODULE],
    BUCK_FET: [Topology.BUCK_FET],
    BUCK_HI_ALL: [Topology.BUCK_FET,
        Topology.BUCK_DIGITAL_FET,
        Topology.BUCK_CONTROLLER,
        Topology.BUCK_DIGITAL_CONTROLLER,
        Topology.BUCK_POWER_MODULE,
        Topology.BUCK_DIGITAL_POWER_MODULE],
    BUCK_HIGHEST: [Topology.BUCK_MULTIPHASE_CONTROLLER, Topology.BUCK_DIGITAL_CONTROLLER, Topology.BUCK_POWER_MODULE,
        Topology.BUCK_DIGITAL_POWER_MODULE],
    BOOST_FET: [Topology.BOOST_FET],
    BUCK_BOOST: [Topology.BUCK_BOOST_FET, Topology.BUCK_BOOST_CONTROLLER],
    BUCK_BOOST_CONTROLLER: [Topology.BUCK_BOOST_CONTROLLER]
};

function powerDrop(p) {
    return (p.vinMax-p.outputs[0].voutMin)*p.outputs[0].ioutMax;
}

const Topologies = [
    { test: (p) => (p.ldo), result: TopologySet.LINEAR_REGULATOR },

    { test: (p) => (p.outputs[0].ioutMax === 0), result: TopologySet.EMPTY },

    { test: (p) => ((p.topologyType === TopologyType.BUCK) &&
                    (p.outputs[0].ioutMax <= 3) &&
                    (p.outputs[0].ioutMax >= 0.1) &&
                    (powerDrop(p) < 1)),
    result: TopologySet.BUCK_LIN_FET_PM },

    { test: (p) => ((p.topologyType===TopologyType.BUCK) &&
                    (p.outputs[0].ioutMax <= 3) &&
                    (p.outputs[0].ioutMax > 0) &&
                    (powerDrop(p) < 1)),
    result: TopologySet.BUCK_LIN_FET},

    { test: (p) => ((p.topologyType === TopologyType.BUCK) &&
                    (p.outputs[0].ioutMax <= 3) &&
                    (p.outputs[0].ioutMax >= 0.1) &&
                    (powerDrop(p) >= 1)),
    result: TopologySet.BUCK_FET_PM},

    { test: (p) => ((p.topologyType === TopologyType.BUCK) &&
                    (p.outputs[0].ioutMax <= 3) &&
                    (p.outputs[0].ioutMax > 0) &&
                    (powerDrop(p) >= 1)),
    result: TopologySet.BUCK_FET},

    { test: (p) => ((p.topologyType === TopologyType.BUCK) &&
                    (p.outputs[0].ioutMax > 3) &&
                    (p.outputs[0].ioutMax <= 30)),
    result: TopologySet.BUCK_HI_ALL},

    { test: (p) => ((p.topologyType === TopologyType.BUCK) &&
                    (p.outputs[0].ioutMax > 30)),
    result: TopologySet.BUCK_HIGHEST},

    { test: (p) => ((p.topologyType === TopologyType.BOOST) &&
                    (p.outputs[0].ioutMax <= 2.5)),
    result: TopologySet.BOOST_FET},

    { test: (p) => ((p.topologyType === TopologyType.BUCK_BOOST) &&
                    (p.outputs[0].ioutMax <= 2.5)),
    result: TopologySet.BUCK_BOOST},

    { test: (p) => (p.topologyType === TopologyType.BUCK_BOOST), result: TopologySet.BUCK_BOOST_CONTROLLER }
];

export function suggestTopologies(params) {
    for (let i = 0; i < Topologies.length; i++) {
        const t = Topologies[i];

        if (t.test(params)) {
            return t.result;
        }
    }
    return TopologySet.NULL;
};
