import PropTypes from 'prop-types';
import React from 'react';
import databind from 'redux/utils/databind';
import {actions as editorActions} from 'redux/modules/editor';
import {formatPercent} from 'logic/formats';
import BufferedInput, {filterAsPercentage} from 'components/BufferedInput';

class MinEfficiency extends React.Component {
    static propTypes = {
        changeMinEfficiency: PropTypes.func.isRequired,
        efficiency: PropTypes.number.isRequired
    };

    static actions = {
        changeMinEfficiency: editorActions.changeMinEfficiency,
    };

    static databind({editor}) {
        return {
            efficiency: editor.efficiency
        };
    }

    onChange = (str) => {
        if (str) {
            this.props.changeMinEfficiency({ efficiency: parseFloat(str)/100 });
        }
    };

    formatWholePercent = (value) => {
        return formatPercent(value, 0);
    };

    render() {
        return (
            <BufferedInput
                name="minEfficiency"
                dataTest="min_efficiency"
                value={this.props.efficiency}
                filter={filterAsPercentage}
                format={this.formatWholePercent}
                onBufferedChange={this.onChange}
                clearOnFocus={true}
            />
        );
    }
}

export default databind(MinEfficiency);
