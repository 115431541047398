import { actions as projectActions } from 'redux/modules/projects';
import projectApi from 'api/projects';
import { actions as authActions } from 'redux/modules/auth';
import { actions as notificationActions } from 'redux/modules/notification';
import { makeAction, createReducer } from '../utils/redux-helpers';
import partition from 'lodash/partition';
import forEach from 'lodash/forEach';
import localforage from 'localforage';
import moment from 'moment';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

export const LocalProjectStatus = {
    READY_TO_SYNC: 'READY',
    SYNCING: 'SYNCING',
    SYNC_FAILED: 'FAILED',
    SYNC_CONFLICT: 'CONFLICT',
    SYNC_COMPLETE: 'COMPLETE'
};

export const SyncFailureStatuses = new Set([LocalProjectStatus.SYNC_FAILED, LocalProjectStatus.SYNC_CONFLICT]);

function prefix(type) { return `offline/${type}`; }

function syncLocalProject(project, onlineProjects = []) {
    const existingNames = new Set();
    let existingProject;

    onlineProjects.forEach(p => {
        existingNames.add(p.name);

        if (p.id === project.id) {
            existingProject = p;
        }
    });

    if (project.wasOnlineProject && existingProject &&
        moment(existingProject.utcUpdatedAt).isAfter(project.updatedAt)) {
        return Promise.resolve({ ...project, syncStatus: LocalProjectStatus.SYNC_CONFLICT });
    }

    // eslint-disable-next-line no-unused-vars
    let { wasOnlineProject, isLocalProject, syncStatus, ...newProject } = project;

    if (project.wasOnlineProject) {
        return projectApi.updateById(project.id, newProject)
            .then(() => {
                return { ...newProject, syncStatus: LocalProjectStatus.SYNC_COMPLETE };
            })
            .catch(() => {
                return Promise.resolve({
                    ...project,
                    name: newProject.name,
                    syncStatus: LocalProjectStatus.SYNC_FAILED
                });
            });
    }

    let iterator = 0;

    while (existingNames.has(newProject.name)) {
        iterator += 1;
        newProject.name = `${newProject.name}-${iterator}`;
    }

    return projectApi.createNew(newProject)
        .then(savedProject => {
            return { ...newProject, newId: savedProject.requestid, syncStatus: LocalProjectStatus.SYNC_COMPLETE };
        })
        .catch(() => {
            return Promise.resolve({ ...project, name: newProject.name, syncStatus: LocalProjectStatus.SYNC_FAILED });
        });
}

export const actionTypes = {
    RESET: prefix('RESET'),
    TOGGLE_OFFLINE_MODE: prefix('TOGGLE_OFFLINE_MODE'),
    TOGGLE_PREPARING: prefix('TOGGLE_PREPARING'),
    TOGGLE_READY: prefix('TOGGLE_READY'),
    ERROR: prefix('ERROR')
};

export const actions = {
    refreshOfflineData: () => async dispatch => {
        dispatch(actions.togglePreparing());

        window.sendMessage && window.sendMessage({ command: 'refreshData' }).then((result) => {
            dispatch(actions.toggleReady(result.ready));
        }).catch((e) => {
            dispatch(actions.error(e));
        });
    },
    toggleServiceWorkerOffline: (offline = true) => async dispatch => {
        try {
            await localforage.setItem('swOffline', offline);
            dispatch(actions.toggleOfflineMode(offline));
        }
        catch (err) {
            dispatch(actions.error(err));
        }

        try {
            await dispatch(authActions.checkIsSignedInNow());
        }
        catch (err) {
            // caught and discarding rejection as not signed in rejection
            return await dispatch(projectActions.refreshProjects());
        }

        await dispatch(projectActions.refreshProjects());

        try {
            !offline && dispatch(actions.syncOfflineProjects());
        }
        catch (err) {
            dispatch(actions.error(err));
        }
    },
    toggleReady: (ready = true) => async dispatch => {
        if (ready) {
            const isOffline = await localforage.getItem('swOffline');
            dispatch(actions.toggleOfflineMode(isOffline));
        }

        return dispatch(makeAction(actionTypes.TOGGLE_READY, ready));
    },
    retrySync: (id) => async (dispatch, getState) => {
        const state = getState();

        if (state.offline.isOffline) {
            return;
        }

        dispatch(actions.togglePreparing(true));

        const project = await projectApi.getLocalProjectById(id);
        await localforage.setItem(`project/${id}`, { ...project, syncStatus: LocalProjectStatus.SYNCING });
        dispatch(projectActions.refreshLocalProjects());

        const onlineProjects = state.projects.listing.filter(p => !p.isLocalProject);
        const result = await syncLocalProject({ ...project, syncStatus: LocalProjectStatus.SYNCING }, onlineProjects);

        await localforage.setItem(`project/${id}`, result);
        dispatch(projectActions.refreshLocalProjects());
        dispatch(actions.togglePreparing(false));

        if (!SyncFailureStatuses.has(result.syncStatus)) {
            setTimeout(() => dispatch(projectActions.refreshProjects()), 3000);

            const localProjectIds = await projectApi.getLocalProjectIds();
            await localforage.setItem('localProjectIds', localProjectIds.filter(pId => pId !== id));
        }
    },
    syncOfflineProjects: () => async (dispatch, getState) => {
        const state = getState();

        if (state.offline.isOffline) {
            return;
        }

        const projects = await projectApi.getLocalProjects();

        if (projects.length === 0) {
            return;
        }

        const onlineProjects = state.projects.listing.filter(p => !p.isLocalProject);
        dispatch(actions.togglePreparing(true));

        const localProjects = projects.map(p => ({ ...p, syncStatus: LocalProjectStatus.SYNCING }));
        await Promise.all(localProjects.map(p => {
            return localforage.setItem(`project/${p.id}`, p);
        }));
        dispatch(projectActions.refreshLocalProjects());

        const results = await Promise.all(localProjects.map(p => syncLocalProject(p, onlineProjects)));

        await Promise.all(results.map(p => {
            return localforage.setItem(`project/${p.id}`, p);
        }));

        await dispatch(projectActions.refreshLocalProjects());
        dispatch(actions.togglePreparing(false));

        // After refreshing local projects above, this updates local storage to hold just the failed projects
        // and then dispatches a full refresh on a 1 second timer so that users can see a Sync Success message
        // on local project rows before they change to online projects. If all projects failed then don't bother
        // refreshing the full project list.
        const [failedProjects, successfulProjects] = partition(results, p => SyncFailureStatuses.has(p.syncStatus));

        forEach(successfulProjects, p => {
            const currentProject = state.projects.current;

            if (p.id === currentProject.id) {
                dispatch(projectActions.setCurrentProjectSummary({
                    ...currentProject,
                    id: p.newId || currentProject.id,
                    isLocalProject: false
                }));
            }
        });

        await Promise.all([localforage.setItem('localProjectIds', failedProjects.map(p => p.id)),
            ...successfulProjects.map(p => localforage.removeItem(`project/${p.id}`))]);

        if (failedProjects.length > 0) {
            dispatch(notificationActions.showError(getText(contentKeys.SYNCING_PROJECTS_FAILED, failedProjects.length),
                false, { url: 'projects', text: 'Review Project Issues' }));
        }

        if (failedProjects.length !== results.length) {
            setTimeout(() => dispatch(projectActions.refreshProjects()), 3000);
        }
    },
    resolveConflict: (projectId, type) => async dispatch => {
        if (type === 'KEEP_ONLINE') {
            await localforage.removeItem(`project/${projectId}`);

            const localProjectIds = await localforage.getItem('localProjectIds');
            await localforage.setItem('localProjectIds', localProjectIds.filter(pId => pId !== projectId));

            return dispatch(projectActions.refreshLocalProjects());
        }
        else if (type === 'KEEP_OFFLINE') {
            const project = await localforage.getItem(`project/${projectId}`);

            try {
                await projectApi.updateById(projectId, project);
            }
            catch (err) {
                return dispatch(projectActions.error(err.message));
            }

            await localforage.removeItem(`project/${projectId}`);

            const localProjectIds = await localforage.getItem('localProjectIds');
            await localforage.setItem('localProjectIds', localProjectIds.filter(pId => pId !== projectId));

            return dispatch(projectActions.refreshProjects());
        }
        else if (type === 'KEEP_COPY') {
            // eslint-disable-next-line no-unused-vars
            const { id, ...project } = await localforage.getItem(`project/${projectId}`);

            try {
                await projectApi.createNew({ ...project, name: project.name + ' copy' });
            }
            catch (err) {
                return dispatch(projectActions.error(err.message));
            }

            await localforage.removeItem(`project/${projectId}`);

            const localProjectIds = await localforage.getItem('localProjectIds');
            await localforage.setItem('localProjectIds', localProjectIds.filter(pId => pId !== projectId));

            return dispatch(projectActions.refreshProjects());
        }
    },
    reset: () => makeAction(actionTypes.RESET),
    toggleOfflineMode: (offline = true) => makeAction(actionTypes.TOGGLE_OFFLINE_MODE, !!offline),
    togglePreparing: (preparing = true) => makeAction(actionTypes.TOGGLE_PREPARING, preparing),
    error: (err) => makeAction(actionTypes.ERROR, err)
};

const ACTION_HANDLERS = {
    [actionTypes.RESET]: () => initialState,
    [actionTypes.ERROR]: (state, { payload }) => {
        return { ...state, error: payload, preparingOffline: false, offlineReady: false };
    },
    [actionTypes.TOGGLE_OFFLINE_MODE]: (state, { payload }) => {
        return { ...state, isOffline: payload, error: null };
    },
    [actionTypes.TOGGLE_PREPARING]: (state, { payload }) => {
        return { ...state, preparingOffline: payload, error: null };
    },
    [actionTypes.TOGGLE_READY]: (state, { payload }) => {
        return { ...state, offlineReady: payload, preparingOffline: false, error: null };
    }
};

const initialState = {
    error: null,
    isOffline: false,
    preparingOffline: true,
    offlineReady: false
};

export default createReducer(initialState, ACTION_HANDLERS);
