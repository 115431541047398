import PropTypes from 'prop-types';
import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import Error from 'components/Error';

const HandleValidationErrors = (props) => {
    let content;
    if (props.error) {
        const stub = props.errorMessage.substr(0, 6);
        const plainMessage = (stub === "Error:")
            ? props.errorMessage.substr(7) : props.errorMessage;
        content = (<Row><Error>{plainMessage}</Error></Row>);
    }
    else {
        content = props.children;
    }

    return (<div>{content}</div>);
};

HandleValidationErrors.propTypes = {
    children: PropTypes.node.isRequired,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired
};

export default HandleValidationErrors;
