export function getValue(name, defaultValue) {
    try {
        const s = localStorage.getItem(name);
        if (s) {
            return JSON.parse(s);
        }
    }
    catch (e) { }

    return defaultValue;
}

export function setValue(name, value) {
    try {
        const s = JSON.stringify(value);
        localStorage.setItem(name, s);
    }
    catch (e) { }
}

export default class PersistentValue {
    constructor(name, defaultValue) {
        this._name = name;
        this._defaultValue = defaultValue;
    }

    get value() { return getValue(this._name, this._defaultValue); }
    set value(v) { setValue(this._name, v); }
}
