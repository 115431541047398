import css from './MultiSelectDropDownList.less';

(function ($) {
    function stopScroll(element) {
        var activeElement;

        $(document).bind('mousewheel DOMMouseScroll', function(e) {
            var scrollTo = null;

            if (!$(activeElement).closest(".k-popup").length) {
                return;
            }

            if (e.type === 'mousewheel') {
                scrollTo = (e.originalEvent.wheelDelta * -1);
            }
            else if (e.type === 'DOMMouseScroll') {
                scrollTo = 40 * e.originalEvent.detail;
            }

            if (scrollTo) {
                e.preventDefault();
                element.scrollTop(scrollTo + element.scrollTop());
            }
        });

        $(document).on('mouseover', function(e) {
            activeElement = e.target;
        });
    }

    const MultiSelectDropDownList = window.kendo.ui.DropDownList.extend({

        init: function (element, options) {
            const widget = this;
            // setup template to include a checkbox
            $(element).addClass(css.multiSelectDropDownList);

            const prepend = options.prepend || "";
            options.template = kendo.template(
                kendo.format('<span class="msddlabel" data-value="#= {1} #"/>'+prepend+'#= {2} #</span>',
                    element.id + "_option_" + options.dataValueField,
                    options.dataValueField,
                    options.dataTextField
                )
            );

            // do not let the base widget manage values
            const values = options.value;
            options.value = undefined;

            // create drop down UI
            window.kendo.ui.DropDownList.fn.init.call(widget, element, options);

            widget.value(values);

            if (!options.selectionLogic) {
                throw new Error('no selection logic function supplied in MultiSelectDropDownList');
            }
            this.applySelectionLogic = options.selectionLogic;

            widget.ul.addClass(css.multiSelectDropDownListPopup);
            if (options.popupClass) {
                widget.list.addClass(options.popupClass);
            }

            const changeHandler = function() {
                const renderedValues = widget.ul.find("." + css.selected)
                    .map(function () { return $(this).find('span').attr('data-value'); })
                    .toArray().sort();

                renderedValues.sort();
                widget.selectedValues.sort();

                if (renderedValues < widget.selectedValues || renderedValues > widget.selectedValues) {
                    widget.refresh();
                    // widget.trigger("change", {});
                    if (options.onChange) {
                        options.onChange(widget.selectedValues, widget);
                    }
                }
            };

            widget.bind('select', changeHandler);

            stopScroll(widget.ul.parent());
        },
        options: {
            name: "MultiSelectDropDownList"
        },
        selectedValues: [],
        updateSelectedValues: function(selection) {
            this.selectedValues = this.applySelectionLogic(this.selectedValues, selection).sort();
        },
        value: function(values) {
            if (values === undefined) {
                return this.selectedValues;
            }
            else {
                this.selectedValues = values;
                this.refresh();
            }
        },
        refresh: function() {
            this.ul.find('li').removeClass(css.selected);
            this.selectedValues.forEach((value) => {
                const selector = 'li:has(span[data-value="' + value + '"])';
                const li = this.ul.find(selector);
                li.addClass(css.selected);
            });
            this._setText();
        },
        _change: function() {
            // this.trigger("change", {});
        },
        _select: function(li) {
            const $span = $(li).find('span.msddlabel');
            if ($span.length === 1) {
                const selectedValue = $span.attr("data-value");
                if ($(li).hasClass(css.selected)) {
                    this.updateSelectedValues(selectedValue);
                }
                else {
                    this.updateSelectedValues(selectedValue);
                }
                this.trigger('select', {});
            }

            return this.listView.select([]);
        }, // kills highlighting behavior
        _blur: function () {
        }, // kills popup-close-on-click behavior
        _setText: function () { // set text based on selections
            const text = this.ul.find("." + css.selected)
                .map(function () { return $(this).text(); })
                .toArray()
                .slice(0, this.options.limitSummary);
            this.text(text.toString().replace(/,/g, ', '));
        }
    });

    window.kendo.ui.plugin(MultiSelectDropDownList);
})(jQuery);
