import {makeAction, createReducer} from '../utils/redux-helpers';
import {partsDbInitialState as initialState} from '../initialStates';
import {groupParts} from 'logic/matching';
import loadPartsDb from 'api/loadPartsDb';

function prefix (type) { return `partsDb/${type}`; }

export const actionTypes = {
    FETCH_DATA: prefix('FETCH_DATA'),
    LOAD_DATA: prefix('LOAD_DATA'),
    RECEIVE_DATA: prefix('RECEIVE_DATA'),
    RECEIVE_ERROR: prefix('RECEIVE_ERROR')
};

function mapSmBusAddressToPmBusAddressArray(parts) {
    return parts.map((part) => {
        // correcting pmbusAddress mis-naming here rather than propagating through the app
        const { smbusAddress, ...others } = part;
        const pmbusAddressList = (smbusAddress !== "") ? smbusAddress.split(',') : [];
        return { ...others, pmbusAddressList };
    });
}

export const actions = {
    fetchData: () => {
        return (dispatch) => {
            dispatch(actions.loadData());
            return loadPartsDb()
                .then((results) => dispatch(actions.receiveData(results)))
                .catch((message) => dispatch(actions.receiveError(message)));
        };
    },
    loadData: () => makeAction(actionTypes.LOAD_DATA),
    receiveData: (data) => makeAction(actionTypes.RECEIVE_DATA, data),
    receiveError: (data) => makeAction(actionTypes.RECEIVE_ERROR, data)
};

const ACTION_HANDLERS = {
    [actionTypes.LOAD_DATA]: (state) => {
        return { ...state, requested: true, received: false, error: false, data: {}, version: "" };
    },
    [actionTypes.RECEIVE_DATA]: (state, {payload}) => {
        const version = payload.revision;
        const parts = mapSmBusAddressToPmBusAddressArray(payload.parts);
        const data = groupParts(parts);
        return { ...state, requested: false, received: true, error: false, data, version };
    },
    [actionTypes.RECEIVE_ERROR]: (state, {payload}) => {
        return { ...state, requested: false, received: false, error: true, message: payload };
    }
};

export default createReducer(initialState, ACTION_HANDLERS);
