/* global store */
import PropTypes from 'prop-types';

import React from 'react';
import {Row} from 'bootstrap-core.js';
import classNames from 'classnames';
import Shepherd from 'tether-shepherd';
import 'tether-shepherd/dist/css/shepherd-theme-arrows.css';
import DocumentTitle from 'components/DocumentTitle';
import InputRails from 'components/Editor/InputRails';
import SystemOutputs from 'components/Editor/SystemOutputs';
import css from './styles.less';
import ConfirmDeleteModal from 'components/Editor/ConfirmDeleteModal';
import TemplateWarningModal from 'components/Editor/TemplateWarningModal';
import ActionButton from 'components/ActionButton';
import databind from 'redux/utils/databind';
import {actions as editorActions} from 'redux/modules/editor';
import DiagramEditView from 'components/Editor/DiagramEditView';
import BlockDiagramDataSource from 'redux/blockDiagramDataSource';
import UserPreferences from 'util/userPrefs';
import EditorSettings from 'components/Editor/EditorSettings';
import flowRight from 'lodash/flowRight';
import {WithPhone} from 'styles/variables/breakpoints';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';
import imageUrl from 'util/imageUrl';
import {
    waitTime,
    hasValidQuerystringParamsInCurrentCycle,
    getConfigTriggerFindSolutions,
} from 'redux/modules/startOptions';

class EditorView extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        findSolutions: PropTypes.func.isRequired,
        toggleDiagram: PropTypes.func.isRequired,
        editorIsValid: PropTypes.bool.isRequired,
        showDiagram: PropTypes.bool,
        phoneMatch: PropTypes.bool,
        templateWarning: PropTypes.string
    };

    static databind({editor}) {
        let editorIsValid = editor.inputs.reduce((valid, input) => valid && !input.invalid, true);
        editorIsValid = editor.outputs.reduce((valid, output) => valid && !output.invalid, editorIsValid);
        return { editorIsValid, showDiagram: editor.showDiagram, templateWarning: editor.templateWarning };
    }

    static actions = {
        findSolutions: editorActions.findSolutions,
        toggleDiagram: editorActions.toggleDiagram
    };

    constructor(props) {
        super(props);
        this.diagramDataSource = null;
        this.state = {
            messageVisible: !UserPreferences.seenCharsWarning
        };
    }

    dismissMessage = () => {
        this.setState({ messageVisible: false });
        UserPreferences.seenCharsWarning = true;
    }

    componentWillMount() {
        this.diagramDataSource = new BlockDiagramDataSource(window.store);
    };

    componentWillUnmount() {
        this.diagramDataSource.unsubscribe();
        this.diagramDataSource = null;

        if (this._tour) {
            this._destroyTour = true;
            this._tour.cancel();
        }
    };

    componentDidMount() {
        if (!this.props.templateWarning && // modals don't play well with Shepherd
            (!UserPreferences.seenDiagramFeature || !UserPreferences.seenSavedProjectsFeature)) {
            window.scrollTo(0, 0);

            const tour = new Shepherd.Tour({
                defaults: {
                    classes: 'shepherd-theme-arrows',
                    tetherOptions: {
                        targetOffset: '15px 0',
                        optimizations: {
                            moveElement: false
                        },
                        constraints: [{
                            to: 'scrollParent',
                            pin: false
                        }]
                    },
                    showCancelLink: true,
                    when: {
                        hide: () => {
                            if (!this._destroyTour) {
                                UserPreferences.seenDiagramFeature = true;
                                UserPreferences.seenSavedProjectsFeature = true;
                            }
                        }
                    }
                }
            });

            const phoneOptions = this.props.phoneMatch ? {
                targetOffset: '5px 0',
                constraints: [{
                    to: 'scrollParent',
                    attachment: 'together',
                    pin: true
                }]
            } : {};

            if (!UserPreferences.seenDiagramFeature) {
                tour.addStep('block-diagram', {
                    tetherOptions: {...tour.options.defaults.tetherOptions, ...phoneOptions},
                    text: getText(contentKeys.YOU_CAN_SEE_AND),
                    attachTo: '.new-feature-diagram bottom',
                    buttons: [
                        { classes: 'shepherd-close', text: getText(contentKeys.CLOSE), action: () => tour.cancel() },
                        { text: getText(contentKeys.NEXT), action: () => tour.next() }
                    ]
                });
            }

            if (!UserPreferences.seenSavedProjectsFeature) {
                const targetSave = $('.new-feature-save')[0];
                tour.addStep('save-projects', {
                    tetherOptions: {...tour.options.defaults.tetherOptions, ...phoneOptions},
                    text: getText(contentKeys.YOU_CAN_SAVE_THE),
                    beforeShowPromise: () => {
                        if (!$('.new-feature-save')[0]) {
                            tour.getCurrentStep().options.attachTo = {
                                element: $('.moreItemsContainer')[0],
                                on: 'bottom'
                            };
                        }

                        return Promise.resolve();
                    },
                    attachTo: {element: targetSave, on: 'bottom'},
                    buttons: [
                        { classes: 'shepherd-close', text: getText(contentKeys.CLOSE), action: () => tour.cancel() },
                        { text: getText(contentKeys.NEXT), action: () => tour.next() }
                    ]
                });
                const targetSend = $('.new-feature-send')[0];
                tour.addStep('send-projects', {
                    attachTo: {element: targetSend, on: 'bottom'},
                    beforeShowPromise: () => {
                        if (!$('.new-feature-send')[0]) {
                            tour.getCurrentStep().options.attachTo = {
                                element: $('.moreItemsContainer')[0],
                                on: 'bottom'
                            };
                        }

                        return Promise.resolve();
                    },
                    tetherOptions: {...tour.options.defaults.tetherOptions, ...phoneOptions},
                    text: getText(contentKeys.ONCE_YOU_SAVE_THE),
                    buttons: [
                        { text: 'OK', action: () => tour.next() }
                    ]
                });
            }

            tour.start();

            this._tour = tour;
        }

        if (hasValidQuerystringParamsInCurrentCycle()) {
            // Trigger clicking the `Find Solutions` button
            // depending on URL configuration parameter passed.
            if (getConfigTriggerFindSolutions() === true) {
                setTimeout(() => {
                    this.props.findSolutions();
                }, waitTime);
            }
        }
    }

    showTable = () => {
        this.props.toggleDiagram(false);
    };

    showDiagram = () => {
        this.props.toggleDiagram(true);
    };

    onClick = (e) => {
        this.props.findSolutions();
    };

    editorTable = (isVisible) => {
        const style = isVisible ? {} : { height: 0, overflow: 'hidden', visibility: 'hidden' };

        return (
            <div className={classNames("content-padding", css.editorContainer, 'row')} style={style}>
                <div className={css.inputsContainer}>
                    <h3 className={css.tableTitle}>{getText(contentKeys.SYSTEM_INPUT_RAILS)}</h3>
                    <InputRails className="eInputRails" />
                </div>
                <div className={css.noPaddingMobile}>
                    <SystemOutputs headerSettingsClassName={css.outputsHeaderSettings} />
                </div>
            </div>
        );
    };

    viewToggleButtons = () => {
        const { showDiagram } = this.props;
        const tableBtnClass = showDiagram ? css.toggleBtn : classNames(css.active, css.toggleBtn);
        const diagramBtnClass = showDiagram ? classNames(css.active, css.toggleBtn) : css.toggleBtn;

        return (
            <div className={classNames(css.viewToggleBtns, 'new-feature-diagram')}>
                <div className={tableBtnClass} onClick={this.showTable}>
                    <i className={classNames(css.toggleIcon, 'icon icon-table')} />
                    {getText(contentKeys.TABLE)}
                </div>
                <div className={diagramBtnClass} onClick={this.showDiagram}>
                    <i className={classNames(css.toggleIcon, 'icon icon-diagram')} />
                    {getText(contentKeys.DIAGRAM)}
                </div>
            </div>
        );
    };

    render() {
        const { showDiagram } = this.props;
        const messageVisible = !UserPreferences.seenCharsWarning;

        return (
            <DocumentTitle title="Multi-Load Configurator Editor">
                <div className={classNames(css.editor, {[css.diagramView]: showDiagram})}>
                    {messageVisible && <div className={css.onlyAlphaAz}>
                        <div className={css.messageWrapper}>
                            <img src={imageUrl("search_error_attention.png")} />
                            <span>{getText(contentKeys.ONLY_ALPHA_NUMERIC_AZ)}</span>
                            <i className="fa fa-close" onClick={this.dismissMessage} />
                        </div>
                    </div>}
                    <div className={classNames("content-padding row", css.tabsContainer)}>
                        <div className={classNames(css.noPadding, css.editorTitle, "container-par parsys")}>
                            <h2>{getText(contentKeys.INPUTS_AND_OUTPUTS)}</h2>
                        </div>
                        <div className={classNames(css.noPadding, "container-par parsys", css.settingsContainer)}>
                            {this.viewToggleButtons()}
                            <EditorSettings className={css.editorSettings} />
                        </div>
                        {
                            showDiagram &&
                                (<div className={css.diagramInstructions}>
                                    {getText(contentKeys.SELECT_A_BLOCK_TO)}
                                </div>)
                        }
                    </div>
                    <DiagramEditView dataSource={this.diagramDataSource}
                        isVisible={showDiagram}
                        diagramSettingsClassName={css.diagramSettings}
                    />
                    {this.editorTable(!showDiagram)}
                    <Row id="findSolutionsContainer" className={css.actionButtonContainer}>
                        <ActionButton
                            id="Editor_FindSolutions"
                            label={getText(contentKeys.FIND_SOLUTIONS)}
                            className={classNames(css.findSolutions, 'large')}
                            testHook="find_solutions"
                            disabled={!this.props.editorIsValid}
                            onClick={this.onClick}
                        />
                    </Row>
                    {this.props.children}
                    <ConfirmDeleteModal />
                    <TemplateWarningModal />
                </div>

            </DocumentTitle>
        );
    }
}

export default flowRight([WithPhone, databind])(EditorView);
