import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import classNames from 'classnames';
import CompactProjectsList from './CompactProjectsList';
import makeSignInUrl from 'util/makeSignInUrl';
import makeReturnUrl from 'util/makeReturnUrl';
import KendoTooltipOverride from 'kendo-plugins/TooltipWrapper';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const noOp = () => {};

const SignInLink = () => (<div dangerouslySetInnerHTML={{__html:
    getText(contentKeys.PLEASE_SIGN_IN_TO,
        `<a href="${makeSignInUrl(makeReturnUrl('signedin'))}">`,
        `</a>`)}}>
</div>);

class SelectSavedStyles extends React.Component {
    static propTypes = {
        selected: PropTypes.bool,
        onSelect: PropTypes.func,
        isSignedIn: PropTypes.bool,
        isOffline: PropTypes.bool
    };

    componentDidMount() {
        KendoTooltipOverride($('#selectSavedTooltip'), getText(contentKeys.CREATE_A_NEW_PROJECT), null, 'bottom');
    }

    handleClick = () => {
        this.props.onSelect();
        document.getElementById('StartSelect_ContinueBtn').scrollIntoView();
    }

    render() {
        const checked = (this.props.selected) ? "checked" : "";
        const cursorPointer = (!this.props.selected) ? css.cursorPointer : "";
        const classes = classNames(css.openSavedProject, cursorPointer);

        return <div className={classes} onClick={this.handleClick}>
            <input type="radio"
                className="k-radio"
                name="startOptions"
                value="openSavedProject"
                checked={checked}
                onChange={noOp}
            />
            <label className="k-radio-label">
                &nbsp;&nbsp;{getText(contentKeys.OPEN_SAVED_PROJECT)}{' '}
                <span id="selectSavedTooltip" className="fa fa-info-circle" />
            </label>
            <div className={css.chooseProjectSubtitle}>{getText(contentKeys.CHOOSE_PROJECT)}:</div>
            {(this.props.isSignedIn || this.props.isOffline)
                ? <CompactProjectsList disabled={!this.props.selected} />
                : <SignInLink />}
        </div>;
    }
}

export default SelectSavedStyles;
