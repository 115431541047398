import find from 'lodash/find';

const isAtLeastOnePartSchematicEnabled = function(rails, selectedPartIds) {
    return rails.reduce((atLeastOne, rail) => {
        const selected = selectedPartIds[rail.id];
        const part = find(rail.items, (part) => part.id === selected);
        return (part) ? atLeastOne || part.orcadEnabled : atLeastOne;
    }, false);
};

export default isAtLeastOnePartSchematicEnabled;
