import PropTypes from 'prop-types';
import React from 'react';
import mapValuesToTextValuePairs from './helpers/mapValuesToTextValuePairs';
import Kendo from 'kendo-ui-react';
import {ResultOrderOptions, ResultOrderDirection} from 'logic/constants';
import css from './styles.less';
import omit from 'lodash/omit';
import classNames from 'classnames';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const isDirection = function(v) {
    return (v === ResultOrderDirection.ASCENDING) || (v === ResultOrderDirection.DESCENDING);
};

const isOrderBy = function(v) {
    return !isDirection(v);
};

class OrderResultsByItem extends React.Component {
    constructor() {
        super();
        this.lastSelected = {};
    }

    shouldComponentUpdate(nextProps) {
        return ((this.lastSelected.direction !== nextProps.selected.direction) ||
            (this.lastSelected.field !== nextProps.selected.field));
    }

    render() {
        // removing fields where there is insufficient data PC-203
        let validOrderOptions = {
            ...omit(ResultOrderOptions, [
                'LOWEST_BOM_COST',
                'LOWEST_BOM_COUNT',
                'SMALLEST_FOOTPRINT'
            ])
        };

        const data = [...mapValuesToTextValuePairs(validOrderOptions),
            ...mapValuesToTextValuePairs(ResultOrderDirection)];

        this.lastSelected = this.props.selected;

        const component = this;
        const options = {
            dataTextField: "text",
            dataValueField: "value",
            dataSource: data,
            value: [ this.props.selected.field, this.props.selected.direction ],
            prepend: '<span>#if ({2} === "Ascending") {#<i class="fa fa-sort-amount-asc"></i>&nbsp;&nbsp;#};'+
            'if ({2} === "Descending") {#<i class="fa fa-sort-amount-desc"></i>&nbsp;&nbsp;#}#</span>',
            onChange: function (value) {
                const selected = { field: value.filter(isOrderBy)[0], direction: value.filter(isDirection)[0] };
                component.lastSelected = selected;
                return component.props.onChange(selected);
            }
        };

        options.selectionLogic = function(values, selection) {
            const orderBy = values.filter(isOrderBy);
            const direction = values.filter(isDirection);
            return (isDirection(selection)) ? [...orderBy, selection] : [...direction, selection];
        };

        options.popupClass = classNames(css.orderResultsByListPopup, this.props.className);
        options.limitSummary = 1;

        return (
            <div className={classNames(css.panelItem, this.props.className)}>
                <span className={css.title}>{getText(contentKeys.ORDER_RESULTS_BY)}</span>
                <Kendo.MultiSelectDropDownList
                    className={css.panelMultiSelect}
                    options={options} />
            </div>);
    }
}

OrderResultsByItem.propTypes = {
    selected: PropTypes.objectOf(PropTypes.string),
    onChange: PropTypes.func,
    className: PropTypes.string
};

export default OrderResultsByItem;
