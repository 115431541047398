import PropTypes from 'prop-types';
import React from 'react';
import makeSignInUrl from 'util/makeSignInUrl';
import makeReturnUrl from 'util/makeReturnUrl';
import css from './styles.less';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const SignInLink = () => (<div dangerouslySetInnerHTML={{__html:
    getText(contentKeys.PLEASE_SIGN_IN_TO,
        `<a href="${makeSignInUrl(makeReturnUrl('signedin'))}">`,
        `</a>`)}}>
</div>);

const SignedInCheck = (props) => {
    return (props.isSignedIn)? <div>{props.children}</div>
        : (<div className={css.signInMessage}>
            <em><SignInLink /></em>
        </div>);
};

SignedInCheck.propTypes = {
    children: PropTypes.node,
    isSignedIn: PropTypes.bool
};

export default SignedInCheck;
