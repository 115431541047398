import {Topology} from './suggestTopology';

export const ColorWeight = {
    MEETS_SPEC: 2,
    NEAR_SPEC: 1,
    BELOW_SPEC: 0
};

/*
 * */
export function meetsSpec(design, part, ioutMult) {
    let meets = true;
    meets = meets &&(part.topology === design.topology);
    meets = meets && (part.numOutputs === design.numOutputs);
    meets = meets && (part.vinMin <= design.vinMin);
    meets = meets && (part.vinMax >= design.vinMax);

    if (design.powerGoodOutput) {
        meets = meets && (part.pGood === design.powerGoodOutput);
    }
    if (design.enablePinInput) {
        meets = meets && (part.enablePin === design.enablePinInput);
    }
    if (!design.ldo) {
        if (design.syncInput) {
            meets = meets && (part.syncIn === design.syncInput);
        }
        if (design.syncOutput) {
            meets = meets && (part.syncOut === design.syncOutput);
        }
    }

    meets = meets && part.outputs.reduce((a, p, i) => {
        let d = design.outputs[i];
        a = a && (p.voutMin <= d.voutMin);
        a = a && (p.voutMax >= d.voutMax);
        a = a && (p.ioutMax >= d.ioutMax);
        a = a && (p.ioutMax <= d.ioutMax*ioutMult);
        return a;
    }, meets);

    return meets;
}

// not needed for MLC is turns out but it is needed for
// later projct stages and our tests use it so leave this here.
export function nearSpec(design, part) {
    let near = true;
    near = near && (part.topology === design.topology);
    near = near && (part.numOutputs === (design.numOutputs+1));
    near = near && (part.vinMin <= (design.vinMin*1.25));
    near = near && (part.vinMax >= (design.vinMax*0.75));

    near = near && part.outputs.reduce((a, p, i) => {
        let d = design.outputs[i];
        a = a && (p.voutMin <= d.voutMin*1.25);
        a = a && (p.voutMax >= d.voutMax*0.75);
        a = a && (p.ioutMax >= d.ioutMax*0.75);
        a = a && (p.ioutMax <= d.ioutMax);
        return a;
    }, near);

    return near;
}

export function computeColorWeight(design, part) {
    const IOUT_MAX_MULTIPLIER = 5;
    if (meetsSpec(design, part, IOUT_MAX_MULTIPLIER)) return ColorWeight.MEETS_SPEC;
    if (nearSpec(design, part)) return ColorWeight.NEAR_SPEC;
    return ColorWeight.BELOW_SPEC;
}

export function ioutMaxMultiplier(topology, ioutMax) {
    const MULTI_OUTPUT = true; // always true for the MLC use case, added to fully reflect spreadsheet logic
    if (ioutMax < 0.1) return (1 / ioutMax);
    switch (topology) {
        case Topology.BUCK_MULTIPHASE_CONTROLLER:
        case Topology.BUCK_DIGITAL_CONTROLLER:
        case Topology.BUCK_CONTROLLER:
        case Topology.BUCK_BOOST_CONTROLLER:
            return 200;
        case Topology.BUCK_POWER_MODULE:
        case Topology.BUCK_BOOST_FET:
            return 5;
        case Topology.BUCK_DIGITAL_POWER_MODULE:
        case Topology.BUCK_DIGITAL_FET:
            return 10;
    }
    if (MULTI_OUTPUT) return 5;
    return 2;
}
