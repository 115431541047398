import PropTypes from 'prop-types';
import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import css from './styles.less';
import StartNewProject from './StartNewProject';
import SelectProjectTemplate from './SelectProjectTemplate';
import databind from 'redux/utils/databind';
import { actions, selectors } from 'redux/modules/startOptions';
import { actions as authActions, selectors as authSelectors } from 'redux/modules/auth';
import classNames from 'classnames';
import SelectSavedProjects from './SelectSavedProjects';

class SelectStartOptions extends React.Component {
    componentWillMount() {
        this.props.checkIsSignedIn();
    }

    render() {
        return (
            <div className={css.rowWrapper}>
                <Row className={css.startOptionsContainer}>
                    <div className={css.noPadding}>
                        <StartNewProject
                            selected={this.props.startNewProject}
                            onSelect={this.props.onSelectStartNew}
                        />
                    </div>
                    <div className={classNames(css.noPadding, css.selectProjectTemplateContainer)}>
                        <SelectProjectTemplate
                            selected={this.props.useTemplate}
                            onSelect={this.props.onSelectUseTemplate}
                        />
                    </div>
                    <div className={classNames(css.noPadding, css.openSavedProjectsContainer)}>
                        <SelectSavedProjects
                            selected={this.props.useSavedProject}
                            onSelect={this.props.onSelectSavedProjects}
                            isSignedIn={this.props.isSignedIn}
                            isOffline={this.props.isOffline}
                        />
                    </div>
                </Row>
            </div>
        );
    }
}

SelectStartOptions.databind = function ({ auth, startOptions, offline }) {
    return {
        startNewProject: selectors.startNewProject(startOptions),
        useTemplate: selectors.useTemplate(startOptions),
        useSavedProject: selectors.useSavedProject(startOptions),
        isSignedIn: authSelectors.isSignedIn(auth),
        isOffline: offline.isOffline
    };
};

SelectStartOptions.actions = {
    onSelectStartNew: actions.selectNewProject,
    onSelectUseTemplate: actions.selectUseTemplate,
    onSelectSavedProjects: actions.selectUseSavedProject,
    checkIsSignedIn: authActions.checkIsSignedInNow
};

SelectStartOptions.propTypes = {
    startNewProject: PropTypes.bool.isRequired,
    useTemplate: PropTypes.bool.isRequired,
    useSavedProject: PropTypes.bool.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    isOffline: PropTypes.bool.isRequired,
    onSelectStartNew: PropTypes.func.isRequired,
    onSelectUseTemplate: PropTypes.func.isRequired,
    onSelectSavedProjects: PropTypes.func.isRequired,
    checkIsSignedIn: PropTypes.func.isRequired
};

export default databind(SelectStartOptions);
