import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import classNames from 'classnames';
import Kendo from 'kendo-ui-react';
import databind from 'redux/utils/databind';
import { buildSourceRailList } from 'logic/editor';
import { actions as editorActions } from 'redux/modules/editor';
import { checkInvalidSupplySequence } from 'logic/editor.js';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const DEFAULT_SELECTION = null;
const computeData = (selected, sources) => {
    let data = [];
    if (selected === "") data.push({ text: getText(contentKeys.SELECT_ONE), value: DEFAULT_SELECTION });
    data.push(...sources);
    return data;
};

export class SourceRailSelect extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        sources: PropTypes.array.isRequired,
        selected: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    componentWillReceiveProps(nextProps) {
        // update drop down here because we want to avoid to be recreated;
        // render method is bypassed when sources and selected change
        const { sources, selected } = this.props;
        const dropDown = this.dropDownRef.$rootElement.data('kendoDropDownList');

        if (sources !== nextProps.sources || selected !== nextProps.selected) {
            const update = () => {
                dropDown.setDataSource(computeData(nextProps.selected, nextProps.sources));
                dropDown.value(nextProps.selected || DEFAULT_SELECTION);
            };

            requestAnimationFrame(update);
        }
    }

    getDropDownRef = (inst) => {
        this.dropDownRef = inst;
    };

    render() {
        const { className, selected, sources, onChange } = this.props;
        const classnames = classNames(css.sourceRail, className);
        const data = computeData(selected, sources);
        const dropDownOptions = {
            dataTextField: "text",
            dataValueField: "value",
            dataSource: data,
            value: selected,
            change: function (e) {
                this.close();
                return onChange(this.value());
            }
        };

        return (
            <div data-test="source_rail">
                <Kendo.DropDownList ref={this.getDropDownRef}
                    className={classnames} options={dropDownOptions} />
            </div>
        );
    }
}

class SourceRailSelectContainer extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        changeSourceRail: PropTypes.func.isRequired,
        outputs: PropTypes.array.isRequired,
        inputs: PropTypes.array.isRequired
    }
    static actions = {
        changeSourceRail: editorActions.changeSourceRail
    };
    static databind({ editor }, { index }) {
        const { inputs, outputs } = editor;
        return {
            outputs,
            inputs
        };
    }

    onSourceRailChange = (value) => {
        const supplySeq = this.props.outputs[this.props.index].supplySeq;
        const check = checkInvalidSupplySequence(this.props.outputs, this.props.index, parseInt(supplySeq));
        this.props.changeSourceRail({
            index: this.props.index,
            value: value,
            outputs: this.props.outputs,
            invalid: (check !== null)
        });
    };

    render() {
        const { inputs, outputs, index, ...rest } = this.props;// eslint-disable-line no-unused-vars
        const sources = buildSourceRailList(inputs, outputs, index);

        return (
            <SourceRailSelect
                onChange={this.onSourceRailChange}
                sources={sources}
                {...rest}
            />
        );
    }
}

export default databind(SourceRailSelectContainer);
