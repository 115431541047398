import {makeAction, createReducer} from '../utils/redux-helpers';

function prefix (type) { return `editor/${type}`; }
export const actionTypes = {
    CONFIRM_DELETE: prefix('CONFIRM_DELETE'),
    CLEAR_CONFIRM: prefix('CLEAR_CONFIRM'),
    ON_RADIO_CHANGE: prefix('ON_RADIO_CHANGE'),
};
export const actions = {
    confirmDelete: (data) => makeAction(actionTypes.CONFIRM_DELETE, data),
    clearConfirm: (data) => makeAction(actionTypes.CLEAR_CONFIRM, data),
    radioChange: (data) => makeAction(actionTypes.ON_RADIO_CHANGE, data)
};

const ACTION_HANDLERS = {
    [actionTypes.CONFIRM_DELETE]: (state, {payload}) => {
        const newState = {...state.confirmDelete,
            show: true,
            input: payload.input || state.confirmDelete.input,
            output: payload.output || state.confirmDelete.output,
            index: payload.index,
            id: payload.id || state.confirmDelete.id
        };
        return {...state, confirmDelete: newState};
    },
    [actionTypes.CLEAR_CONFIRM]: (state) => {
        const newState = {...state.confirmDelete,
            show: false,
            input: false,
            output: false,
            index: null,
            id: null,
            deleteConnections: false
        };
        return {...state, confirmDelete: newState};
    },
    [actionTypes.ON_RADIO_CHANGE]: (state, {payload}) => {
        return {...state, confirmDelete: {...state.confirmDelete, deleteConnections: payload.deleteConnections}};
    }
};

export const initialState = {
    confirmDelete: {
        show: false,
        input: false,
        output: false,
        index: null,
        id: null,
        deleteConnections: false
    }
};

export default createReducer(initialState, ACTION_HANDLERS);
