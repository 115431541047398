import PropTypes from 'prop-types';
import React from 'react';
import css from './styles.less';
import classNames from 'classnames';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

class DesignNameSelector extends React.Component {
    static propTypes = {
        availableDesigns: PropTypes.array.isRequired,
        selectedDesign: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        onDismiss: PropTypes.func.isRequired
    };

    render() {
        let listingWithTitle = (<div></div>);

        const clearButton = (<div className={css.clearButton}>
            <i className="fa fa-times" aria-hidden="true" onClick={this.props.onDismiss}></i>
        </div>);

        if (this.props.availableDesigns.length > 0) {
            let designsListing = null;

            const disabledClass = (this.props.disabled) ? css.disabledDesign : "";
            const disabledAttr = (this.props.disabled) ? "disabled" : "";

            if (this.props.availableDesigns.length === 1) {
                designsListing = (<div className={classNames(css.listing, css.single, disabledClass)}>
                    {clearButton}
                    {this.props.availableDesigns[0]}
                </div>);
            }
            else if (this.props.availableDesigns.length > 1) {
                const designNameItems = this.props.availableDesigns.map((designName) => {
                    const checked = (designName === this.props.selectedDesign) ? "checked" : "";
                    return (
                        <div key={designName}>
                            <input type="radio"
                                name="designSelector"
                                className="k-radio"
                                value={designName}
                                checked={checked}
                                disabled={disabledAttr}
                            />
                            <label onClick={this.props.onChange} htmlFor={designName} className="k-radio-label">
                              &nbsp;&nbsp;{designName}
                            </label>
                        </div>
                    );
                });
                designsListing = (<div
                    className={classNames(css.listing, css.multiple, disabledClass)}>
                    {clearButton}
                    {designNameItems}
                </div>);
            }

            listingWithTitle = (
                <div className={css.designNameSelector}>
                    <p className={classNames(css.templateMessage, disabledClass)}>
                        {getText(contentKeys.SELECTED_TEMPLATE)}:
                    </p>
                    {designsListing}
                </div>
            );
        }

        return listingWithTitle;
    }
}

export default DesignNameSelector;
