import {NUM_SAMPLES_PER_CURVE, MATCH_SCORE_LIMIT} from './constants';
import {calcLdoEfficiency, getDataPoints} from './efficiency';
import {resampleReferenceCurve, lookupFromReferenceCurve} from './resampling';
import {Topology} from './suggestTopology';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import isArray from 'lodash/isArray';
import fill from 'lodash/fill';
import find from 'lodash/find';
import keys from 'lodash/keys';
import findIndex from 'lodash/findIndex';
import {computePowerDissipationReferenceCurve} from "./powerDissipation";
import {computeJunctionTemperatureReferenceCurve} from "./junctionTemperature";
import {getReferenceCurvesForOutputAndPart} from './curveUtils';

export function computeDefaultLoadCurrents(amps) {
    return {
        lightLoad: 0.1*amps,
        typicalLoad: 0.5*amps,
        maxLoad: amps
    };
}

function buildReferenceObject(output, part, load) {
    return {
        topology: part.topology,
        numOutputs: part.numOutputs,
        numDevicesinSolution: part.numDevicesinSolution,
        vinMin: output.vinMin,
        vinMax: output.vinMax,
        outputs: [
            {
                voutMin: output.volts,
                voutMax: output.volts,
                ioutMax: load || output.amps
            }
        ]
    };
}

export function computeLdoEfficiencyCurve(referenceObj, loadRange) {
    let efficiency = [];
    efficiency.length = loadRange.length;
    const ldoEfficiencyValue = calcLdoEfficiency(referenceObj);
    fill(efficiency, ldoEfficiencyValue);
    return { y: efficiency, x: loadRange, fsw: null, mode: null };
}

export function calcScoreForCurveGeneration(referenceObj, dataPoint) {
    let score = 0.0;

    const refVin = (referenceObj.vinMax + referenceObj.vinMin) / 2;
    let ave = Math.abs(refVin - dataPoint.vin);

    ave += Math.abs(referenceObj.outputs[0].voutMax - dataPoint.vout);
    ave += Math.abs(referenceObj.outputs[0].ioutMax/referenceObj.numDevicesinSolution - dataPoint.iout);

    return score + (ave / 3.0);
}

export function computeEfficiencyCurveFromDataPoints(referenceObj, dataPoints, maxIout) {
    if (!isArray(dataPoints) || (dataPoints.length === 0)) return null;
    // calculate scores
    const scoredDataPoints = dataPoints.map((dataPoint, idx) => {
        return { ...dataPoint, score: calcScoreForCurveGeneration(referenceObj, dataPoint) };
    });

    // get the best matching data points and collect all datapoints with the same configuration
    const sorted = sortBy(scoredDataPoints, 'score');
    const bestMatchingDataPoint = sorted[0];

    // only accept a matching point if it is within our match limit
    if (bestMatchingDataPoint.score <= MATCH_SCORE_LIMIT) {
        let rawData = scoredDataPoints.filter(function(x) {
            return ((x.vin === bestMatchingDataPoint.vin) &&
            (x.vout === bestMatchingDataPoint.vout) &&
            (x.mode === bestMatchingDataPoint.mode) &&
            (x.fsw === bestMatchingDataPoint.fsw));
        });

        // sort and remove any duplicates
        rawData = sortBy(uniqBy(rawData, 'iout'), 'iout');

        // insert a data point a 0 amps if one does not exist
        if (rawData[0].iout !== 0) {
            const valueAtZeroA = (rawData[0].efficiency < 0.4) ? rawData[0].efficiency : 0.4;
            rawData = [{ iout: 0, efficiency: valueAtZeroA }, ...rawData];
        }

        const curve = { x: rawData.map((x) => x.iout), y: rawData.map((x) => x.efficiency) };
        let resampled = resampleReferenceCurve(curve,
            NUM_SAMPLES_PER_CURVE, maxIout, referenceObj.numDevicesinSolution);
        resampled.fsw = bestMatchingDataPoint.fsw;
        resampled.mode = bestMatchingDataPoint.mode;
        return resampled;
    }

    return null;
}

export function computeEfficiencyReferenceCurve(efficiencyDb, output, part) {
    const referenceObj = buildReferenceObject(output, part);
    const step = output.amps / (NUM_SAMPLES_PER_CURVE-1);
    if (referenceObj.topology === Topology.LINEAR_REGULATOR) {
        const loadRange = [ ...range(0, output.amps, step), output.amps ];
        return computeLdoEfficiencyCurve(referenceObj, loadRange);
    }
    else {
        const dataPoints = getDataPoints(efficiencyDb, part.deviceName, part.numOutputs);
        return computeEfficiencyCurveFromDataPoints(referenceObj, dataPoints, output.amps);
    }
}

export function computeEfficiencyAndThermalReferenceData(results, efficiencyDb, ambientTemp) {
    return results.map(function(output) {
        let parts = output.items.map(function(part) {
            const partData = { id: part.id, referenceCurves: {} };
            partData.referenceCurves.efficiency =
                computeEfficiencyReferenceCurve(efficiencyDb, output, part);
            partData.referenceCurves.powerDissipation =
                computePowerDissipationReferenceCurve(partData.referenceCurves.efficiency, output.volts);
            partData.referenceCurves.junctionTemp = computeJunctionTemperatureReferenceCurve(
                partData.referenceCurves.powerDissipation, part, ambientTemp);

            return partData;
        });

        return { id: output.id, parts };
    });
}

export function updateJunctionTempComputedData(computedData, results, ambientTemp) {
    return results.map((output, i) => {
        const computedOutput = computedData[i];
        return {
            ...computedOutput,
            parts: output.items.map((part, partIndex) => {
                const computedPart = computedOutput.parts[partIndex];
                return {
                    ...computedPart,
                    referenceCurves: {
                        ...computedPart.referenceCurves,
                        junctionTemp: computeJunctionTemperatureReferenceCurve(
                            computedPart.referenceCurves.powerDissipation,
                            part,
                            ambientTemp
                        )
                    }
                };
            })
        };
    });
}

export function buildDefaultSelectedPartIds(results) {
    let selectedPartIds = {};
    results.forEach((output) => {
        selectedPartIds[output.id] = (output.items.length > 0) ? output.items[0].id : null;
    });
    return selectedPartIds;
}

export function getPmbusAddressForPart(output, partId) {
    if (partId) {
        const selectedPart = find(output.items, (item) => partId === item.id);
        if (selectedPart.pmbusAddressList.length > 0) {
            return selectedPart.pmbusAddressList[0];
        }
    }
}

export function buildDefaultSelectedPmbusAddresses(results, selectedPartIds) {
    let selectedPmbusAddresses = {};
    results.forEach((output) => {
        selectedPmbusAddresses[output.id] = {};
        output.items.forEach((part) => {
            selectedPmbusAddresses[output.id][part.id] = getPmbusAddressForPart(output, part.id);
        });
    });
    return selectedPmbusAddresses;
}

export function buildDefaultLoadEfficienciesForEachOutput(results, loads, computedData) {
    let efficiencies = {};
    results.forEach(function(output, index) {
        let eff = {
            id: output.id,
            index,
            lightLoad: null,
            typicalLoad: null,
            maxLoad: null
        };

        const refCurve = getReferenceCurvesForOutputAndPart(output.id, null, computedData);
        if ((refCurve != null) && (refCurve.efficiency != null)) {
            const effCurve = refCurve.efficiency;
            eff.lightLoad = lookupFromReferenceCurve(effCurve, loads[output.id].lightLoad);
            eff.typicalLoad = lookupFromReferenceCurve(effCurve, loads[output.id].typicalLoad);
            eff.maxLoad = lookupFromReferenceCurve(effCurve, loads[output.id].maxLoad);
        }

        efficiencies[output.id] = eff;
    });
    return efficiencies;
}

export function buildDefaultLoadCurrentsForEachOutput(results) {
    let loadsForEachOutput = {};
    results.forEach(function(output, index) {
        const loads = computeDefaultLoadCurrents(output.amps);
        loadsForEachOutput[output.id] = {
            id: output.id,
            index,
            lightLoad: loads.lightLoad,
            typicalLoad: loads.typicalLoad,
            maxLoad: loads.maxLoad,
        };
    });
    return loadsForEachOutput;
}

export function getSelectedPmbusAddressesForSelectedParts(selectedPmbusAddresses, selectedPartIds) {
    const outputIds = keys(selectedPartIds);
    let selectedPmbusAddressesForSelectedParts = {};
    outputIds.forEach((outputId) => {
        const selectedPartId = selectedPartIds[outputId];
        const pmbusAddressesForOutput = selectedPmbusAddresses[outputId];
        if ((pmbusAddressesForOutput != null) && // as we could have cases where no parts are available
            (pmbusAddressesForOutput[selectedPartId] != null)) { // part should have a pmbus selection / be digital
            selectedPmbusAddressesForSelectedParts[outputId] = pmbusAddressesForOutput[selectedPartId];
        }
    });
    return selectedPmbusAddressesForSelectedParts;
}

export function formatDeviceName(part) {
    const numOutputs = part ? part.numOutputs : -1;
    let name = part ? part.deviceName : 'No Solutions';

    if (numOutputs !== -1) {
        let suffix = '';

        if (numOutputs === 4) {
            suffix = ' (Q)';
        }
        else if (numOutputs === 3) {
            suffix = ' (T)';
        }
        else if (numOutputs === 2) {
            suffix = ' (D)';
        }
        else if (numOutputs === 1) {
            suffix = ' (S)';
        }
        else {
            suffix = ` (CS${part.numDevicesinSolution})`;
        }

        name =`${name}${suffix}`;
    }

    return name;
}

export function getSelectedPartInfo(results, selectedPartIds, outputIndex, outputId) {
    const selectedId = selectedPartIds[outputId];
    let selectedIndex = findIndex(results[outputIndex].items, {id: selectedId});
    if (selectedIndex === -1) {
        selectedIndex = 0;
    }
    return { selectedId, selectedIndex };
}
