import PropTypes from 'prop-types';
import React from 'react';
import Kendo from 'kendo-ui-react';
import databind from 'redux/utils/databind';
import {actions as startOptionsActions, selectors} from 'redux/modules/startOptions';
import getText from 'util/translations';
import contentKeys from 'translations/contentKeys';

const PartsList = function(props) {
    const dataSource = props.partsForSelectedPlatform.map((obj) => obj.part);
    const enable = (props.useTemplate) && props.enablePartNumberSelection;

    const onSelectPart = function() {
        props.onSelectPart({ partNumber: this.value() });
    };

    return (
        <Kendo.DropDownList
            options={{
                dataSource,
                value: props.selectedPartNumber,
                change: onSelectPart,
                optionLabel: getText(contentKeys.SELECT_A_PART_NUMBER),
                enable,
                filter: 'contains',
                open: function (e) {
                    e.sender.optionLabel.hide();
                },
                select: function (e) {
                    document.getElementById('StartSelect_ContinueBtn').scrollIntoView();
                }
            }}
        />
    );
};

PartsList.propTypes = {
    partsForSelectedPlatform: PropTypes.array.isRequired,
    selectedPartNumber: PropTypes.string.isRequired,
    useTemplate: PropTypes.bool.isRequired,
    enablePartNumberSelection: PropTypes.bool.isRequired,
    onSelectPart: PropTypes.func.isRequired
};

PartsList.actions = {
    onSelectPart: startOptionsActions.onSelectPart
};

PartsList.databind = function({startOptions}) {
    const { partsForSelectedPlatform,
        selectedPartNumber,
        enablePartNumberSelection } = startOptions;
    return { partsForSelectedPlatform,
        selectedPartNumber,
        useTemplate: selectors.useTemplate(startOptions),
        enablePartNumberSelection };
};

export default databind(PartsList);
