import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import remove from 'lodash/remove';

function applyOrderBySorting(data, spec, sortResolveFn) {
    // using lodash as it likes pat.hs
    let sortedCopy = sortBy(data, 'msrp');
    if (spec.dir === "desc") {
        sortedCopy = sortedCopy.reverse();
    }

    const sortByOption = sortResolveFn || spec.field;
    sortedCopy = sortBy(sortedCopy, sortByOption);
    if (spec.dir === "desc") {
        sortedCopy = sortedCopy.reverse();
    }

    // ensure that items with falsy spec.field values appear at the end of the sorted list
    const nullItems = remove(sortedCopy, (item) => !get(item, spec.field));
    sortedCopy = [ ...sortedCopy, ...nullItems ];

    // copying back into the data array as this it not a plain js array
    // kendo has decorated it
    sortedCopy.forEach((item, idx) => {
        data[idx] = sortedCopy[idx];
    });
    return data;
};

export default applyOrderBySorting;
