import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import omit from 'lodash/omit';
import classNames from 'classnames';
import css from './styles.less';

const ActionButton = (props) => {
    const {label, testHook, className, disabled} = props;
    let classes = classNames(css.actionBtn, { [className]: !!className, [css.disabled]: disabled });

    const childProps = omit(props, ['label', 'testHook', 'showForwardNav', 'disabled', disabled ? 'onClick' : '']);

    return (
        <Button {...childProps} className={classes} data-test={testHook}>
            {label}{' '}{props.showForwardNav && <i className="fa fa-angle-double-right"></i>}
        </Button>
    );
};

ActionButton.propTypes = {
    label: PropTypes.string.isRequired,
    testHook: PropTypes.string,
    className: PropTypes.string,
    showForwardNav: PropTypes.bool,
    disabled: PropTypes.bool
};

export default ActionButton;
